import React from "react";
import AceptCancelModalBase from "../modals/AceptCancelModalBase";
import { T } from "../../utils/i18n-config"

interface UpdateStatusNotesModalProps {
  showModal: boolean;
  noteFindingId: number;
  newStatus: string;
  reference: string;
  handleAddNotesAndFindings: (noteFindingId: number, newStatus: string) => void;
  handleAddNotesAndFindingsCancel: () => void;
}

const UpdateStatusNotesModal = ({ showModal, noteFindingId, newStatus, reference, handleAddNotesAndFindingsCancel, handleAddNotesAndFindings }: UpdateStatusNotesModalProps) => {

  let action;
  let contentBody;
  switch (newStatus) {
    case "completed":
      action = T("the note as completed");
      break;
    case "approved":
      action = T("the note as approved");
      break;
    case "refused":
      action = T("the note as refused");
      break;
  }
  
  contentBody = T('Are you sure you want to mark {{theReference}}?', { theReference: action})

  return (
    <div>
      <AceptCancelModalBase
        showModal={showModal}
        title={T("Update Status")}
        size="lg"
        onAccept={() => handleAddNotesAndFindings(noteFindingId, newStatus)}
        onCancel={handleAddNotesAndFindingsCancel}>
        {contentBody}
      </AceptCancelModalBase>
    </div>
  );
};

export default UpdateStatusNotesModal;