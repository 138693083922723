

export function getWeb2pyAppName(): string {
  const pathname = window.location.pathname;
  const segments = pathname.split('/');
  if (segments.length > 1) {
    if (segments.length === 2 && segments[1] === "") {
      return "audix";
    }
    return segments[1];
  }
  throw new Error("Can't get web2py app name");
}


function getWeb2pyBaseUrl(): string {  
  if (window.location.origin.includes("localhost")) {
    return "/api";
  }
  return `${window.location.origin}/${getWeb2pyAppName()}`;
}


/**
 * Join the given parts into a URL.
 * @param parts - The parts to join.
 * @returns The joined URL.
 * @example
 * ```typescript
 * joinURL('path', 'to', 'resource'); // returns 'path/to/resource'
 * joinURL('path', 123, 'resource/'); // returns 'path/123/resource'
 * ```
 */
export function joinURL (...parts: any[]): string {
  return parts.map(part => String(part).replace(/\/$/, '').replace(/^\//, '')).join('/')
}

/**
 * Get the URL for the given path.
 * @param path - The path to get the URL for.
 * @returns The URL for the given path.
 */
export function getUrl (path: string): string {
  return joinURL(getWeb2pyBaseUrl(), path)
}
