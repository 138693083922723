import React from "react";
import AceptCancelModalBase from "../modals/AceptCancelModalBase";
import { T } from "../../utils/i18n-config"

interface UpdateStatusNotesAndFindingsModalProps {
  showModal: boolean;
  noteFindingId: number;
  newStatus: string;
  reference: string;
  handleAddNotesAndFindings: (noteFindingId: number, newStatus: string) => void;
  handleAddNotesAndFindingsCancel: () => void;
}

const UpdateStatusNotesAndFindingsModal = ({ showModal, noteFindingId, newStatus, reference, handleAddNotesAndFindingsCancel, handleAddNotesAndFindings }: UpdateStatusNotesAndFindingsModalProps) => {

  let action;
  let contentBody;
  switch (newStatus) {
    case "completed":
      action = reference === "notes" ? T("the note as completed") : T("the finding as completed");
      break;
    case "approved":
      action = reference === "notes" ? T("the note as approved") : T("the finding as approved");
      break;
    case "refused":
      action = reference === "notes" ? T("the note as refused") : T("the finding as refused");
      break;
  }
  
  contentBody = T('Are you sure you want to mark {{theReference}}?', { theReference: action})

  return (
    <div>
      <AceptCancelModalBase
        showModal={showModal}
        title={T("Update Status")}
        size="lg"
        onAccept={() => handleAddNotesAndFindings(noteFindingId, newStatus)}
        onCancel={handleAddNotesAndFindingsCancel}>
        {contentBody}
      </AceptCancelModalBase>
    </div>
  );
};

export default UpdateStatusNotesAndFindingsModal;