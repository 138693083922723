import { EngagementMaterialityReportRepository } from "../repository/EngagementMaterialityReportRepository"


export class EngagementMaterialityReportController{
  private _engagementMaterialityReportRepository: EngagementMaterialityReportRepository
 

  constructor() {  
    this._engagementMaterialityReportRepository = new EngagementMaterialityReportRepository()
   }

   async saveData(engagementID: number){
    const considerations = (document.getElementById('ctr-materialty-considerations') as HTMLInputElement).value
    const conclusions = (document.getElementById('ctr-materialty-conclusions') as HTMLInputElement).value
    const status = await this._engagementMaterialityReportRepository.saveData(engagementID, considerations, conclusions)
    if (status){
      window.htmlHelpers?.showToast('success', 'Data saved successfully', 'Success')
      window.menuService?.breadcrumbBackLevel()
    }
    else{
      window.htmlHelpers?.showToast('danger', 'There was an error saving the data', 'Error')
    }
  }
}
