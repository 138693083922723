import React, { useState, useEffect, useRef, CSSProperties } from 'react'
import DOMPurify from 'isomorphic-dompurify'
import { T } from "../../utils/i18n-config"
import { type Permissions } from '../../models/special-document/ElementBase'
import { ConditionalParagraphOfacElement } from "../../models/special-document/ConditionalParagraphOfacElement"
import ViewModeProps from './ViewModeProps'
import ViewModeBase from "./ViewModeBase"

const ConditionalParagraphOfacViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<ConditionalParagraphOfacElement>(iElement as ConditionalParagraphOfacElement).current
  const [selectedMessage, setSelectedMessage] = useState<string>("")
  const [showAlert, setShowAlert] = useState<boolean>(false)

  useEffect(() => {
    const hasReported = element.ofac_results.some(partner => partner.reported)
    const foundMessage = (hasReported) ? element.messageYes : element.messageNo
    setSelectedMessage(foundMessage)
    setShowAlert(hasReported)
  }, [])

  return (
    <ViewModeBase
      isEditable={false}
      permissions={element.permissions as Permissions}
    >
      {
        showAlert ? (
          <div className="alert border-0" role="alert" style={alertStyles}>
            <div className="d-flex gap-2 align-items-center">
              <i className="fa-solid fa-triangle-exclamation"></i>
              <strong>{T("OFAC Alert")}!</strong>
            </div>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedMessage) }} />
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedMessage) }} />
        )
      }
    </ViewModeBase>
  );
};

const alertStyles: CSSProperties = {
  backgroundColor: "#fbcdcd",
  color: "#8c0404"
};

export default ConditionalParagraphOfacViewMode;
