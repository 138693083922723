import { T } from "../../utils/i18n-config"
import { ElementArgs } from "./ElementArgs";
import { IElementBase, ElementBase } from "./ElementBase";

export class ProbabilityAnalysisElement extends ElementBase implements IElementBase {
  private _text_default: string;
  private _optionValueSelected: string;
  private _is_alert_warning: boolean = false;

  constructor(args:ElementArgs) {
    super(args);
    this._text_default = args.text_default ?? T("Add text here")
    this._optionValueSelected = args.optionValueSelected ? args.optionValueSelected as string : "Low";
    this._is_alert_warning = args.is_alert_warning ?? false;
  }
  
  get text_default(): string {
    return this._text_default;
  }
  set text_default(text: string) {
    this._text_default = text;
    this.args.text_default = text;
  }

  get optionValueSelected(): string {
    return this._optionValueSelected;
  }
  set optionValueSelected(optionValueSelected: string) {
    this._optionValueSelected = optionValueSelected;
    this.args.optionValueSelected = optionValueSelected;
  }

  get is_alert_warning(): boolean {
    return this._is_alert_warning;
  }
  
  set is_alert_warning(is_alert_warning: boolean) {
    this._is_alert_warning = is_alert_warning;
    this.args.is_alert_warning = is_alert_warning;
  }
  render(): void {
    console.log("render ProbabilityAnalysis")
  }
}