import { getUrl } from '../utils/app-config'
import { fetchAPI } from '../services/api_services/fetchAPI'

export interface VariableOptionsData {
  id: number
  name: string
}

export interface EngagementVariableData {
  id: number
  name: string
  description: string
  type: string
  options: VariableOptionsData[]
}


export class EngagementVariableRepository {
  private readonly apiUrl: string = getUrl('engagement_variables_api/get_all')

  /**
   * The constructor.
   * @param fetcher - The fetcher.
   */
  constructor (private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) {}

  async getAll (): Promise<EngagementVariableData[]> {
    try {

      const response = await this.fetcher(this.apiUrl, {
        method: 'POST'
      })

      if (!response.ok) {
        return []
      }

      if (response.status !== 200) {
        return []
      }

      const data: {success: boolean, data: EngagementVariableData[]} = await response.json()
      if (!data.success) {
        return []
      }
      
      return data.data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return []
    }
  }
}
