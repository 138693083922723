import { type Breadcrumb } from './Breadcrumb'

/**
 * This class represents a page.
 */
export class Page {
  private readonly _target: string
  private _visible: boolean
  private readonly _breadcrumb: Breadcrumb
  private _index: number = 0
  private _isLoaded: boolean = false

  /**
   * The constructor.
   * @param target - The target.
   * @param breadcrumb - The breadcrumb.
   * @param visible - The visibility.
   */
  constructor (target: string, breadcrumb: Breadcrumb, visible: boolean = false) {
    this._target = target
    this._breadcrumb = breadcrumb
    this._visible = visible
  }

  /**
   * The index.
   * @returns The index.
   */
  get index (): number {
    return this._index
  }

  /**
   * The index.
   */
  set index (index: number) {
    this._index = index
  }

  /**
   * The target.
   * @returns The target.
   */
  get target (): string {
    return this._target
  }

  /**
   * The breadcrumb.
   * @returns The breadcrumb.
   */
  get breadcrumb (): Breadcrumb {
    return this._breadcrumb
  }

  /**
   * The visibility.
   * @returns The visibility.
   */
  get visible (): boolean {
    return this._visible
  }

  /**
   * The visibility.
   */
  set visible (visible: boolean) {
    this._visible = visible
  }

  /**
   * The isLoaded.
   * @returns The isLoaded.
   */
  get isLoaded (): boolean {
    return this._isLoaded
  }

  /**
   * The isLoaded.
   */
  set isLoaded (isLoaded: boolean) {
    this._isLoaded = isLoaded
  }
}
