import { QuestionsTableArgs, type ItemOption } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class QuestionsTableElement extends ElementBase implements IElementBase {
  private _items: ItemOption[]

  constructor(args: QuestionsTableArgs) {
    super(args)
    this._items = args.items || []
  }

  get items(): ItemOption[] {
    return this._items
  }

  get args(): QuestionsTableArgs {
    return {
      ...super.args,
      items: this._items,
    }
  }

  set items(items: ItemOption[]) {
    this._items = items
  }

  render(): void {}
}