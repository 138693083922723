import type { InternalAuditor, Transaction, StageSchedule } from '@/models/special-document/ElementArgs'
import React, { useState, useEffect, useRef, useId, useMemo } from 'react'
import { T } from '@/utils/i18n-config'
import { Permissions } from '@/models/special-document/ElementBase'
import { arrayToOptions, decryptUniqueFilename } from '@/utils/utilities'
import { InternalAuditorElement } from '@/models/special-document/InternalAuditorElement'
import { ElementRepository, type TransactionCategory } from '@/repository/special-document/ElementRepository'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import ModalWithButtons from '../modals/AceptCancelModalBase'

import Summernote from '../utils/Summernote'
import Conclusion from '../commons/Conclusion'
import DropzoneComponent from '../utils/Dropzone'
import { ButtonModal } from '../commons/button-modal'
import Select, { type Option } from '../commons/Select'
import TableComponent from '../commons/TableComponent'


/**
 * Internal Auditor View Mode Component
 * @param iElement Internal Auditor Element
 */
const InternalAuditorViewMode = ({ iElement }: ViewModeProps) => {
  const element = useRef<InternalAuditorElement>(iElement as InternalAuditorElement).current
  const [questionAnswers, setQuestionAnswers] = useState(element.conclusionQuestions)
  const allQuestionsComplete = questionAnswers.every((answer) => answer !== -1)

  function updateConclusionQuestion(index: number, value: number) {
    const newAnswers = [...questionAnswers]
    newAnswers[index] = value
    setQuestionAnswers(newAnswers)
  }

  useEffect(() => {
    async function updateAnswers() {
      const elementRepository = new ElementRepository()
      const lastAnswers = element.conclusionQuestions
      element.conclusionQuestions = questionAnswers

      let success = await elementRepository.saveElement("view", element.args)
      if (!success) {
        window.htmlHelpers?.swalError()
        element.conclusionQuestions = lastAnswers
        setQuestionAnswers(lastAnswers)
      }
    }

    if (questionAnswers.some((answer) => answer !== -1)) updateAnswers()
  }, [questionAnswers])

  return (
    <>
      <ViewModeBase
        isEditable={false}
        handleEdit={() => { }}
        permissions={iElement.permissions as Permissions}
      >
        <>
          <QuestionConclusion
            title="Application of internal audit in the entity"
            subtitle="Determinar si la entidad cuenta con un departamento de auditoría interna y su uso en el trabajo del auditor externo."
            question="Con base en el conocimiento de la estructura organizacional y las áreas operativas de la entidad, determine si esta cuenta con un departamento de auditoría interna formalmente establecido"
            answer={questionAnswers[0]}
            conclusion="La entidad no dispone de un departamento de Auditoría Interna y/o Control Interno formalmente establecido. En consecuencia, no es necesario realizar la evaluación de dicha área en el contexto de los procedimientos de auditoría."
            onChange={(answer) => updateConclusionQuestion(0, answer)}
          >
            <QuestionConclusion
              title="La jurisdicción y el uso del trabajo de los auditores internos"
              question="Según la jurisdicción aplicable al proceso de auditoría, determine si las leyes y regulaciones locales permiten que el auditor externo considere el trabajo de la auditoría interna como parte de la planificación y ejecución de los procedimientos de auditoría."
              answer={questionAnswers[1]}
              conclusion="Las disposiciones legales y normativas aplicables no autorizan la utilización del trabajo de los auditores internos como soporte en la ejecución de la auditoría externa. Por lo tanto, no será necesario evaluar esta área en el marco de los procedimientos de auditoría planificados."
              onChange={(answer) => updateConclusionQuestion(1, answer)}
            >
              <QuestionConclusion
                title="Consideración del uso del trabajo de auditores internos"
                question="¿Planea el equipo de auditoría utilizar el trabajo de los auditores internos como insumo para obtener evidencia suficiente y adecuada en la realización de los procedimientos de auditoría?"
                answer={questionAnswers[2]}
                conclusion="Según el plan de trabajo establecido, el equipo de auditoría no contempla incluir el trabajo de los auditores internos para ajustar la naturaleza, el momento o el alcance de los procedimientos de auditoría que se llevarán a cabo."
                onChange={(answer) => updateConclusionQuestion(2, answer)}
              >
                <div className="mt-5">
                  <h6>Evaluación de la función de auditoría interna</h6>
                  <p>Con base en la selección del trabajo de la auditoría interna realizada, determine si el trabajo de la función de auditoría interna puede utilizarse para los fines de la auditoría:</p>
                  <ObjectivitySection element={element} />
                  <CompetenceSection element={element} />
                  <ApproachSection element={element} />
                </div>
                <QuestionConclusion
                  title="Inclusión del trabajo de los auditores internos en la realización de la auditoria"
                  question="De acuerdo con la objetividad, competencia y enfoque sistemático del equipo de auditoría interna, concluya si se incluirá su trabajo en el plan de auditoría para la realización de pruebas, bajo la supervisión del equipo de auditoría externa."
                  answer={questionAnswers[3]}
                  conclusion="El trabajo de los auditores internos no será incorporado en el plan de auditoría, debido a consideraciones relacionadas con la objetividad, independencia o competencia del equipo de auditoría interna, o por no cumplir con los requisitos necesarios para respaldar los procedimientos de auditoría externa"
                  onChange={(answer) => updateConclusionQuestion(3, answer)}
                >
                  <TransactionsFlows element={element} />
                  <ProcedureDetails element={element} />
                  <StagesSelection element={element} />
                </QuestionConclusion>
              </QuestionConclusion>
            </QuestionConclusion>
          </QuestionConclusion>
          {allQuestionsComplete && (
            <Conclusion
              element={element}
              subtitle={T("Based on the work plan designed for internal auditors, evaluate and conclude whether the plan is adequate and sufficient to meet the objectives and purposes established by the auditor.")}
            />
          )}
        </>
      </ViewModeBase>
    </>
  )
}

// COMPONENT SECTION
interface ObjectivitySectionProps {
  element: InternalAuditorElement
}
function ObjectivitySection({ element }: ObjectivitySectionProps) {
  const [justifications, setJustifications] = useState<string[]>(element.objectivityJustifications)
  const questions = [
    "Documente si la auditoría interna de la entidad responde ante el gobierno corporativo o a un funcionario designado con la autoridad adecuada, o si, en su lugar, reporta a la gerencia de la entidad",
    "Determine si las funciones desempeñadas por la auditoría interna están exentas de responsabilidades conflictivas, o si, por el contrario, la auditoría interna asume responsabilidades de gestión u operativas fuera del ámbito de sus funciones.",
    "Documente si las decisiones laborales relacionadas con las remuneraciones de los auditores internos son determinadas por el gobierno corporativo o un funcionario con la autoridad adecuada, o si, en su defecto, son establecidas por la gerencia de la entidad.",
    "Indague si existen limitaciones impuestas por la gerencia o el gobierno corporativo que puedan impedir a la auditoría interna comunicar hallazgos relevantes al auditor externa.",
    "De acuerdo con las indagaciones realizadas, concluya si el trabajo de los auditores internos cumple con los requisitos de objetividad e independencia necesarios para ser incluido en los procedimientos de auditoría."
  ]

  function updateJustification(index: number, value: string) {
    const newAnswers = [...justifications]
    newAnswers[index] = value
    setJustifications(newAnswers)
  }

  useEffect(() => {
    async function updateJustifications() {
      const elementRepository = new ElementRepository()
      const lastJustifications = element.objectivityJustifications
      element.objectivityJustifications = justifications

      let success = await elementRepository.saveElement("view", element.args)
      if (!success) {
        window.htmlHelpers?.swalError()
        element.objectivityJustifications = lastJustifications
        setJustifications(lastJustifications)
      }
    }

    updateJustifications()
  }, [justifications])

  return (
    <section className="mt-2">
      <b className="d-block mb-2 fs-6">&bull; Objetividad:</b>
      <div className="d-flex flex-column gap-4">
        {questions.map((question, index) => (
          <QuestionJustification
            key={index}
            question={question}
            justification={justifications[index]}
            onChange={(value) => updateJustification(index, value)}
          />
        ))}
      </div>
    </section>
  )
}


interface CompetenceSectionProps {
  element: InternalAuditorElement
}
function CompetenceSection({ element }: CompetenceSectionProps) {
  const [auditorsTeam, setAuditorsTeam] = useState(element.competenceTeam)

  function handleUpdateAuditor(index: number, auditor: InternalAuditor) {
    const newAuditors = [...auditorsTeam]
    newAuditors[index] = auditor
    setAuditorsTeam(newAuditors)
  }

  function handleAddAuditor() {
    const newAuditors = [...auditorsTeam]
    newAuditors.push({ role: "", name: "", competence: "", independence: "" })
    setAuditorsTeam(newAuditors)
  }

  useEffect(() => {
    async function updateJustifications() {
      const elementRepository = new ElementRepository()
      const lastJustifications = element.competenceTeam
      element.competenceTeam = auditorsTeam

      let success = await elementRepository.saveElement("view", element.args)
      if (!success) {
        window.htmlHelpers?.swalError()
        element.competenceTeam = lastJustifications
        setAuditorsTeam(lastJustifications)
      }
    }

    updateJustifications()
  }, [auditorsTeam])

  return (
    <section className="d-flex flex-column mt-5">
      <b className="d-block mb-2 fs-6">&bull; Competencia:</b>
      <p>El equipo de auditoría interna está formado por las siguientes personas:</p>
      <div className="d-flex flex-column gap-3">
        {auditorsTeam.map((auditor, index) => (
          <InternalAuditor
            key={index}
            elementId={element.id}
            auditor={auditor}
            updateAuditor={(auditor) => handleUpdateAuditor(index, auditor)}
          />
        ))}
      </div>
      <button className="btn btn-primary mt-2 me-auto" onClick={handleAddAuditor}>
        {T("Add Auditor")}
      </button>
    </section>
  )
}


interface ApproachSectionProps {
  element: InternalAuditorElement
}
function ApproachSection({ element }: ApproachSectionProps) {
  const [deliverables, setDeliverables] = useState(element.approachWork.deliverables ?? "")
  const [actionPlan, setActionPlan] = useState(element.approachWork.action_plan ?? "")
  const [showModal, setShowModal] = useState(false)

  function handleModalAccept() {
    setShowModal(false)
    const elementRepository = new ElementRepository()
    element.approachWork = { action_plan: actionPlan, deliverables }
    const success = elementRepository.saveElement("view", element.args)
    if (!success) window.htmlHelpers?.swalError()
  }

  function handleModalCancel() {
    setShowModal(false)
  }

  return (
    <>
      <section className="position-relative mt-5">
        <b className="d-block mb-2 fs-6">&bull; {T("Enfoque sistemático del trabajo")}</b>
        <p className="m-0">Solicite el plan de acción elaborado por la auditoría interna, así como los entregables generados a partir de dicho plan, con el fin de concluir si existen hallazgos que puedan ser relevantes para la realización de la auditoría.</p>
        {actionPlan && (
          <div className="d-flex flex-column gap-1 mt-2">
            <span className="fw-bold">Plan de Acción</span>
            <div dangerouslySetInnerHTML={{ __html: actionPlan }} />
          </div>
        )}
        {deliverables && (
          <div className="d-flex flex-column gap-1 mt-2">
            <span className="fw-bold">Entregables</span>
            <p>{decryptUniqueFilename(deliverables)}</p>
          </div>
        )}
        <ButtonModal onDisplayModal={() => setShowModal(true)} />
      </section>
      <ModalWithButtons
        showModal={showModal}
        title={T("Enfoque sistemático del trabajo")}
        size="xl"
        onAccept={handleModalAccept}
        onCancel={handleModalCancel}
      >
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-column gap-1">
            <span className="fw-bold">Plan de Acción</span>
            <Summernote value={actionPlan} onChange={(value) => setActionPlan(value)} />
          </div>
          <div className="d-flex flex-column gap-1">
            <span className="fw-bold">Adjuntar Entregables</span>
            <DropzoneComponent
              elementId={element.id}
              attachmentName={deliverables}
              onUpload={(filename) => setDeliverables(filename)}
            />
          </div>
        </div>
      </ModalWithButtons>
    </>
  )
}


interface TransactionsFlowsProps {
  element: InternalAuditorElement
}
function TransactionsFlows({ element }: TransactionsFlowsProps) {
  const [transactions, setTransactions] = useState(element.transactions)
  const [transactionCategories, setTransactionCategories] = useState<TransactionCategory[]>([])
  const transactionsToOptions = useMemo(() => {
    return transactionCategories.map(({ title, risk }) => {
      const transaction = transactions.find(({ title: t }) => t === title)
      if (!transaction) return null
      return { title, risk, options: transaction?.options || [] }
    }).filter(Boolean) as (Transaction & { risk: string })[]
  }, [transactions, transactionCategories])

  function handleSelectOption(transaction: Transaction) {
    const newTransactions = [...transactions]
    const index = newTransactions.findIndex(({ title }) => title === transaction.title)
    newTransactions[index] = transaction
    setTransactions(newTransactions)
  }

  useEffect(() => {
    async function fetchTransactionRisks() {
      const elementRepository = new ElementRepository()
      const { success, data } = await elementRepository.getTransactionFlowRisks(
        element.engagement_id, element.documentId, element.id
      )
      if (!success) return window.htmlHelpers?.swalError()
      setTransactionCategories(data)
    }

    fetchTransactionRisks()
  }, [])

  useEffect(() => {
    async function updateTransactions() {
      const elementRepository = new ElementRepository()
      const lastTransactions = element.transactions
      element.transactions = transactions

      let success = await elementRepository.saveElement("view", element.args)
      if (!success) {
        window.htmlHelpers?.swalError()
        element.transactions = lastTransactions
        setTransactions(lastTransactions)
      }
    }

    updateTransactions()
  }, [transactions])

  return (
    <div>
      <b className="d-block mb-3 fs-6">{T("Determining the nature and scope of work of the internal audit function")}</b>
      <div className="d-flex flex-column gap-4">
        {transactionsToOptions.map((transaction, index) => (
          <TransactionProcedures key={index} transaction={transaction} onChange={handleSelectOption} />
        ))}
      </div>
    </div>
  )
}


interface ProcedureDetailsProps {
  element: InternalAuditorElement
}
function ProcedureDetails({ element }: ProcedureDetailsProps) {
  const [transactions, setTransactions] = useState(element.transactions)
  const [showModal, setShowModal] = useState(false)

  function handleModalAccept() {
    setShowModal(false)
    const elementRepository = new ElementRepository()
    element.transactions = transactions
    const success = elementRepository.saveElement("view", element.args)
    if (!success) window.htmlHelpers?.swalError()
  }

  function handleModalCancel() {
    setShowModal(false)
  }

  function handleUpdateTransaction(index: number, value: Transaction) {
    const newTransactions = [...transactions]
    newTransactions[index] = value
    setTransactions(newTransactions)
  }

  return (
    <>
      <section className="position-relative mt-5">
        <b className="d-block mb-2 fs-6">{T("Detalle de los procedimientos de la auditoría interna")}</b>
        <p>De acuerdo con el flujo de transacciones en el que se planea involucrar a los auditores internos y los procedimientos asignados a ellos, describa con detalle los procedimientos, alcance y objetivos de cada prueba.</p>
        <div className="d-flex flex-column gap-1">
          {transactions.map((transaction, index) => (
            <div key={index}>
              <b className="d-block mb-1">{transaction.title}: </b>
              <p dangerouslySetInnerHTML={{ __html: transaction.details ?? "" }} />
            </div>
          ))}
        </div>
        <ButtonModal onDisplayModal={() => setShowModal(true)} />
      </section>
      <ModalWithButtons
        showModal={showModal}
        title={T("Detalle de los procedimientos de la auditoría interna")}
        size="xl"
        onAccept={handleModalAccept}
        onCancel={handleModalCancel}
      >
        <div className="grid gap-4" style={{ gridTemplateColumns: "repeat(2, minmax(300px, 1fr))" }}>
          {transactions.map((transaction, index) => (
            <TransactionProcedure
              key={index}
              transaction={transaction}
              onChange={(transaction) => handleUpdateTransaction(index, transaction)}
            />
          ))}
        </div>
      </ModalWithButtons>
    </>
  )
}

interface StagesSelectionProps {
  element: InternalAuditorElement
}

const STAGE_SCHEDULE_ATTR = { 0: "stage", 1: "holder", 2: "startDate", 3: "endDate" } as const
type ScheduleIndex = keyof typeof STAGE_SCHEDULE_ATTR

function StagesSelection({ element }: StagesSelectionProps) {
  const defaultOptions = useMemo(() => arrayToOptions(element.schedule.map(({ stage }) => stage)), [])
  const options = arrayToOptions([T("Planning"), T("Execution"), T("Completion")])
  const [schedules, setSchedules] = useState(element.schedule)
  const schedulesList = useMemo(() => {
    return schedules.map(({ stage, holder, startDate, endDate }) => [stage, holder, startDate, endDate])
  }, [schedules])

  async function updateSchedules(schedules: StageSchedule[]) {
    const elementRepository = new ElementRepository()
    const lastSchedules = element.schedule
    element.schedule = schedules

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      window.htmlHelpers?.swalError()
      element.schedule = lastSchedules
      setSchedules(lastSchedules)
    }
  }

  function handleUpdateSchedule(rowIndex: number, colIndex: number, value: any) {
    const newSchedules = [...schedules]
    const scheduleAttr = STAGE_SCHEDULE_ATTR[colIndex as ScheduleIndex]
    newSchedules[rowIndex][scheduleAttr as keyof StageSchedule] = value
    setSchedules(newSchedules)
  }

  function handleSelectStages(options: Option[]) {
    const newSchedules = options.map(({ label }) => {
      const schedule = schedules.find(({ stage }) => stage === label)
      if (schedule) return schedule
      return { stage: label, holder: "", startDate: "", endDate: "" }
    })
    setSchedules(newSchedules)
    updateSchedules(newSchedules)
  }

  return (
    <>
      <div className="mt-5">
        <b className="d-block mb-2 fs-6">Cronograma de los procedimientos de la auditoría interna</b>
        <p>El trabajo de campo de auditoría planificado está programado para las siguiente (s) etapa (s) de auditoria:</p>
        <Select
          multiple
          options={options}
          defaultValue={defaultOptions}
          placeholder={T("Select one or more options")}
          onChange={handleSelectStages}
        />
      </div>
      <TableComponent
        labels={[T("Stage"), T("Holder"), T("Start Date"), T("End Date")]}
        values={schedulesList}
        className="mt-4"
        inputColumns={[1, 2, 3]}
        typeColumns={[{ index: 1, type: "text" }, { index: 2, type: "date" }, { index: 3, type: "date" }]}
        onChange={(rowIndex, colIndex, value) => handleUpdateSchedule(rowIndex, colIndex, value)}
      />
    </>
  )
}


// SUB-COMPONENTS
interface TransactionProcedureProps {
  transaction: Transaction
  onChange: (transaction: Transaction) => void
}
function TransactionProcedure({ transaction, onChange }: TransactionProcedureProps) {
  const [details, setDetails] = useState(transaction.details ?? "")

  function handleUpdateDetails(value: string) {
    onChange({ ...transaction, details: value })
    setDetails(value)
  }

  return (
    <div>
      <b className="mb-2">{transaction.title}</b>
      <Summernote value={details} onChange={(value) => handleUpdateDetails(value)} />
    </div>
  )
}


interface InternalAuditorProps {
  elementId: number
  auditor: InternalAuditor
  updateAuditor: (auditor: InternalAuditor) => void
}
function InternalAuditor({ elementId, auditor, updateAuditor }: InternalAuditorProps) {
  const [role, setRole] = useState(auditor.role ?? "")
  const [fullName, setFullName] = useState(auditor.name ?? "")
  const [competence, setCompetence] = useState(auditor.competence ?? "")
  const [independence, setIndependence] = useState(auditor.independence ?? "")
  const [showModal, setShowModal] = useState(false)
  const inputId = useId()

  function handleModalAccept() {
    setShowModal(false)
    updateAuditor({ ...auditor, name: fullName, competence, independence })
  }

  function handleModalCancel() {
    setShowModal(false)
  }

  return (
    <>
      <div className="position-relative d-flex flex-column gap-3 ms-4">
        <div>
          <b className="fs-6">
            {role || <span className="text-muted">{T("Role")}</span>}
          </b>
          <p className="m-0">
            {fullName || <span className="text-muted">{T("Name")}</span>}
          </p>
        </div>
        <div className="d-flex flex-column gap-1">
          {competence && (
            <p className="m-0">
              <b>{T("CV")}: </b>
              <span>{decryptUniqueFilename(competence)}</span>
            </p>
          )}
          {independence && (
            <p className="m-0">
              <b>{T("Acta de Independencia")}: </b>
              <span>{decryptUniqueFilename(independence)}</span>
            </p>
          )}
        </div>
        <ButtonModal onDisplayModal={() => setShowModal(true)} />
      </div>
      <ModalWithButtons
        showModal={showModal}
        title={T("Internal Auditor")}
        size="xl"
        onAccept={handleModalAccept}
        onCancel={handleModalCancel}
      >
        <div className="d-flex gap-4 mb-4">
          <div className="w-100">
            <label htmlFor={`${inputId}-role`} className="form-label text-sm">{T("Role")}</label>
            <input
              type="text"
              value={role}
              id={`${inputId}-role`}
              className="form-control"
              placeholder={T("Manager/Partner")}
              onChange={(e) => setRole(e.target.value)}
            />
          </div>
          <div className="w-100">
            <label htmlFor={`${inputId}-name`} className="form-label text-sm">{T("Name")}</label>
            <input
              type="text"
              value={fullName}
              id={`${inputId}-name`}
              className="form-control"
              placeholder={T("John Doe")}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex gap-4">
          <div className="w-100">
            <b className="text-sm">{T("CV")}</b>
            <DropzoneComponent
              elementId={elementId}
              attachmentName={competence}
              onUpload={(filename) => setCompetence(filename)}
            />
          </div>
          <div className="w-100">
            <b className="text-sm">{T("Acta de Independencia")}</b>
            <DropzoneComponent
              elementId={elementId}
              attachmentName={independence}
              onUpload={(filename) => setIndependence(filename)}
            />
          </div>
        </div>
      </ModalWithButtons>
    </>
  )
}


interface QuestionJustificationProps {
  question: string
  justification: string
  onChange: (value: string) => void
}
function QuestionJustification({ question, justification, onChange }: QuestionJustificationProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [justificationValue, setJustificationValue] = useState(justification)

  function handleUpdateJustification() {
    if (isEditing) {
      onChange(justificationValue)
      setIsEditing(false)
    } else setIsEditing(true)
  }

  return (
    <div className="ms-4">
      <p>{question}</p>
      <div className="d-flex align-items-center gap-4">
        <b>{T("Justification")}</b>
        <button className="p-2 bg-secondary border-0 rounded text-white text-sm lh-1" onClick={handleUpdateJustification}>
          {isEditing ? (
            <i className="fa-solid fa-check" />
          ) : (
            <i className="fa-solid fa-pencil" />
          )}
        </button>
      </div>
      {isEditing ? (
        <Summernote value={justification} onChange={(value) => setJustificationValue(value)} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: justification }} />
      )}
    </div>
  )
}


interface QuestionConclusionProps {
  title: string
  subtitle?: string
  question: string
  answer: number
  conclusion: string
  children?: React.ReactNode
  onChange: (answer: number) => void
}
function QuestionConclusion({ title, subtitle, question, answer, conclusion, children, onChange }: QuestionConclusionProps) {
  const id = useId()

  /**
   * Handle the change of the answer
   * @param event Change event
   */
  function handleAnswerChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(Number(event.target.value))
  }

  return (
    <div className="mt-4">
      <h6 className="fw-bolder">{T(title)}</h6>
      {subtitle && <p>{T(subtitle)}</p>}
      <div className="d-flex flex-column">
        <p>{T(question)}</p>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name={`yes-no-answer-${id}`}
            id={`yes-option-${id}`}
            value="1"
            checked={answer === 1}
            onChange={handleAnswerChange}
          />
          <label className="form-check-label" htmlFor={`yes-option-${id}`}>
            {T("Yes")}
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name={`yes-no-answer-${id}`}
            id={`no-option-${id}`}
            value="0"
            checked={answer === 0}
            onChange={handleAnswerChange}
          />
          <label className="form-check-label" htmlFor={`no-option-${id}`}>
            {T("No")}
          </label>
        </div>
      </div>
      {answer === 0 && (<p>{T(conclusion)}</p>)}
      {(children && answer === 1) && children}
    </div>
  )
}


interface TransactionProceduresProps {
  transaction: Transaction & { risk: string }
  onChange: (transaction: Transaction) => void
}
function TransactionProcedures({ transaction, onChange }: TransactionProceduresProps) {
  const options = [
    { id: "pruebas_eficacia", label: "Pruebas de la eficacia operativa de los controles." },
    { id: "procedimientos_sustantivos", label: "Procedimientos sustantivos que implican juicio limitado." },
    { id: "observaciones_inventario", label: "Observaciones de recuentos de inventarios (exclusivo de Inventarios – Costo)." },
    { id: "seguimiento_transacciones", label: "Seguimiento de transacciones a través del sistema de información relevante para la presentación de informes financieros." }
  ]

  function handleSelectOption(options: Option[]) {
    onChange({ ...transaction, options })
  }

  return (
    <div className="d-flex flex-column gap-1">
      <h6 className="d-flex justify-content-between align-items-center gap-4 m-0 fw-bolder">
        <span>{transaction.title}</span>
        <span>{T(transaction.risk)}</span>
      </h6>
      {transaction.risk !== "High" ? (
        <div className="d-flex flex-column">
          <label htmlFor="procedimientos" className="fw-bold">Procedimientos Aplicables:</label>
          <Select
            multiple
            options={options}
            defaultValue={transaction.options}
            placeholder={T("Select one or more options")}
            onChange={handleSelectOption}
          />
        </div>
      ) : (
        <p className="fs-6 fw-bold">No Aplica</p>
      )}
    </div>
  )
}

export default InternalAuditorViewMode