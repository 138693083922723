import React from "react";

interface CharacterCounterProps {
  text: string;
  maxLength: number;
}

export const CharacterCounter = ({ text, maxLength}: CharacterCounterProps) => {

  const extractText = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };
  const visibleText = extractText(text);

  return (
    <div className="character-counter d-flex justify-content-end">
      <span style={{ color: 
        visibleText.length > maxLength*0.7 && visibleText.length < maxLength*0.9 ? "orange" :
        visibleText.length >= maxLength*0.9 && visibleText.length < maxLength ? "red" : "gray"
      }}>
        {visibleText.length}/{maxLength}
      </span>
    </div>
  );
}