
import { T } from "../../utils/i18n-config"
import { ElementTypeFactory } from "../../factory/special-document/ElementTypeFactory"
import { Board } from "../../models/special-document/Board"
import { ModalHelper } from './ModalHelper'
import { IPermissions } from "../../controller/IPermissions"

export class BoardRender{
  private _board: Board
  private _firstRender: boolean = true
  private _documentContentID: string = "document-content"
  private _specialDocumentContentID: string = "board-content"
  private _renderingMode: string = "edition"
  private _modalElmentsID: string = "modal-elements"
  private _permissions: IPermissions = {created: false, updated: false, deleted: false, read: false}

  constructor(private board: Board, private renderingMode: string = "edition", 
    private boardContentID: string = "board-content",
    permissions: IPermissions,  documentContentID: string = "document-content"){  
    this._board = board
    this._renderingMode = renderingMode
    this._specialDocumentContentID = boardContentID
    this._documentContentID = documentContentID
    this._permissions = permissions
    this._modalElmentsID = "modal-elements" + boardContentID
  }

  renderInEdit (reference: string): void {
    if (this._permissions.created === true){
      this.addButtonOpenModal(reference)
    }
    this._firstRender = false
    this.addDivDocumentContent() 
  }

  renderInView (reference: string): void {
    this._firstRender = false
    this.addDivDocumentContent()
  }

  addDivDocumentContent(){
    const div = document.createElement('div')
    div.id = this._documentContentID
    div.classList.add('container')
    const divTarget = document.getElementById(this._specialDocumentContentID)
    divTarget?.appendChild(div)
  }

  addButtonOpenModal(reference: string){
    const button = document.createElement('button')
    button.classList.add('btn', 'bg-gradient-primary')
    button.textContent = T('Add Element')
    button.onclick = () => {
      if (window.boardController) {
        window.boardController.openElementTypeModal(reference)
      }
    }
    
    const divTarget = document.getElementById(this._specialDocumentContentID)
    divTarget?.appendChild(button)
  }

  
  async openElementTypeModal(reference: string){
    if (this._board.loadedElementTypeModal === false) {
        try{
          const modalContent =  this.contenModal(reference)
          ModalHelper.createModal(this._specialDocumentContentID, modalContent)
          this._board.loadedElementTypeModal = true
        }
          catch (error) {
          console.error("Error while getting modal content:", error)
          return
        }
        
    }
    ModalHelper.openModal(this._modalElmentsID)
  }

   contenModal(reference: string): string {
    const elementTypeFactory = new ElementTypeFactory()
    const elementType = elementTypeFactory.getElementTypes()
    const optionsHtml = elementType.map(e => `<option value="${e.type}">${T(e.name)}</option>`).join('')
    const bodyContent = `<select class="form-control" id="selectedElement">${optionsHtml}</select>`
    const footerButtons = [
      `<button type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" onclick="window.boardController.addElement('${reference}',document.getElementById(\'selectedElement\').value)">${T("Add")}</button>`
    ]

    return ModalHelper.createContentModal(this._modalElmentsID, T('Elements'), bodyContent, footerButtons)   
  }
}
