import { getUrl } from '../utils/app-config'
import { fetchAPI } from '../services/api_services/fetchAPI'


export class UserRepository {
  private readonly getApiUrl: string = getUrl('notes_and_finding_api/get')
  private readonly postApiUrl: string = getUrl('notes_and_finding_api/post')
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  private async fetchJSON(url: string, options?: RequestInit): Promise<any> {
    const response = await this.fetcher(url, options);
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  async getUserAvatar(createdBy: number) {
    const apiUrl = `${getUrl("user_api/get_user_avatar")}/${createdBy}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}