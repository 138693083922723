import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface BarGraphProps {
    data: { 
        label: string, 
        value: number, 
        color: string,
        borderColor: string,
        hoverColor: string,
        hoverBorderColor: string,
    }[];
    title: string;
    xLabel: string;
    yLabel: string;
}

export const BarGraph = ({ data, title, xLabel, yLabel}: BarGraphProps) => {
    const chartData = {
        labels: data.map(d => d.label),
        datasets: [
            {
                label: yLabel,
                data: data.map(d => d.value),
                backgroundColor: data.map(d => d.color),
                borderColor: data.map(d => d.borderColor),
                borderWidth: 1,
                hoverBackgroundColor: data.map(d => d.hoverColor),
                hoverBorderColor: data.map(d => d.hoverBorderColor),
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: xLabel,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                title: {
                    display: false,
                    text: yLabel,
                },
                
            },
        },
    };


    return (

        <div className='d-flex flex-column flex-grow-1 gap-3'>
            <div className='text-start my-3'>
                <strong style={{marginBottom: '0px', fontSize: '1.4rem'}}>
                    {title}
                </strong>
            </div>
            <div style={{
                position: 'relative',
                height: '100%',
            }} id='barGraph'>
                <Bar id='chart' data={chartData} options={options} />
            </div>
        </div>

    );
};
