import React, { ReactNode } from 'react';
import ModalBase, { ModalBaseProps } from './ModalBase';
import { T } from "../../utils/i18n-config"

interface AceptCancelModalBaseProps extends ModalBaseProps {
    onAccept: () => void;
    children?: ReactNode;
}

const AceptModalBase: React.FC<AceptCancelModalBaseProps> = ({ onAccept, children, ...modalBaseProps }) => {
    return (
        <ModalBase {...modalBaseProps}>
            <div className="modal-body">
                {children}
            </div>
            <div className="modal-footer">
                <button type="button" className="btn bg-gradient-primary" onClick={onAccept}>{T("Accept")}</button>
            </div>
        </ModalBase>
    );
};

export default AceptModalBase;
