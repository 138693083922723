import { IElementBase } from "./ElementBase"
import { IElementRender } from "../../renderer/special-document/IElementRender"

export class ElementType{
  private _type: number
  private _name: string
  private _classes: new (...args: any[]) => IElementBase

  constructor(type: number, name: string, classes: new (...args: any[]) => IElementBase){
    this._type = type
    this._name = name
    this._classes = classes    
  }

  get type(): number{
    return this._type
  }

  get name(): string{
    return this._name
  }
  get classes(): new (...args: any[]) => IElementBase{
    return this._classes
  } 
}