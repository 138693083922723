import { getUrl } from '../utils/app-config';
import { fetchAPI } from '../services/api_services/fetchAPI';
import { NotesAndFindingsArgs } from '../components/notes-and-findings/NotesAndFindings';


export class NotesAndBindingRepository{
  private readonly getApiUrl: string = getUrl('notes_and_finding_api/get')
  private readonly postApiUrl: string = getUrl('notes_and_finding_api/post')
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  private async fetchJSON(url: string, options?: RequestInit): Promise<any> {
    const response = await this.fetcher(url, options);
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  async setRegister(dataRegister: any): Promise<boolean> {
    const apiUrl = `${getUrl('notes_and_finding_api/set_register/')}/${dataRegister.engagement_id}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(dataRegister)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  async getRegisters(engagementId: number, transactionFlowControlId: number, referenceTransactionFlowControl: string, reference:string): Promise<NotesAndFindingsArgs[]> {
    const apiUrl = `${getUrl("notes_and_finding_api/get_registers")}/${engagementId}/${transactionFlowControlId}/${referenceTransactionFlowControl}/${reference}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async deleteRegister(engagementId: number, registerId: number, reference: string, referenceTransactionFlowControl: string): Promise<boolean> {
    const apiUrl = `${getUrl('notes_and_finding_api/delete_register')}/${engagementId}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({register_id: registerId, reference: reference, reference_transaction_flow_control: referenceTransactionFlowControl})  
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  async updateMessageRegister(register: any): Promise<boolean> {
    const apiUrl = `${getUrl('notes_and_finding_api/update_message_register')}/${register.engagement_id}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'PUT',
          headers: this._jsonContentType,
          body: JSON.stringify(register)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  async updateStatusRegister(register: any): Promise<boolean> {
    const apiUrl = `${getUrl('notes_and_finding_api/update_status_register')}/${register.engagement_id}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'PUT',
          headers: this._jsonContentType,
          body: JSON.stringify(register)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

}

