import React, { useState } from 'react';
import { FindingsConfig, FindingsBase } from './FindingsConfig';
import { T } from '../../utils/i18n-config';
import { EvaluationIcon } from './EvaluationIcon';
import { Constants } from '../../utils/Constants';
import DOMPurify from 'isomorphic-dompurify';

export const FindingArea = ({...args}: FindingsBase) => {

  const [findingTitle, setFindingTitle] = useState<string>(args.initialData.finding_title)
  const [description, setDescription] = useState<string>(args.initialData.description)
  const [recomendations, setRecomendations] = useState<string>(args.initialData.recomendations)
  const [origingsAndCauses, setOrigingsAndCauses] = useState<string>(args.initialData.origins_and_causes)
  const [answer, setAnswer] = useState<string>(args.initialData.answer_field)
  const [adminActionPlan, setAdminActionPlan] = useState<string>(args.initialData.admin_action_plan)
  const [implementationDate, setImplementationDate] = useState<string>(args.initialData.implementation_date)
  const [evaluation, setEvaluation] = useState<number>(args.initialData.finding_evalution_type_id)

  return (
    <>
      {
        args.permissions?.read &&
        <div className='d-grid w-full p-0 mb-5' style={{gridTemplateColumns: '10fr 1fr'}}>
          <div className={`d-flex flex-column gap-4 ${Constants.CONTENT_CLASS}`}>
            <div className="d-flex gap-2 justify-content-start align-items-center">
                <h2>{findingTitle}</h2>
                <EvaluationIcon evaluation={evaluation}/>
            </div>
            <div className="w-full d-flex px-4 pr-0 flex-column gap-4">
              <div className="finding-area-description">
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description)}} />
              </div>
              <div className="finding-area-recomendations gap-2">
                <strong>{T("Recomendations")}:</strong>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(recomendations)}} />
              </div>
              <div className="finding-area-answer-field d-flex gap-2">
                <strong>{T("Are you agree with the recomendation?")}</strong>
                { T(answer) }
              </div>
              <div className="finding-area-origins-and-causes gap-2">
                <strong>{T("Origins and causes:")}</strong>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(origingsAndCauses)}} />
              </div>
              <div className="finding-area-admin-action-plan gap-2">
                <strong>{T("Admin action plan:")}</strong>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(adminActionPlan)}} />
              </div>
              <div className="finding-area-implementation-date gap-2">
                <strong>{T("Implementation date and responsible:")}</strong>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(implementationDate)}} />
              </div>
            </div>
          </div>
          <FindingsConfig 
            findingBase={args}
            stateVariables={{
              findingTitle: findingTitle,
              setFindingTitle: setFindingTitle,
              description: description,
              setDescription: setDescription,
              recomendations: recomendations,
              setRecomendations: setRecomendations,
              origingsAndCauses: origingsAndCauses,
              setOrigingsAndCauses: setOrigingsAndCauses,
              answer: answer,
              setAnswer: setAnswer,
              adminActionPlan: adminActionPlan,
              setAdminActionPlan: setAdminActionPlan,  
              implementationDate: implementationDate,
              setImplementationDate: setImplementationDate,
              evaluation: evaluation,
              setEvaluation: setEvaluation    
            }}
          />
        </div>
      }
    </>
  );
};
