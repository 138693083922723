// bootstrap
import * as bootstrap from 'bootstrap';
import { HtmlHelpers } from '../../utils/HtmlHelpers';
import { Constants } from '../../utils/Constants';

export class TrialBalanceVariations {
  private readonly _htmlHelpers: HtmlHelpers = new HtmlHelpers()


  createPopup(title:string, url:any): void {
    const component = this._htmlHelpers.createPopup('trialBalanceModal', title, Constants.CTR_POP_UP+'-content')
  
    const trialBalanceModalVariations = document.getElementById(Constants.CTR_POP_UP)
    if (trialBalanceModalVariations != null) {
      trialBalanceModalVariations.innerHTML = component
      web2py_component(url, Constants.CTR_POP_UP+'-content')
      const modal_id = document.getElementById('trialBalanceModal')
      if (modal_id != null) {
        const myModal = new bootstrap.Modal(modal_id)
        myModal.show()
      }
    }
  }

  addColorButton(id_analysis_variance: number, reference: string): void {
    const elemento: HTMLElement | null = document.querySelector(`div[id='${id_analysis_variance}'][data-type='${reference}']`);

    if (elemento !== null) {
        const boton: HTMLAnchorElement | null = elemento.querySelector('a');
        if (boton !== null) {
            boton.classList.add('btn-success');
        }
    }
  }

  removeColorButton(id_analysis_variance: number, reference: string): void {
    const elemento: HTMLElement | null = document.querySelector(`div[id='${id_analysis_variance}'][data-type='${reference}']`);

    if (elemento !== null) {
        const boton: HTMLAnchorElement | null = elemento.querySelector('a');
        if (boton !== null) {
            boton.classList.remove('btn-success');
        }
    }
  }
  
  increaseNumber(id_analysis_variance:number, reference: string):void {
    const elemento: HTMLElement | null = document.querySelector(`div[id='${id_analysis_variance}'][data-type='${reference}'] a span`);

    if (elemento !== null) {
        const textoActual: string = elemento.innerHTML;
        console.log("textoActual: ", textoActual);

        const numeroActual: RegExpMatchArray | null = textoActual.match(/\d+/);

        if (numeroActual !== null) {
            const nuevoNumero: number = parseInt(numeroActual[0], 10) + 1;
            const nuevoTexto: string = textoActual.replace(/\d+/, nuevoNumero.toString());
            elemento.innerHTML = nuevoTexto;
        } else {
            elemento.innerHTML = `${textoActual} (1)`;
        }
    }
  }
  
  decreaseNumber(id_analysis_variance:number, reference: string, cantFiles: number):void {    
    const elemento: HTMLElement | null = document.querySelector(`div[id='${id_analysis_variance}'][data-type='${reference}'] a span`);

    if (elemento !== null) {
        const textoActual: string = elemento.innerHTML;
        const numeroActual: RegExpMatchArray | null = textoActual.match(/\d+/);

        if (cantFiles > 0) {
            if (numeroActual !== null) {
                elemento.innerHTML = textoActual.replace(/\d+/, cantFiles.toString());
            } else {
                elemento.innerHTML = `${textoActual} (${cantFiles})`;
            }
        } else {
            elemento.innerHTML = textoActual.replace(/\s*\(\d+\)/, '');
        }
    }
  }

  unfoldAccordion():void{
    const accordions = document.querySelectorAll('.accordion-collapse');
    accordions.forEach(accordion => {
      if (!accordion.classList.contains('show')) {
        const exclamationIcon = accordion.querySelector('.exclamation-icon');

        if (exclamationIcon) {
            accordion.classList.add('show');
        }
      }
    });
  }


}
