import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'select2';
import { T } from "../../utils/i18n-config";
import Swal from 'sweetalert2';
import Select2 from '../commons/Select2';
import UpdateStatusNotesAndFindingsModal from './UpdateStatusNotesAndFindingsModal';
import UserAvatarComment from '../commons/UserAvatar';
import TeamUsersAvatar from '../notes/TeamUsersAvatar';
import { NotesRepository } from '../../repository/NotesRepository';
import { NotesAndBindingRepository } from '../../repository/NotesAndFindingsRepository';
import { TeamUsersRepository } from '../../repository/TeamUsersRepository';
import DOMPurify from 'isomorphic-dompurify';

export interface NotesAndFindingsArgs {
    id: number
    message_description: string
    status?: string
    message_id: number
    reference: string
    transaction_flow_control_id: number
    reference_transaction_flow_control: number
    created_at: string
    created_by: number
    created_by_full_name: string
    completed_by?: number
    approved_by?: number
    refused_by?: number
}

export interface NotesAndFindingsProps {
    engagementId: number;
    transactionFlowControlId: number;
    reference: "notes" | "findings";
    referenceTransactionFlowControl: "dai" | "oe";
    userId: number;
    readPermission: boolean;
    createPermission: boolean;
    updatePermission: boolean;
    deletePermission: boolean;
    completePermission: boolean;
    approvePermission: boolean;
    refusePermission: boolean;
    replyPermission: boolean;
}

export interface NotesArgs {
    id: number
    message_description: string
    status?: string
    message_id: number
    reference: string
    created_at: string
    created_by: number
    created_by_full_name: string
    completed_by?: number
    approved_by?: number
    refused_by?: number
}

export interface DropdownMenuPrincipalProps {
    iElement: number;
    isCurrentUser: boolean;
    status: string | undefined;
}

export interface DropdownMenuReplyProps {
    iElement: number;
    IElementFather: number;
    isPermission: boolean;
    isLast?: boolean;
    status: string | undefined;
}

export interface TeamUser{
    engagement_note_id: number | undefined;
    engagement_id: number;
    users_id: string | number | string[] | undefined;
}  

export interface User {
    user_id: number;
    user_name: string;
    background_color?: string;
    initials_name?: string;
    font_color?: string;
    full_name?: string;
}


const NotesAndFindings = ({ engagementId, transactionFlowControlId, reference, referenceTransactionFlowControl, userId, readPermission, createPermission, updatePermission, deletePermission, completePermission, approvePermission, refusePermission, replyPermission }: NotesAndFindingsProps) => {
    const [notesAndFindings, setNotesAndFindings] = useState<NotesAndFindingsArgs[] | NotesArgs[]>([]);
    const [message, setMessage] = useState<string>('');
    const [replyingTo, setReplyingTo] = useState<number | null>(null);

    const [editMessage, setEditMessage] = useState<string>('');
    const [messageTo, setMessageTo] = useState<number | null>(null);

    const [createSection, setCreateSection] = useState<boolean>(false);
    const [messageCreate, setMessageCreate] = useState<string>('');

    const [updateNotesFindingsShowModal, setUpdateNotesFindingsShowModal] = useState(false);
    const [newStatus, setNewStatus] = useState<string>('');
    const [noteFindingId, setNoteFindingId] = useState<number>(0);

    const [noRecords, setNoRecords] = useState<boolean>(false);

    const [selectedUsers, setSelectedUsers] = useState<string | number | string[] | undefined>(undefined);
    const [teamUsers, setTeamUsers] = useState<User[]>([]);

    let title;
    if (reference == "notes") {
        title = T("Notas");
    } else {
        title = T("Hallazgos");
    }

    const handleUpdateNotesAndFindingsCancel = () => {
        setUpdateNotesFindingsShowModal(false);
    }

    const getRegisters = async () => {
        if (!readPermission) return;
        try {
            if (reference == "findings") {
                const notesAndFindingsRepository = new NotesAndBindingRepository()
                const result = await notesAndFindingsRepository.getRegisters(engagementId, transactionFlowControlId, referenceTransactionFlowControl, reference);
                setNotesAndFindings(result);
                setNoRecords(result.length === 0);
            }
            else if (reference == "notes") {
                const notesRepository = new NotesRepository()
                const result = await notesRepository.getRegisters(engagementId, (referenceTransactionFlowControl +"_"+ transactionFlowControlId));
                setNotesAndFindings(result);
                setNoRecords(result.length === 0);
            }
        } catch (error) {
            console.log("error: ", error)
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    }

    const handleCloseEditClick = () => {
        setMessageTo(0);
    }

    const handleInputChange = (e: any) => {
        setEditMessage(e.target.value);
    }

    const handleCreateChange = (e: any) => {
        setMessageCreate(e.target.value);
    }

    const saveMessage = async (noteFindingId: number) => {
        setReplyingTo(null);
        try {
            if (reference == "findings") {
                const notesAndFindingsRepository = new NotesAndBindingRepository()
                const result = await notesAndFindingsRepository.setRegister({ engagement_id: engagementId, message_description: message, message_id: noteFindingId, reference: reference, transaction_flow_control_id: transactionFlowControlId, reference_transaction_flow_control: referenceTransactionFlowControl });
            } 
            else if (reference == "notes") {
                const notesRepository = new NotesRepository()
                const resultNotes = await notesRepository.setRegister({ engagement_id: engagementId, message_description: message, message_id: noteFindingId, reference: (referenceTransactionFlowControl +"_"+ transactionFlowControlId)});
            }            
            setMessage('');
            getRegisters();
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const savePrincipalMessage = async (noteFindingId: number) => {
        setCreateSection(false)
        try {
            if (reference == "findings") {
                const notesAndFindingsRepository = new NotesAndBindingRepository();
                const result = await notesAndFindingsRepository.setRegister({ engagement_id: engagementId, message_description: messageCreate, message_id: noteFindingId, reference: reference, transaction_flow_control_id: transactionFlowControlId, reference_transaction_flow_control: referenceTransactionFlowControl });
            }
            else if (reference == "notes") {
                const notesRepository = new NotesRepository();
                const teamUsersRepository = new TeamUsersRepository();
                if (!selectedUsers || (Array.isArray(selectedUsers) && selectedUsers.length === 0)) return;
                const resultNotes = await notesRepository.setRegister({ engagement_id: engagementId, message_description: messageCreate, message_id: noteFindingId, reference: (referenceTransactionFlowControl +"_"+ transactionFlowControlId)});
                if (selectedUsers) {
                    const resultTeamUsers = await teamUsersRepository.setRegister({ engagement_note_id: resultNotes.id, engagement_id: engagementId, users_id: selectedUsers, reference: (referenceTransactionFlowControl +"_"+ transactionFlowControlId)})
                    setSelectedUsers([]);
                }
            }
            setMessageCreate('');
            getRegisters();
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const deleteMessage = async (noteFindingId: number) => {
        try {
            if (reference == "findings") {
                const notesAndFindingsRepository = new NotesAndBindingRepository()
                const result = await notesAndFindingsRepository.deleteRegister(engagementId, noteFindingId, reference, referenceTransactionFlowControl);
            }
            else if (reference == "notes") {
                const notesRepository = new NotesRepository()
                const result = await notesRepository.deleteRegister(engagementId, noteFindingId, (referenceTransactionFlowControl +"_"+ transactionFlowControlId));
            }
            getRegisters();
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const handleDelete = (iElement: number) => {
        Swal.fire({
            title: T('Are you sure?'),
            text: T("You won't be able to revert this!"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: T('Yes, delete it!'),
            cancelButtonText: T('Cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMessage(iElement);
            }
        });
    };

    const updateMessage = async (registerId: number) => {
        try {
            if (reference == "findings") {
            const notesAndFindingsRepository = new NotesAndBindingRepository()
            const result = await notesAndFindingsRepository.updateMessageRegister({ engagement_id: engagementId, note_finding_id: registerId, message_description: editMessage, reference: reference, reference_transaction_flow_control: referenceTransactionFlowControl });
            }
            else if (reference == "notes") {
                const notesRepository = new NotesRepository()
                const result = await notesRepository.updateMessageRegister({ engagement_id: engagementId, note_id: registerId, message_description: editMessage, reference: (referenceTransactionFlowControl+"_"+transactionFlowControlId) });
            }

            setMessageTo(0);
            getRegisters();
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const updateStatus = async (noteFindingId: number, status: string) => {
        try {
            if (reference == "findings"){
                const notesAndFindingsRepository = new NotesAndBindingRepository()
                const result = await notesAndFindingsRepository.updateStatusRegister({ engagement_id: engagementId, note_finding_id: noteFindingId, status: status, reference: reference, reference_transaction_flow_control: referenceTransactionFlowControl });
            }
            else if (reference == "notes"){
                const notesRepository = new NotesRepository()
                const result = await notesRepository.updateStatusRegister({ engagement_id: engagementId, note_id: noteFindingId, status: status, reference: (referenceTransactionFlowControl +"_"+ transactionFlowControlId)});
            }
            getRegisters();
            setUpdateNotesFindingsShowModal(false);
        } catch (error) {
            console.log("error: ", error)
        }
    }
    async function fetchTeamUsers() {
        const teamUsersRepository = new TeamUsersRepository()
        const result = await teamUsersRepository.getTeamUsersRegisters(engagementId);
        setTeamUsers(result);
    }

    // useEffect(() => {
    //     getRegisters();
    //     fetchTeamUsers();
    // }, []);

    const DropdownMessagePrincipal: React.FC<DropdownMenuPrincipalProps> = ({ iElement, isCurrentUser, status }) => {
        const optionMenu = useRef() as React.MutableRefObject<HTMLDivElement>;

        const handleDropDown = (e: React.MouseEvent<HTMLDivElement>) => {
            if (e.target){
                e.stopPropagation(); 
            }
            optionMenu.current.classList.toggle('show');
        }

        const closeDropdown = () => {
            optionMenu.current.classList.remove('show');
        }
    
        useEffect(() => {
            document.addEventListener('click', closeDropdown);
            return () => {
                document.removeEventListener('click', closeDropdown);
            };
        }, []); 

        return (
            <div className="dropdown" onClick={handleDropDown}>
                <button className="btn btn-link text-secondary px-3 py-2 drop-activation-button-react mb-0" id="dropdownMenuButton">
                    <i className="fas fa-ellipsis-v text-lg drop-activation-button-react"></i>
                </button>
                <div id={`drop-menu-${iElement}`} ref={optionMenu} className="dropdown-menu drop-menu-react dropdown-menu-end">
                    {isCurrentUser && (
                        <React.Fragment>
                            {(updatePermission && status != "approved")  && (
                                <button className="dropdown-item d-flex gap-2 align-items-center" onClick={() => setMessageTo(iElement)} >
                                    <i className="fa fa-pen"></i>
                                    <span>{T("Edit")}</span>
                                </button>
                            )}
                            {(deletePermission && status != "approved") && (
                                <button className="dropdown-item d-flex gap-2 align-items-center" onClick={() => { handleDelete(iElement) }} >
                                    <i className="fa fa-trash"></i>
                                    <span>{T("Delete")}</span>
                                </button>
                            )}
                        </React.Fragment>
                    )}
                    {(replyPermission && status != "approved") && (
                        <button className="dropdown-item d-flex gap-2 align-items-center" onClick={() => setReplyingTo(iElement)} >
                            <i className="fa fa-reply"></i>
                            <span>{T("Reply")}</span>
                        </button>
                    )}
                </div>
            </div>
        );
    }

    const DropdownMenuReply: React.FC<DropdownMenuReplyProps> = ({ iElement, IElementFather, isPermission, isLast, status }) => {
        const optionMenu = useRef() as React.MutableRefObject<HTMLDivElement>;

        const handleDropDown = (e: React.MouseEvent<HTMLDivElement>) => {
            if (e.target){
                e.stopPropagation(); 
            }
            optionMenu.current.classList.toggle('show');
        }

        const closeDropdown = () => {
            optionMenu.current.classList.remove('show');
        }
    
        useEffect(() => {
            document.addEventListener('click', closeDropdown);
            return () => {
                document.removeEventListener('click', closeDropdown);
            };
        }, []); 

        return (
            <div className="dropdown" onClick={handleDropDown}>
                <button className="btn btn-link text-secondary px-3 py-2 drop-activation-button-react mb-0" id="dropdownMenuButton">
                    <i className="fas fa-ellipsis-v text-lg drop-activation-button-react"></i>
                </button>
                <div id={`drop-menu-${iElement}`} ref={optionMenu} className="dropdown-menu drop-menu-react dropdown-menu-end">
                    {isPermission && (
                        <React.Fragment>
                            {(updatePermission && status != "approved") && (
                                <button className="dropdown-item d-flex gap-2 align-items-center" onClick={() => setMessageTo(iElement)} >
                                    <i className="fa fa-pen"></i>
                                    <span>{T("Edit")}</span>
                                </button>
                            )}
                            {(deletePermission && status != "approved") && (
                                <button className="dropdown-item d-flex gap-2 align-items-center" onClick={() => { handleDelete(iElement) }} >
                                    <i className="fa fa-trash"></i>
                                    <span>{T("Delete")}</span>
                                </button>
                            )}
                        </React.Fragment>
                    )}
                    {(isLast && replyPermission && status != "approved") && (
                        <button className="dropdown-item d-flex gap-2 align-items-center" onClick={() => setReplyingTo(IElementFather)} >
                            <i className="fa fa-reply"></i>
                            <span>{T("Reply")}</span>
                        </button>
                    )}
                </div>
            </div>
        );
    }

    const mainComments = notesAndFindings.filter(note => note.message_id == 0);
    const teamUsersOptions = teamUsers.map((user) => ({
        id: user.user_id,
        text: user.user_name,
        background_color: user.background_color,
        color: user.font_color,
        full_name: user.full_name,
        initials: user.initials_name
    }));

    let noRecordsTitle = ''
    let noRecordsText = ''
    let noRecordsButton = ''
    if (reference === "findings") {
        noRecordsTitle = T("Welcome to your {{theReference}} space!", { theReference: T("findings") });
        noRecordsText = T("It seems you don't have any {{theReference}} registered yet. No problem, start right now!", { theReference: T("findings")});
        noRecordsButton = T("Create finding");
    }else{
        noRecordsTitle = T("Welcome to your {{theReference}} space!", { theReference: T("notes") });
        noRecordsText = T("It seems you don't have any {{theReference}} registered yet. No problem, start right now!", { theReference: T("notes")});
        noRecordsButton = T("Create note");
    }

    return (
        <div className="col-md-12">
            {(createPermission) && (
                <div className="col-12">
                    <div className="d-flex justify-content-end">
                        <button className="btn bg-gradient-primary" style={{ padding: '0.75rem 1.5rem' }} onClick={() => setCreateSection(true)}>
                            <i className='fas fa-plus'></i>
                        </button>
                    </div>
                </div>
            )}

            {(noRecords === true && createPermission) && (
                <div className="card text-dark mb-4">
                    <div className="card-body">
                        <h5 className="card-title text-center">{noRecordsTitle}</h5>
                        <p className="card-text text-center">{noRecordsText}</p>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-primary" onClick={() => setCreateSection(true)}>{noRecordsButton}</button>
                        </div>
                    </div>
                </div>
            )}

            {createSection && (
                <div className="card text-dark mb-2 p-2">
                    <div className="card-body p-1">
                        <div className="d-flex justify-content-start">
                            <p className="m-0 text-sm fw-bold"><strong className='text-dark'>{title}</strong></p>
                        </div>
                        <textarea className="form-control" value={messageCreate} onChange={handleCreateChange} />
                        <div className={`d-flex ${reference === "notes" ? "justify-content-between" : "justify-content-end mt-3"} align-items-end`}>
                            {reference === "notes" && (
                                <div className="col-md-4">
                                    <label className="form-label">{T("Users")}</label>
                                    <Select2
                                        multiple={true}
                                        options={teamUsersOptions}
                                        placeholder={T("Select Users")}
                                        onChange={setSelectedUsers}
                                    />
                                </div>
                            )}
                            <div>
                                <button className="btn btn-sm btn-secondary me-2" style={{ padding: '0.5rem 0.8rem' }} onClick={() => {setCreateSection(false);setSelectedUsers([])}}>{T("Cancel")}</button>
                                <button className={`btn btn-sm btn-primary ${reference == "notes" && !selectedUsers || (Array.isArray(selectedUsers) && selectedUsers.length === 0) ? 'disabled' : ''}`} style={{ padding: '0.5rem 0.8rem' }} onClick={() => savePrincipalMessage(0)}>{T("Send")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {mainComments.map((mainComment, index) => (
                <React.Fragment key={mainComment.id}>
                    <div key={mainComment.id} className="card text-dark mb-2">
                        <div className="card-body p-3">
                            <div className="d-flex flex-start">
                                <div style={{ width: '100%' }}>
                                    <div className="d-flex">
                                        <div className="d-flex col-12 flex-column">
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <div className='d-flex align-items-center'>
                                                    <UserAvatarComment userId={mainComment.created_by} />
                                                    <div className='ms-2'>
                                                        <p className="m-0 text-sm fw-bold"><strong className='text-dark'>{mainComment.created_by_full_name}</strong></p>
                                                        <p className="m-0 font-weight-normal opacity-7" style={{ fontSize: '0.650rem' }}>{mainComment.created_at}</p>
                                                    </div>
                                                    {reference === "notes" && (
                                                        <div className='ms-2 d-flex align-items-center'>
                                                            <TeamUsersAvatar notesId={mainComment.id} engagementId={engagementId} />
                                                        </div>
                                                    )}
                                                </div>
                                                <DropdownMessagePrincipal iElement={mainComment.id} isCurrentUser={mainComment.created_by === Number(userId)} status={mainComment.status} />
                                            </div>
                                        </div>
                                    </div>
                                    {messageTo === mainComment.id ? (
                                        <textarea className="form-control mb-2" defaultValue={mainComment.message_description} rows={3} onChange={handleInputChange} />
                                    ) : (
                                        <p className='mb-0 text-sm font-weight-normal' style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mainComment.message_description) }}></p>
                                    )}
                                    <div className="d-flex col-12 justify-content-end">

                                        {messageTo === mainComment.id && (
                                            <React.Fragment>
                                                <button className='m-0 button btn btn-secondary btn-sm me-2' style={{ padding: '0.5rem 0.8rem' }} onClick={handleCloseEditClick}>{T("Cancel")}</button>
                                                <button className="m-0 button btn btn-primary btn-sm" style={{ padding: '0.5rem 0.8rem' }} onClick={() => updateMessage(mainComment.id)}><i className="fa fa-save text-xs"></i></button>
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between mt-1">
                                        <div className="d-flex align-items-center">
                                            {(completePermission && mainComment.status != 'completed' && mainComment.status != 'approved') && (
                                                <button className="m-0 me-2 d-flex justify-content-center align-items-center btn btn-sm btn-slack btn-icon-only rounded-circle" onClick={() => { setNoteFindingId(mainComment.id); setNewStatus("completed"); setUpdateNotesFindingsShowModal(true); }} title={T("completed")}>
                                                    <span className="d-flex btn-inner--icon"><i className="fa fa-circle-check text-xs"></i></span>
                                                </button>
                                            )}
                                            {(approvePermission && mainComment.status != null && mainComment.status !== 'refused' && mainComment.status !== 'approved') && (
                                                <button className="m-0 me-2 d-flex justify-content-center align-items-center btn btn-sm btn-twitter btn-icon-only rounded-circle" onClick={() => { setNoteFindingId(mainComment.id); setNewStatus("approved"); setUpdateNotesFindingsShowModal(true); }} title={T("approved")}>
                                                    <span className="d-flex btn-inner--icon"><i className="fa fa-thumbs-up text-xs"></i></span>
                                                </button>
                                            )}
                                            {(refusePermission && mainComment.status != null && mainComment.status !== 'refused' && mainComment.status !== 'approved') && (
                                                <button className="m-0 me-2 d-flex justify-content-center align-items-center btn btn-sm btn-youtube btn-icon-only rounded-circle" onClick={() => { setNoteFindingId(mainComment.id); setNewStatus("refused"); setUpdateNotesFindingsShowModal(true); }} title={T("refused")}>
                                                    <span className="d-flex btn-inner--icon"><i className="fa fa-thumbs-down text-xs"></i></span>
                                                </button>
                                            )}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {mainComment.completed_by != null && (
                                                <UserAvatarComment userId={mainComment.completed_by} />
                                            )}
                                            {mainComment.approved_by != null && (
                                                <UserAvatarComment userId={mainComment.approved_by} />
                                            )}
                                            {mainComment.refused_by != null && (
                                                <UserAvatarComment userId={mainComment.refused_by} />
                                            )}
                                            <p className="m-0 ms-2 d-flex">
                                                <span className={`badge ${mainComment.status == 'completed' ? 'bg-success' : mainComment.status == 'approved' ? 'bg-info' : 'bg-danger'}`}>{mainComment.status && T(mainComment.status)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {notesAndFindings
                        .filter(response => response.message_id === mainComment.id)
                        .sort((a, b) => a.id - b.id)
                        .map((response, responseIndex, array) => (
                            <div key={response.id} className="card text-dark ms-5 mb-2" style={{ backgroundColor: '#f8f9fa' }}>
                                <div className="card-body p-2">
                                    <div className="d-flex flex-start">
                                        <div style={{ width: '100%' }}>
                                            <div className="d-flex">
                                                <div className="d-flex col-12 flex-column">
                                                    <div className='d-flex justify-content-between align-items-start'>
                                                        <div className='d-flex align-items-center'>
                                                            <UserAvatarComment userId={response.created_by} />
                                                            <div className='ms-2'>
                                                                <p className="m-0 text-sm fw-bold"><strong className='text-dark'>{response.created_by_full_name}</strong></p>
                                                                <p className="m-0 font-weight-normal text-dark" style={{ fontSize: '0.650rem' }}>{response.created_at}</p>
                                                            </div>
                                                        </div>
                                                        <DropdownMenuReply iElement={response.id} IElementFather={mainComment.id} isPermission={response.created_by === Number(userId)} isLast={responseIndex === array.length - 1} status={mainComment.status} />
                                                    </div>
                                                </div>
                                            </div>
                                            {messageTo === response.id ? (
                                                <React.Fragment>
                                                    <input className="form-control mb-2" defaultValue={response.message_description} type="text" onChange={handleInputChange} />
                                                    <div className="d-flex justify-content-end">
                                                        <button className='button btn btn-secondary btn-sm me-2' style={{ padding: '0.5rem 0.8rem' }} onClick={handleCloseEditClick}>{T("Cancel")}</button>
                                                        <button className="button btn btn-primary btn-sm" style={{ padding: '0.5rem 0.8rem' }} onClick={() => updateMessage(response.id)}><i className="fa fa-save text-xs"></i></button>
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <p className="mb-0 text-sm font-weight-normal">
                                                    {response.message_description}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    {replyingTo === mainComment.id && (
                        <div key={mainComment.id} className="card text-dark mb-2 ms-5">
                            <div className="card-body p-4">
                                <div className="d-flex justify-content-start">
                                    <h4>{T("Reply")}</h4>
                                </div>
                                <div>
                                    <input className="form-control" type="text" value={message} onChange={handleChange} />
                                    <br />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="m-0 btn btn-sm btn-secondary me-2" style={{ padding: '0.5rem 0.8rem' }} onClick={() => setReplyingTo(null)}>{T("Cancel")}</button>
                                    <button className="m-0 btn btn-sm btn-primary" style={{ padding: '0.5rem 0.8rem' }} onClick={() => saveMessage(mainComment.id)}>{T("Send")}</button>
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            ))}
            <UpdateStatusNotesAndFindingsModal
                showModal={updateNotesFindingsShowModal}
                noteFindingId={noteFindingId}
                newStatus={newStatus}
                reference={reference}
                handleAddNotesAndFindings={updateStatus}
                handleAddNotesAndFindingsCancel={handleUpdateNotesAndFindingsCancel} />
        </div>
    );
}

export default NotesAndFindings;