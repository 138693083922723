import React from "react";
import { EvaluationIcon } from "./EvaluationIcon";
import Summernote from './Summernote';
import { T } from "../../utils/i18n-config"
import { Constants } from "../../utils/Constants";
import DOMPurify from "isomorphic-dompurify";


interface FindingsEvaluationAreaProps {
  highEvaluationText: string;
  mediumEvaluationText: string;
  lowEvaluationText: string;
  optionalEvaluationText: string;
  mode: "view" | "edit";
}
export const FindingsEvaluationArea = (
  { highEvaluationText, mediumEvaluationText, lowEvaluationText, optionalEvaluationText, mode } : FindingsEvaluationAreaProps
) => {
  return (
    <ul className={`${mode === 'view' ? Constants.CONTENT_CLASS : ''}`}>
      <li>
        <div className="form-group d-flex">
          <EvaluationIcon evaluation={1}/>
          <div dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize(`<b>${T("High")}: </b>`+highEvaluationText)}} />
        </div>
      </li>
      <li>
        <div className="form-group d-flex">
          <EvaluationIcon evaluation={2}/>
          <div dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize(`<b>${T("Medium")}: </b>`+mediumEvaluationText)}} />
        </div>
      </li>
      <li>
        <div className="form-group d-flex">
          <EvaluationIcon evaluation={3}/>
          <div dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize(`<b>${T("Low")}: </b>`+lowEvaluationText)}} />
        </div>
      </li>
      <li>
        <div className="form-group d-flex">
          <EvaluationIcon evaluation={4}/>
          <div dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize(`<b>${T("Optional")}: </b>`+optionalEvaluationText)}} />
        </div>
      </li>
    </ul>
  )
}


interface FindingsEvaluationEditProps {
  highEvaluationText: string;
  mediumEvaluationText: string;
  lowEvaluationText: string;
  optionalEvaluationText: string;
  handleHighEditorChange: (content: string) => void;
  handleMediumEditorChange: (content: string) => void;
  handleLowEditorChange: (content: string) => void;
  handleOptionalEditorChange: (content: string) => void;
  editionMode: boolean;
}

export const FindingsEvaluationEdit = (
  { highEvaluationText, mediumEvaluationText, lowEvaluationText, optionalEvaluationText, 
    handleHighEditorChange, handleMediumEditorChange, handleLowEditorChange, handleOptionalEditorChange, editionMode } : FindingsEvaluationEditProps
) => {
  return(
    <div>
      <div className="form-group">
        <label>{T("High")}</label>
        <Summernote value={highEvaluationText} onChange={handleHighEditorChange} showModal={editionMode} />
      </div>
      <div className="form-group">
        <label>{T("Medium")}</label>
        <Summernote value={mediumEvaluationText} onChange={handleMediumEditorChange} showModal={editionMode} />
      </div>
      <div className="form-group">
        <label>{T("Low")}</label>
        <Summernote value={lowEvaluationText} onChange={handleLowEditorChange} showModal={editionMode} />
      </div>
      <div className="form-group">
        <label>{T("Optional")}</label>
        <Summernote value={optionalEvaluationText} onChange={handleOptionalEditorChange} showModal={editionMode} />
      </div>
    </div>
  )
}
