import React, { useState, useEffect, useRef } from 'react';
import type { Chat } from '../../types/AttachmentsChatType';

/**
 * The sidebar of the chat component
 * @param chats - The chat list
 * @param chatsLoading - The loading state of the chats
 * @param onCreateChat - The function to create a chat
 * @param onSelectChat - The function to select a chat
 * @param onDeleteChat - The function to delete a chat
 */
const ChatSidebar = ({ chats, chatsLoading, onCreateChat, onSelectChat, onDeleteChat }: {
  chats: Chat[],
  chatsLoading: boolean,
  onCreateChat: () => void,
  onSelectChat: (chatId: number) => void,
  onDeleteChat: (chatId: number) => void
}) => {
  const chatButtonRef = useRef<HTMLButtonElement>(null);
  
  useEffect(() => {
    if (chatsLoading) chatButtonRef.current?.setAttribute('disabled', 'true');
    else chatButtonRef.current?.removeAttribute('disabled');
  }, [chatsLoading]);

  return (
    <aside className="p-4 w-25 h-100" style={{ backgroundColor: '#eeec', borderRadius: '.75rem 0 0 .75rem' }}>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="mb-0 fs-4 text-dark">Chats</h2>
        <button ref={chatButtonRef} className="btn bg-gradient-primary mb-0 px-3 fs-6" onClick={onCreateChat}>
          <i className="fa-solid fa-plus"></i>
        </button>
      </div>
      <div className="list-group mt-2">
        {
          chats.map((chat) => (
            <ChatOption
              key={chat.id}
              chat={chat}
              onSelectChat={onSelectChat}
              onDeleteChat={onDeleteChat}
            />
          ))
        }
      </div>
      <div className="d-flex justify-content-center mt-4 w-100">
      {
        chatsLoading && (
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )
      }
      </div>
    </aside>
  );
}

/**
 * A chat option
 * @param chat - The chat object
 * @param onSelectChat - The function to select the chat
 * @param onDeleteChat - The function to delete the chat
 */
function ChatOption({ chat, onSelectChat, onDeleteChat }: {
  readonly chat: Chat,
  readonly onSelectChat: (chatId: number) => void,
  readonly onDeleteChat: (chatId: number) => void
}) {
  const [onHover, setOnHover] = useState<boolean>(false);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (onHover) deleteButtonRef.current?.classList.remove('invisible');
    else deleteButtonRef.current?.classList.add('invisible');
  }, [onHover]);

  return (
    <div className="list-group-item list-group-item-action d-flex gap-3">
      <button
        type="button"
        className="flex-grow-1 text-truncate text-start"
        style={{ border: 'none', backgroundColor: 'transparent' }}
        onClick={() => onSelectChat(chat.id)}
      >
        {chat.chatTitle}
      </button>
      <div
        role="button"
        tabIndex={0}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <button
          type="button"
          ref={deleteButtonRef}
          className="btn btn-outline-danger mb-0 p-2 border-0 fs-6 invisible"
          onClick={() => onDeleteChat(chat.id)}
        >
          <i className="fa-solid fa-trash"></i>
        </button>
      </div>
    </div>
  )
}

export default ChatSidebar;