import { useState, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { decryptUniqueFilename } from "../../utils/utilities"
import { Permissions } from '../../models/special-document/ElementBase'
import { DisallowanceIncomesElement } from '../../models/special-document/DisallowanceIncomesElement'
import { ElementRepository } from '../../repository/special-document/ElementRepository'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'
import DropzoneComponent from '@/components/utils/Dropzone'

/**
 * Component that renders the edit mode of the disallowance incomes element.
 * @param iElement The disallowance incomes element.
 * @param handleDeleteElement The function to delete the element.
 * @param handleUpElement The function to move the element up.
 * @param handleDownElement The function to move the element down.
 */
const DisallowanceIncomesEditMode = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<DisallowanceIncomesElement>(iElement as DisallowanceIncomesElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [localsTemplateName, setLocalsTemplateName] = useState(element.localsTemplateName)
  const [exportsTemplateName, setExportsTemplateName] = useState(element.exportsTemplateName)
  const [returnsTemplateName, setReturnsTemplateName] = useState(element.returnsTemplateName)

  /**
   * Opens the configuration modal by updating the state.
   */
  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  /**
   * Handles the accept event of the modal.
   */
  async function handleOnAcceptModal() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastLocalsTemplateName = element.localsTemplateName
    const lastExportsTemplateName = element.exportsTemplateName
    const lastReturnsTemplateName = element.returnsTemplateName

    element.localsTemplateName = localsTemplateName
    element.exportsTemplateName = exportsTemplateName
    element.returnsTemplateName = returnsTemplateName

    const success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.localsTemplateName = lastLocalsTemplateName
      element.exportsTemplateName = lastExportsTemplateName
      element.returnsTemplateName = lastReturnsTemplateName

      setLocalsTemplateName(lastLocalsTemplateName)
      setExportsTemplateName(lastExportsTemplateName)
      setReturnsTemplateName(lastReturnsTemplateName)
      return window.htmlHelpers?.swalError()
    }
  }

  /**
   * Handles the cancel event of the modal.
   */
  function handleOnCancelModal() {
    setShowConfigurationsModal(false)
    setLocalsTemplateName(element.localsTemplateName)
    setExportsTemplateName(element.exportsTemplateName)
    setReturnsTemplateName(element.returnsTemplateName)
  }

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <p className="fs-6">{T("Local Deliveries Template")}: <em>{decryptUniqueFilename(localsTemplateName)}</em></p>
        <p className="fs-6">{T("Exportations Template")}: <em>{decryptUniqueFilename(exportsTemplateName)}</em></p>
        <p className="fs-6">{T("Credit Notes Template")}: <em>{decryptUniqueFilename(returnsTemplateName)}</em></p>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleOnAcceptModal}
        onCancel={handleOnCancelModal}
      >
        <div className="d-grid gap-4 mx-auto my-2" style={{ gridTemplateColumns: "repeat(2, 1fr)", width: "100%", maxWidth: "600px" }}>
          <div className="d-flex flex-column w-full">
            <h6 className="text-center">{T("Local Deliveries Template")}</h6>
            <DropzoneComponent
              elementId={element.id}
              renderingMode="edition"
              attachmentName={localsTemplateName}
              onUpload={(attachmentName) => setLocalsTemplateName(attachmentName)}
            />
          </div>
          <div className="d-flex flex-column w-full">
            <h6 className="text-center">{T("Exportations Template")}</h6>
            <DropzoneComponent
              elementId={element.id}
              renderingMode="edition"
              attachmentName={exportsTemplateName}
              onUpload={(attachmentName) => setExportsTemplateName(attachmentName)}
            />
          </div>
          <div className="d-flex flex-column w-full">
            <h6 className="text-center">{T("Credit Notes Template")}</h6>
            <DropzoneComponent
              elementId={element.id}
              renderingMode="edition"
              attachmentName={returnsTemplateName}
              onUpload={(attachmentName) => setReturnsTemplateName(attachmentName)}
            />
          </div>
        </div>
      </ModalWithButtons>
    </>
  );
}

export default DisallowanceIncomesEditMode;