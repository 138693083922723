import React, { useState, useRef, useEffect} from 'react';
import { T } from "../../utils/i18n-config"
import { FindingsEvaluationElement } from '../../models/special-document/FindingsEvaluationElement';
import EditModeBase from "./EditModeBase";
import EditModeProps from './EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalWithButtons from '../modals/AceptCancelModalBase';
import { ElementRepository } from '../../repository/special-document/ElementRepository';
import { FindingsEvaluationArea, FindingsEvaluationEdit } from '../utils/FindingsEvaluationAreaEdit';

const FindingsEvaluationEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<FindingsEvaluationElement>(iElement as FindingsEvaluationElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isEditable, setIsEditable] = useState(element.editable)

  const [highEvaluationText, setHighEvaluationText] = useState(element.high_evaluation_text)
  const [mediumEvaluationText, setMediumEvaluationText] = useState(element.medium_evaluation_text)
  const [lowEvaluationText, setLowEvaluationText] = useState(element.low_evaluation_text)
  const [optionalEvaluationText, setOptionalEvaluationText] = useState(element.optional_evaluation_text)

  const [tempHighEvaluationText, setTempHighEvaluationText] = useState(element.high_evaluation_text)
  const [tempMediumEvaluationText, setTempMediumEvaluationText] = useState(element.medium_evaluation_text)
  const [tempLowEvaluationText, setTempLowEvaluationText] = useState(element.low_evaluation_text)
  const [tempOptionalEvaluationText, setTempOptionalEvaluationText] = useState(element.optional_evaluation_text)

  useEffect(() => {
    if(element.state === "closed"){
      setIsEditable(false)
    }
  },[]);

  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  const handleConfigurationsModalAccept = async () => { 
    const elementRepository = new ElementRepository()
    element.high_evaluation_text = tempHighEvaluationText
    element.medium_evaluation_text = tempMediumEvaluationText
    element.low_evaluation_text = tempLowEvaluationText
    element.optional_evaluation_text = tempOptionalEvaluationText
    const lastEditable = element.editable
    element.editable = isEditable
    let success = await  elementRepository.saveElement("edition", element.args)
    if (success) {
      setHighEvaluationText(tempHighEvaluationText)
      setMediumEvaluationText(tempMediumEvaluationText)
      setLowEvaluationText(tempLowEvaluationText)
      setOptionalEvaluationText(tempOptionalEvaluationText)
      setShowConfigurationsModal(false);   
    } else {
      element.high_evaluation_text = highEvaluationText
      element.medium_evaluation_text = mediumEvaluationText
      element.low_evaluation_text = lowEvaluationText
      element.optional_evaluation_text = optionalEvaluationText
      element.editable = lastEditable
      window.htmlHelpers?.swalError()
    }
  };

  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    setTempHighEvaluationText(highEvaluationText)
    setTempMediumEvaluationText(mediumEvaluationText)
    setTempLowEvaluationText(lowEvaluationText)
    setTempOptionalEvaluationText(optionalEvaluationText)
    setIsEditable(element.editable)
  };

  const handleHighEditorChange = (content: string) => {
    setTempHighEvaluationText(content);
  }

  const handleMediumEditorChange = (content: string) => {
    setTempMediumEvaluationText(content);
  }

  const handleLowEditorChange = (content: string) => {
    setTempLowEvaluationText(content);
  }

  const handleOptionalEditorChange = (content: string) => {
    setTempOptionalEvaluationText(content);
  }

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <FindingsEvaluationArea
                highEvaluationText={highEvaluationText}
                mediumEvaluationText={mediumEvaluationText}
                lowEvaluationText={lowEvaluationText}
                optionalEvaluationText={optionalEvaluationText}
                mode='edit'
              />
            </div>
          </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Findings Evaluation settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
          <FindingsEvaluationEdit
            highEvaluationText={tempHighEvaluationText}
            mediumEvaluationText={tempMediumEvaluationText}
            lowEvaluationText={tempLowEvaluationText}
            optionalEvaluationText={tempOptionalEvaluationText}
            handleHighEditorChange={handleHighEditorChange}
            handleMediumEditorChange={handleMediumEditorChange}
            handleLowEditorChange={handleLowEditorChange}
            handleOptionalEditorChange={handleOptionalEditorChange}
            editionMode={showConfigurationsModal}
          />
      </ModalWithButtons>
    </div>
  );
};

export default FindingsEvaluationEditMode;