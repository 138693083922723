import { ElementArgs, PartnerOfac } from "./ElementArgs";
import { IElementBase, ElementBase } from "./ElementBase";

export class ElementOfacResults extends ElementBase implements IElementBase {
  private _text_modified: string;
  private _editable: boolean;
  private _ofac_results: PartnerOfac[]

  constructor(args:ElementArgs) {
    super(args);
    this._text_modified = args.text_modified ?? ""
    this._editable = args.editable ?? false
    this._ofac_results = args.ofac_results ?? []
  }
  
  get text_modified(): string {
    return this._text_modified;
  }
  get editable(): boolean {
    return this._editable;
  }
  get ofac_results(): PartnerOfac[] {
    return this._ofac_results;
  }
  set text_modified(text: string) {
    this._text_modified = text;
    this.args.text_modified = text;
  }
  set editable(editable: boolean) {
    this._editable = editable;
    this.args.editable = editable;
  }
  render(): void {
    console.log("render paragraph")
  }
}