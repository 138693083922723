import { useState } from 'react'
import { T } from '@/utils/i18n-config'
import { decryptUniqueFilename, setImportantAttr } from '@/utils/utilities'
import Dropzone from '../utils/Dropzone'

interface ToggleDropzoneProps {
  elementId: number
  attachmentName: string
  onUpload: (attachmentName: string) => void
}

/**
 * Component that allows to toggle between a dropzone and a text to display the attachment name
 * @param elementId The id of the element
 * @param attachmentName The name of the attachment
 * @param onUpload Callback to when the attachment is uploaded
 */
const ToggleDropzone = ({ elementId, attachmentName, onUpload }: ToggleDropzoneProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [attachment, setAttachment] = useState(attachmentName)

  /**
   * Callback to handle the toggle of the dropzone
   * It will switch between uploading and displaying the attachment name
   */
  function handleToggle() {
    setIsEditing(!isEditing)
  }

  /**
   * Callback to handle the change of the attachment in the dropzone
   * @param attachment The new attachment value
   */
  function handleUpload(attachment: string) {
    setAttachment(attachment)
    onUpload(attachment)
  }

  return (
    <div className="position-relative">
      <button
        style={{ right: "-4rem", transform: "translateY(-50%)" }}
        className="position-absolute top-50 p-2 bg-secondary border-0 rounded text-white lh-1" onClick={handleToggle}>
        <i className={isEditing ? "fa-solid fa-check" : "fa-solid fa-upload"} />
      </button>
      {isEditing ? (
        <Dropzone elementId={elementId} attachmentName={attachment} onUpload={handleUpload} />
      ) : (
        <div className="mt-2 py-3 px-4 border border-2 rounded-3" ref={(el) => setImportantAttr(el, "border-style", "dashed")}>
          <p className="m-0 fs-6">{T("Attachment")}:{" "}
            <em>{attachment ? decryptUniqueFilename(attachment) : T("No attachment yet")}</em>
          </p>
        </div>
      )}
    </div>
  )
}

export default ToggleDropzone