import React from 'react'

export const EvaluationIcon = ({evaluation}:{evaluation: number}) => {
  return(
    <div className={`icon icon-shape me-2 shadow border-radius-sm rounded-circle text-center d-flex align-items-center justify-content-center 
      ${
        evaluation === 1 ? 'bg-gradient-danger' :
        evaluation === 2 ? 'bg-gradient-info' :
        evaluation === 3 ? 'bg-gradient-warning' :
        evaluation === 4 ? 'bg-gradient-secondary' : 'bg-gradient-primary'
      }`}
      style={{height: '25px', width: '25px', minHeight: '25px', minWidth: '25px'}}>

      <i className={`fa-solid fa-1x top-0 color-white
      ${
        evaluation === 1 ? 'fa-h' :
        evaluation === 2 ? 'fa-m' :
        evaluation === 3 ? 'fa-l' :
        evaluation === 4 ? 'fa-n' : 'fa-question'
      }`}/>
    </div>
  )
}