import { useState } from 'react'
import { T } from '@/utils/i18n-config'
import { setImportantAttr } from '@/utils/utilities'

interface TableComponentProps<T = any> {
  labels: string[]
  values: T[][]
  className?: string
  inputColumns?: number[]
  typeColumns?: {
    index: number
    type: "integer" | "float" | "date" | "text"
  }[]
  onChange?: (rowIndex: number, colIndex: number, value: any) => void
}

/**
 * TableComponent renders a dynamic table based on provided labels, values, and configuration.
 * @param labels The table header labels.
 * @param values The table cell values.
 * @param className The table class name.
 * @param inputColumns The columns that require input.
 * @param typeColumns The columns that require type formatting.
 * @param onChange The change event handler.
 */
export default function TableComponent({ labels, values, className = "", inputColumns = [], typeColumns = [], onChange }: TableComponentProps) {
  /**
   * Formats the cell value based on its type and column configuration.
   * @param colIndex - The index of the current column.
   * @param cell - The cell value to be formatted.
   * @returns The formatted cell value.
   */
  function displayCellValue(colIndex: number, cell: boolean | string) {
    const matchedCell = typeColumns.find((column) => column.index === colIndex)
    const isDate = (date: string) => /\d{4}-\d{2}-\d{2}/.test(date)
    const isFloat = (number: string) => /^-?\d+\.\d+$/.test(number)
    const isInteger = (number: string) => /^\d+$/.test(number)
    
    if (typeof cell === "boolean") return cell ? T("Yes") : T("No")
    if ((typeof cell === "string" && isDate(cell)) || matchedCell?.type === "date") {
      return new Date(cell).toLocaleDateString()
    }
    if ((typeof cell === "string" && isFloat(cell)) || matchedCell?.type === "float") {
      return Intl.NumberFormat("en-US", { style: "decimal", maximumFractionDigits: 2 }).format(Number(cell))
    }
    if ((typeof cell === "string" && isInteger(cell)) || matchedCell?.type === "integer") {
      return Number(cell).toLocaleString()
    }
    return cell as string
  }

  /**
   * Handles updating the cell value.
   * @param rowIndex - The index of the row.
   * @param colIndex - The index of the column.
   * @param value - The new value for the cell.
   */
  function handleUpdate(rowIndex: number, colIndex: number, value: any) {
    onChange && onChange(rowIndex, colIndex, value)
  }

  return (
    <table
      className={`table table-responsive table-striped table-hover ${className}`}
      style={{ width: "fit-content", borderCollapse: "collapse" }}>
      <thead className="table-dark border">
        <tr>
          {labels.map((label, index) => (
            <th key={index} scope="col" className="text-center text-wrap align-middle">{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, colIndex) => (
              <td key={colIndex} className="text-center text-wrap border align-middle">
                {inputColumns.includes(colIndex) ? (
                  <TableCell
                    cell={cell}
                    type={typeColumns.find((column) => column.index === colIndex)?.type}
                    onChange={(value) => handleUpdate(rowIndex, colIndex, value)}
                  />
                ) : (displayCellValue(colIndex, cell))}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}


interface TableCellProps {
  cell: string | boolean
  type?: string
  onChange: (value: any) => void
}
/**
 * TableCell renders a table cell based on the cell type.
 * @param cell - The cell value.
 * @param type - The cell type.
 * @param onChange - The change event handler.
 */
function TableCell({ cell, type, onChange }: TableCellProps) {
  if (typeof cell === "boolean") return <CheckBox defaultValue={cell} onChange={onChange} />
  if (type === "date" && typeof cell === "string") return <DateInput defaultValue={cell} onChange={onChange} />
  if (type === "text" && typeof cell === "string") return (
    <input
      type="text"
      value={cell}
      className="form-control border-0"
      onChange={onChange}
    />
  )
  return (
    <textarea
      rows={2}
      value={cell}
      className="form-control border-0"
      style={{ fieldSizing: "content" }}
      onChange={({ target }) => onChange(target.value)}
    />
  )
}

interface DateInputProps {
  defaultValue: string
  onChange: (value: string) => void
}
/**
 * DateInput renders a date input.
 * @param defaultValue - The default value.
 * @param onChange - The change event handler.
 */
function DateInput({ defaultValue, onChange }: DateInputProps) {
  const [date, setDate] = useState(defaultValue)

  function handleChange(value: string) {
    setDate(value)
    onChange(value)
  }

  return (
    <input
      type="date"
      value={date}
      className="form-control border-0"
      onChange={({ target }) => handleChange(target.value)}
    />
  )
}


interface CheckBoxProps {
  defaultValue: boolean
  onChange: (value: boolean) => void
}
/**
 * CheckBox renders a checkbox input.
 * @param defaultValue - The default value.
 * @param onChange - The change event handler.
 */
function CheckBox({ defaultValue, onChange }: CheckBoxProps) {
  const [isChecked, setIsChecked] = useState(defaultValue)

  function handleChange(value: boolean) {
    setIsChecked(value)
    onChange(value)
  }

  return (
    <div className="form-check" ref={(el) => setImportantAttr(el, "padding-left", "6px")}>
      <input
        type="checkbox"
        checked={isChecked}
        className="form-check-input"
        onChange={({ target }) => handleChange(target.checked)}
      />
    </div>
  )
}
