import React, { useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { FindingsElement } from '../../models/special-document/FindingsElement';
import EditModeBase from "./EditModeBase";
import EditModeProps from './EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalBase from '../modals/ModalBase';

const ProbabilityAnalysisEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<FindingsElement>(iElement as FindingsElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  
  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  }

  const handleConfigurationsModalAccept = () => {
    setShowConfigurationsModal(false);
  }
  
  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={true}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={element.permissions as Permissions}>
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Probability Analysis")}</h6>
            </div>
          </div>
      </EditModeBase>
      <ModalBase 
        showModal={showConfigurationsModal}
        title={T("Probability Analysis")}
        size="xl">
            <div className="modal-body">
              <div>
                <h6>{T("Probability Analysis")}</h6>
              </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn bg-gradient-primary" onClick={handleConfigurationsModalAccept}>{T("Accept")}</button>
            </div>
      </ModalBase>
    </div>
  )
}
export default ProbabilityAnalysisEditMode;
