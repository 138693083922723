import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { IndependenceFormatElement } from "../../models/special-document/IndependenceFormatElement"
import EditModeBase from "./EditModeBase";
import EditModeProps from './EditModeProps';
import ModalBase from '../modals/ModalBase';
import { Permissions } from '../../models/special-document/ElementBase';

const IndependenceFormatViewMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<IndependenceFormatElement>(iElement as IndependenceFormatElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const handleConfguration = () => {
    setShowConfigurationsModal(true);
  }

  const handleConfigurationsModalAccept = (): void => {
    setShowConfigurationsModal(false);
  }
  
  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfguration}
        permissions={element.permissions as Permissions}>
          <div className="card h-100 bg-light px-4 py-2">
            <h6>
              {T("Independence Format Element Edit")}
            </h6>
          </div>
      </EditModeBase>
      <ModalBase 
        showModal={showConfigurationsModal}
        title={T("Independence Format Information")}    
        size="xl">
            <div className="modal-body">
              <div>
                <h6>{T("Independence Format Information")}</h6>
              </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn bg-gradient-primary" onClick={handleConfigurationsModalAccept}>{T("Accept")}</button>
            </div>
      </ModalBase>
    </div>
  )

}

export default IndependenceFormatViewMode;