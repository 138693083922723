import { type Page } from './Page'
import { type Menu } from './Menu'

/**
 * This class represents a menu item.
 */
export class MenuItem {
  private readonly _name: string
  private readonly _label: string
  private readonly _icon: string | null
  private readonly _url: string | null
  private _page: Page | null
  private readonly _subMenu: Menu | null
  private _index: number = 0
  private _active: boolean = false
  private _visible: boolean = true

  /**
   * The constructor.
   * @param name - The name.
   * @param label - The label.
   * @param icon - The icon.
   * @param url - The url.
   * @param page - The page.
   * @param subMenu - The sub menu.
   */
  constructor (name: string, label: string, icon: string | null, url: string | null = null, page: Page | null = null, subMenu: Menu | null = null, visible: boolean = true) {
    this._name = name
    this._label = label
    this._icon = icon
    this._url = url
    this._page = page
    this._subMenu = subMenu
    this._visible = visible
  }

  /**
   * The name.
   * @returns The name.
   */
  get name (): string {
    return this._name
  }

  /**
   * The label.
   * @returns The label.
   */
  get label (): string {
    return this._label
  }

  /**
   * The url.
   * @returns The url.
   */
  get url (): string | null {
    return this._url
  }

  /**
   * The page.
   * @returns The page.
   */
  get page (): Page | null {
    return this._page
  }

  /**
   * The page.
   */
  set page (page: Page | null) {
    this._page = page
  }

  /**
   * The index.
   * @returns The index.
   */
  get index (): number {
    return this._index
  }

  /**
   * The index.
   */
  set index (index: number) {
    this._index = index
  }

  /**
   * The icon.
   * @returns The icon.
   */
  get icon (): string | null {
    return this._icon
  }

  /**
   * The sub menu.
   * @returns The sub menu.
   */
  get subMenu (): Menu | null {
    return this._subMenu
  }

  /**
   * The active flag.
   * @returns The active flag.
   */
  get active (): boolean {
    return this._active
  }

  /**
   * The visible flag.
   * @returns The visible flag.
   */
  get visible (): boolean {
    return this._visible
  }

  /**
   * The active flag.
   */
  set active (active: boolean) {
    this._active = active
    if (this._page != null) {
      this._page.visible = active
    }
  }

  /**
   * Check if the menu item has sub items.
   * @returns True if the menu item has sub items, false otherwise.
   */
  hasSubItems (): boolean {
    return this._subMenu != null && this._subMenu.items.length > 0
  }
}
