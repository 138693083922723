import { useState } from 'react'
import { T } from '@/utils/i18n-config'
import { sanitizeHTML } from '@/utils/utilities'
import Summernote from '../utils/Summernote'

interface ToggleSummernoteParagraphProps {
  initialText: string
  onChange: (text: string) => void
}

/**
 * Component that allows to toggle between a paragraph and a summernote to edit it
 * @param initialText Initial text to display
 * @param onChange Callback to be called when the text is changed 
 */
const ToggleSummernoteParagraph = ({ initialText, onChange }: ToggleSummernoteParagraphProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [text, setText] = useState(initialText)

  /**
   * Callback to handle the toggle of the summernote
   * It will switch between editing and displaying the text
   */
  function handleToggle() {
    setIsEditing(!isEditing)
  }

  /**
   * Callback to handle the change of the text in the summernote
   * @param value The new text value
   */
  function handleChange(value: string) {
    setText(value)
    onChange(value)
  }

  return (
    <div className="position-relative">
      <button
        style={{ right: "-4rem", transform: "translateY(-50%)" }}
        className="position-absolute top-50 p-2 bg-secondary border-0 rounded text-white lh-1" onClick={handleToggle}>
        <i className={isEditing ? "fa-solid fa-check" : "fa-solid fa-pencil"} />
      </button>
      {isEditing ? (
        <Summernote value={text} onChange={handleChange} />
      ) : (
        <div>
          <span className="mb-1 fw-bold">{T("Justification")}:</span>
          {text && <div className="text-wrap" dangerouslySetInnerHTML={{ __html: sanitizeHTML(text) }} />}
        </div>
      )}
    </div>
  )
}

export default ToggleSummernoteParagraph