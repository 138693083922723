import { UserRepository } from '../repository/UserRepository';
import { type UserAvatarData } from '../components/commons/UserAvatar';

export class UserAvatarFactory {
  private static _instance: UserAvatarFactory;
  private readonly _userRepository: UserRepository;
  private _cache: Record<number, UserAvatarData>;

  public constructor(engagementVariableRepository?: UserRepository) {
    this._userRepository = engagementVariableRepository || new UserRepository();
    this._cache = {};
  }

  public static getInstance(): UserAvatarFactory {
    if (!UserAvatarFactory._instance) {
      UserAvatarFactory._instance = new UserAvatarFactory();
    }
    return UserAvatarFactory._instance;
  }

  create = async (userId: number): Promise<UserAvatarData> => {
    if (this._cache[userId]) {
      return this._cache[userId];
    }
    const data = await this._userRepository.getUserAvatar(userId);    
    this._cache[userId] = data;
    return data;
  }
}
