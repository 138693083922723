import React, { useEffect, useState } from 'react'
import { T } from "../../utils/i18n-config"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ConditionalParagraphElementWithGlobalVariable } from "../../models/special-document/ConditionalParagraphElementWithGlobalVariable"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { ConditionalParagraphOption } from "../../models/special-document/ElementArgs"
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import Summernote from '../utils/Summernote';
import { Permissions } from '../../models/special-document/ElementBase'
import DOMPurify from 'isomorphic-dompurify';
import { Constants } from '../../utils/Constants';

const ConditionalParagraphWithVariableViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<ConditionalParagraphElementWithGlobalVariable>(iElement as ConditionalParagraphElementWithGlobalVariable)
  const [elementOptions, setElementOptions] = useState<ConditionalParagraphOption[]>(element.options)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [lastOptions, setLastOptions] = useState<ConditionalParagraphOption[]>(JSON.parse(JSON.stringify(elementOptions)))
  const [selectedOption, setSelectedOption] = useState<ConditionalParagraphOption | null>(null)
  const [selectedOptionMessage, setSelectedOptionMessage] = useState<string>("")

  useEffect(() => {
    const currentOptionValue = element.optionValueSelected
    const foundOption = elementOptions.find(option => {
      if(currentOptionValue === null) return false
      else if(option.condition === T("Equals")) return option.value === currentOptionValue
      else if(option.condition === T("Contains")) return (currentOptionValue as string).includes(option.value)
      else if(option.condition === T("Not Contains")) return !(currentOptionValue as string).includes(option.value)
      else if(option.condition === T("Starts With")) return (currentOptionValue as string).startsWith(option.value)
      else if(option.condition === T("Ends With")) return (currentOptionValue as string).endsWith(option.value) 
    });
    if (foundOption) {
      setSelectedOption(foundOption)
      setSelectedOptionMessage(foundOption.message)
    }
  }, [])

  const handleEdit = () => {
    setSelectedOptionMessage(selectedOption?.message ?? "")
    setShowConfigurationsModal(true)
  };

  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);    
    const copySelectedOption = JSON.parse(JSON.stringify(selectedOption))
    copySelectedOption.message = selectedOptionMessage
    const copyElementOptions = JSON.parse(JSON.stringify(elementOptions)) as ConditionalParagraphOption[]
    const index = copyElementOptions.findIndex(option => option.id === copySelectedOption.id)
    copyElementOptions[index] = copySelectedOption
    element.options = copyElementOptions
    setSelectedOption(copySelectedOption)
    setElementOptions(copyElementOptions)

    const elementRepository = new ElementRepository()
    let success = await elementRepository.saveElement("view", element.args)
    if (success) {
      setLastOptions(JSON.parse(JSON.stringify(copyElementOptions)))
    }
    else {
      setElementOptions(lastOptions)
      window.htmlHelpers?.swalError()
    }
  };

  const handleEditModalCancel = () => {
    setElementOptions(lastOptions)
    setSelectedOptionMessage(selectedOption?.message ?? "")
    setShowConfigurationsModal(false)
  };

  const handleChangeSummerNote = (value: string) => {
    setSelectedOptionMessage(value)
  }

  if (selectedOption){
    return (
      <div>
        <ViewModeBase
          isEditable={selectedOption.editable}
          handleEdit={handleEdit}
          permissions={element.permissions as Permissions}>
          {elementOptions.length === 0 && 
            <div className="card h-100 bg-light">
              <div className="card-body p-3">
                <h6>{T("Without configuration")}</h6>
                <div className="chart pt-3">
                  <p>{T("Configure this element from the template.")}</p>
                </div>
              </div>
            </div>
          }
          {elementOptions.length > 0 && element.optionValueSelected === null &&
            <div className="card h-100 bg-light">
              <div className="card-body p-3">
                <h6>{element.variable}</h6>
                <div className="chart pt-3">
                  <p>{T("Waiting to get variable value.")}</p>
                </div>
              </div>
            </div>
          }
          {elementOptions && elementOptions.length > 0 && element.optionValueSelected != null &&
            <div className="">
              {selectedOption &&
                <div className={`${Constants.CONTENT_CLASS}`}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedOption.message) }} />
              }
            </div>
          }
        </ViewModeBase>
        <ModalWithButtons
          showModal={showConfigurationsModal}
          title={element.variable}
          size="xl"
          onAccept={handleEditModalAccept}
          onCancel={handleEditModalCancel}>
          <Summernote
            value={selectedOptionMessage}
            onChange={handleChangeSummerNote}
            showModal={showConfigurationsModal} 
          />
        </ModalWithButtons>
      </div>
    );
  }
  else{
    return <></>
  }
};

export default ConditionalParagraphWithVariableViewMode;
