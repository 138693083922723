import React from 'react';
import Board from '../special-document/Board';


interface IndependenceDocumentType {
    engagementId: number
    documentId: number
    setDocumentId: React.Dispatch<React.SetStateAction<number>>
    documentReference: string
}


export const IndependenceDocument = ({props}:{props: IndependenceDocumentType}) => {

    const handleBack = () => {
        props.setDocumentId(0);
    }

    return(
        <div className='d-flex flex-column' style={{position: 'relative'}}>
            <div className='d-flex align-items-center ps-4 pt-3'
                style={{
                    position: 'sticky',
                    top: '2rem',
                    zIndex: '1000'
                }}>
                    <i className="fas fa-arrow-left cursor-pointer" onClick={handleBack}/>
            </div>
            <div className='px-6'>
                <Board
                    name='Independence'
                    reference={props.documentReference}
                    engagementReference={undefined}
                    renderingMode='view'
                    engagementID={props.engagementId}
                    permissions={{
                        read: true,
                        create: false,
                        update: false,
                        delete: false
                    }}
                />
            </div>
        </div>
    )
}