import { ElementBase, IElementBase } from './ElementBase'
import { ElementArgs, PartnerOfac } from './ElementArgs'

export class ConditionalParagraphOfacElement extends ElementBase implements IElementBase{
  private _messageYes: string
  private _messageNo: string
  private _ofac_results: PartnerOfac[]

  constructor(args:ElementArgs){
    super(args)
    this._messageYes = args.messageYes ?? ""
    this._messageNo = args.messageNo ?? ""
    this._ofac_results = args.ofac_results ?? []
  }

  get messageYes(): string {
    return this._messageYes
  }
  get messageNo(): string {
    return this._messageNo
  }
  get ofac_results(): PartnerOfac[] {
    return this._ofac_results;
  }
  set messageYes(messageYes: string) {
    this._messageYes = messageYes
    this.args.messageYes = messageYes
  }
  set messageNo(messageNo: string) {
    this._messageNo = messageNo
    this.args.messageNo = messageNo
  }

  render(): void {
    console.log("ConditionalParagraphOfacElement render") 
  }
}