import { type Menu } from '../models/menu/Menu'
import { type MenuItem } from '../models/menu/MenuItem'
import { HtmlHelpers } from '../utils/HtmlHelpers'

/**
 * This class is responsible for rendering the page.
 */
export class PageRenderer {
  private readonly _menu: Menu
  private readonly _pageContainerId: string = 'page-container'
  private _firstRender: boolean = true
  private readonly _htmlHelpers: HtmlHelpers = new HtmlHelpers()

  /**
   * The constructor.
   * @param menu - The menu.
   */
  constructor (menu: Menu) {
    this._menu = menu
  }

  /**
   * Render the page.
   */
  render (): void {
    this._menu.items.forEach((item) => {
      this._firstRender ? this.renderLoad(item) : this.renderUpdate(item)
    })
    this._firstRender = false
  }

  /**
   * This method renders the page the first time.
   * @param menuItem - The menu item.
   */
  private renderLoad (menuItem: MenuItem): void {
    if (!menuItem.hasSubItems()) return

    const pageContainer = document.getElementById(this._pageContainerId)
    if (pageContainer == null) return

    menuItem.subMenu?.items.forEach(subItem => {
      this.processSubItem(subItem, pageContainer)
    })
  }

  /**
   * This method processes the sub item.
   * @param subItem - The sub item.
   * @param container - The container.
   */
  private processSubItem (subItem: MenuItem, container: HTMLElement): void {
    const pageId = subItem.page?.target
    if (pageId == null) return

    this.appendPageDiv(subItem, container, pageId)
    this.loadComponentIfActive(subItem, pageId)
  }

  /**
   * This method appends the page div.
   * @param subItem - The sub item.
   * @param container - The container.
   * @param pageId - The page id.
   */
  private appendPageDiv (subItem: MenuItem, container: HTMLElement, pageId: string): void {
    container.innerHTML += `<div id="${pageId}" style="display: ${subItem.active ? 'block' : 'none'}; height: 100vh;"></div>`
  }

  /**
   * This method renders the page the first time.
   * @param subItem - The sub item.
   * @param pageId - The page id.
   */
  private loadComponentIfActive (subItem: MenuItem, pageId: string): void {
    if (subItem.active && subItem.page != null && subItem.url != null) {
      subItem.page.isLoaded = true
      this._htmlHelpers.setSpinner(pageId)
      web2py_component(subItem.url, pageId)
    }
  }

  /**
   * This method renders the page the first time.
   * @param menuItem - The menu item.
   */
  private renderUpdate (menuItem: MenuItem): void {
    if (!menuItem.hasSubItems()) return

    menuItem.subMenu?.items.forEach(subItem => {
      this.updateSubItem(subItem)
    })
  }

  /**
   * This method updates the sub item.
   * @param subItem - The sub item.
   */
  private updateSubItem (subItem: MenuItem): void {
    const pageId = subItem.page?.target
    if (pageId == null) return

    const page = document.getElementById(`${pageId}`)
    if (page == null) return

    page.style.display = subItem.active ? 'block' : 'none'

    this.loadComponentIfNotLoaded(subItem, pageId)
  }

  /**
   * This method loads the component if not loaded.
   * @param subItem - The sub item.
   * @param pageId - The page id.
   */
  private loadComponentIfNotLoaded (subItem: MenuItem, pageId: string): void {
    if (subItem.active && subItem.page != null && !subItem.page.isLoaded && subItem.url != null) {
      subItem.page.isLoaded = true
      this._htmlHelpers.setSpinner(pageId)
      web2py_component(subItem.url, pageId)
    }
  }
}
