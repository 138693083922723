import { CommitmentFormArgs, type CommitmentQuestion } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class CommitmentFormElement extends ElementBase implements IElementBase {
  private _questions: CommitmentQuestion[]

  constructor(args: CommitmentFormArgs) {
    super(args)
    this._questions = args.questions || []
  }

  get questions(): CommitmentQuestion[] {
    return this._questions
  }

  get args(): CommitmentFormArgs {
    return {
      ...super.args,
      questions: this._questions,
    }
  }

  set questions(questions: CommitmentQuestion[]) {
    this._questions = questions
  }

  render(): void {}
}