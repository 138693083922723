import React, {useState, useEffect} from 'react';
import ModalBase from  '../modals/ModalBase';
import { IndependenceFormatRepository, BasicResponse, IndependenceFormatFormsType, IndependenceFormatStatsType } from '../../repository/special-document/IndependenceFormatRepository';
import { BarGraph } from '../utils/BarGraph';
import {IndependenceFormsTable} from '../tables/IndependenceFormsTable';
import { T } from "../../utils/i18n-config"


interface IndependenceFormatConfigProps {
  engagmentId: number
  showConfigurationsModal: boolean
  setShowConfigurationsModal: React.Dispatch<React.SetStateAction<boolean>>

}


export const IndependenceFormatInfo = ({ ...props }: IndependenceFormatConfigProps) => {
  const [independenceFormatStats, setIndependenceFormatStats] = useState<IndependenceFormatStatsType>()
  const [independenceFormatForms, setIndependenceFormatForms] = useState<IndependenceFormatFormsType[]>()
  const [statsLoaded, setStatsLoaded] = useState<boolean>(false)
  const [formsLoaded, setFormsLoaded] = useState<boolean>(false)
  const [selection, setSelection] = useState<string>('stats') 

  useEffect(() => {
    setStatsLoaded(false)
    setFormsLoaded(false)
    const independenceFormatRepository = new IndependenceFormatRepository()
    if(props.showConfigurationsModal) {
      handleClear()
      if (selection === 'stats') {
        independenceFormatRepository.getStatsInfo(props.engagmentId)
        .then((data: IndependenceFormatStatsType | BasicResponse) => {
            if ('error_message' in data) {
              console.error('Error: ', data.error_message)
              return
            }
            setIndependenceFormatStats(data as IndependenceFormatStatsType)
            setStatsLoaded(true)
          })
          .catch((error) => {
            console.error('There has been a problem with your fetch operation:', error)
          })
      }
      else if (selection === 'forms') {
        independenceFormatRepository.getFormsInfo(props.engagmentId)
          .then((data: IndependenceFormatFormsType[] | BasicResponse) => {
            if ('error_message' in data) {
              console.error('Error: ', data.error_message)
              return
            }
            
            setIndependenceFormatForms(data as IndependenceFormatFormsType[])
            setFormsLoaded(true)
          })
          .catch((error) => {
            console.error('There has been a problem with your fetch operation:', error)
          })
      }
      else {
        console.error('Invalid selection')
      }
    }
  }, [selection, props.showConfigurationsModal])

  const handleClose = () => {
    handleClear()
    props.setShowConfigurationsModal(false)
  }

  const handleSelection = (selection: string) => {
    setSelection(selection)
  }

  const handleClear = () => {
    setIndependenceFormatStats(
      {
        progress: 0,
        approve_formats: 0,
        reject_formats: 0,
        missing_formats: 0
      }
    )
    setIndependenceFormatForms(
      [
        {
          user_id: 0,
          user_name: '',
          state: null,
          email: '',
          role: '',
          role_description: '',
          documents: [
            {
              document_id: 0,
              document_description: '',
              document_reference: '',
              document_state: null
            }
          ]
        }
      ]
    )
  }
  
  return(
    <ModalBase
      showModal={props.showConfigurationsModal}
      title=""
      size="xl"
      fixedTop={true}>
        <div className='d-flex flex-column flex-grow-1'> 
          <div className='switch-container bg-light'>
            <div className={`switch ${selection === 'stats' ? 'switch-to-left' : 'switch-to-right'}`}/>
            <div 
              className='switch-left'
              onClick={() => handleSelection('stats')}>
              <strong className={`${selection === 'stats' ? 'text-white' : ''}`}>
                {T("Independence Format Stats")}
              </strong>
            </div>
            <div 
              className='switch-right'
              onClick={() => handleSelection('forms')}>
              <strong className={`${selection === 'forms' ? 'text-white' : ''}`}>
                {T("Independence Format Forms")}
              </strong>
            </div>
          </div>
          <div className='d-flex flex-column flex-grow-1'>
            {
              (selection === 'stats' && statsLoaded) ?
              <IndependenceFormatStatsComponent 
                independenceFormatStats={independenceFormatStats} 
              />
              : selection === 'stats' &&
              <Loading />
            }
            {
              (selection === 'forms' && formsLoaded) ?
              <IndependenceFormatFormsComponent 
                independenceFormatForms={independenceFormatForms} 
                engagementID={props.engagmentId} 
              />
              : selection === 'forms' &&
              <Loading />
            }
          </div>

          <div className='mt-auto d-flex gap-2 px-3'>
            <button onClick={handleClose} className="btn btn-secondary" style={{padding: '6px 23px'}}>
              <span style={{color: 'white', textTransform: 'capitalize', fontSize: '0.8rem'}}>
                {T("Close")}
              </span>
            </button>
          </div>
        </div>
    </ModalBase>
  )
}

const Loading = () => {
  return (
    <div className='d-flex flex-column flex-grow-1 justify-content-center align-items-center'>
      <div className="spinner-border text-secondary" role="status"/>
      <span className='text-secondary mt-2'>{T("Loading")}...</span>
    </div>
  )
}


const IndependenceFormatStatsComponent = ({ independenceFormatStats }: { independenceFormatStats: IndependenceFormatStatsType | undefined }) => {
  return (
    <div className='d-flex flex-column flex-grow-1 py-2 px-6'>
      <div className="progress-wrapper">
        <div className="text-start my-3">
          <strong style={{marginBottom: '0px', fontSize: '1.4rem'}}>
            {T("Total Progress By Current Stage")}
          </strong>
        </div>
        <div className="progress-info">
          <div className="progress-percentage">
            <span className="text-sm font-weight-bold">
              {independenceFormatStats?.progress}%
            </span>
          </div>
        </div>
        <div className="progress progress-bar-custom scale-in-out mb-4" style={{height: '25px'}}>
          <div 
            className="progress-bar bg-gradient-primary" 
            role="progressbar" 
            style={{
              width: `${independenceFormatStats?.progress}%`,
              height: '25px',
            }}/>

        </div>
      </div>
      <BarGraph 
        data={[
          {
            label: T('Approved'),
            value: independenceFormatStats?.approve_formats ?? 0,
            color: 'rgba(203, 12, 159, 0.2)',
            borderColor: 'rgba(203, 12, 159)',
            hoverColor: 'rgba(203, 12, 159)',
            hoverBorderColor: 'rgba(203, 12, 159)'

          },
          {
            label: T('Refused'),
            value: independenceFormatStats?.reject_formats ?? 0,
            color: 'rgba(234, 6, 6, 0.2)',
            borderColor: 'rgba(234, 6, 6)',
            hoverColor: 'rgba(234, 6, 6)',
            hoverBorderColor: 'rgba(234, 6, 6)'
          },
          {
            label: T('Pending'),
            value: independenceFormatStats?.missing_formats ?? 0,
            color: 'rgba(23, 193, 232, 0.2)',
            borderColor: 'rgba(23, 193, 232)',
            hoverColor: 'rgba(23, 193, 232)',
            hoverBorderColor: 'rgba(23, 193, 232)'            
          }
        ]}
        title={T("Independence Format Stats")}
        xLabel={T('State')}
        yLabel={T('Quantity')}
      />
    </div>
  )
}

const IndependenceFormatFormsComponent = (
  { independenceFormatForms, engagementID }: 
  { independenceFormatForms: IndependenceFormatFormsType[] | undefined, engagementID: number }
) => {
  return (
    <IndependenceFormsTable 
      engagementID={engagementID}
      independenceFormatForms={independenceFormatForms}
    />
  )
}