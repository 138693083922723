import React, { ReactNode, useState, useEffect } from 'react';
import ModalBase, { ModalBaseProps } from './ModalBase';
import { ElementBase } from "../../models/special-document/ElementBase";
import { EngagementVariableRepository } from '../../repository/EngagementVariableRepository'
import { T } from "../../utils/i18n-config"
import Select from '../commons/Select';

interface AceptCancelModalBaseProps extends ModalBaseProps {
    onAccept: () => void;
    onCancel: () => void;
    onAcceptVariable?: ({ name, value }: { name: string, value: string }) => void;
    currentElement?: ElementBase;
    children?: ReactNode;
}

const AceptCancelModalBase: React.FC<AceptCancelModalBaseProps> = ({ onAccept, onCancel, onAcceptVariable, currentElement, children, ...modalBaseProps }) => {
    const [showVariableOption, setShowVariableOption] = useState(false)
    const [globalVariableName, setGlobalVariableName] = useState("")
    const [globalVariableValue, setGlobalVariableValue] = useState("")
    const [variables, setVariables] = useState<any[]>([])

    async function getInitialVariables() {
        const engagementVariableRepository = new EngagementVariableRepository()
        const engagementVariables = await engagementVariableRepository.getAll()
        return engagementVariables.map((variable) => ({ id: variable.name, label: variable.name }))
    }

    useEffect(() => {
        getInitialVariables().then((variables) => setVariables(variables))
        if (currentElement && currentElement.global_variable) {
            setShowVariableOption(currentElement.global_variable.value !== "")
            setGlobalVariableName(currentElement.global_variable.name)
            setGlobalVariableValue(currentElement.global_variable.value)
        }
    }, []);

    useEffect(() => {
        if (currentElement && currentElement.global_variable) {
            currentElement.global_variable = {
                name: globalVariableName,
                value: globalVariableValue
            }
        }
    }, [globalVariableName, globalVariableValue])

    return (
        <ModalBase {...modalBaseProps}>
            {currentElement && (
                <div className="modal-header flex-column align-items-start gap-2">
                <div className="form-check form-switch d-flex align-items-center ps-0">
                    <input
                        id="showOptions"
                        role="switch"
                        type="checkbox"
                        className="form-check-input mt-0"
                        checked={showVariableOption}
                        onChange={() => setShowVariableOption(!showVariableOption)}
                    />
                    <label className="form-check-label mb-0" htmlFor="showOptions">{T("Display by Global Variable")}</label>
                </div>
                {showVariableOption && (
                    <div className="d-flex align-items-center gap-2 w-100">
                        <div className="form-group w-100">
                            <label htmlFor="variableName">{T("Variable Name")}</label>
                            <Select
                                hasSearch={false}
                                defaultValue={[{ id: globalVariableName, label: globalVariableName }]}
                                options={variables}
                                onChange={(value) => setGlobalVariableName(value[0].id)}
                            />
                        </div>
                        <div className="form-group w-100">
                            <label htmlFor="variableName">{T("Value to Compare")}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={globalVariableValue}
                                onChange={(e) => setGlobalVariableValue(e.target.value)}
                            />
                        </div>
                    </div>
                )}
                </div>
            )}
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
                <button type="button" className="btn bg-gradient-secondary" onClick={onCancel}>{T("Cancel")}</button>
                <button type="button" className="btn bg-gradient-primary" onClick={onAccept}>{T("Accept")}</button>
            </div>
        </ModalBase>
    );
};

export default AceptCancelModalBase;
