import React, { useEffect, useState } from 'react';
import ModalBase from '../modals/ModalBase';
import { T } from "../../utils/i18n-config"
import DatatableSearch from '../tables/DatatableSearch';
import { EngagementVariableFactory } from '../../factory/EngagementVariableFactory';
import { copyTextToClipboard } from '../../utils/utilities';


export interface VariablesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectVariable: (variableName: string) => void;
  copyToClipboard?: boolean;
}


const VariablesModal: React.FC<VariablesModalProps> = ({ isOpen, onClose, onSelectVariable, copyToClipboard }) => {

    const headers = [T('Name'), T('Description'), T('Action')];
    const [variables, setVariables] = useState<any[][]>([]);

    useEffect(() => {
      const fetchVariables = async () => {
        const engagementVariableFactory = EngagementVariableFactory.getInstance();
        const engagementVariables = await engagementVariableFactory.getAll();
        const data: any[][] = [];
        engagementVariables.forEach((variable) => {
          data.push([variable.name, variable.description, [{ class: 'bg-gradient-secondary action-cell action-select', label: T("Select") }]]);
        });
        setVariables(data);
      };
      fetchVariables();
    }, []);

    const handleButtonClick = (rowIndex: number, btnClass: string) => {
      if (btnClass.includes('action-select')) {
        const variable = variables[rowIndex][0] as string;
        if (copyToClipboard) {
          window.htmlHelpers?.showToast('success', T('Variable copied to clipboard. Paste it where needed using Ctrl + V.'));
          copyTextToClipboard('{{' + variable + '}}');
        }
        onSelectVariable(variable);
      }
    };

    return (
      <ModalBase
        zIndex={1060}
        showModal={isOpen}
        title={T("Engagement variables")}
        size="lg">
        <div className="modal-body">
          <DatatableSearch
            data={variables}
            isVisible={isOpen}
            headers={headers}
            handleButtonClick={handleButtonClick}
            fixedHeight={true}
          />
        </div>
        <div className="modal-footer">
          <button type="button" className="btn bg-gradient-secondary" onClick={onClose}>{T("Close")}</button>
        </div>
      </ModalBase>
    );
};

export default VariablesModal;
