import { T } from "../../utils/i18n-config"
import { ElementArgs } from "./ElementArgs";
import { IElementBase, ElementBase } from "./ElementBase";

export class ElementParagraph extends ElementBase implements IElementBase {
  private _text_default: string;
  private _text_modified: string;
  private _editable: boolean;

  constructor(args:ElementArgs) {
    super(args);
    this._text_modified = args.text_modified ?? ""
    this._editable = args.editable ?? false
    this._text_default = args.text_default ?? T("Add text here")
  }
  
  get text_default(): string {
    return this._text_default;
  }
  get text_modified(): string {
    return this._text_modified;
  }
  get editable(): boolean {
    return this._editable;
  }
  set text_modified(text: string) {
    this._text_modified = text;
    this.args.text_modified = text;
  }
  set editable(editable: boolean) {
    this._editable = editable;
    this.args.editable = editable;
  }
  set text_default(text: string) {
    this._text_default = text;
    this.args.text_default = text;
  }
  render(): void {
    console.log("render paragraph")
  }
}