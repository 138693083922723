import { ElementBase, IElementBase } from './ElementBase'
import { ElementArgs } from './ElementArgs'
import { ConditionalParagraphOption } from './ConditionalParagraphOption'

export class ConditionalMathFormulaElementMessage extends ElementBase implements IElementBase{
  private _mathOperation : string
  private _options: ConditionalParagraphOption[]
  constructor(args:ElementArgs){
    super(args)
    this._mathOperation = args.mathOperation ?? "Add math operation"
    if (Array.isArray(args.options) ){
      this._options = args.options
    }else if (typeof args.options === 'string'){
      try {
        this._options = JSON.parse(args.options);
      } catch (e) {
        this._options = []
      }
    }else{
      this._options = []
    }
  }

  get mathOperation(): string {
      return this._mathOperation
  }

  get options(): ConditionalParagraphOption[] {
    return this._options;
  }
  

  set mathOperation(mathOperation: string) {
      this._mathOperation = mathOperation
  }

  set options(options: ConditionalParagraphOption[]) {
    this._options = options;
  }


  
  

  render(): void {
    console.log("ConditionalMathFormulaElementMessage render") 
  }
}