import { useEffect, useState, useRef, useMemo } from "react"

export interface Option {
  id: string;
  label: string;
}

interface SelectProps {
  id?: string;
  options: Option[];
  multiple?: boolean;
  className?: string;
  placeholder?: string;
  defaultValue?: Option[];
  hasSearch?: boolean;
  hasDelete?: boolean;
  position?: "top" | "bottom";
  onChange: (value: Option[]) => void;
}

const Select = ({
  id,
  options,
  multiple,
  className,
  placeholder,
  defaultValue,
  hasSearch,
  hasDelete = true,
  position = "bottom",
  onChange,
}: SelectProps) => {
  const [search, setSearch] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(defaultValue ?? []);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const selectInputContainerRef = useRef<HTMLDivElement>(null);
  const selectInputRef = useRef<HTMLInputElement>(null);
  const activeSearch = hasSearch ?? true;
  const firstRender = useRef(true);
  const filteredOptions = useMemo(() => {
    if (search === '') return options;
    return options.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, options]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        selectContainerRef.current &&
        selectInputContainerRef.current &&
        !selectContainerRef.current.contains(event.target as Node)
      ) {
        selectInputContainerRef.current.classList.remove('active');
      }
    }

    function focusInputContainer() {
      selectInputContainerRef.current?.classList.add('active');
      selectInputRef.current?.focus();
    }

    document.addEventListener('click', handleClickOutside);
    selectInputContainerRef.current?.addEventListener('click', focusInputContainer);
    selectInputRef.current?.addEventListener('click', focusInputContainer);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      selectInputContainerRef.current?.removeEventListener('click', focusInputContainer);
      selectInputRef.current?.removeEventListener('click', focusInputContainer);
    };
  }, []);

  useEffect(() => {
    if (defaultValue) setSelectedOptions(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    if (selectedOptions.length > 0) onChange(selectedOptions);

    if (selectedOptions.length === 0) selectInputRef.current?.classList.remove('py-2');
    else selectInputRef.current?.classList.add('py-2');
  }, [selectedOptions]);

  function handleSelectOption(option: Option) {
    const isOptionSelected = selectedOptions.find(
      (selectedOption) => selectedOption.id === option.id
    );
    if (!multiple) setSelectedOptions([option]);
    else if (!isOptionSelected) setSelectedOptions([...selectedOptions, option]);
    else setSelectedOptions(
      selectedOptions.filter(
        (selectedOption) => selectedOption.id !== option.id
      )
    );

    if (!multiple) {
      selectInputContainerRef.current?.classList.remove('active');
      selectInputRef.current?.blur();
    }
  }

  function handleRemoveOption(option: Option) {
    setSelectedOptions(
      selectedOptions.filter(
        (selectedOption) => selectedOption.id !== option.id
      )
    );
  }

  function handleClearOptions() {
    setSelectedOptions([]);
  }

  function checkActiveOption(option: Option) {
    return selectedOptions.find(
      (selectedOption) => selectedOption.id === option.id
    );
  }

  return (
    <div ref={selectContainerRef} className={`${className ?? ""} custom-select2-container position-relative d-flex flex-column gap-2`}>
      <div ref={selectInputContainerRef} className={
        `custom-select2-input active position-relative py-2 px-2 pe-4 border rounded-3 ${selectedOptions.length > 0 ? "filled" : ""}`
      }>
        <div className="custom-select2-selected d-flex flex-wrap gap-2 pe-2">
          {!activeSearch && selectedOptions.length === 0 && (
            <span className="py-1 ps-3 text-muted">{placeholder}</span>
          )}
          {multiple && selectedOptions.map((option) => (
            <div key={option.id} className="py-1 px-2 border rounded-2">
              <span>{option.label}</span>
              <button className="bg-transparent border-0 ms-1 p-0 text-sm" onClick={() => handleRemoveOption(option)}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          ))}
          {(multiple == null) && selectedOptions.length > 0 && (
            <div className="py-1 px-2">
              <span className="ms-1 text-wrap">{selectedOptions[0].label}</span>
            </div>
          )}
        </div>
        {selectedOptions.length > 0 && hasDelete && (
          <button
            type="button"
            className="custom-select2-clear p-0 bg-transparent border-0 text-muted fs-5"
            onClick={handleClearOptions}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        )}
        {activeSearch && (
          <input
            type="text"
            id={id}
            value={search}
            ref={selectInputRef}
            className="custom-select2-search py-2 border-0"
            placeholder={placeholder}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
      </div>
      <div
        className={`custom-select2-options position-absolute d-none w-100 bg-white border rounded-3 ${(position === "top" ? " bottom-100 mb-2" : " top-100 mt-2")}`}
        style={{ zIndex: 10 }}
      >
        <ul className="list-group d-flex flex-column">
          {filteredOptions.map((option) => (
            <li
              key={option.id}
              className={`list-group-item d-flex justify-content-between align-items-center border-0 ${checkActiveOption(option) ? 'active' : ''
                }`}
              onClick={() => handleSelectOption(option)}
            >
              <span className="text-wrap">{option.label}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Select;
