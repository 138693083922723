import { IConditionalParagraphOption } from "./IConditionalParagraphOption";
import { createId } from "../../utils/utilities";


export class ConditionalParagraphOption implements IConditionalParagraphOption {
  private _id: number = createId();
  private _value?: string;
  private _message: string;
  private _condition?: string;
  private _editable: boolean = false;

  constructor(message: string, value?: string, condition?: string, editable?: boolean, id?: number) {
    this._value = value;
    this._message = message;
    this._condition = condition;
    this._editable = editable ?? false;
    this._id = id ?? createId();
  }

  clone(): ConditionalParagraphOption {
    return new ConditionalParagraphOption(this._message, this._value, this._condition, this._editable, this._id);
  }

  get id(): number {
    return this._id;
  }

  get value(): string {
    return this._value? this._value : "";
  }
  get message(): string {
    return this._message;
  }
  get condition(): string {
    return this._condition? this._condition : "";
  }

  get editable(): boolean {
    return this._editable;
  }

  set value(value: string) {
    this._value = value;
  }

  set message(message: string) {
    this._message = message;
  }

  set condition(condition: string) {
    this._condition = condition;
  }

  set editable(editable: boolean) {
    this._editable = editable;
  }

  toJSON(): any {
    return {
      id: this._id,
      value: this._value,
      message: this._message,
      condition: this._condition,
      editable: this._editable
    }
  }
}