import { ElementArgs } from './ElementArgs'
import { ElementBase, IElementBase} from './ElementBase'

export class OfacLinkElement extends ElementBase implements IElementBase{
  private _resultsLink: string
  constructor(args:ElementArgs){
    super(args)
    this._resultsLink = args.resultsLink ?? ""
  }

  get resultsLink(): string {
    return this._resultsLink
  }

  set resultsLink(resultsLink: string) {
    this._resultsLink = resultsLink
  }

  render(): void {
    console.log("OfacLinkElement render") 
  }
}