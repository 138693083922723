import { type MenuItem } from './MenuItem'
import { BreadcrumbLevel } from './BreadcrumbLevel'

/**
 * This class represents a menu.
 */
export class Menu {
  private readonly _items: MenuItem[] = []

  /**
   * The constructor.
   * @param item - This is the MenuItem.
   */
  addItem (item: MenuItem): void {
    // set index item
    item.index = this._items.length
    this._items.push(item)
  }

  /**
   * The items.
   * @returns The items.
   */
  get items (): MenuItem[] {
    return this._items
  }

  /**
   * Navigate to the page.
   * @param name - The name of the page.
   * @returns True if the page was found.
   */
  navigateTo (name: string): boolean {
    let found = false
    this._items.forEach(item => {
      if (item.name === name) {
        item.active = true
        found = true
      } else {
        item.active = false
      }
    })
    if (!found) {
      this._items.forEach(item => {
        if (item.subMenu != null) {
          found = item.subMenu.navigateTo(name)
          if (found) {
            item.active = true
          }
        }
      })
    }
    return found
  }

  /**
   * Set the breadcrumb level.
   * @param index - The index.
   */
  breadcrumbSetLevel (index: number): void {
    this._items.forEach(item => {
      if (item.active) {
        item.subMenu?._items.forEach(subItem => {
          if (subItem.active) {
            subItem.page?.breadcrumb.removeLevels(index)
          }
        })
      }
    })
  }

  /**
   * Add the breadcrumb level.
   * @param url - The url.
   * @param label - The label.
   */
  breadcrumbAddLevel (url: string, label: string, reactContainerId: string | null = null): void {
    this._items.forEach(item => {
      if (item.active) {
        item.subMenu?._items.forEach(subItem => {
          if (subItem.active) {
            const breadcrumb = new BreadcrumbLevel(label, url, reactContainerId)
            subItem.page?.breadcrumb.addLevel(breadcrumb)
          }
        })
      }
    })
  }

  /**
   * Back the breadcrumb level with a new url.
   * @param url - The url.
   * @param label - The label.
   */
  breadcrumbBackLevelWithNewUrl (url: string, label: string, reactContainerId: string | null = null): void {
    this._items.forEach(item => {
      if (item.active) {
        item.subMenu?._items.forEach(subItem => {
          if (subItem.active) {
            const breadcrumb = new BreadcrumbLevel(label, url, reactContainerId)
            subItem.page?.breadcrumb.setPassLevel(breadcrumb)
          }
        })
      }
    })
  }

  /**
   * get Back the breadcrumb index.
   */
  private getBreadcrumbBackIndex(): number {
    const activeItem = this._items.find(item => item.active);
    if (!activeItem) {
        return 0;
    }

    return this.getBackIndexFromSubMenu(activeItem);
  }

  private getBackIndexFromSubMenu(item: MenuItem): number {
    if (!item.subMenu?._items) {
        return 0;
    }

    const activeSubItem = item.subMenu._items.find(subItem => subItem.active);
    if (!activeSubItem) {
        return 0;
    }

    const countLevels = activeSubItem.page?.breadcrumb.countLevels();
    return countLevels ? countLevels - 2 : 0;
  }

  /**
   * Back the breadcrumb level.
   */
  breadcrumbBackLevel (): void {
    this.breadcrumbSetLevel(this.getBreadcrumbBackIndex())
  }

  getReactContainerId(): string | null {
    const activeItem = this._items.find(item => item.active);
    if (!activeItem) {
        return null;
    }

    return this.getReactContainerIdFromSubMenu(activeItem);
  }

  private getReactContainerIdFromSubMenu(item: MenuItem): string | null {
    if (!item.subMenu?._items) {
        return null;
    }

    const activeSubItem = item.subMenu._items.find(subItem => subItem.active);
    if (!activeSubItem) {
        return null;
    }

    return activeSubItem.page?.breadcrumb.getReactContainerId() ?? null;
  }

  setReactContainerId(reactContainerId: string): void {
    const activeItem = this._items.find(item => item.active);
    if (!activeItem) {
        return;
    }

    this.setReactContainerIdFromSubMenu(activeItem, reactContainerId);
  }

  private setReactContainerIdFromSubMenu(item: MenuItem, reactContainerId: string): void {
    if (!item.subMenu?._items) {
        return;
    }

    const activeSubItem = item.subMenu._items.find(subItem => subItem.active);
    if (!activeSubItem) {
        return;
    }

    activeSubItem.page?.breadcrumb.setReactContainerId(reactContainerId);
  }

}
