import React from 'react';
import ReactDOM from 'react-dom';
import AppNotifications from '../components/notifications/AppNotifications';


interface AppNotificationsArgs {
  containerId: string;
  userId: number;
}

export function AppNotificationRenderer(args: AppNotificationsArgs) {
  ReactDOM.render(
  <AppNotifications userId={args.userId} />,
  document.getElementById(args.containerId)
  );
}
