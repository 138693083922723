import { Board } from "../models/special-document/Board"
import { BoardRender } from "../renderer/special-document/BoardRender"
import { DocumentSpecialFactory}  from "../factory/special-document/DocumentSpecialFactory"
import { ElementFactory } from "../factory/special-document/ElementFactory"
import { DocumentSpecialRender } from "../renderer/special-document/DocumentSpecialRender"
import { ElementRenderFactory } from "../factory/special-document/ElementRenderFactory"
import { DocumentSpecial } from "../models/special-document/DocumentSpecial"
import { ElementRepository } from "../repository/special-document/ElementRepository"
import { DocumentSpecialRepository } from "../repository/special-document/DocumentSpecialRepository"
import { BoardContext } from "./BoardContext"


export class BoardController{
  private _board: Board | null = null
  private _boardRender: BoardRender | null = null
  private _documentSpecialFactory: DocumentSpecialFactory
  private _elementFactory: ElementFactory
  private _elementRenderFactory: ElementRenderFactory
  private _renderingMode: string = "edition"
  private _documentSpecial: DocumentSpecial | null = null
  private _boardContext: Map<string, BoardContext> = new Map()

 

  constructor(documentSpecialFactory: DocumentSpecialFactory = new DocumentSpecialFactory(new DocumentSpecialRepository(),
  new ElementFactory(new ElementRepository())),
    elementFactory: ElementFactory = new ElementFactory(new ElementRepository())){  
    this._documentSpecialFactory = documentSpecialFactory
    this._elementFactory = elementFactory
    this._elementRenderFactory = ElementRenderFactory.getInstance()
   }

   getBoardContext(boardId: string): BoardContext | undefined {
    return this._boardContext.get(boardId)
   }

   async loadBoard(reference:string, renderingMode: string, boardContentID:string,
    permissions: {created: boolean, updated: boolean, deleted: boolean, read: boolean},
    engagementID: number){
      console.log("loadBoard")
      console.log("reference", reference)
      console.log("renderingMode", renderingMode)
      console.log("permissions", permissions)
      console.log("engagementID", engagementID)
      const context = await BoardContext.createInstance(reference, renderingMode, 
        this._documentSpecialFactory, permissions,boardContentID, engagementID)
      this._boardContext.set(reference, context)
      
      if (context.boardRender){
        if (context.renderingMode === "edition"){
          context.boardRender.renderInEdit(reference)}
        else{
          context.boardRender.renderInView(reference) 
        }
      }
      if (context.documentSpecial){
        const documentRender = new DocumentSpecialRender(context.documentSpecial, 
          context.permissions, context.documentContentID)
        documentRender.render(context.renderingMode, reference)
        
      }
    
   }

   openElementTypeModal(reference: string){
    const context = this._boardContext.get(reference)
    if (context){
      context.boardRender?.openElementTypeModal(reference)
    }else{
      throw new Error('Failed to open element type modal')
    }
    
   }

   async addElement(reference:string, id: string){
    const context = this._boardContext.get(reference)
    if(context){
      if (context.documentSpecial){
        let iElement = await this._elementFactory.createClassByType(Number(id), context.documentSpecial.id)
        
        if (iElement){
          context.documentSpecial.addElement(iElement)
          const documentRender = new DocumentSpecialRender(context.documentSpecial, context.permissions, context.documentContentID)
          documentRender.render(context.renderingMode, reference)
        }
      }
    }
  }

  async openElementConfigModal(reference: string, contenID: string, id: string){
    const context = this._boardContext.get(reference)
    if (context){
      if (context.documentSpecial){
        let iElement = context.documentSpecial.getElement(Number(id))
        let classElementRender = this._elementRenderFactory.createClassByType(iElement, 
          context.permissions, reference, context.renderingMode)
        classElementRender.configRender(contenID, context.renderingMode, Number(id))
      }
    }
  }

  async confirmElementDeletionModal(reference: string, contenID:string, id:string){
    const context = this._boardContext.get(reference)
    if (context){
      if (context.documentSpecial){
        let iElement = context.documentSpecial.getElement(Number(id))
        let classElementRender = this._elementRenderFactory.createClassByType(iElement, context.permissions,
           reference, context.renderingMode)
        classElementRender.confirmDeletion(contenID, Number(id))
      }
    }
  }

  async saveElementConfig(reference: string, contenID:string, id: string){
    const context = this._boardContext.get(reference)
    if (context){
      if (context.documentSpecial){
        console.log("id", id)
        let iElement = context.documentSpecial.getElement(Number(id))
        console.log("iElement", iElement)
        console.log("ID de iElement:", iElement.id);
        let classElementRender = this._elementRenderFactory.createClassByType(iElement, context.permissions, 
          reference, context.renderingMode)
        classElementRender.processConfigModal(context.renderingMode)
        console.log("iElement", iElement)
        console.log("ID de iElement:", iElement.id);
        if (context.renderingMode === "edition"){
          classElementRender.renderInEdit(contenID, iElement.id)
        }else{
          classElementRender.renderInView(contenID, iElement.id)
        }
        const elementRepository = new ElementRepository()
        iElement = context.documentSpecial.getElement(Number(id))
        console.log("element guardar", iElement)
        console.log("ID de iElement:", iElement.id);
        console.log("args a guardar", iElement.args)
        elementRepository.saveElement(iElement.args)
      }
    }
  }

  async deleteElement(reference:string, id: string, idElementToRemove: string){
    const context = this._boardContext.get(reference)
    if (context){
      if (context.documentSpecial){
        let iElement = context.documentSpecial.getElement(Number(id))
        let classElementRender = this._elementRenderFactory.createClassByType(iElement, context.permissions,
           reference, context.renderingMode)
        classElementRender.deleteElement(idElementToRemove)
        context.documentSpecial.deleteElement(Number(id))
        const elementRepository = new ElementRepository()
        await elementRepository.deleteElement(Number(id))
        const documentRender = new DocumentSpecialRender(context.documentSpecial, context.permissions, context.documentContentID)
        documentRender.render(context.renderingMode, reference)
      }
    }
    
  }

  async renderElementWithAdditionalFields(reference: string, id: string, fieldsContainerID: string){
    const context = this._boardContext.get(reference)
    if(context){
      if (context.documentSpecial){
        let iElement = context.documentSpecial.getElement(Number(id))
        let classElementRender = this._elementRenderFactory.createClassByType(iElement, context.permissions, 
          reference, context.renderingMode)
        classElementRender.addFields(fieldsContainerID)
      }
    }
  }

  async removeLastAddedFieldPair(reference: string, id: string){
    const context = this._boardContext.get(reference)
    if (context){
      if (context.documentSpecial){
        let iElement = context.documentSpecial.getElement(Number(id))
        let classElementRender = this._elementRenderFactory.createClassByType(iElement, context.permissions,
           reference, context.renderingMode)
        classElementRender.removeLastPairElement()
      }
    }
    
  }

  async renderToggleText(reference: string, id: string){
    const context = this._boardContext.get(reference)
    if (context){
      if (context.documentSpecial){
        let iElement = context.documentSpecial.getElement(Number(id))
        let classElementRender = this._elementRenderFactory.createClassByType(iElement, context.permissions,
           reference, context.renderingMode)
        classElementRender.toggleText()
      }
    }
    
  }

  async seepHelp(reference: string, id: string, destiny: string, contenID: string){
    const context = this._boardContext.get(reference)
    if (context){
      if (context.documentSpecial){
        let iElement = context.documentSpecial.getElement(Number(id))
        let classElementRender = this._elementRenderFactory.createClassByType(iElement, context.permissions, 
          reference, context.renderingMode)
        if (destiny === "viewVariables"){
          classElementRender.viewVariables(contenID)}
        else if (destiny === "viewAccounts"){
          classElementRender.viewAccounts(contenID)
        }
      }
    }
    
    
  }


}
