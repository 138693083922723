import { AttachDocumentArgs } from './ElementArgs'
import { ElementBase, IElementBase} from './ElementBase'

export class AttachDocumentElement extends ElementBase implements IElementBase{
  private _extensions: string[]
  private _fileCount: number
  private _buttonDescription: string = "Attach Document"
  private _attachmentName: string
  private _templateName: string
  private _title: string
  
  constructor(args: AttachDocumentArgs) {
    super(args)
    this._extensions = args.extensions? args.extensions : []
    this._fileCount = args.fileCount? args.fileCount : 0
    this._buttonDescription = args.buttonDescription? args.buttonDescription : "Attach Document"
    this._attachmentName = args.attachmentName ? args.attachmentName : ""
    this._templateName = args.templateName ? args.templateName : ""
    this._title = args.title ? args.title : ""
  }

  get extensions(): string[] {
    return this._extensions
  }
  get fileCount(): number {
    return this._fileCount
  }
  get buttonDescription(): string {
    return this._buttonDescription
  }
  get title(): string {
    return this._title
  }
  get templateName(): string {
    return this._templateName
  }
  get attachmentName(): string {
    return this._attachmentName
  }
  get args(): AttachDocumentArgs {
    return {
      ...super.args,
      attachmentName: this._attachmentName,
      templateName: this._templateName,
      title: this._title
    }
  }

  set extensions(value: string[]) {
    this._extensions = value
  }
  set fileCount(value: number) {
    this._fileCount = value
  }
  set buttonDescription(value: string) {
    this._buttonDescription = value
  }
  set title(value: string) {
    this._title = value
  }
  set templateName(value: string) {
    this._templateName = value
  }
  set attachmentName(value: string) {
    this._attachmentName = value
  }
 

  render(): void {
    console.log("AttachDocumentElement render") 
  }
}