import { useState, useRef, useEffect } from 'react'
import { T } from '@/utils/i18n-config'
import { arrayToOptions } from '@/utils/utilities'
import { Permissions } from '@/models/special-document/ElementBase'
import type { Transaction } from '@/models/special-document/ElementArgs'
import { ElementRepository } from '@/repository/special-document/ElementRepository'
import { InternalAuditorElement } from '@/models/special-document/InternalAuditorElement'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'
import Select, { type Option } from '../commons/Select'

/**
 * Component to display the internal auditor element in edit mode
 * @param iElement the internal auditor element
 * @param handleDeleteElement the function to handle the deletion of the element
 * @param handleUpElement the function to handle the up movement of the element
 * @param handleDownElement the function to handle the down movement of the element
 */
const InternalAuditorEditMode = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<InternalAuditorElement>(iElement as InternalAuditorElement).current
  const [transactions, setTransactions] = useState<Option[]>([])
  const selectedTransactions = arrayToOptions(element.transactions.map(({ title }) => title))

  /**
   * Saves the selected options per transaction into the element
   * @param selectedTransactions the selected transactions
   */
  async function saveUpdatedTransactions(selectedTransactions: Transaction[]) {
    const elementRepository = new ElementRepository()
    const lastSelectedTransactions = element.transactions
    element.transactions = selectedTransactions
    const success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.transactions = lastSelectedTransactions
      return window.htmlHelpers?.swalError();
    }
  }

  /**
   * Get the transaction flows titles
   */
  async function getTransactionFlows() {
    const elementRepository = new ElementRepository()
    const { success, data } = await elementRepository.getTransactionFlows(null, element.documentId, element.id)
    if (!success) return window.htmlHelpers?.swalError();
    setTransactions(arrayToOptions(data.map((title) => title)))
  }

  /**
   * Handles the selection of the options
   * @param options the selected options
   */
  function handleSelectOptions(options: Option[]) {
    const selectedOptions = options.map((option) => ({ title: option.label, options: [] }))
    saveUpdatedTransactions(selectedOptions)
  }

  useEffect(() => {
    getTransactionFlows()
  }, [])

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={() => { }}
        permissions={iElement.permissions as Permissions}
      >
        <div>
          <h6 className="fw-bolder">{T("Choose the transaction flows used for the internal audit function")}</h6>
          <div className="d-flex flex-column">
            <label htmlFor="transaction-flow-selector" className="text-sm fw-bold">{T("Transaction Flows")}:</label>
            <Select
              multiple
              position="top"
              id="transaction-flow-selector"
              defaultValue={selectedTransactions}
              options={transactions}
              placeholder={T("Select one or more options")}
              onChange={handleSelectOptions}
            />
          </div>
        </div>
      </EditModeBase>
    </>
  )
}

export default InternalAuditorEditMode