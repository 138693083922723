import { ElementTypeRepository } from "../../repository/special-document/ElementTypeRepository"
import { IElementBase } from "../../models/special-document/ElementBase"
import { ElementTypeFactory } from "./ElementTypeFactory"
import { IElementRender } from "../../renderer/special-document/IElementRender"
import { IPermissions } from "../../controller/IPermissions"

export class ElementRenderFactory {
  private static _instance: ElementRenderFactory | null = null;
  private _elementTypeRepository: ElementTypeRepository;
  private _elementTypeFactory: ElementTypeFactory;
  private _cache: Map<string, IElementRender> = new Map(); // Cache for the createClassByType function

  private constructor(elementTypeRepository: ElementTypeRepository = new ElementTypeRepository()) {
    this._elementTypeRepository = elementTypeRepository;
    this._elementTypeFactory = new ElementTypeFactory();
  }

  // Singleton instance accessor
  public static getInstance(): ElementRenderFactory {
    if (!this._instance) {
      this._instance = new ElementRenderFactory();
    }
    return this._instance;
  }

  

  clearCache() {
    this._cache.clear()  // reset the caché.
  }

   createClassByType(iElementBase: IElementBase, permissions:IPermissions, reference:string, renderingMode:string): IElementRender {
    const cacheKey = `${iElementBase.type}_${iElementBase.id}_${renderingMode}`;
    
    // Check if the result is already in the cache
    if (this._cache.has(cacheKey)) {
      return this._cache.get(cacheKey)!;
    }

    const classType = this._elementTypeFactory.getElementType(iElementBase.type);
    if (classType) {
      const instance = new classType.classRender(iElementBase, permissions, reference)
      this._cache.set(cacheKey, instance); // Cache the result
      return instance
    }
    throw new Error("ID no found")
  }
}
