import React, { useState, useEffect, useRef, useMemo } from 'react'
import { T } from "../../utils/i18n-config"
import { arrayToOptions } from "../../utils/utilities"
import { Constants } from '../../utils/Constants'
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { QuestionsTableElement } from '../../models/special-document/QuestionsTableElement'
import type { ItemType, AnswerType, ReferenceType, ItemOption } from '../../models/special-document/ElementArgs'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'
import Select from "../commons/Select"
import TagInput from "../commons/TagInput"

const { ITEM_TYPE_OPTIONS, ANSWER_TYPE_OPTIONS, ANSWER_REFERENCE_OPTIONS } = Constants

const QuestionWithAttachmentEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<QuestionsTableElement>(iElement as QuestionsTableElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [items, setItems] = useState(element.items)
  const [itemText, setItemText] = useState<string>("")
  const [itemIndex, setItemIndex] = useState<number>(-1)
  const [itemType, setItemType] = useState<ItemType>(ITEM_TYPE_OPTIONS[0].toLowerCase() as ItemType)
  const [answerType, setAnswerType] = useState<AnswerType[]>([])
  const [answerReference, setAnswerReference] = useState<ReferenceType>(ANSWER_REFERENCE_OPTIONS[0].toLowerCase() as ReferenceType)
  const [uniqueOptions, setUniqueOptions] = useState<string[]>([])

  const currentItemType = useMemo(() => ITEM_TYPE_OPTIONS.find((type) => itemType.includes(type.toLowerCase() as ItemType))!, [itemType])
  const currentAnswerTypes = useMemo(() => ANSWER_TYPE_OPTIONS.filter((type) => answerType.includes(type.toLowerCase() as AnswerType)), [answerType])
  const currentAnswerReference = useMemo(() => ANSWER_REFERENCE_OPTIONS.filter((type) => answerReference.includes(type.toLowerCase() as ReferenceType))!, [answerReference])
  const hasExternal = answerType.includes("external")
  const hasUnique = answerType.includes("unique")

  function handleConfiguration() {
    setItems(element.items)
    setShowConfigurationsModal(true)
  }

  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastItems = element.items
    element.items = items

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.items = lastItems
      setItems(lastItems)
      window.htmlHelpers?.swalError()
    }
  }

  function handleConfigurationsModalCancel() {
    setItems(element.items)
    setShowConfigurationsModal(false)
  }

  function handleSavingItemOption() {
    if (itemText !== "") {
      const answers = answerType?.map((type) => ({
        type,
        content: type === "external" ? answerReference : "",
        options: type === "unique" ? uniqueOptions : []
      }))
      if (itemIndex === -1) {
        setItems([...items, { type: itemType, text: itemText, answers: answers }])
      } else {
        setItems([...items].with(itemIndex, { type: itemType, text: itemText, answers: answers }))
      }
    }
  }

  function handleEditItem(index: number, item: ItemOption) {
    setItemIndex(index)
    setItemType(item.type)
    setItemText(item.text)
    setAnswerType(item.answers?.map((answer) => answer.type) ?? [])
    const externalAnswer = item.answers?.find((answer) => answer.type === "external")
    if (externalAnswer) setAnswerReference(externalAnswer.content as ReferenceType)
    const uniqueAnswer = item.answers?.find((answer) => answer.type === "unique")
    if (uniqueAnswer) setUniqueOptions(uniqueAnswer.options ?? [])
  }

  function handleDeleteItem(index: number) {
    setItems(items.filter((_, i) => i !== index))
  }

  useEffect(() => {
    setItemText("")
    setItemIndex(-1)
    setAnswerType([])
  }, [items])

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <ItemsPreview items={items} />
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div>
          <div className="d-flex align-items-start gap-4">
            <div className="form-group w-100">
              <label htmlFor="item-type" className="form-label">{T("Item Type")}</label>
              <Select
                hasSearch={false}
                options={arrayToOptions(ITEM_TYPE_OPTIONS, T)}
                defaultValue={arrayToOptions([currentItemType], T)}
                placeholder={T("Select an option")}
                onChange={(values) => setItemType(values[0].id as ItemType)}
              />
            </div>
            <div className="form-group w-100">
              <label htmlFor="item-text" className="form-label">{T("Text")}</label>
              <input
                type="text"
                id="item-text"
                value={itemText}
                className="form-control fs-6"
                style={{ paddingBlock: "0.85rem" }}
                placeholder={T("Insert the text")}
                onChange={(e) => setItemText(e.target.value)}
              />
            </div>
          </div>
          {itemType === "question" && (
            <div className="d-flex align-items-start gap-4">
              <div className="form-group w-50">
                <label htmlFor="answer-type" className="form-label">{T("Answer Type")}</label>
                <Select
                  multiple
                  hasSearch={false}
                  options={arrayToOptions(ANSWER_TYPE_OPTIONS, T)}
                  defaultValue={arrayToOptions(currentAnswerTypes, T)}
                  placeholder={T("Select an option")}
                  onChange={(values) => setAnswerType(values.map((value) => value.id as AnswerType))}
                />
              </div>
              {hasExternal && (
                <div className="form-group w-50">
                  <label htmlFor="item-reference" className="form-label">{T("Reference")}</label>
                  <Select
                    hasSearch={false}
                    options={arrayToOptions(ANSWER_REFERENCE_OPTIONS, T)}
                    defaultValue={arrayToOptions([...currentAnswerReference], T)}
                    placeholder={T("Select an option")}
                    onChange={(values) => setAnswerReference(values[0].id as ReferenceType)}
                  />
                </div>
              )}
              {hasUnique && (
                <div className="form-group w-50">
                  <label htmlFor="item-reference" className="form-label">{T("Posible Answers")}</label>
                  <TagInput
                    defaultValue={uniqueOptions}
                    placeholder={T("Add an option and press Enter")}
                    onChange={(values) => setUniqueOptions(values)}
                  />
                </div>
              )}
            </div>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSavingItemOption}
          >{T("Add")}</button>
        </div>
        <ItemsList
          items={items}
          onEditItem={handleEditItem}
          onDeleteItem={handleDeleteItem}
        />
      </ModalWithButtons>
    </>
  );
}


interface ItemsPreviewProps {
  items: ItemOption[]
}

function ItemsPreview({ items }: ItemsPreviewProps) {
  return (
    <div className="card card-body p-3 bg-light">
      <details>
        <summary>
          <h5 className="d-inline ms-2">
            {items.length === 0 && T("No items")}
            {items.length > 0 && items[0].text}
          </h5>
        </summary>
        {items.length > 0 && (<div className="mb-2" />)}
        {items.slice(1).map((item, index) => (
          <div key={index} className="mb-3">
            {item.type === "subtitle" && (
              <b className="mt-2">{item.text}</b>
            )}
            {item.type === "question" && (
              <p className="mb-1">{item.text}</p>
            )}
            {item.answers && item.answers.length > 0 && (
              <div className="d-flex align-items-center gap-2">
                {item.answers.map((answer, index) => (
                  <span key={index} className="badge rounded-pill bg-primary">
                    {answer.type === "textbox" && (T("Justification"))}
                    {answer.type === "attachment" && (T("Attachment"))}
                    {answer.type === "yes/no" && (T("Yes") && "/" && T("No"))}
                    {answer.type === "unique" && (T("Single Choice"))}
                    {answer.type === "external" && (T("External Information"))}
                  </span>
                ))}
              </div>
            )}
          </div>
        ))}
      </details>
    </div>
  )
}


interface ItemsOnEditProps {
  items: { type: ItemType, text: string }[]
  onEditItem: (index: number, item: ItemOption) => void
  onDeleteItem: (index: number) => void
}

function ItemsList({ items, onEditItem, onDeleteItem }: ItemsOnEditProps) {
  return (
    <div className="mt-2">
      <h6>{T("Table Items")}</h6>
      <ul className="list-group overflow-auto" style={{ maxHeight: "40vh" }}>
        {
          items.map((item, index) => (
            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
              <div>
                {item.type === "title" && (
                  <h5>{item.text}</h5>
                )}
                {item.type === "subtitle" && (
                  <b>{item.text}</b>
                )}
                {item.type === "question" && (
                  <p>{item.text}</p>
                )}
              </div>
              <div className="d-flex gap-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onEditItem(index, item)}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onDeleteItem(index)}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default QuestionWithAttachmentEditMode;