/**
 * ButtonModal component displays a button that triggers a modal view
 * @param onDisplayModal - Callback function to display the modal
 */
export function ButtonModal({ onDisplayModal }: { onDisplayModal: () => void }) {
  return (
    <button
      style={{ right: "-4rem", transform: "translateY(-50%)" }}
      className="position-absolute top-50 p-2 bg-primary border-0 rounded text-white lh-1"
      onClick={onDisplayModal}
    >
      <i className="fa-solid fa-pen-to-square" />
    </button>
  )
}
