import React from 'react';
import ReactDOM from 'react-dom';
import NotesAndFinding from '../components/notes-and-findings/NotesAndFindings';


interface NotesAndFindingsArgs {
  containerId: string;
  engagementId: number;
  transactionFlowControlId: number;
  reference: "notes" | "findings";
  referenceTransactionFlowControl: "dai" | "oe";
  userId: number;
  readPermission: boolean;
  createPermission: boolean;
  updatePermission: boolean;
  deletePermission: boolean;
  completePermission: boolean;
  approvePermission: boolean;
  refusePermission: boolean;
  replyPermission: boolean;
}


export function notesAndFindingsRenderer(args: NotesAndFindingsArgs) {
  ReactDOM.render(
  <NotesAndFinding 
    engagementId={args.engagementId}
    transactionFlowControlId={args.transactionFlowControlId}
    reference={args.reference}
    referenceTransactionFlowControl={args.referenceTransactionFlowControl}
    userId={args.userId}
    readPermission={args.readPermission}
    createPermission={args.createPermission}
    updatePermission={args.updatePermission}
    deletePermission={args.deletePermission}    
    completePermission={args.completePermission}
    approvePermission={args.approvePermission}
    refusePermission={args.refusePermission}
    replyPermission={args.replyPermission}
  />, 
  document.getElementById(args.containerId)
  );
  window.menuService?.breadcrumbSetReactContainerId(args.containerId);
}
