import { useState, useEffect, useRef } from 'react'
import { T } from "@/utils/i18n-config"
import { Constants } from '@/utils/Constants'
import { DisallowanceIncomesElement } from '@/models/special-document/DisallowanceIncomesElement'
import { DisallowanceIncomesRepository } from '@/repository/special-document/DisallowanceIncomesRepository'
import { ElementRepository } from '@/repository/special-document/ElementRepository'
import ModalWithButtons from '@/components/modals/AceptCancelModalBase'
import { type DeliveryLocation, DeliveryLocationTable, DeliveryLocationAdder } from './delivery-location'
import { InvoiceSelection } from './invoice-selection'
import { ButtonModal } from '@/components/commons/button-modal'
import { AttachmentZone } from '@/components/commons/attachment-zone'
import ToggleInputParagraph from '@/components/commons/toogle-input'

interface LocalsDeliveriesProps {
  element: DisallowanceIncomesElement
}

/**
 * Component to manage the local deliveries of the disallowance income element.
 * @param element The disallowance income element
 */
function LocalsDeliveries({ element }: LocalsDeliveriesProps) {
  const [showModal, setShowModal] = useState(false)
  const [locals, setLocals] = useState<Array<number | string | boolean>[]>([])
  const [destinations, setDestinations] = useState<DeliveryLocation[]>([])
  const [localsAttachmentName, setLocalsAttachmentName] = useState(element.localsAttachmentName)
  const deliveryLocations = useRef<DeliveryLocation[]>([])
  const localsTemplateName = element.localsTemplateName
  const deliveryType = Constants.DELIVERY_TYPE_LOCAL
  const [localsLabels, setLocalsLabels] = useState(() => {
    if (element.localsLabels.length > 0) return element.localsLabels
    return [
      T("Invoice Number"),
      T("Shipment Date"),
      T("City"),
      T("Estimated Delivery Date"),
      T("Risk"),
      T("Delivery Date"),
      T("Amount"),
      T("Adjustment"),
    ]
  })

  /**
   * Handle the modal accept event.
   */
  async function handleModalAccept() {
    setShowModal(false)

    // Save destinations
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    const locations = destinations.map(({ location, deliveryDays }) => ({ location, delivery_days: deliveryDays, delivery_type: deliveryType }))
    await disallowanceIncomesRepository.saveDestinations(element.id, "view", locations)

    // Save local attachments
    const elementRepository = new ElementRepository()
    const lastLocalsAttachmentName = element.localsAttachmentName
    element.localsAttachmentName = localsAttachmentName
    element.localsLabels = localsLabels
    const success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.localsAttachmentName = lastLocalsAttachmentName
      setLocalsAttachmentName(lastLocalsAttachmentName)
      return window.htmlHelpers?.swalError()
    }
  }

  /**
   * Handle the modal cancel event.
   */
  function handleModalCancel() {
    setShowModal(false)
    setDestinations(deliveryLocations.current)
    setLocalsAttachmentName(element.localsAttachmentName)
  }
  
  /**
   * Generate the list of local deliveries based on the attachment
   * and update the table with the new data
   */
  async function handleGenerationLocals() {
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    if (locals.length === 0) {
      const { success } = await disallowanceIncomesRepository.generateInvoicesSelection(
        element.id, "view", element.engagement_id, deliveryType, localsAttachmentName
      )
      if (!success) return window.htmlHelpers?.swalError()
    }
    getLocalDeliveries()
  }

  /**
   * Get the local deliveries of the element and update the table with the new data
   */
  async function getLocalDeliveries() {
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    const { success, invoices } = await disallowanceIncomesRepository.getInvoicesSelection(element.id, "view", deliveryType)
    if (!success) return window.htmlHelpers?.swalError()
    setLocals(invoices.map(invoice => ([
      invoice.invoice_id,
      invoice.shipment_date,
      invoice.delivery_location,
      invoice.estimated_date,
      invoice.risk,
      invoice.delivery_date,
      invoice.amount,
      invoice.adjustment
    ])))
  }

  /**
   * Update the delivery date of a local delivery
   * @param invoiceId The invoice identifier
   * @param deliveryDate The new delivery date
   * @param adjustment The adjustment flag
   */
  async function updateLocalDelivery(invoiceId: number, deliveryDate: Date, adjustment: boolean) {
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    const updatedData = { invoiceId, deliveryDate: deliveryDate.toDateString(), adjustment }
    const { success } = await disallowanceIncomesRepository.updateInvoiceSelection(element.id, "view", updatedData)
    if (!success) return window.htmlHelpers?.swalError()

    const updatedInvoices = locals.map((invoice) => {
      if (invoice[0] === invoiceId) {
        invoice[5] = deliveryDate.toDateString()
        invoice[7] = adjustment
      }
      return invoice
    })
    setLocals(updatedInvoices)
  }

  /**
   * Get the destinations of the element and update the table with the new data
   */
  async function getDestinations() {
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    const { success, destinations } = await disallowanceIncomesRepository.getDestinations(element.id, "view", deliveryType)
    if (!success) return window.htmlHelpers?.swalError();

    const locations = destinations.map(destination => ({
      location: destination.location,
      deliveryDays: destination.delivery_days,
      deliveryType: destination.delivery_type
    }))
    deliveryLocations.current = locations
    setDestinations(locations)
  }

  /**
   * Add a new delivery location to the list
   * @param location The location
   * @param deliveryDays The average delivery time
   */
  function handleAddLocation(location: string, deliveryDays: number) {
    setDestinations([...destinations, { location, deliveryDays, deliveryType }])
  }

  /**
   * Update the label of a column
   * @param index The index of the column
   * @param label The new label
   */
  async function handleUpdateLabels(index: number, label: string) {
    const updatedLabels = [...localsLabels]
    updatedLabels[index] = label
    setLocalsLabels(updatedLabels)
  }

  useEffect(() => {
    getLocalDeliveries()
    getDestinations()
  }, [])

  useEffect(() => {
    setLocals([])
  }, [localsAttachmentName])

  return (
    <div className="position-relative">
      <ButtonModal onDisplayModal={() => setShowModal(true)} />
      <DeliveryLocationTable locationLabel="City" locations={destinations} />
      <InvoiceSelection
        invoices={locals}
        labels={localsLabels}
        inputColumns={[5]}
        typeColumns={[{ index: 5, type: "date" }, { index: 6, type: "float" }]}
        updateInvoice={updateLocalDelivery}
      />
      <ModalWithButtons
        showModal={showModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleModalAccept}
        onCancel={handleModalCancel}
      >
        <div className="d-flex flex-column gap-5 px-4">
          <div className="d-flex flex-wrap justify-content-between align-items-center gap-5">
            <DeliveryLocationAdder
              locationLabel="City"
              onAddDeliveryLocation={handleAddLocation}
            />
            <div>
              <h6>{T("Table Header")}</h6>
              <div
                className="justify-items-center align-items-center"
                style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "0.5rem 1rem" }}
              >
                {localsLabels.map((label, index) => (
                  <ToggleInputParagraph key={index} initialText={label} onChange={(label) => handleUpdateLabels(index, label)} />
                ))}
              </div>
            </div>
          </div>
          {destinations.length > 0 && (
            <AttachmentZone
              elementId={element.id}
              templateName={localsTemplateName}
              attachmentName={localsAttachmentName}
              onUpdateAttachment={setLocalsAttachmentName}
              onAction={handleGenerationLocals}
            />
          )}
        </div>
      </ModalWithButtons>
    </div>
  )
}

export default LocalsDeliveries
