import { CompanySettingsRepository } from '../../repository/CompanySettingsRepository';
//import Swal from 'sweetalert2'


export class CompanySettingsService {
  private companySettingsRepository: CompanySettingsRepository;

  constructor () {
    this.companySettingsRepository = new CompanySettingsRepository();
  }

  attendForm() {
    //get selector by id metadata_variable_type_id
    const metadataVariableSelectElement = document.getElementById('metadata_variable_type_id') as HTMLSelectElement;

    if (!metadataVariableSelectElement) {
      return;
    }
    
    // add event listener to selector
    metadataVariableSelectElement.addEventListener('change', this.onChangeMetadataVariableType.bind(this));
    // get variable type id
    const variableTypeId = parseInt(metadataVariableSelectElement.value);
    // set variable format selector
    this.setVariableFormatSelectElement(variableTypeId);
  }

  private onChangeMetadataVariableType(event: Event) {
    const metadataVariableSelectElement = event.target as HTMLSelectElement;
    const variableTypeId = parseInt(metadataVariableSelectElement.value);
    this.setVariableFormatSelectElement(variableTypeId);
  }

  private setVariableFormatSelectElement(variableTypeId: number) {
    //get selector by id metadata_variable_format_id
    const metadataVariableFormatSelectElement = document.getElementById('metadata_variable_format_id') as HTMLSelectElement;

    this.companySettingsRepository.getVariablesFormat(variableTypeId)
      .then(data => {
        metadataVariableFormatSelectElement.innerHTML = '';

        data.forEach(item => {
          const option = document.createElement('option');
          option.value = item.id.toString();
          option.text = item.name;
          metadataVariableFormatSelectElement.appendChild(option);
        });
      })
      .catch(error => {
        console.error('Error getting variables format', error);
        window.htmlHelpers?.swalError()
      });
  }
}