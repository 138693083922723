import { ElementType } from "../../models/special-document/ElementType"
import { template } from "../../templates/ElementTypeTemplate"
import { getUrl } from '../../utils/app-config'


export class ElementTypeRepository{
  private _elements: ElementType[]
  private readonly getApiUrl: string = getUrl('elementType_api/get')
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor() {
    this._elements = template
  }

  getElementByType(type: number): ElementType{
    const element = this._elements.find(element => element.type === type)
    if (!element){
      throw new Error(`Element type ${type} not found`)
    }
    return element
  }

  getAllElements(): ElementType[]{
    return this._elements
  }

  
}


