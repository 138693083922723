import React, { useEffect, useState, useRef } from 'react';
import { T } from "@/utils/i18n-config"
import { Strategy } from '@/models/special-document/ElementArgs'
import { ElementParagraph } from "@/models/special-document/ElementParagraph"
import ViewModeProps from './ViewModeProps';
import DOMPurify from 'isomorphic-dompurify';
import { TransactionFlowStrategyRepository } from '@/repository/special-document/TransactionFlowStrategyRepository';


/**
 * Component to display the KamParagraphOpinion in view mode
 * @param ViewModeProps - interface with the element to display
 */
const TransactionFlowStrategiesViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<ElementParagraph>(iElement as ElementParagraph).current
  const [transactionFlowStrategies, setTransactionFlowStrategies] = useState<Strategy[]>([])

  /**
   * Get the transaction flow strategies for the current element
   */
  async function getTransactionFlowStrategy() {
    const transactionFlowStrategyRepository = new TransactionFlowStrategyRepository();
    const { success, data } = await transactionFlowStrategyRepository.getTransactionFlowStrategiesInView(element.engagement_id, element.id, "view");
    if (!success) setTransactionFlowStrategies([]);
    else setTransactionFlowStrategies(data);
  }

  useEffect(() => {
    getTransactionFlowStrategy()
  }, [])

  /**
   * Redirect to the transaction flow view
   * @param reference - reference of the transaction flow
   */
  function redirectTransactionFlow(reference: string) {
    const url = `/audix/substantive_test_strategy/index.load/${element.args.engagement_id}/${reference}`;
    window.menuService?.breadcrumbAddLevel(url, T('Substantive test'));
  }

  return (
    <div className="container">
      <h2>{T("Strategies List")}</h2>
      {transactionFlowStrategies.length === 0 ? (
        <p>{T("No strategies found")}</p>
      ) : (
        <div className="accordion" id="strategyAccordion">
          {transactionFlowStrategies.map((strategy, index) => (
            <div className="accordion-item" key={strategy.id}>
              <h2 className="accordion-header" id={`heading${index}`}>
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded="true"
                  aria-controls={`collapse${index}`}
                >
                  {strategy.title}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse show"
                aria-labelledby={`heading${index}`}
                data-bs-parent="#strategyAccordion"
              >
                <div className="accordion-body">
                  <strong>{T("Description")}</strong>
                  {strategy.description ? (
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(strategy.description) }} />
                  ) : (
                    <p>{T("No description")}</p>
                  )}

                  <h5>{T("Associated Controls")}</h5>
                  <ul className="list-group">
                    {strategy.controls.length === 0 ? (
                      <li className="list-group-item text-muted">{T("No associated controls")}</li>
                    ) : (
                      strategy.controls.map((control) => (
                        <li key={control.id} className="list-group-item">
                          <p><strong>{T("Name")}:</strong> {control.name}</p>
                          <p><strong>{T("Asseveration")}:</strong> {control.asseveration_name}</p>
                          <p><strong>{T("Risk Level")}:</strong> {control.name_risk}</p>
                          <p><strong>{T("Strategy")}:</strong> {control.is_confidence ? T("Confidence") : T("No Confidence")}</p>
                          <p><strong>{T("Substantive Procedures")}:</strong></p>
                          {control.substantive_test_strategy.length > 0 ? (
                            <ul>
                              {control.substantive_test_strategy.map((test) => (
                                <li key={test.id}>
                                  {test.substantive_test_name}
                                  <div className="d-flex justify-content-center mt-2">
                                    <button className="btn btn-primary" onClick={() => redirectTransactionFlow(test.reference)}>
                                      {T("Substantive Procedure")}
                                    </button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="text-muted">{T("No substantive procedures")}</p>
                          )}
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TransactionFlowStrategiesViewMode;
