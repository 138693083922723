import { InternalAuditorArgs, type Transaction, InternalAuditor, StageSchedule } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class InternalAuditorElement extends ElementBase implements IElementBase {
  private _conclusion_questions: number[]
  private _transactions: Transaction[]
  private _objectivity_justifications: string[]
  private _competence_team: InternalAuditor[]
  private _approach_work: { action_plan: string, deliverables: string }
  private _schedule: StageSchedule[]
  private _conclusion: string

  constructor(args: InternalAuditorArgs) {
    super(args)
    this._conclusion_questions = args.conclusion_questions || new Array(4).fill(-1)
    this._transactions = args.transactions || []
    this._objectivity_justifications = args.objectivity_justifications || []
    this._competence_team = args.competence_team || []
    this._approach_work = args.approach_work || { action_plan: "", deliverables: "" }
    this._schedule = args.schedule || []
    this._conclusion = args.conclusion || ""
  }

  get conclusionQuestions(): number[] {
    return this._conclusion_questions
  }

  get transactions(): Transaction[] {
    return this._transactions
  }

  get objectivityJustifications(): string[] {
    return this._objectivity_justifications
  }

  get competenceTeam(): InternalAuditor[] {
    return this._competence_team
  }

  get approachWork(): { action_plan: string; deliverables: string } {
    return this._approach_work
  }

  get schedule(): StageSchedule[] {
    return this._schedule
  }

  get conclusion(): string {
    return this._conclusion
  }

  get args(): InternalAuditorArgs {
    return {
      ...super.args,
      conclusion_questions: this._conclusion_questions,
      transactions: this._transactions,
      objectivity_justifications: this._objectivity_justifications,
      competence_team: this._competence_team,
      approach_work: this._approach_work,
      schedule: this._schedule,
      conclusion: this._conclusion
    }
  }

  set conclusionQuestions(conclusionQuestions: number[]) {
    this._conclusion_questions = conclusionQuestions
  }

  set transactions(transactions: Transaction[]) {
    this._transactions = transactions
  }

  set objectivityJustifications(objectivityJustifications: string[]) {
    this._objectivity_justifications = objectivityJustifications
  }

  set competenceTeam(competenceTeam: InternalAuditor[]) {
    this._competence_team = competenceTeam
  }

  set approachWork(approachWork: { action_plan: string; deliverables: string }) {
    this._approach_work = approachWork
  }

  set schedule(schedule: StageSchedule[]) {
    this._schedule = schedule
  }

  set conclusion(conclusion: string) {
    this._conclusion = conclusion
  }

  render(): void {}
}