import type { ElementArgs } from '@/models/special-document/ElementArgs'
import { getUrl } from '@/utils/app-config'
import { fetchAPI } from '@/services/api_services/fetchAPI'
import { T } from '@/utils/i18n-config'
import type {
  BasicResponse,
  Account,
  LedgerAccountTypeOpeningBalance,
  LedgerAccountType,
  AuditPlanStages,
  InternalControlComponentsConfigurations,
  InternalControlComponentsData,
  InternalControlComponentsConclusionData,
  InternalControlComponentsConclusion
} from '@/types/special-document'

export interface IReturnId {
  id: number
}

export interface ResponseUpDown {
  success: boolean,
  id: number,
  swapId: number | null
}

export type TransactionCategory = {
  title: string
  risk: string
}

interface InternalControlComponentsColumn {
  type: string;
  items: InternalControlComponentsData[];
}

export class ElementRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  /**
   * Constructor for ElementRepository
   * @param fetcher - fetch function to be used for API calls
   */
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  private async fetchJSON(url: string, options?: RequestInit): Promise<any> {
    const response = await this.fetcher(url, options);
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  /**
   * Function to create an element
   * @param element - The element to create
   * @return A promise that resolves to the response data
   */
  async createElement(element: ElementArgs): Promise<number> {
    const engagementIdModified = element.engagement_id ?? 'None';
    const apiUrl = `${getUrl("elements_api/create_element")}/${engagementIdModified}`
    const data: IReturnId = await this.fetchJSON(apiUrl, {
      method: 'POST',
      headers: this._jsonContentType,
      body: JSON.stringify(element)
    })
    if ("success" in data && !data.success) {
      throw new Error(T("Error creating element"))
    }
    return data.id
  }

  /**
   * Function to save an element
   * @param element - The element to save
   * @return A promise that resolves to the response data
   */
  async saveElement(renderingMode: string, element: ElementArgs): Promise<boolean> {
    const engagementIdModified = element.engagement_id ?? 'None';
    const apiUrl = `${getUrl("elements_api/save_element")}/${engagementIdModified}/${renderingMode}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(element)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: BasicResponse = await response.json()
      if (!data.success) {
        throw new Error(T("Error saving element"))
      }
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }

  }

  /**
   * Function to get elements by document ID
   * @param engagementId - The engagement ID
   * @param documentId - The document ID
   * @return A promise that resolves to the response data
   */
  async getElementsByDocumentSpecialId(engagementId: number | null, documentId: number, renderingMode: string): Promise<ElementArgs[]> {
    const engagementIdModified = engagementId ?? 'None';
    const apiUrl = `${getUrl("elements_api/get_elements_by_special_document_id")}/${engagementIdModified}/${documentId}/${renderingMode}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: ElementArgs[] | BasicResponse = await response.json()
      if ("success" in data && !data.success) {
        throw new Error(T("Error getting elements"))
      }
      return data as ElementArgs[]
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Function to delete an element
   * @param engagementId - The engagement ID
   * @param documentId - The document ID
   * @param elementId - The element ID
   * @return A promise that resolves to the response data
   */
  async deleteElement(engagementId: number | null, documentId: number, elementId: number): Promise<boolean> {
    const engagementIdModified = engagementId ?? 'None';
    const apiUrl = `${getUrl("elements_api/delete_element")}/${engagementIdModified}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: BasicResponse = await response.json()
      if (!data.success) {
        throw new Error(T("Error deleting element"))
      }
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  /**
   * Function to move the element up in the ordering
   * @param engagementId - The engagement ID
   * @param documentId - The document ID
   * @param elementId - The element ID
   * @return A promise that resolves to the response data
   */
  async upElement(engagementId: number | null, documentId: number, elementId: number): Promise<ResponseUpDown> {
    const engagementIdModified = engagementId ?? 'None';
    const apiUrl = `${getUrl("elements_api/up_element")}/${engagementIdModified}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false, id: 0, swapId: 0 }
      }
      const data: ResponseUpDown = await response.json()
      if (!data.success) {
        return { success: false, id: 0, swapId: 0 }
      }
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, id: 0, swapId: 0 }
    }
  }

  /**
   * Function to move the element down in the ordering
   * @param engagementId - The engagement ID
   * @param documentId - The document ID
   * @param elementId - The element ID
   * @return A promise that resolves to the response data
   */
  async downElement(engagementId: number | null, documentId: number, elementId: number): Promise<ResponseUpDown> {
    const engagementIdModified = engagementId ?? 'None';
    const apiUrl = `${getUrl("elements_api/down_element")}/${engagementIdModified}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false, id: 0, swapId: 0 }
      }
      const data: ResponseUpDown = await response.json()
      if (!data.success) {
        throw new Error(T("Error updating position element"))
      }
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, id: 0, swapId: 0 }
    }
  }

  /**
   * Function to get an element by ID
   * @param engagementId - The engagement ID
   * @param documentId - The document ID
   * @param elementId - The element ID
   * @return A promise that resolves to the response data
   */
  async getRedirectUrl(engagementId: number | null, documentId: number, elementId: number, context: string): Promise<string> {
    const engagementIdModified = engagementId ?? 'None';
    const baseUrl = getUrl("elements_api/get_contextual_ids")
    const apiUrl = `${baseUrl}/${engagementIdModified}/${documentId}/${elementId}?context=${context}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return ""
      }

      const data = await response.json()
      return data.url as string
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return ""
    }
  }

  /**
  * Retrieves the memorandum redirect URL based on the engagement and element IDs.
  * @param {number | null} engagementId - The engagement identifier or null if not provided.
  * @param {number} elementId - The identifier of the element.
  * @returns {Promise<string>} A promise that resolves to the memorandum URL as a string.
  */
  async getRedirectMemorandumUrl(engagementId: number | null, elementId: number): Promise<string> {
    const engagementIdModified = engagementId ?? 'None';
    const baseUrl = getUrl("elements_api/get_url_button_redirect_memorandum")
    const apiUrl = `${baseUrl}/${engagementIdModified}/${elementId}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return ""
      }

      const data = await response.json()
      return data.url as string
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return ""
    }
  }

  async getExtractedValues(
    engagementId: number, documentId: number, elementId: number, attachmentName: string, informationList: string[]
  ): Promise<{ success: boolean, information?: string }> {
    const apiUrl = `${getUrl("elements_api/get_extracted_values")}/${engagementId}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ attachmentName, informationList })
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false }
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
   * Function to get an element by ID
   * @param engagementId - The engagement ID
   * @param documentId - The document ID
   * @param elementId - The element ID
   * @return A promise that resolves to the response data
   */
  async getGlobalVariable(
    engagementId: number, elementId: number, variable: string, reference?: string,
  ): Promise<{ success: boolean, variable: string[] }> {
    const checkedReference = reference ?? "None"
    const baseUrl = getUrl("elements_api/get_global_variable")
    const apiUrl = `${baseUrl}/${engagementId}/${elementId}?reference=${checkedReference}&variable=${variable}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false, variable: [] }
      }

      const data = await response.json()
      if (data.success) return data
      return { success: false, variable: [] }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, variable: [] }
    }
  }

  /**
   * Function to get an element by ID and return the transaction flows
   * @param engagementId - The engagement ID
   * @param documentId - The document ID
   * @param elementId - The element ID
   * @return A promise that resolves to the response data
   */
  async getTransactionFlows(engagementId: number | null, documentId: number, elementId: number): Promise<{ success: boolean, data: string[] }> {
    const baseUrl = getUrl("elements_api/get_transaction_flows")
    const apiUrl = `${baseUrl}/${engagementId}/${documentId}/${elementId}`

    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false, data: [] }
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, data: [] }
    }
  }

  /**
   * Function to get an element by ID and return the transaction flows and risks
   * @param engagementId - The engagement ID
   * @param documentId - The document ID
   * @param elementId - The element ID
   * @return A promise that resolves to the response data
   */
  async getTransactionFlowRisks(engagementId: number, documentId: number, elementId: number): Promise<{ success: boolean, data: TransactionCategory[] }> {
    const baseUrl = getUrl("elements_api/get_transaction_flow_risks")
    const apiUrl = `${baseUrl}/${engagementId}/${documentId}/${elementId}`

    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false, data: [] }
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, data: [] }
    }
  }

  /**
 * Retrieves the trial balance registers for a given engagement.
 * @param engagementId - The ID of the engagement (nullable).
 * @param documentId - The ID of the document.
 * @param elementId - The ID of the element.
 * @returns A promise that resolves to an object containing a success flag and an array of trial balance ledger accounts.
 */
  async getRegistersTrialBalance(engagementId: number | undefined | null, documentId: number, elementId: number): Promise<{ success: boolean, data: LedgerAccountType[] }> {
    const apiUrl = getUrl('elements_api/get_registers_trial_balance/' + engagementId + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: LedgerAccountType[] } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }


  /**
  * Validates the opening balances document for a specific engagement.
  * @param engagementId - The ID of the engagement.
  * @param documentId - The ID of the document to validate.
  * @param elementId - The ID of the specific element within the document.
  * @returns A promise that resolves to an object containing the validation result.
  */
  async validateOpeningBalancesDocument(
    engagementId: number, documentId: number, elementId: number
  ): Promise<{ success: boolean, data?: Account, is_not_required?: boolean, no_variance_analysis?: boolean }> {
    const apiUrl = `${getUrl("elements_api/validate_opening_balances_document")}/${engagementId}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false }
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
 * Retrieves the configuration settings for internal control components.
 * @param engagement_id - The ID of the engagement (can be null).
 * @param documentId - The ID of the document.
 * @param elementId - The ID of the element.
 * @param renderingMode - The rendering mode used for fetching the configuration.
 * @returns A promise that resolves to an object containing the internal control components configuration.
 */
  async getInternalControlComponentsConfiguration(engagement_id: number | null, documentId: number, elementId: number, renderingMode: string): Promise<InternalControlComponentsConfigurations> {
    const apiUrl = getUrl('elements_api/get_internal_control_components_configuration/' + engagement_id + '/' + documentId + '/' + elementId + '/' + renderingMode)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: InternalControlComponentsConfigurations = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
 * Retrieves the materiality report for a given engagement.
 * @param engagementId - The ID of the engagement (nullable).
 * @returns A promise that resolves to an object containing the success status and the materiality value.
 */
  async getEngagementMaterialityReport(engagementId: number | undefined | null): Promise<{ success: boolean, materiality: number }> {
    const apiUrl = getUrl('elements_api/get_engagement_materiality_report/' + engagementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, materiality: number } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Retrieves ledger account items related to opening balances.
   * @param engagementId - The engagement ID (can be null or undefined).
   * @param documentId - The document ID.
   * @param elementId - The element ID.
   * @returns A promise resolving to an object containing the ledger account data.
   */
  async getLedgerAccountItemOpening(engagementId: number | undefined | null, documentId: number, elementId: number): Promise<{ success: boolean, data: LedgerAccountTypeOpeningBalance[] }> {
    const apiUrl = getUrl('elements_api/get_ledger_accounts_by_type_opening_balances/' + engagementId + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: LedgerAccountTypeOpeningBalance[] } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }


  /**
   * Validates the engagement configuration for opening balances.
   *
   * @param engagementId - The engagement ID (can be null or undefined).
   * @param documentId - The document ID.
   * @param elementId - The element ID.
   * @returns A promise resolving to an object indicating validation success, 
   *          optional status of accounts, and materiality check results.
   */
  async validateEngagementConfigurationOpeningBalancesDocument(engagementId: number | undefined | null, documentId: number, elementId: number): Promise<{ success: boolean, is_not_required?: boolean, accounts_status?: { [key: string]: boolean }, no_materiality?: boolean }> {
    const apiUrl = getUrl('elements_api/validate_engagement_configuration_opening_balances_document/' + engagementId + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, is_not_required?: boolean, accounts_status?: { [key: string]: boolean }, no_materiality?: boolean } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }


  /**
 * Updates the configuration of internal control components for a given engagement.
 * @param engagementId - The ID of the engagement (nullable).
 * @param documentId - The ID of the document.
 * @param elementId - The ID of the element.
 * @param data_internal - An array of internal control components configuration data.
 * @returns A promise that resolves to a basic response object indicating the success of the update.
 */
  async updateInternalControlComponentsConfiguration(engagementId: number | null | undefined, documentId: number, elementId: number, data_internal: InternalControlComponentsColumn[]): Promise<BasicResponse> {
    const apiUrl = getUrl('elements_api/update_internal_control_components_configuration/' + engagementId + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ "data_internal": data_internal })
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
 * Deletes the configuration of an internal control component.
 * @param engagementId - The ID of the engagement (nullable).
 * @param documentId - The ID of the document.
 * @param registerId - The ID of the register to be deleted (can be a number or string).
 * @returns A promise that resolves to a BasicResponse indicating the success or failure of the deletion.
 */
  async deleteInternalControlComponentsConfiguration(engagement_id: number | null | undefined, documentId: number, registerId: number | string): Promise<BasicResponse> {
    const apiUrl = getUrl('elements_api/delete_internal_control_components_configuration/' + engagement_id + '/' + documentId + '/' + registerId)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
 * Retrieves the result of answers for internal control components.
 * @param engagementId - The ID of the engagement (nullable).
 * @param documentId - The ID of the document.
 * @param elementId - The ID of the element.
 * @returns A promise that resolves to an object containing the success status and the valoration result.
 */
  async getResultOfAnswersInternalControlComponents(engagement_id: number | null | undefined, documentId: number, elementId: number): Promise<{ success: boolean, valoration: number | undefined | null }> {
    const apiUrl = getUrl('elements_api/get_result_of_answers_internal_control_components/' + engagement_id + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, valoration: number | undefined | null } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
 * Validates the internal control component questions for a given engagement.
 * @param engagementId - The ID of the engagement (nullable).
 * @param documentId - The ID of the document.
 * @param elementId - The ID of the element to validate.
   * @return A promise that resolves to an object containing the success status and the valoration result.
   */
  async validateQuestionsInternalControlComponents(engagement_id: number | null | undefined, documentId: number, elementId: number): Promise<{ success: boolean, valoration: number | undefined | null }> {
    const apiUrl = getUrl('elements_api/validate_questions_internal_control_components/' + engagement_id + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, valoration: number | undefined | null } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Validates the scope and objectives for the use of expert work.
  * @param engagementId - The ID of the engagement (nullable).
  * @param elementId - The ID of the element.
  * @returns A promise that resolves to an object containing the validation result, including success status and data.
  */
  async validateScopeAndObjetivesUseExpertWork(engagementId: number | undefined | null, elementId: number): Promise<{ success: boolean, data: string[] }> {
    const apiUrl = getUrl('elements_api/validate_scope_and_objectives_use_expert_work/' + engagementId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: string[] } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
 * Retrieves the conclusion scale for internal control components.
 * @param engagementId - The ID of the engagement (nullable).
 * @param documentId - The ID of the document.
 * @param elementId - The ID of the element.
 * @returns A promise that resolves to an InternalControlComponentsConclusion object.
 */
  async getConclusionScaleInternalControlComponents(engagement_id: number | null | undefined, documentId: number, elementId: number): Promise<InternalControlComponentsConclusion> {
    const apiUrl = getUrl('elements_api/get_conclusion_scale_internal_control_components/' + engagement_id + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: InternalControlComponentsConclusion = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
 * Updates the conclusion scale for internal control components.
 * @param engagementId - The ID of the engagement (nullable).
 * @param documentId - The ID of the document.
 * @param elementId - The ID of the element.
 * @param data_internal - An array containing the updated conclusion scale data.
 * @returns A promise that resolves to a `BasicResponse` indicating the success or failure of the update.
 */
  async updateConclusionScaleControlComponents(engagementId: number | null | undefined, documentId: number, elementId: number, data_internal: InternalControlComponentsConclusionData[]): Promise<BasicResponse> {
    const apiUrl = getUrl('elements_api/update_conclusion_scale_control_components/' + engagementId + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ "data_internal": data_internal })
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
 * Retrieves the internal control components of a specific type section.
 * @param engagementId - The ID of the engagement (nullable).
 * @param documentId - The ID of the document.
 * @param elementId - The ID of the element.
 * @returns A promise that resolves to an object containing a success flag and an array of type sections.
 */
  async getTypeSectionInternalControlComponents(engagement_id: number | null | undefined, documentId: number, elementId: number): Promise<{ success: boolean, type_sections: { id: number; name: string }[]; }> {
    const apiUrl = getUrl('elements_api/get_type_section_internal_control_components/' + engagement_id + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, type_sections: { id: number; name: string }[]; } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
 * Retrieves the audit plan stages associated with a specific engagement.
 * @param engagementId - The ID of the engagement (nullable).
 * @param elementId - The ID of the element.
 * @returns A promise that resolves to an object containing a success flag and an array of audit plan stages.
 */
  async getAuditPlanStagesByEngagementId(engagementId: number | undefined | null, elementId: number): Promise<{ success: boolean, data: AuditPlanStages[] }> {
    const apiUrl = getUrl('elements_api/get_audit_plan_stages_by_engagement_id/' + engagementId + '/' + elementId)

    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: AuditPlanStages[] } = await response.json()

      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
* Function to get redirect URL for memo use service organization
* @param engagementId - The engagement ID
* @param documentId - The document ID
* @param elementId - The element ID
* @param transactionFlowId - The transaction flow ID
* @return A promise that resolves to an object containing the URL and title
*/
  async getRedirectUrlMemoUseServiceOrganization(engagementId: number | null, documentId: number, elementId: number, transactionFlowId: number): Promise<{ url: string, title: string }> {
    const engagementIdModified = engagementId ?? 'None';
    const baseUrl = getUrl("elements_api/get_use_service_organization_transaction_flow_control")
    const apiUrl = `${baseUrl}/${engagementIdModified}/${documentId}/${elementId}?transaction_flow_id=${transactionFlowId}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { url: string, title: string } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}



