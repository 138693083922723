import { EngagementVariableRepository, type EngagementVariableData } from '../repository/EngagementVariableRepository';

export class EngagementVariableFactory {
  private static _instance: EngagementVariableFactory;
  private readonly _engagementVariableRepository: EngagementVariableRepository;
  private _cache: EngagementVariableData[] | null;

  public constructor(engagementVariableRepository?: EngagementVariableRepository) {
    this._engagementVariableRepository = engagementVariableRepository || new EngagementVariableRepository();
    this._cache = null;
  }

  public static getInstance(): EngagementVariableFactory {
    if (!EngagementVariableFactory._instance) {
      EngagementVariableFactory._instance = new EngagementVariableFactory();
    }
    return EngagementVariableFactory._instance;
  }

  getAll = async (): Promise<EngagementVariableData[]> => {
    if (this._cache) {
      return this._cache;
    }
    const data = await this._engagementVariableRepository.getAll();
    this._cache = data;
    return data;
  }

  getByName = async (name: string): Promise<EngagementVariableData | null> => {
    const data = await this.getAll();
    return data.find((item) => item.name === name) ?? null;
  }
}
