import { ElementBase, IElementBase } from './ElementBase'
import { ElementArgs } from './ElementArgs'

export class MathFormulaDisplayValueElement extends ElementBase implements IElementBase{
  private _mathOperation : string
  private _message : string
  constructor(args:ElementArgs){
    super(args)
    this._mathOperation = args.mathOperation ?? "Add math operation"
    this._message = args.message ?? ""
  }

  get mathOperation(): string {
      return this._mathOperation
  }

  get message(): string {
      return this._message
  }

  set mathOperation(mathOperation: string) {
      this._mathOperation = mathOperation
  }

  set message(message: string) {
      this._message = message
  }
 

  render(): void {
    console.log("MathFormulaDisplayValueElement render") 
  }
}