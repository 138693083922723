import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { QuestionTableRepository } from "../../repository/special-document/QuestionTableRepository"
import { QuestionsTableElement } from '../../models/special-document/QuestionsTableElement'
import type { AnswerType, ItemAnswer } from '../../models/special-document/ElementArgs'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import Summernote from '../utils/Summernote'
import Dropzone from '../utils/Dropzone'

const QuestionWithAttachmentViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<QuestionsTableElement>(iElement as QuestionsTableElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [items, setItems] = useState(element.items)

  async function retrieveExternalInformation() {
    const questionTableRepository = new QuestionTableRepository()
    const elementId = element.id
    const itemsExternal = items.map(async item => {
      if (item.answers) {
        const updatedAnswers = item.answers.map(async answer => {
          if (answer.type === "external" && !answer.externalContent) {
            const { success, content } = await questionTableRepository.getExternalContent(elementId, element.engagement_id, answer.content)
            if (success) return { ...answer, externalContent: content }
            return answer
          }
          return answer
        })
        return { ...item, answers: await Promise.all(updatedAnswers) }
      }
      return item
    })
    setItems((await Promise.all(itemsExternal)))
  }

  function hasExternalInformation() {
    const itemExternals = items.filter(item => item.answers?.some(answer => answer.type === "external"))
    return itemExternals.every(item => item.answers?.some(answer => !!answer?.externalContent))
  }

  function handleEdit() {
    setShowConfigurationsModal(true)
  }

  async function handleEditModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastItems = element.items
    element.items = items

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.items = lastItems
      setItems(lastItems)
      window.htmlHelpers?.swalError()
    }
  }

  function handleEditModalCancel() {
    setItems(element.items)
    setShowConfigurationsModal(false)
  }

  function setItemAnswerContent(index: number, answerType: string, content: string) {
    const item = items[index]
    const answer = item?.answers?.find(a => a.type === answerType)
    if (!answer) return
    const answers = item.answers?.with(item.answers.indexOf(answer), { ...answer, content })
    const newItems = [...items].with(index, { ...item, answers: answers })
    setItems(newItems)
  }

  useEffect(() => {
    if (!hasExternalInformation() && !showConfigurationsModal) {
      retrieveExternalInformation()
      handleEditModalAccept()
    }
  }, [showConfigurationsModal])

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div className="card card-body p-3 bg-light">
          <details>
            <summary>
              <h5 className="d-inline ms-2">{items[0].text}</h5>
            </summary>
            <div className="mb-2" />
            {items.slice(1).map((item, index) => (
              <div key={index} className="mb-3">
                {item.type === "subtitle" && (
                  <b className="mt-2">{item.text}</b>
                )}
                {item.type === "question" && (
                  <p className="mb-1">{item.text}</p>
                )}
                {item.answers && item.answers.length > 0 && (
                  <div className="d-flex align-items-center gap-2">
                    {item.answers.map((answer, index) => (
                      <span key={index} className="badge rounded-pill bg-primary">
                        {answer.type === "textbox" && (T("Justification"))}
                        {answer.type === "attachment" && (T("Attachment"))}
                        {answer.type === "yes/no" && (T("Yes") && "/" && T("No"))}
                        {answer.type === "unique" && (T("Single Choice"))}
                        {answer.type === "external" && (T("External Information"))}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </details>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={element.items[0].text}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}
      >
        <div className="d-flex flex-column gap-1">
          {items.slice(1).map((item, index) => (
            <div key={index} className={
              item.type === "subtitle" ? "mt-2 mb-2" :
                item.type === "question" ? "mb-4" : ""
            }>
              {item.type === "subtitle" && (<b className="fs-5">{item.text}</b>)}
              {item.type === "question" && (<p className="mb-1">{item.text}</p>)}
              {item.type === "question" && item.answers?.map((answer, index) => (
                <QuestionAnswers
                  key={index}
                  answer={answer}
                  itemIndex={index}
                  elementId={element.id}
                  uniqueId={item.type + item.text + index}
                  onUpdate={setItemAnswerContent}
                />
              ))}
            </div>
          ))}
        </div>
      </ModalWithButtons>
    </>
  );
}


interface QuestionAnswersProps {
  answer: ItemAnswer
  itemIndex: number
  elementId: number
  uniqueId: string
  onUpdate: (index: number, answerType: AnswerType, content: string) => void
}
function QuestionAnswers({ answer, itemIndex, elementId, uniqueId, onUpdate }: QuestionAnswersProps) {
  const [content, setContent] = useState(answer.content)

  function setAnswerContent(value: string) {
    setContent(value)
    onUpdate(itemIndex, answer.type, value)
  }

  return (
    <>
      {answer.type === "textbox" && (
        <div className="mt-1">
          <label htmlFor="justification" className="text-sm">{T("Justification")}</label>
          <Summernote
            value={content}
            onChange={(value) => setAnswerContent(value)}
            maxLength={2000}
          />
        </div>
      )}
      {answer.type === "attachment" && (
        <div className="mt-1">
          <label htmlFor="attachment" className="text-sm">{T("Attachment")}</label>
          <Dropzone
            elementId={elementId}
            attachmentName={content}
            onUpload={(attachmentName) => setAnswerContent(attachmentName)}
          />
        </div>
      )}
      {answer.type === "yes/no" && (
        <div className="mt-1">
          <div className="form-check form-switch">
            <label htmlFor="yes/no" className="form-check-label me-2">{T("No")}</label>
            <input
              id="yes/no"
              role="switch"
              type="checkbox"
              className="form-check-input"
              checked={content === "yes"}
              onChange={(e) => setAnswerContent(e.target.checked ? "yes" : "no")}
            />
            <label htmlFor="yes/no" className="form-check-label ms-2">{T("Yes")}</label>
          </div>
        </div>
      )}
      {answer.type === "unique" && (
        <div>
          {answer.options?.map((option, index) => (
            <div className="form-check" key={index}>
              <input
                type="radio"
                name={uniqueId}
                id={answer.type + index}
                className="form-check-input"
                checked={content === "yes"}
                onChange={() => setAnswerContent(option)}
              />
              <label className="form-check-label" htmlFor={answer.type + index}>
                {option}
              </label>
            </div>
          ))}
        </div>
      )}
      {answer.type === "external" && (
        <div className="mt-1">
          <label htmlFor="external" className="text-sm">{T("External Information")}</label>
          <div className="py-3 px-3 mt-1 bg-light text-dark rounded-2" style={{ width: "fit-content"}}>
            {answer.content === "ofac" && (<b>{T("OFAC Results")}</b>)}
            {answer.content === "budgeted_hours" && (<b>{T("Budgeted Hours")}</b>)}
            {answer.content === "independence_format" && (<b>{T("Independence Format")}</b>)}
            <pre className="m-0 text-muted fs-6" style={{ fontFamily: "sans-serif" }}>{answer.externalContent}</pre>
          </div>
        </div>
      )}
    </>
  )
}

export default QuestionWithAttachmentViewMode;