import { IElementBase } from './ElementBase'

export class DocumentSpecial{
  private _id: number
  private _reference: string
  private _content: IElementBase[]
  private _state: string
  private _engagement_id: number|null
  private _name?: string

  constructor(id: number, reference: string, engagement_id: number|null = 0, name?: string, state?: string){
    this._id = id
    this._reference = reference
    this._content = []
    this._state = state ?? "open"
    this._engagement_id = engagement_id
    this._name = name
  }

  get id(): number{
    return this._id
  } 
  get reference(): string{
    return this._reference
  }


  get content(): IElementBase[]{
    return this._content
  }
  get state(): string{
    return this._state
  }
  get engagementID(): number|null{
    return this._engagement_id
  }

  get name(): string | undefined{
    return this._name
  }

  set state(state: string){   
    this._state = state
  }

  set reference(reference: string){
    this._reference = reference
  }

  set content(content: IElementBase[]){
    this._content = content
  }

  set id(id: number){ 
    this._id = id
  }
  
  set engagementID(engagementID: number|null){
    this._engagement_id = engagementID
  }

  set name(name: string | undefined){
    this._name = name
  }

  addElement(element: IElementBase){
    this._content.push(element)
  }

  deleteElement(id: number){
    this._content = this._content.filter(element => element.id !== id)
  }

  getElement(id: number): IElementBase {
    const iElementBase = this._content.find(element => element.id === id)
    if (iElementBase){
      return iElementBase
    }
    throw new Error("ID no found")
  }

  updateElement(element: IElementBase){
    const index = this._content.findIndex(iElement => iElement.id === element.id)
    if (index !== -1){
      this._content[index] = element
    }else{
      throw new Error("ID no found")
    }
  }

}