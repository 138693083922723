/**
 * Creates a unique identifier based on the current date.
 * @returns {number} - A unique identifier.
 */

export function createId(): number {
  const ahora = new Date();
  return ahora.getTime();
}


/**
 * Converts a string to a hexadecimal string.
 * @param {string} str The string to convert.
 * @returns The hexadecimal string.
 */
export function hexString(str: string) {
  return str.split("").map((c) => c.charCodeAt(0).toString(16)).join("");
}

/**
  * Returns the current date in the format 'YYYY-MM-DD'.
  * @param {string} text - The text to be copied to the clipboard.
  * @returns {string} - The current date in the format 'YYYY-MM-DD'.
 */

export async function copyTextToClipboard(text: string): Promise<void> {
  if (navigator.clipboard && window.isSecureContext) {
    // if clipboard API is available and the code is running in a secure context
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  } else {
    // if clipboard API is not available or the code is not running in a secure context
    try {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  }
}


/**
 * Modifies the table name by capitalizing each word and removing numbers if the name includes 'independence_format_table'.
 * @param {string} name - The original table name.
 * @returns {string} - The modified table name.
 */

export function modifyThreeStatesTableName(name: string): string {
  const words = name.replace(/_/g, " ").split(" ");
  let formattedTitle = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  if (name.includes("independence_format_table")) {
    formattedTitle = formattedTitle.replace(/[0-9]/g, "");
  }
  return formattedTitle.replace(/\s+/g, " ").trim();
}

/**
  * Groups the elements of an array by a given key.
  * @param {T[]} array - The array to group.
  * @param {(item: T) => K} callback - The function that returns the key to group by.
  * @returns {{ [key in K]: T[] }} - An object with the elements grouped by the key.
*/
export function groupBy<T, K extends string | number | symbol>(
  array: T[], callback: (item: T) => K
): { [key in K]: T[] } {
  return array.reduce((result, item) => {
    const key = callback(item);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {} as { [key in K]: T[] });
}

/**
 * Strips the HTML tags from a string.
 * @param {string} html - The HTML string to strip.
 * @returns {string} The string without HTML tags.
 */
export function stripHtml(html: string): string {
  // Create a new DOM element and set its innerHTML to the provided HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Retrieve and return only the text content
  return tempDiv.textContent || tempDiv.innerText || "";
}

/**
 * Encrypts a string using Base64 encoding.
 * @param {string} hashedString - The encrypted string.
 * @returns {string} The decrypted filename.
 */
export function decryptUniqueFilename(encryptedFilename: string): string {
  let filename = "";
  if (!encryptedFilename) return filename;

  const [, hashedString] = encryptedFilename.split(".");
  const [, base64] = hashedString.split(/_+/);
  try {
    filename = atob(base64);
  } catch (err) {
    filename = base64;
  }
  return filename;
}


/**
 * Downloads a file.
 * @param {Blob} file - The file to be downloaded.
 * @param {string} filename - The name of the file.
 */
export function downloadFile(file: Blob, filename: string): void {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = decryptUniqueFilename(filename);
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}


/**
 * Parses an object to a JSON object.
 * @param {any} obj - The object to be parsed.
 * @returns {any} The JSON object.
 */
export function parseObject(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

/**
  * Formats a number as a currency.
  * @param {number | string} value - The number to be formatted.
  * @param {string} locale - The locale to be used.
  * @param {string} currency - The currency to be used.
  * @returns {string} - The formatted currency.
  */
export function formatCurrency(value: number | string, locale: string = 'en-US', currency: string = 'USD'): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(Number(value));
}

/**
 * Converts an array of strings to an array of objects with 'id' and 'label' properties.
 * @param {string[]} array - The array of strings.
 * @param {function} transform - The function to transform the string.
 * @returns {Object[]} - The array of objects.
 */
type Option = { id: string; label: string };
export function arrayToOptions(array: readonly string[], transform?: (value: string) => string): Option[] {
  const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  return array.map((item) => ({
    id: item.split(" ").join("_").toLowerCase(),
    label: transform ? transform(capitalize(item)) : capitalize(item)
  }));
}

/**
 * Sanitizes a html string by removing all tags except the allowed ones.
 * @param {string} html - The html string to sanitize.
 * @returns The sanitized html string.
 */
export function sanitizeHTML(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // List of disallowed tags (lowercase)
  const disallowedTags = new Set([
    'script', 'iframe', 'object', 'embed',
    'link', 'style', 'meta', 'noscript',
    'frame', 'frameset', 'base', 'applet',
    'form', 'input', 'textarea', 'button',
    'select', 'option', 'marquee'
  ]);

  // Attributes to remove (case-insensitive check)
  const disallowedAttributes = new Set(['style', 'srcdoc']);

  // NodeWalker to traverse all elements
  const walker = doc.createTreeWalker(
    doc.body,
    NodeFilter.SHOW_ELEMENT,
    null
  );

  const nodesToRemove: Element[] = [];
  let currentNode = walker.currentNode as Element;

  while (currentNode) {
    const tagName = currentNode.tagName.toLowerCase();

    // Check if tag is disallowed
    if (disallowedTags.has(tagName)) {
      nodesToRemove.push(currentNode);
    } else {
      // Remove dangerous attributes
      const attributes = currentNode.attributes;
      const attrsToRemove: Attr[] = [];

      for (const attr of Array.from(attributes)) {
        const attrName = attr.name.toLowerCase();

        // Remove attributes if:
        // 1. In disallowedAttributes list
        // 2. Starts with 'on' (event handlers)
        // 3. Contains dangerous URI schemes
        if (disallowedAttributes.has(attrName) ||
          attrName.startsWith('on') ||
          isUnsafeAttributeValue(attr.value)) {
          attrsToRemove.push(attr);
        }
      }

      attrsToRemove.forEach(attr => {
        currentNode.removeAttribute(attr.name);
      });
    }

    currentNode = walker.nextNode() as Element;
  }

  // Remove disallowed nodes after traversal
  nodesToRemove.forEach(node => {
    node.parentNode?.removeChild(node);
  });

  return doc.body.innerHTML;
}

/**
 * Checks if a value is an unsafe attribute value.
 * @param {string} value - The attribute value to check.
 * @returns `true` if the value is unsafe, `false` otherwise.
 */
function isUnsafeAttributeValue(value: string): boolean {
  return value.startsWith('javascript:') ||
    value.startsWith('data:') ||
    value.startsWith('vbscript:');
}


/**
 * Sets the important attribute for the checkbox element.
 * @param el - The checkbox element.
 * @param property - The CSS property.
 * @param value - The CSS value.
 */
export function setImportantAttr(el: HTMLElement | null, property: string, value: string): void {
  if (el) el.style.setProperty(property, value, "important");
}