import { TrialBalanceUploadSessionRepository } from '../repository/TrialBalanceUploadSessionRepository'
import { T } from "../utils/i18n-config"

export class TrialBalanceUploadSessionController {

  constructor(private readonly _trialBalanceUploadSessionRepository = new TrialBalanceUploadSessionRepository()) {}

  public async updateMatchingAccount (event: Event, code: string, isNewAccount: boolean) {

    const value = !!+(event.target as HTMLInputElement).value
    const status = await this._trialBalanceUploadSessionRepository.updateAccountAttributeByCode(
      code,
      value,
      'same_account',
      isNewAccount
    )
    if (!status) {
      this._showErrorAndReturnDefaultValue(event, '')
      return
    }

    /*
    If they are the same account (value: true), allow to the user look
    for their importance and transaction flow, otherwise not 
    */
    if (value) {
      this.setDisableSwitches(code)
    } else {
      this.activateSwitches(code)
    }
  }

  public async updateIsImportantAccount (event: Event, code: string, isNewAccount: boolean) {
    const status = await this._trialBalanceUploadSessionRepository.updateAccountAttributeByCode(
      code,
      (event.target as HTMLInputElement).checked,
      'is_important',
      isNewAccount
    )
    if (!status) {
      this._showErrorAndReturnDefaultValue(event, !(event.target as HTMLInputElement).checked)
    }
  }

  public async updateRequireTransactionFlow (event: Event, code: string, isNewAccount: boolean) {
    const status = await this._trialBalanceUploadSessionRepository.updateAccountAttributeByCode(
      code,
      (event.target as HTMLInputElement).checked,
      'require_transaction_flow',
      isNewAccount
    )
    if (!status) {
      this._showErrorAndReturnDefaultValue(event, !(event.target as HTMLInputElement).checked)
    }
  }

  private _showErrorAndReturnDefaultValue (event: Event, value: string | boolean) {
    if (typeof value === 'boolean') {
      (event.target as HTMLInputElement).checked = value
    } else {
      (event.target as HTMLInputElement).value = value
    }
    window.htmlHelpers?.showToast('danger', T('There was an error updating the status'), T('Ups!'));
  }

  private setDisableSwitches (code: string) {
    const importanceSwitch = $(`#important-account-${code}`)
    const transactionFlowSwitch = $(`#require-transaction-flow-${code}`)

    importanceSwitch.prop('checked', false)
    transactionFlowSwitch.prop('checked', false)

    importanceSwitch.prop('disabled', true)
    transactionFlowSwitch.prop('disabled', true)

    importanceSwitch.trigger('change')
    transactionFlowSwitch.trigger('change')
  }

  private activateSwitches (code: string) {
    const importanceSwitch = $(`#important-account-${code}`)
    const transactionFlowSwitch = $(`#require-transaction-flow-${code}`)

    importanceSwitch.prop('disabled', false)
    transactionFlowSwitch.prop('disabled', false)
  }
  
}