import React, { useRef } from 'react'
import { T } from '@/utils/i18n-config'
import { Permissions } from '@/models/special-document/ElementBase'
import { CommitmentQuestion } from '@/models/special-document/ElementArgs'
import { CommitmentFormElement } from '@/models/special-document/CommitmentFormElement'
import EditModeProps from './EditModeProps'
import EditModeBase from './EditModeBase'


/**
 * Component for editing a Commitment Form element in the Special Document Editor
 * @param EditModeProps The properties for the Edit Mode component
 * @returns The Commitment Form element in edit mode
 */
const CommitmentFormEditMode = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<CommitmentFormElement>(iElement as CommitmentFormElement).current

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={() => { }}
        permissions={iElement.permissions as Permissions}
      >
        <div className="mb-5" style={{ marginTop: "-1.5rem"}}>
          {element.questions.map((question) => (
            <React.Fragment key={question.id}>
              {question.type === "title" && <h5 className="mt-5">{T(question.text)}</h5>}
              {question.type === "sub-header" && (
                <strong className="d-block mt-4">{T(question.text)}</strong>
              )}
              {question.type === "question" && (<QuestionOption question={question} />)}
            </React.Fragment>
          ))}
        </div>
      </EditModeBase>
    </>
  );
}


interface QuestionOptionProps {
  question: CommitmentQuestion
}

/**
 * Component for rendering a question with options in the Commitment Form
 * @param question The question to render
 * @returns The Question Option component
 */
function QuestionOption({ question }: QuestionOptionProps) {
  const hasExternals = question.reference || question.hasTextbox || question.hasAttachment

  function referenceToTitle(reference: string) {
    switch (reference) {
      case "ofac":
        return T("OFAC results")
      case "budgeted_hours":
        return T("Budgeted Hours")
      case "accounting_framework":
        return T("Accounting Framework")
      default:
        return ""
    }
  }

  return (
    <div>
      <p className="m-0 mb-2">{T(question.text)}</p>
      {hasExternals && (
        <div className="d-flex align-items-center gap-2">
          {question.reference && (<span className="badge rounded-pill bg-primary">{referenceToTitle(question.reference)}</span>)}
          {question.hasTextbox && (<span className="badge rounded-pill bg-primary">{T("Textbox")}</span>)}
          {question.hasAttachment && (<span className="badge rounded-pill bg-primary">{T("Attachment")}</span>)}
        </div>
      )}
    </div>
  )
}

export default CommitmentFormEditMode;
