import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'

interface IHtml2DocxRepositoryResponse {
    status: string;
    message: string;
    complete_error: string;
    base64_content: string;
}



export class Html2DocxRepository {
    private _jsonContentType = {
        'Content-Type': 'application/json'
    }
    constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

    async convertToDocx(htmlContent: string): Promise<IHtml2DocxRepositoryResponse> {
        const apiUrl = getUrl('html_to_docx_api/convert')
        try {
            const response = await this.fetcher(
                apiUrl,
                {
                    method: 'POST',
                    headers: this._jsonContentType,
                    body: JSON.stringify({ html_content: htmlContent })
                }
            )

            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            if (response.status !== 200) {
                throw new Error('Status is : ' + response.status)
            }

            return await response.json() as IHtml2DocxRepositoryResponse;
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error)
            throw error
        }
    }
}