import { getUrl } from '../../utils/app-config';
import { fetchAPI } from '../../services/api_services/fetchAPI';

export class CommitmentFormRepository {
  private readonly getRegistersApiUrl: string = getUrl("elements_api/get_external_content")

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
    * Get the external content of a specific question's answer.
    * @param elementId The id of the element to get the external content.
    * @param engagementId The id of the engagement to get the external content.
    * @param reference The reference for external content.
   */
  async getExternalContent(elementId: number, engagementId: number, reference: string): Promise<{success: boolean, content: string}> {
    const apiUrl = `${this.getRegistersApiUrl}/${elementId}/${engagementId}?reference=${reference}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
