import React from 'react';
import AceptCancelModalBase from '../modals/AceptCancelModalBase';
import { T } from "../../utils/i18n-config"
import { ElementTypeFactory } from "../../factory/special-document/ElementTypeFactory"


interface AddElementModalProps {
  showModal: boolean;
  handleAddElement: (elemetType: number) => void;
  handleAddElementCancel: () => void;
  reference: string;
}


const AddElementModal = ({ showModal, handleAddElementCancel, handleAddElement, reference }: AddElementModalProps) => {

  const elementTypeFactory = new ElementTypeFactory()
  const elementType = elementTypeFactory.getElementTypes(reference)
  const [selectedOption, setSelectedOption] = React.useState(elementType[0].type)

  const handleAccept = () => {
    handleAddElement(selectedOption);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(parseInt(event.target.value, 10));
  };

  return (
    <div>
      <AceptCancelModalBase
        showModal={showModal}
        title={T("Add Element")}
        size="lg"
        onAccept={handleAccept}
        onCancel={handleAddElementCancel}>
        <select value={selectedOption} onChange={handleSelectChange} className="form-select">
          {elementType.map((option) => (
            <option key={option.type} value={option.type}>
              {T(option.name)}
            </option>
          ))}
        </select>
      </AceptCancelModalBase>
    </div>
  );
};

export default AddElementModal;
