import React, { useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { IndependenceFormatElement } from "../../models/special-document/IndependenceFormatElement"
import ViewModeBase from "./ViewModeBase";
import ViewModeProps from './ViewModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import { IndependenceFormatInfo } from '../utils/IndependenceFormatInfo';

const IndependenceFormatEditMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef(iElement as IndependenceFormatElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)

  const handleConfguration = () => {
    setShowConfigurationsModal(true);
  }
  return (
    <div>
      <button className="btn btn-primary" onClick={handleConfguration} style={{width: '100%', padding: '5px 0px'}}>
        <h6 style={{ color: 'white', textTransform: 'capitalize'}}>
          {T("Independence Format Element View")}
        </h6>
      </button>
      <IndependenceFormatInfo
        engagmentId={element.engagement_id}
        showConfigurationsModal={showConfigurationsModal}
        setShowConfigurationsModal={setShowConfigurationsModal}
      />
    </div>
  );
}

export default IndependenceFormatEditMode;
