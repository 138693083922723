import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { ElementOfacResults } from "../../models/special-document/ElementOfacResults"
import EditModeBase from "./EditModeBase";
import ModalWithButtons from '../modals/AcceptModalBase';
import EditModeProps from './EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';


const ShowOfacResultsEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ElementOfacResults>(iElement as ElementOfacResults).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  useEffect(() => {
    if(element.state === "closed"){
      setIsEditable(false)
    }
  },[]);

  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);
  };


  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <h6>{T("OFAC results")}</h6>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("OFAC results settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}>
        <div className="card-body p-3">
          <h6>{T("Nothing to configure")}</h6>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default ShowOfacResultsEditMode;
