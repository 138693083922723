import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { ThreeStatesTableRepository } from "../../repository/special-document/ThreeStatesTableRepository"
import { ElementRepository } from '../../repository/special-document/ElementRepository';
import { ThreeStatesTableElement } from "../../models/special-document/ThreeStatesTableElement"
import EditModeBase from "./EditModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase';
import EditModeProps from './EditModeProps';
import { TablesOptions } from "../../models/special-document/ElementArgs";
import { BasicResponse } from "../../repository/special-document/ThreeStatesTableRepository";
import { Permissions } from '../../models/special-document/ElementBase';

const ThreeStatesTableEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ThreeStatesTableElement>(iElement as ThreeStatesTableElement).current
  const [tablesOptions, setTablesOptions] = useState<TablesOptions[]>()
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [optionIdSelected, setOptionIdSelected] = useState<number>()
  const [optionSelectedText, setOptionSelectedText] = useState<string>()
  useEffect(() => {
    const threeStatesTableRepository = new ThreeStatesTableRepository()
    threeStatesTableRepository.getThreeStatesTableOptions(element.documentId, element.engagement_id, 'edition').then((data) => {
      if((data as BasicResponse ).success === false) {
        data = data as BasicResponse
        // if(data.error_message) window.htmlHelpers?.customSwalError(data.error_message);
        return
      }
      data = data as TablesOptions[]
      setTablesOptions(data)  
      setOptionIdSelected(element.args.optionIdSelected as number)
      if(element.args.optionValueSelected) setOptionSelectedText(element.args.optionValueSelected as string)
    }).catch((error) => {
      console.error('There has been a problem with your fetch operation:', error)
    })  
  }, [element])

  const handleConfiguration = () => {
    const threeStatesTableRepository = new ThreeStatesTableRepository()
    threeStatesTableRepository.getThreeStatesTableOptions(element.documentId, element.engagement_id, 'edition').then((data) => {
      if((data as BasicResponse ).success === false) {
        data = data as BasicResponse
        if(data.error_message) window.htmlHelpers?.customSwalError(data.error_message);
        return
      }
      data = data as TablesOptions[]
      setTablesOptions(data)  
      setShowConfigurationsModal(true);
      
    }).catch((error) => {
      console.error('There has been a problem with your fetch operation:', error)
    })  
  };

  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);    
    if (!optionIdSelected || optionIdSelected === 0) {
      setOptionIdSelected(element.args.optionIdSelected as number)
      window.htmlHelpers?.customSwalError(T("Select a table"))
      return
    }
    const elementRepository = new ElementRepository()
    element.args.optionValueSelected = optionSelectedText
    element.args.optionIdSelected = optionIdSelected
    let success = await  elementRepository.saveElement("edition", element.args)
    
    if (success) {
         return 
    } else {
      window.htmlHelpers?.swalError()
    }
  };

  const handleConfigurationsModalCancel = () => {
    const currentOption = element.args.optionIdSelected as number
    if(currentOption) {
      setOptionIdSelected(currentOption)
    }
    else{
      setOptionIdSelected(0)
    }
    setShowConfigurationsModal(false)
  };

  const handleSelectVariable = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setOptionIdSelected(parseInt(event.target.value))
  }

  useEffect(() => {
    const optionName = tablesOptions?.find((table) => table.id === optionIdSelected)?.name
    if(optionName){
      setOptionSelectedText(optionName)
    }
    else{
      setOptionSelectedText("")
    }
  }, [optionIdSelected])

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              {
                optionSelectedText !== "" ? 
                <div>
                  <h6>{T("Table selected")}</h6>
                  <div className='chart p-3'>
                    {optionSelectedText}
                  </div>
                </div>
                :
                <h6>{T("Select a table")}</h6>
              }
            </div>
          </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Three States Table settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
        
        <select className="form-select" value={optionIdSelected} onChange={handleSelectVariable}>
          <option key={`option-i`} value={0}>{T("Select one format three states table")}</option>
          {
            tablesOptions?.map((table) => {
              return <option key={`option-${table.id}`} value={table.id as number}>{table.name}</option>
            })
          }
        </select>
      </ModalWithButtons>
    </div>
  );
};

export default ThreeStatesTableEditMode;
