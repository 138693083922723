import React, { useState, useEffect, useRef } from 'react'
import DOMPurify from 'isomorphic-dompurify';
import { T } from "../../utils/i18n-config"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ConditionalParagraphOfacElement } from "../../models/special-document/ConditionalParagraphOfacElement"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import Summernote from '../utils/Summernote';
import EditModeProps from './EditModeProps'
import EditModeBase from './EditModeBase'

const ConditionalParagraphOfacEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ConditionalParagraphOfacElement>(iElement as ConditionalParagraphOfacElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [messageYes, setMessageYes] = useState(element.messageYes)
  const [messageNo, setMessageNo] = useState(element.messageNo)
  const [isEditable, setIsEditable] = useState(false)

  useEffect(() => {
    if (element.state === "closed") {
      setIsEditable(false)
    }
  }, [])

  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  function handleMessageYes(message: string) {
    setMessageYes(message)
  }

  function handleMessageNo(message: string) {
    setMessageNo(message)
  }

  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const previousMessageYes = element.messageYes
    const previousMessageNo = element.messageNo
    element.messageYes = messageYes
    element.messageNo = messageNo

    const response = await elementRepository.saveElement("edition", element.args)
    if (!response) {
      element.messageYes = previousMessageYes
      element.messageNo = previousMessageNo
      setMessageYes(previousMessageYes)
      setMessageNo(previousMessageNo)
      window.htmlHelpers?.swalError()
    }
  }

  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
  }

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <h6>{T("Are there any indications that the entity may be involved in money laundering or other criminal activities?")}</h6>
            <div className="chart pt-3">
              <ul>
                <li>
                  <div className="row">
                    <div className="col-3">
                      <span className="d-block text-sm font-weight-normal">{T("Yes")}</span>
                      <span className="text-sm fst-italic">({T("Generate an alert")})</span>
                    </div>
                    <div className="col-9 d-flex">
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(messageYes) }} />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div className="col-3">
                      <span className="text-sm font-weight-normal">{T("No")}</span>
                    </div>
                    <div className="col-9 d-flex">
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(messageNo) }} />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div>
          <div className="form-group">
            <label>{T("Message if yes")}</label>
            <Summernote
              value={messageYes}
              onChange={handleMessageYes}
              showModal={showConfigurationsModal}
            />
          </div>
          <div className="form-group">
            <label>{T("Message if no")}</label>
            <Summernote
              value={messageNo}
              onChange={handleMessageNo}
              showModal={showConfigurationsModal}
            />
          </div>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default ConditionalParagraphOfacEditMode;
