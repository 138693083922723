import React, { useEffect, useRef } from 'react';


export interface DatatableSearchProps {
    data: any[][];
    headers: string[];
    isVisible: boolean;
    fixedHeight?: boolean;
    handleButtonClick: (rowIndex: number, btnClass: string) => void;
}
  

const DatatableSearch: React.FC<DatatableSearchProps> = ({ data, headers, isVisible, handleButtonClick, fixedHeight = true }) => {
    const tableRef = useRef<HTMLTableElement>(null);
    const dataTableRef = useRef<any>(null);

    useEffect(() => {
        if (isVisible && tableRef.current && !dataTableRef.current) {
            dataTableRef.current = new window.simpleDatatables.DataTable(tableRef.current, {
                searchable: true,
                fixedHeight: fixedHeight,
                perPage: 5,
            });
        } else if (!isVisible && dataTableRef.current) {
            dataTableRef.current.destroy();
            dataTableRef.current = undefined;
        }
    }, [isVisible]);

    const renderCellContent = (cell: any, rowIndex: number, cellIndex: number) => {
        if (Array.isArray(cell)) {
            return (
                <td key={'table-body-col-'+cellIndex} className="action-cell">
                    {cell.map((btn, btnIndex) => (
                        <button
                            key={`search-${rowIndex}-${btnIndex}`}
                            type="button"
                            className={`btn-sm btn ${btn.class}`}
                            data-row-index={rowIndex}
                            onClick={(event) => handleButtonClick(rowIndex, btn.class)}>
                            {btn.label}
                        </button>
                    ))}
                </td>
            );
        }
        return <td key={'table-body-col-'+cellIndex} className="text-sm font-weight-normal">{cell}</td>
    };

    useEffect(() => {
        const handleActionClick = (event: any) => {
            if (event.target.tagName === 'BUTTON' && event.target.closest('.action-cell')) {
                const btnClass = event.target.getAttribute('class');
                const rowIndex = event.target.getAttribute('data-row-index');
                handleButtonClick(rowIndex, btnClass);
            }
        };
    
        if (isVisible && dataTableRef.current && tableRef.current) {
            const tbody = tableRef.current.querySelector('tbody');
            if (tbody) tbody.addEventListener('click', handleActionClick);
        }
    
        return () => {
            if (tableRef.current) {
                const tbody = tableRef.current.querySelector('tbody');
                if (tbody) tbody.removeEventListener('click', handleActionClick);
            }
        };
    }, [isVisible, data]);

    return (
        <div className="table-responsive">
            <table className="table table-flush" ref={tableRef}>
                <thead className="thead-light">
                    <tr>
                        {headers.map((header, index) => (
                            <th key={'table-head-row'+index} className="text-sm font-weight-normal">{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={'table-body-row-'+rowIndex}>
                            {row.map((cell, cellIndex) => {
                                return renderCellContent(cell, rowIndex, cellIndex);
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DatatableSearch;
