import React from 'react';
import { NotificationType } from './NotificationType';

/**
 * Component that displays the icon of a notification based on its type.
 * @param type The type of the notification.
 * @description Supported types: {@link NotificationType}
 */
const NotificationIcon = ({ type }: { type: NotificationType }) => {
  const backgroundColors = {
    [NotificationType.INFO]: "bg-secondary",
    [NotificationType.SUCCESS]: "bg-success",
    [NotificationType.WARNING]: "bg-warning",
    [NotificationType.ERROR]: "bg-danger",
  };

  const borderColors = {
    [NotificationType.INFO]: "border-secondary",
    [NotificationType.SUCCESS]: "border-success",
    [NotificationType.WARNING]: "border-warning",
    [NotificationType.ERROR]: "border-danger",
  };

  const textColors = {
    [NotificationType.INFO]: "text-white",
    [NotificationType.SUCCESS]: "text-white",
    [NotificationType.WARNING]: "text-dark",
    [NotificationType.ERROR]: "text-white",
  };

  const iconType = {
    [NotificationType.INFO]: "fa-info",
    [NotificationType.SUCCESS]: "fa-check",
    [NotificationType.WARNING]: "fa-triangle-exclamation",
    [NotificationType.ERROR]: "fa-circle-exclamation",
  };

  return (
    <div
      className={`d-flex justify-content-center align-items-center me-1 ${backgroundColors[type]} border border-3 ${borderColors[type]} rounded-2 ${textColors[type]}`}
      style={{ width: "40px", height: "40px" }}
    >
      <i className={`fa-regular ${iconType[type]} fs-6`}></i>
    </div>
  );
}

export default NotificationIcon;