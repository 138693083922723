import { type Menu } from '../models/menu/Menu'
import { type MenuItem } from '../models/menu/MenuItem'

/**
 * This class is responsible for rendering the menu.
 */
export class MenuRenderer {
  private readonly _menu: Menu
  private readonly _menuContainerId: string = 'sidenav-collapse-main'
  private _firstRender: boolean = true

  /**
   * The constructor.
   * @param menu - The menu.
   */
  constructor (menu: Menu) {
    this._menu = menu
  }

  /**
   * Render the menu.
   */
  render (): void {
    if (this._firstRender) {
      this._firstRender = false
      let html = '<ul class="navbar-nav">'
      this._menu.items.forEach((item) => {
        if (!item.visible) {
          return
        }
        html += this.renderLoad(item)
      })
      html += '</ul>'

      const menuContainer = document.getElementById(this._menuContainerId)
      if (menuContainer != null) {
        menuContainer.innerHTML = html
      } else {
        console.error(`The element with id ${this._menuContainerId} was not found`)
      }
    } else {
      this._menu.items.forEach((item) => {
        if (!item.visible) {
          return
        }
        this.renderUpdate(item)
      })
    }
  }

  /**
   * This method renders the menu item the first time.
   * @param menuItem - The menu item.
   * @returns The html.
   */
  private renderLoad (menuItem: MenuItem): string {
    let html = `
    <li class="nav-item">
      <a id="${menuItem.name}-menu" data-bs-toggle="collapse" href="#${menuItem.name}-sub-menu" class="nav-link ${menuItem.active ? 'active' : ''}" aria-controls="${menuItem.name}-sub-menu" role="button" aria-expanded="${menuItem.active ? 'true' : 'false'}">
        <div id="${menuItem.name}-menu-div" class="${menuItem.active ? 'icon icon-shape' : ''} icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2">
          <i class="${menuItem.icon}"></i>
        </div>
        <span class="nav-link-text ms-1">${menuItem.label}</span>
      </a>`
    if (menuItem.hasSubItems()) {
      html += `<div class="collapse show" id="${menuItem.name}-sub-menu" style=""><ul class="nav ms-4 ps-3">`
      menuItem.subMenu?.items.forEach(subItem => {
        html += `
        <li id="${subItem.name}" class="nav-item ${subItem.active ? 'active' : ''}">
          <a id="${subItem.name}-item-sub-menu-a" class="nav-link ${subItem.active ? 'active' : ''}" href="#" onclick="window.menuService.navigateTo('${subItem.name}');">
            <span class="sidenav-mini-icon"> ${subItem.icon} </span>
            <span class="sidenav-normal"> ${subItem.label}</span>
          </a>
        </li>`
      })
      html += '</ul></div>'
    }
    html += '</li>'
    return html
  }

  /**
   * This method renders the menu item after the first render.
   * @param menuItem - The menu item.
   */
  private renderUpdate (menuItem: MenuItem): void {
    this.updateMenuItem(menuItem, `${menuItem.name}-menu`, 'aria-expanded', menuItem.active ? 'true' : 'false', `nav-link ${menuItem.active ? 'active' : ''}`)
    this.updateMenuItem(menuItem, `${menuItem.name}-menu`, 'class', `nav-link ${menuItem.active ? 'active' : ''}`)
    this.updateMenuItem(menuItem, `${menuItem.name}-menu-div`, 'class', `${menuItem.active ? 'icon icon-shape' : ''} icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2`)

    if (menuItem.hasSubItems()) {
      menuItem.subMenu?.items.forEach(subItem => {
        this.updateMenuItem(subItem, `${subItem.name}`, 'class', `nav-item ${subItem.active ? 'active' : ''}`)
        this.updateMenuItem(subItem, `${subItem.name}-item-sub-menu-a`, 'class', `nav-link ${subItem.active ? 'active' : ''}`)
      })
    }
  }

  /**
   * This method updates the menu item.
   * @param item - The menu item.
   * @param elementId - The element id.
   * @param attribute - The attribute.
   * @param value - The value.
   * @param secondaryValue - The secondary value.
   */
  private updateMenuItem (item: MenuItem, elementId: string, attribute: string, value: string, secondaryValue: string = ''): void {
    const element = document.getElementById(elementId)
    if (element != null) {
      if (secondaryValue !== '') {
        element.setAttribute(attribute, secondaryValue)
      } else {
        element.setAttribute(attribute, value)
      }
    } else {
      console.error(`The element with id ${elementId} was not found`)
    }
  }
}
