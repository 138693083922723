import { AccountFlowchartRepository } from '../../repository/AccountFlowchartRepository';
import Swal from 'sweetalert2'


export class AccountFlowchartService {
  private updater: AccountFlowchartRepository;

  constructor () {
    this.updater = new AccountFlowchartRepository();
  }

  update(selectElement: HTMLSelectElement): void {
    const accountFlowchartId = selectElement.getAttribute('data-account-flowchart-id');
    const transactionFlowCategoryId = selectElement.value;

    if (!accountFlowchartId || !transactionFlowCategoryId) {
      console.error('Required data attributes are missing');
      return;
    }

    const payload = {
      account_flowchart_id: parseInt(accountFlowchartId, 10),
      transaction_flow_category_id: parseInt(transactionFlowCategoryId, 10)
    };

    this.updater.update(payload)
      .then(() => {
        //console.log('Update successful');
      })
      .catch(error => {
        console.error('Update failed', error);
        Swal.fire({
          title: 'Error!',
          text: error,
          icon: 'error',
          confirmButtonText: 'Cool'
        })
      });
  }
}
