import { TablesOptions } from '../../models/special-document/ElementArgs'
import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'


export interface BasicResponse {
  success: boolean
  error_message?: string
}


export class ThreeStatesTableRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  async getTableData(documentId: number, engagement_id: number|null, tableId: number, renderingMode: string): Promise<TablesOptions|BasicResponse> {
    const apiUrl = getUrl('three_states_table_api/get_three_state_table_data/' + engagement_id +'/'+ documentId+ '/' + tableId + '/' + renderingMode)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: TablesOptions = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async getThreeStatesTableOptions(documentId: number, engagement_id: number|null, renderingMode: string): Promise<TablesOptions[]|BasicResponse> {
    const apiUrl = getUrl('three_states_table_api/get_three_state_table_options/' + engagement_id + '/' + documentId + '/' + renderingMode)
    
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: TablesOptions[]|BasicResponse = await response.json()
      return data

    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async updateThreeStatesTableData(documentId: number, engagement_id: number|null, tableData: TablesOptions, renderingMode: string): Promise<BasicResponse> {
    const apiUrl = getUrl('three_states_table_api/update_three_state_table_data/' + engagement_id + '/' + documentId + '/' + renderingMode)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(tableData)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}