import React, { useState, useId } from 'react';
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from '../../utils/utilities';
import Summernote from '../utils/Summernote';
import Dropzone from '../utils/Dropzone';


interface ConditionalParagraphWithSelectorOptionProps {
  index: number;
  value: string;
  message: string;
  elementId: number;
  isEditable: boolean;
  hasAttachment: boolean;
  attachmentName: string;
  showModal: boolean;
  handleEditValue: (index: number, value: string) => void;
  handleEditMessage: (index: number, message: string) => void;
  handleSetIsEditable: (index: number, isEditable: boolean) => void;
  handleSetHasAttachment: (index: number, hasAttachment: boolean) => void;
  handleEditAttachmentName: (index: number, attachment_name: string) => void;
}


const ConditionalParagraphWithSelectorOption = ({
  index, value, message, elementId, isEditable, hasAttachment, attachmentName, showModal,
  handleEditValue, handleEditMessage, handleSetIsEditable, handleSetHasAttachment, handleEditAttachmentName
}: ConditionalParagraphWithSelectorOptionProps) => {
  const [switchValue, setSwitchValue] = useState(hasAttachment);
  const [attachment, setAttachment] = useState(attachmentName);
  const switchId = useId();

  const handleEditorChange = (content: string) => {
    handleEditMessage(index, content);
  };

  const handleSetLocalIsEditable = () => {
    handleSetIsEditable(index, !isEditable);
  }

  function handleHasAttachment(hasAttachment: boolean) {
    setSwitchValue(hasAttachment);
    handleSetHasAttachment(index, hasAttachment);
  }

  function handleSetAttachment(attachmentName: string, originalFilename: string) {
    setAttachment(originalFilename);
    handleEditAttachmentName(index, attachmentName);
  }

  return (
    <div>
      <div className="form-group">
        <label className="mb-0">{T("Option")}</label>
        <input type="text" className="form-control" placeholder={T('Enter option')} value={value} onChange={(e) => handleEditValue(index, e.target.value)} />
      </div>
      <div className="form-group">
        <div className="d-flex justify-content-between align-items-center">
          <label>{T("Message")}</label>
          <div className="form-check form-switch d-flex align-items-center gap-2 mb-2">
            <label className="form-check-label m-0" htmlFor={switchId}>{T("Text Box")}</label>
            <input
              type="checkbox"
              role="switch"
              id={switchId}
              className="form-check-input m-0"
              checked={switchValue}
              onChange={() => handleHasAttachment(!switchValue)}
            />
            <label className="form-check-label m-0" htmlFor={switchId}>{T("Attachment")}</label>
          </div>
        </div>
        {
          attachment && (
            <div className="d-flex align-items-center gap-2 py-1 px-2 border border-2 border-secondary rounded" style={{ width: 'fit-content'}}>
              <span className="text-sm fw-bold text-truncate text-uppercase" style={{ maxWidth: '150px'}}>
                {decryptUniqueFilename(attachment)}
              </span>
              <button
                type="button"
                style={{ cursor: 'pointer' }}
                className="p-1 bg-transparent border-0 text-secondary"
                onClick={() => handleSetAttachment('', '')}
              >
                <i className="fa-regular fa-trash-can"></i>
                <span className="visually-hidden">{T("Remove file")}</span>
              </button>
            </div>
          )
        }
        {
          (switchValue && !attachment) && (
            <Dropzone elementId={elementId} onUpload={handleSetAttachment} />
          )
        }
        {
          !switchValue && (
            <Summernote value={message} onChange={handleEditorChange} showModal={showModal} />
          )
        }
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" checked={isEditable} onChange={() => handleSetLocalIsEditable()} />
        <label className="custom-control-label">{T("Is Editable")}</label>
      </div>
      <hr className="horizontal dark my-3"></hr>
    </div>
  );
};

export default ConditionalParagraphWithSelectorOption
