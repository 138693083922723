import React, { useState, useEffect } from 'react';
import { T } from "../../utils/i18n-config";
import { ElementBase, Permissions } from '../../models/special-document/ElementBase';
import { ElementRepository } from '../../repository/special-document/ElementRepository';

interface ViewModeBaseProps {
  isEditable: boolean;
  handleEdit?: () => void;
  permissions: Permissions;
  children?: React.ReactNode;
  isAlertWarning?: boolean;
  currentElement?: ElementBase;
}


const ViewModeBase = ({ isEditable, handleEdit, permissions, children, isAlertWarning = false, currentElement }: ViewModeBaseProps) => {
  const [showByVariable, setShowByVariable] = useState(true)

  async function getGlobalVariable(element: ElementBase) {
    if (!element.global_variable) return
    const elementRepository = new ElementRepository()
    const response = await elementRepository.getGlobalVariable(
      element.engagement_id, element.id, element.global_variable.name, element.reference
    )
    if (!response.success) return
    const checkedValue = response.variable.includes(element.global_variable.value)
    setShowByVariable(checkedValue)
  }

  useEffect(() => {
    if (currentElement) getGlobalVariable(currentElement)
  }, []);

  if (!showByVariable) return null;
  return (
    <>
      {isAlertWarning ? (
        <div className={`card h-100 mb-3 ${isAlertWarning ? 'bg-warning' : 'bg-light'}`}>
          <div className="card-body p-3">
            <h6>{T("It is necessary to complete the planning stage in order to access this element.")}</h6>
          </div>
        </div>
      ) : (
        <div>
          <div className="row align-items-center mb-3">
            <div id="1_3" className="col-11">{children}</div>
            <div className="col-1 text-end d-print-none">

              {isEditable && permissions.update &&
                (
                  <button className="btn btn-icon btn-primary element-card-buttons" onClick={handleEdit}>
                    <span className="btn-inner--icon"><i className="fa fa-pen py-2" aria-hidden="true"></i></span>
                  </button>
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewModeBase;
