import React, { useEffect, useRef, useState } from 'react';
import { T } from "../../utils/i18n-config"
import { IElementBase } from "../../models/special-document/ElementBase"
import Swal from 'sweetalert2';
import { Permissions } from '../../models/special-document/ElementBase';

interface EditModeBaseProps {
  iElement: IElementBase;
  isEditable: boolean;
  handleDeleteElement: (iElement: IElementBase) => void;
  handleUpElement: (iElement: IElementBase) => void;
  handleDownElement: (iElement: IElementBase) => void;
  handleConfiguration: () => void;
  permissions: Permissions;
  children?: React.ReactNode;
}


const EditModeBase = ({ iElement, isEditable, handleDeleteElement, handleConfiguration, handleUpElement, handleDownElement, permissions, children}: EditModeBaseProps) => {
  const optionMenu = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleDelete = () => {
    Swal.fire({
        title: T('Are you sure?'),
        text: T("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: T('Yes, delete it!'),
        cancelButtonText: T('Cancel')
    }).then((result) => {
        if (result.isConfirmed) {
            handleDeleteElement(iElement);
        }
    });
  };

  const handleUp = () => {
    handleUpElement(iElement);
  }

  const handleDown = () => {
    handleDownElement(iElement);
  }
  
  const handleDropDown = () => {
    optionMenu.current.classList.toggle('show');
    const elements = document.getElementsByClassName('drop-menu-react');
    Array.from(elements).forEach((currentElement) => {
      const htmlElement = currentElement as HTMLElement;
      if(htmlElement.classList.contains('show') && htmlElement.id !== `drop-menu-${iElement.id}`) {
        htmlElement.classList.remove('show');  
      }
    });
  }

  return (
    <div className="row align-items-center mb-3">
      <div id="1_3" className="col-11">{children}</div>
      <div className="col-1 d-flex text-start pl-6 span-1 align-items-center">
        {
          (permissions.update || permissions.delete) && iElement.state === "open" &&
          <div className="dropdown" onClick={handleDropDown}>
            <button className="btn btn-link text-secondary px-3 py-2 drop-activation-button-react mb-0" id="dropdownMenuButton">
              <i className="fas fa-ellipsis-v text-lg drop-activation-button-react"></i>
            </button>
            <div id= {`drop-menu-${iElement.id}`} ref={optionMenu} className="dropdown-menu drop-menu-react">
              {
                permissions.update &&
                <button className="dropdown-item d-flex gap-2 align-items-center" onClick={handleConfiguration} >
                  <i className="fas fa-cog"></i>
                  <span>{T("Configuration")}</span>
                </button>
              }
              {
                permissions.delete &&
                <button className="dropdown-item d-flex gap-2 align-items-center" onClick={handleDelete} >
                  <i className="fas fa-trash"></i>
                  <span>{T("Delete")}</span>
                </button>
              }
              {
                permissions.update &&
                <button className="dropdown-item d-flex gap-2 align-items-center" onClick={handleUp} >
                  <i className="fas fa-arrow-up"></i>
                  <span>{T("Up")}</span>
                </button>
              }
              {
                permissions.update &&
                <button className="dropdown-item d-flex gap-2 align-items-center" onClick={handleDown} >
                  <i className="fas fa-arrow-down"></i>
                  <span>{T("Down")}</span>
                </button>
              }
            </div>
          </div>  
        }
        {isEditable && permissions.update && <i className="fas fa-pencil-alt text-primary"></i>}
      </div>
    </div>
  );
};

export default EditModeBase;
