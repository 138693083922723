import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'summernote/dist/summernote-lite.js';
import VariablesModal from '../special-document/EngagementVariablesModal';
import { CharacterCounter } from './CharacterCounter';

interface SummernoteProps {
  value: string;
  onChange: (value: string) => void;
  showModal?: boolean;
  maxLength?: number;
}


const Summernote: React.FC<SummernoteProps> = ({ value, onChange, showModal, maxLength = 6000 }) => {
    const editorRef = useRef<HTMLDivElement>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [variableText, setVariableText] = useState('');

    useEffect(() => {
      try{
        if (editorRef.current) {
          $(editorRef.current).summernote({
              // height: 200,
              toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'italic', 'underline', 'clear']],
                ['fontname', ['fontname']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['height', ['height']],
                ['table', ['table']],
                //['insert', ['link', 'picture', 'video']],
                //['view', ['fullscreen', 'codeview', 'help']],
                ['custom', ['variables']]
              ],
              fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '22', '24', '28', '30', '36'],
              fontNames: ['Arial', 'Courier New', 'Times New Roman', 'Open Sans', 'sans-serif'],
              fontNamesIgnoreCheck: ['Arial', 'Courier New', 'Times New Roman', 'Open Sans', 'sans-serif'],
              buttons: {
                  variables: function(context: any) {
                      const ui = $.summernote.ui;
                      const button = ui.buttonGroup([
                          ui.button({
                              contents: 'V',
                              click: function() {
                                if (editorRef.current) { 
                                  $(editorRef.current).summernote('focus');
                                }
                                setIsModalOpen(true);
                              }
                          })
                      ]);
  
                      return button.render();
                  }
              },
              callbacks: {
                onChange: (contents: string) => {
                  if (contents.length > maxLength) {
                      // Recorta el contenido para que cumpla con el límite de caracteres
                      const trimmedContent = contents.substring(0, maxLength);
                      $(editorRef.current).summernote('code', contents.substring(0, contents.lastIndexOf(trimmedContent) + trimmedContent.length));
                  } else {
                      setVariableText(contents);
                      onChange(contents);
                  }
                },
                onPaste: (event: any) => {
                  event.preventDefault();
                  let bufferText = ((event.originalEvent || event).clipboardData || window.Clipboard).getData('Text');
                  if (bufferText.length > maxLength) {
                      bufferText = bufferText.substring(0, maxLength);
                  }
                  document.execCommand('insertText', false, bufferText);
                }
              }
          });
  
          return () => {
            if (editorRef.current) {
              $(editorRef.current).summernote('destroy');
            }
          };
        }
      }
      catch(err){
        console.error(err);
      }
    }, []);

    useEffect(() => {
      if (editorRef.current) {
        $(editorRef.current).summernote('code', value);
      }
    }, [showModal]);

    const handleSelectVariable = () => {
      setIsModalOpen(false);
    };

    return (
      <div>
        <div ref={editorRef}></div>
          <VariablesModal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                onSelectVariable={handleSelectVariable} 
                copyToClipboard={true}
          />
          <CharacterCounter text={variableText} maxLength={maxLength} />
      </div>
    );
};

export default Summernote;
