import { ElementArgs } from "./ElementArgs"

export interface Permissions{
  read: boolean
  create: boolean
  update: boolean
  delete: boolean
}

export class ElementBase {
  private _id: number
  private _name?: string
  private _type: number
  private _documentId: number
  private _engagement_id: number
  private _reference?: string
  private _permissions?: Permissions = {
    read: false,
    create: false,
    update: false,
    delete: false
  }
  private _state: string
  private _height: number
  private _width : number
  private _x: number
  private _y: number
  private _draggable: boolean
  private _args: ElementArgs
  private _global_variable?: {
    name: string;
    value: string;
  }

  constructor( args: ElementArgs){
    this._id = args.id
    this._name = args.name
    this._type = args.type
    this._documentId = args.document_id
    this._engagement_id = args.engagement_id ?? 0
    this._reference = args.reference
    this._permissions = args.permissions
    this._state = args.state
    this._height = args.height ?? 0
    this._width = args.width ?? 0
    this._draggable = args.draggable ?? true
    this._x = args.x ?? 0
    this._y = args.y ?? 0
    this._args = args
    this._global_variable = args.global_variable
  }
  get id(): number{
    return this._id
  }

  get name(): string | undefined{
    return this._name
  }

  get type(): number{
    return this._type
  }

  get documentId(): number{
    return this._documentId
  }

  get engagement_id(): number{
    return this._engagement_id
  }

  get permissions(): Permissions | undefined{
    return this._permissions
  }

  get reference(): string | undefined{
    return this._reference
  }

  get state(): string{
    return this._state
  }

  get height(): number{
    return this._height
  }

  get width(): number{
    return this._width
  }

  get draggable(): boolean{    
    return this._draggable
  }

  get x(): number{
    return this._x
  }

  get y(): number{  
    return this._y
  }

  get global_variable(): { name: string; value: string; } | undefined {
    return this._global_variable
  }

  set reference(reference: string | undefined){
    this._reference = reference
  }

  set x(x: number){
    this._x = x
  }

  set y(y: number){
    this._y = y
  } 

  set height(height: number){
    this._height = height
  }

  set width(width: number){
    this._width = width
  }
  
  set draggable(draggable: boolean){
    this._draggable = draggable
  }

  set name(name: string | undefined){
    this._name = name
    this._args.name = name
  }

  set permissions(permissions: Permissions | undefined){
    this._permissions = permissions
    this._args.permissions = permissions
  }

  set global_variable(variable: {name: string, value: string} | undefined) {
    this._global_variable = variable
    this._args.global_variable = variable
  }

  getKey(): string{
    return `${this._type}_${this._id}`
  }

  get args(): ElementArgs{
    return this._args
  }

}

export interface IElementBase extends ElementBase{

  render() : void;
  
}