import { type Menu } from '../models/menu/Menu'
import { type MenuItem } from '../models/menu/MenuItem'
import { HtmlHelpers } from '../utils/HtmlHelpers'

/**
 * This class is responsible for rendering the breadcrumb.
 */
export class BreadcrumbRenderer {
  private readonly _menu: Menu
  private readonly _breadcrumbContainerId: string = 'breadcrumb'
  private readonly _breadcrumbTitleId: string = 'breadcrumb-title'
  private readonly _htmlHelpers: HtmlHelpers = new HtmlHelpers()

  /**
   * The constructor.
   * @param menu - The menu.
   */
  constructor (menu: Menu) {
    this._menu = menu
  }

  /**
   * Render the breadcrumb.
   * @param reload - This flag indicates if the page should be reloaded.
   */
  render (reload: boolean = false): void {
    this._menu.items.forEach((item) => {
      if (item.active && item.hasSubItems()) {
        this.renderLoad(item, reload)
      }
    })
  }

  /**
   * Render the breadcrumb for the given menu item.
   * @param menuItem - The menu item.
   * @param reload - This flag indicates if the page should be reloaded.
   */
  private renderLoad (menuItem: MenuItem, reload: boolean): void {
    const breadcrumbContainer = document.getElementById(this._breadcrumbContainerId)
    const titleElement = document.getElementById(this._breadcrumbTitleId)
    if (breadcrumbContainer != null && titleElement != null) {
      menuItem.subMenu?.items.forEach(subItem => {
        const page = subItem.page
        if (subItem.active && page != null) {
          breadcrumbContainer.innerHTML = ''
          let title = ''
          let url = ''
          page.breadcrumb.levels.forEach((level, index) => {
            const li = this.createBreadcrumbLevel(level.url, level.label, '', index, level.icon)
            breadcrumbContainer.innerHTML += li
            title = level.label
            url = level.url
          })
          titleElement.textContent = title
          if (reload) {
            this._htmlHelpers.setSpinner(page.target)
            web2py_component(url, page.target)
          }
        }
      })
    }
  }

  /**
   * Create a breadcrumb level.
   * @param url - The url.
   * @param name - The name.
   * @param basePageContentDivId - The base page content div id.
   * @param levelIndex - The level index.
   * @param iconClass - The icon class.
   * @returns The breadcrumb level.
   */
  createBreadcrumbLevel (url: string, name: string, basePageContentDivId: string, levelIndex: number, iconClass: string | null): string {
    let iconHtml = ''
    if (iconClass !== null) {
      iconHtml = `<i class="${iconClass}" style="margin-right: 8px;"></i>`
    }

    return `
        <li class="breadcrumb-item text-sm">
            <a href="javascript:void(0)" onclick="window.menuService.breadcrumbSetLevel(${levelIndex});">
                ${iconHtml}
                ${name}
            </a>
        </li>
    `
  }
}
