import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { VarianceAnalysisNotesElement } from "../../models/special-document/VarianceAnalysisNotesElement"
import ViewModeBase from "./ViewModeBase";
import ViewModeProps from './ViewModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import DOMPurify from 'isomorphic-dompurify';


/**
 * Component to display variance analysis notes in view mode.
 * @param {ViewModeProps} iElement - The variance analysis element.
 */

const VarianceAnalysisNotesViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<VarianceAnalysisNotesElement>(iElement as VarianceAnalysisNotesElement).current
  const [ledgerAccountID, setLedgerAccountID] = useState(0)
  const [ledgerAccountCode, setLedgerAccountCode] = useState("")
  const [ledgerAccountName, setLedgerAccountName] = useState("")
  const [amountValue, setAmountValue] = useState(0)
  const [amountNote, setAmountNote] = useState("")
  const [percentageValue, setPercentageValue] = useState(0)
  const [percentageNote, setPercentageNote] = useState("")

  useEffect(() => {
    setLedgerAccountID(element.ledger_account_id)
    setLedgerAccountCode(element.ledger_account_code)
    setLedgerAccountName(element.ledger_account_name)
    setAmountValue(element.amount_value)
    setAmountNote(element.amount_note)
    setPercentageValue(element.percentage_value)
    setPercentageNote(element.percentage_note)
  }, [element])


  return (
    <div>
      <ViewModeBase
        isEditable={false}
        handleEdit={() => {}}
        permissions={element.permissions as Permissions}
        isAlertWarning={element.is_alert_warning}>
          {
            ledgerAccountID > 0 &&
            <div className="py-3">
              <h4>{T("Variance Analysis Notes")}</h4>
              <h6>
                {ledgerAccountCode} - {ledgerAccountName}
              </h6>
              <div className='d-flex mt-2 mx-2 flex-column'>
                <h5>{T("Amount")}</h5>
                <div className='mx-2 d-flex flex-column'>
                  <strong>{T("Value")}</strong>
                  <div className='mx-2'>
                    {amountValue.toLocaleString(
                      'es-CO',
                      { 
                        maximumFractionDigits: 0,
                        style: 'currency',
                        currency: 'COP'
                      }
                    )}
                  </div>
                  {
                    amountNote !== "" &&
                    <div className='d-flex flex-column'>
                      <strong>{T("Note")}</strong>
                      <div className='mx-2' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(amountNote) }} />
                    </div>
                  }
                </div>
              </div>
              <div className='d-flex mt-2 mx-2 flex-column'>
                <h5>{T("Percentage")}</h5>
                <div className='mx-2 d-flex flex-column'>
                  <strong>{T("Value")}</strong>
                  <div className='mx-2'>
                    {Math.floor(percentageValue*100)}%
                  </div>
                  {
                    percentageNote !== "" &&
                    <div className='d-flex flex-column'>
                      <strong>{T("Note")}</strong>
                      <div className='mx-2' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(percentageNote) }} />
                    </div>
                  }
                </div>
              </div>
              </div>
          }
      </ViewModeBase>
    </div>
  );
}

export default VarianceAnalysisNotesViewMode;