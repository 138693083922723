import { MenuFactory } from '../../factory/MenuFactory'
import { MenuRenderer } from '../../renderer/MenuRenderer'
import { PageRenderer } from '../../renderer/PageRenderer'
import { type Menu } from '../../models/menu/Menu'
import { BreadcrumbRenderer } from '../../renderer/BreadcrumbRenderer'
import { HtmlHelpers } from '../../utils/HtmlHelpers'

/**
 * This class represents the menu service. It is responsible for the business logic.
 */
export class MenuService {
  private _menu: Menu | null = null
  private _menuRenderer: MenuRenderer | null = null
  private _pageRenderer: PageRenderer | null = null
  private _breadcrumbRenderer: BreadcrumbRenderer | null = null
  private _htmlHelpers: HtmlHelpers = new HtmlHelpers()

  /**
   * Create the menu.
   */
  createMenu(): void {
    const menuFactory = new MenuFactory()
    menuFactory.createMenu().then(menu => {
      this._menu = menu
      this._menuRenderer = new MenuRenderer(this._menu)
      this._menuRenderer.render()
      this._pageRenderer = new PageRenderer(this._menu)
      this._pageRenderer.render()
      this._breadcrumbRenderer = new BreadcrumbRenderer(this._menu)
      this._breadcrumbRenderer.render()
    }).catch(error => {
      console.error(error)
    })
  }

  /**
   * Navigate to the page.
   * @param name - The name of the page.
   */
  navigateTo (name: string): void {
    if (this._menu != null) {
      this._menu.navigateTo(name)
      this._menuRenderer?.render()
      this._pageRenderer?.render()
      this._breadcrumbRenderer?.render()
      this._htmlHelpers.scrollToTop()
    }
  }

  /**
   * Set the breadcrumb level. This method is called when the user clicks on a breadcrumb.
   * @param index - The index.
   */
  breadcrumbSetLevel (index: number): void {
    if (this._menu != null) {
      this.unmountComponentAtNode()
      this._menu.breadcrumbSetLevel(index)
      this._breadcrumbRenderer?.render(true)
      this._htmlHelpers.scrollToTop()
    }
  }

  /**
   * Add a breadcrumb level. This method is called when the user clicks on a link.
   * @param url - The url.
   * @param label - The label.
   */
  breadcrumbAddLevel (
    url: string, 
    label: string,
    reactContainerId: string | null = null
    ): void {
    if (this._menu != null) {
      this.unmountComponentAtNode()
      this._menu.breadcrumbAddLevel(url, label, reactContainerId)
      this._breadcrumbRenderer?.render(true)
      this._htmlHelpers.scrollToTop()
    }
  }

  /**
   * Back a breadcrumb level with a new url. This method is called when the user clicks on a link.
   * @param url - The url.
   * @param label - The label.
   */
  breadcrumbBackLevelWithNewUrl (
    url: string, 
    label: string,
    reactContainerId: string | null = null
    ): void {
    if (this._menu != null) {
      this.unmountComponentAtNode()
      this._menu.breadcrumbBackLevelWithNewUrl(url, label, reactContainerId)
      this._breadcrumbRenderer?.render(true)
      this._htmlHelpers.scrollToTop()
    }
  }


  breadcrumbBackLevel (): void {
    if (this._menu != null) {
      this.unmountComponentAtNode()
      this._menu.breadcrumbBackLevel()
      this._breadcrumbRenderer?.render(true)
    }
  }

  breadcrumbRefreshLevel (): void {
    if (this._menu != null) {
      this.unmountComponentAtNode()
      this._breadcrumbRenderer?.render(true)
      this._htmlHelpers.scrollToTop()
    }
  }

  private unmountComponentAtNode(): void {
    if (this._menu != null) {
      const reactContainerId = this._menu.getReactContainerId()
      if (reactContainerId != null) {
        window.htmlHelpers?.unmountComponentAtNode(reactContainerId)
      }
    }
  }

  breadcrumbSetReactContainerId(reactContainerId: string): void {
    if (this._menu != null) {
      this._menu.setReactContainerId(reactContainerId)
    }
  }
}
