import { getUrl } from '../utils/app-config'
import { fetchAPI } from '../services/api_services/fetchAPI'

export interface IDataItem {
  id: number
  name: string
}


export class CompanySettingsRepository {
  private readonly apiUrl: string = getUrl('company_settings_api/get_variables_format')

  constructor (private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) {}

  async getVariablesFormat (variableTypeId: number): Promise<IDataItem[]> {
    try {
      const response = await this.fetcher(this.apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          variableTypeId
        })
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data: {success: boolean, data: IDataItem[]} = await response.json()

      if (!data.success) {
        throw new Error('Response was not successful')
      }

      return data.data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw new Error('There has been a problem with your fetch operation')
    }
  }
}
