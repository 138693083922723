import { DocumentSpecial } from "../../models/special-document/DocumentSpecial"
import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'

export interface IDocumentSpecial {
  id: number
  reference: string
  state: string, 
  engagement_id?: number | null
  name?: string
}

export interface IReturnId {
  id: number
}


export class DocumentSpecialRepository{
  private readonly getApiUrl: string = getUrl('special_document_api/get')
  private readonly postApiUrl: string = getUrl('special_document_api/post')
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  
  constructor (private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  private async fetchJSON(url: string, options?: RequestInit): Promise<any> {
    const response = await this.fetcher(url, options);
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  async createDocumentSpecial(documentSpecial: DocumentSpecial): Promise<number> {
    const data: IDocumentSpecial = {
      id: documentSpecial.id,
      reference: documentSpecial.reference,
      state: documentSpecial.state,
      engagement_id: documentSpecial.engagementID,
      name: documentSpecial.name
    }
    const apiUrl = getUrl('special_document_api/create_special_document')
    const returnData: IReturnId = await this.fetchJSON(apiUrl, {
      method: 'POST',
      headers: this._jsonContentType,
      body: JSON.stringify(data)
    })

    return returnData.id
  }

  async saveDocumentSpecial(documentSpecial: DocumentSpecial): Promise<void> {
    const data: IDocumentSpecial = {
      id: documentSpecial.id,
      reference: documentSpecial.reference,
      state: documentSpecial.state,
      engagement_id: documentSpecial.engagementID,
      name: documentSpecial.name
    }
    const apiUrl = getUrl('special_document_api/save_special_document')
    this.fetchJSON(apiUrl, {
      method: 'POST',
      headers: this._jsonContentType,
      body: JSON.stringify(data)
    })

  }

  async getDocumentByReference(reference: string): Promise<IDocumentSpecial | null > {
    const apiUrl = `${getUrl('special_document_api/get_document_by_reference')}/${reference}`
    const data: IDocumentSpecial = await this.fetchJSON(apiUrl)
    if (data.id != 0){
      return data
    }
    return null
  }
}
