import { DocumentSpecial } from './DocumentSpecial'
export class Board{
  private _documentSpecial: DocumentSpecial
  private _loadedElemenTypeModal: boolean = false

  constructor(documentSpecial: DocumentSpecial){
    this._documentSpecial = documentSpecial
  }

  get loadedElementTypeModal(): boolean{
    return this._loadedElemenTypeModal
  }

  set loadedElementTypeModal(value: boolean){
    this._loadedElemenTypeModal = value
  }
}