import React, { useState, useEffect, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from './ViewModeProps';
import { FindingsEvaluationElement } from '../../models/special-document/FindingsEvaluationElement';
import ViewModeBase from './ViewModeBase';
import ModalWithButtons from '../modals/AceptCancelModalBase';
import { Permissions } from '../../models/special-document/ElementBase';
import Summernote from '../utils/Summernote';
import { ElementRepository } from '../../repository/special-document/ElementRepository';
import { FindingsEvaluationArea, FindingsEvaluationEdit } from '../utils/FindingsEvaluationAreaEdit';


const FindingsEvaluationViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<FindingsEvaluationElement>(iElement as FindingsEvaluationElement).current
  const [isEditable, setIsEditable] = useState(element.editable)
  const [editionMode, setEditionMode] = useState(false)

  const [highEvaluationText, setHighEvaluationText] = useState(element.high_evaluation_text)
  const [mediumEvaluationText, setMediumEvaluationText] = useState(element.medium_evaluation_text)
  const [lowEvaluationText, setLowEvaluationText] = useState(element.low_evaluation_text)
  const [optionalEvaluationText, setOptionalEvaluationText] = useState(element.optional_evaluation_text)

  const [tempHighEvaluationText, setTempHighEvaluationText] = useState(element.high_evaluation_text)
  const [tempMediumEvaluationText, setTempMediumEvaluationText] = useState(element.medium_evaluation_text)
  const [tempLowEvaluationText, setTempLowEvaluationText] = useState(element.low_evaluation_text)
  const [tempOptionalEvaluationText, setTempOptionalEvaluationText] = useState(element.optional_evaluation_text)

  useEffect(() => {
    if(element.state === "closed"){
      setIsEditable(false)
    }
  },[]);

  const handleEdit = () => {
    setEditionMode(true);
  };

  const handleConfigurationsModalAccept = async () => { 
    const elementRepository = new ElementRepository()
    element.high_evaluation_text = tempHighEvaluationText
    element.medium_evaluation_text = tempMediumEvaluationText
    element.low_evaluation_text = tempLowEvaluationText
    element.optional_evaluation_text = tempOptionalEvaluationText
    const lastEditable = element.editable
    element.editable = isEditable
    let success = await  elementRepository.saveElement("view", element.args)
    if (success) {
      setHighEvaluationText(tempHighEvaluationText)
      setMediumEvaluationText(tempMediumEvaluationText)
      setLowEvaluationText(tempLowEvaluationText)
      setOptionalEvaluationText(tempOptionalEvaluationText)
      setEditionMode(false);   
    } else {
      element.high_evaluation_text = highEvaluationText
      element.medium_evaluation_text = mediumEvaluationText
      element.low_evaluation_text = lowEvaluationText
      element.optional_evaluation_text = optionalEvaluationText
      element.editable = lastEditable
      window.htmlHelpers?.swalError()
    }
  };

  const handleConfigurationsModalCancel = () => {
    setEditionMode(false)
    setTempHighEvaluationText(highEvaluationText)
    setTempMediumEvaluationText(mediumEvaluationText)
    setTempLowEvaluationText(lowEvaluationText)
    setTempOptionalEvaluationText(optionalEvaluationText)
  };

  const handleHighEditorChange = (content: string) => {
    setTempHighEvaluationText(content);
  }

  const handleMediumEditorChange = (content: string) => {
    setTempMediumEvaluationText(content);
  }

  const handleLowEditorChange = (content: string) => {
    setTempLowEvaluationText(content);
  }

  const handleOptionalEditorChange = (content: string) => {
    setTempOptionalEvaluationText(content);
  }

  return(
    <>
      <ViewModeBase
          isEditable={isEditable}
          handleEdit={handleEdit}
          permissions={element.permissions as Permissions}> 
            <FindingsEvaluationArea
                highEvaluationText={highEvaluationText}
                mediumEvaluationText={mediumEvaluationText}
                lowEvaluationText={lowEvaluationText}
                optionalEvaluationText={optionalEvaluationText}
                mode='view'
              />
      </ViewModeBase>
      <ModalWithButtons
        showModal={editionMode}
        title={T("Paragraph settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
          <FindingsEvaluationEdit
            highEvaluationText={tempHighEvaluationText}
            mediumEvaluationText={tempMediumEvaluationText}
            lowEvaluationText={tempLowEvaluationText}
            optionalEvaluationText={tempOptionalEvaluationText}
            handleHighEditorChange={handleHighEditorChange}
            handleMediumEditorChange={handleMediumEditorChange}
            handleLowEditorChange={handleLowEditorChange}
            handleOptionalEditorChange={handleOptionalEditorChange}
            editionMode={editionMode}
          />
      </ModalWithButtons>
    </>
  )
};

export default FindingsEvaluationViewMode;