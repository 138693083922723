/**
 * This class represents a level of the breadcrumb.
 */
export class BreadcrumbLevel {
  private readonly _label: string
  private readonly _url: string
  private _reactContainerId: string | null = null
  private _icon: string | null = null
  private _index: number = 0

  /**
   * The constructor.
   * @param label - The label.
   * @param url - The url.
   */
  constructor (label: string, url: string, reactContainerId: string | null = null) {
    this._label = label
    this._url = url
    this._reactContainerId = reactContainerId
  }

  /**
   * The label.
   * @returns The label.
   */
  get label (): string {
    return this._label
  }

  /**
   * The url.
   * @returns The url.
   */
  get url (): string {
    return this._url
  }

  /**
   * The react container id.
   * @returns The react container id.
   */
  get reactContainerId (): string | null {
    return this._reactContainerId
  }

  set reactContainerId (reactContainerId: string | null) {
    this._reactContainerId = reactContainerId
  } 

  /**
   * The index.
   * @returns The index.
   */
  get index (): number {
    return this._index
  }

  /**
   * The index.
   * @param index - The index.
   */
  set index (index: number) {
    this._index = index
  }

  /**
   * The icon.
   * @returns The icon.
   */
  get icon (): string | null {
    return this._icon
  }

  /**
   * The icon.
   * @param icon - The icon.
   */
  set icon (icon: string) {
    this._icon = icon
  }


}
