import { ElementArgs } from './ElementArgs'
import { ElementBase, IElementBase} from './ElementBase'



export class CustomerRiskClassificationElement extends ElementBase implements IElementBase{
  private _responseForNormal: string
  private _responseForGreaterThanNormal: string
  private _responseForMuchGreaterThanNormal: string
  private _includeApprovalForAssignment: boolean
  private _responseIfNotApproved: string

  constructor(args:ElementArgs){
    super(args)
    this._responseForNormal = args.responseForNormal ?? ""
    this._responseForGreaterThanNormal = args.responseForGreaterThanNormal ?? ""
    this._responseForMuchGreaterThanNormal = args.responseForMuchGreaterThanNormal ?? ""
    this._includeApprovalForAssignment = args.includeApprovalForAssignment ?? false
    this._responseIfNotApproved = args.responseIfNotApproved ?? ""
  }

  get responseForNormal(): string {
    return this._responseForNormal
  }

  get responseForGreaterThanNormal(): string {
    return this._responseForGreaterThanNormal
  }

  get responseForMuchGreaterThanNormal(): string {
    return this._responseForMuchGreaterThanNormal
  }

  get includeApprovalForAssignment(): boolean {
    return this._includeApprovalForAssignment
  }

  get responseIfNotApproved(): string {
    return this._responseIfNotApproved
  }

  set responseForNormal(responseForNormal: string) {
    this._responseForNormal = responseForNormal
  }

  set responseForGreaterThanNormal(responseForGreaterThanNormal: string) {  
    this._responseForGreaterThanNormal = responseForGreaterThanNormal
  }

  set responseForMuchGreaterThanNormal(responseForMuchGreaterThanNormal: string) {
    this._responseForMuchGreaterThanNormal = responseForMuchGreaterThanNormal
  }

  set includeApprovalForAssignment(includeApprovalForAssignment: boolean) {
    this._includeApprovalForAssignment = includeApprovalForAssignment
  }

  set responseIfNotApproved(responseIfNotApproved: string) {
    this._responseIfNotApproved = responseIfNotApproved
  }


  render(): void {
    console.log("render customer risk classification element")
  }
}