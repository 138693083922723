import React from 'react';
import Swal, { type SweetAlertIcon } from 'sweetalert2';
import { T } from "../../utils/i18n-config";
import { NotificationType } from './NotificationType';
import NotificationIcon from './NotificationIcon';

interface BaseNotificationProps {
  id: number;
  title: string;
  description: string;
  stateType: NotificationType;
  createdAt: string;
  onMarkAsRead: (notificationId: number) => void;
}

const BaseNotification = ({ id, title, description, stateType, createdAt, onMarkAsRead }: BaseNotificationProps) => {
  
  /**
   * Opens a notification dialog with the notification details.
   */
  const openNotification = () => {
    Swal.fire({
      title: T(title),
      html: description,
      icon: stateType as SweetAlertIcon,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: T('Mark as read'),
      cancelButtonText: T('Cancel')
    }).then((result) => {
      if (result.isConfirmed) markAsRead();
    });
  }

  /**
   * Marks the notification as read.
   */
  const markAsRead = () => {
    onMarkAsRead(id)
  }

  return (
    <li className="mb-2">
      <button
        type="button"
        className="dropdown-item d-flex gap-3 align-items-center justify-content-between border-radius-md"
        style={{ marginBottom: 0, textTransform: "none", boxShadow: "none" }}
      >
        <div className="d-flex gap-3 py-1" onClick={openNotification}>
          <NotificationIcon type={stateType} />
          <div className="d-flex flex-column justify-content-center">
            <h6 className="text-sm font-weight-normal mb-1">
              <span className="font-weight-bold">{T(title)}</span>
            </h6>
            <p className="text-xs text-secondary mb-0">
              <i className="fa fa-clock me-1"></i>
              <span>{createdAt}</span>
            </p>
          </div>
        </div>
        <button
          type="button"
          title={T('Mark as read')}
          className="btn notification-icon btn-icon-only btn-outline-white py-1 px-2 border-2 text-secondary"
          style={{ marginBottom: 0 }}
          onClick={markAsRead}
        >
          <i className="fa-solid fa-check fs-6"></i>
        </button>
      </button>
    </li>
  );
}

export default BaseNotification;