import React, { useEffect, useRef, useState } from 'react';
import { T } from "../../utils/i18n-config"
import Summernote from '../utils/Summernote';
import { EngagementVariableFactory } from '../../factory/EngagementVariableFactory';
import { VariableOptionsData } from '../../repository/EngagementVariableRepository';
import { init } from 'i18next';


interface ConditionalParagraphWithVariableOptionProps {
  key: string;
  index: number;
  variable: string;
  condition: string;
  value: string;
  message: string;
  isEditable: boolean;
  showModal: boolean;
  handleEditValue: (index: number, value: string) => void;
  handleEditMessage: (index: number, message: string) => void;
  handleSetIsEditable: (index: number, isEditable: boolean) => void;
  handleEditCondition: (index: number, condition: string) => void;
  initFunction: (index: number, initialVariableOptionParams: {condition: string, value: string}) => void;
}


const ConditionalParagraphWithVariableCondition = ({index, variable, condition, value, message, isEditable, showModal, handleEditValue, handleEditMessage, handleSetIsEditable, handleEditCondition, initFunction }: ConditionalParagraphWithVariableOptionProps) => {
  const [conditionOptions, setConditionOptions] = useState<string[]>([])
  const [variableOptions, setVariableOptions] = useState<VariableOptionsData[]>([])
  const [conditionOptionSelected, setConditionOptionSelected] = useState<string>(condition)
  const [variableOptionSelected, setVariableOptionSelected] = useState<string>(value)

  useEffect(() => {
    const fetchVariables = async () => {
      const engagementVariableFactory = EngagementVariableFactory.getInstance()
      const variableSettings = await engagementVariableFactory.getByName(variable);
      const conditionOptionsData: string[] = [];
      const initialVariableOptionParams = {condition: "", value: ""};
      if( variableSettings?.type === "Text" ) {
        conditionOptionsData.push(T("Equals"))
        conditionOptionsData.push(T("Contains"))
        conditionOptionsData.push(T("Not Contains"))
        conditionOptionsData.push(T("Starts With"))
        conditionOptionsData.push(T("Ends With"))
        initialVariableOptionParams.condition = T("Equals")
      } else if( variableSettings?.type === "Integer" || variableSettings?.type === "Decimal" ) {
        conditionOptionsData.push("=")
        conditionOptionsData.push("!=")
        conditionOptionsData.push(">")
        conditionOptionsData.push("<")
        conditionOptionsData.push(">=")
        conditionOptionsData.push("<=")    
        initialVariableOptionParams.condition = "="    
      }
      
      const variableOptionsData: VariableOptionsData[] = [];
      variableSettings?.options.forEach((option) => {
        variableOptionsData.push(option);
      });
      setConditionOptions(conditionOptionsData);
      setVariableOptions(variableOptionsData);
      if(variableOptionsData.length > 0){
        initialVariableOptionParams.value = variableOptionsData[0].name
      }
      if(condition === "" && value === "" && message === ""){
        initFunction(index, initialVariableOptionParams)
      }
    };
    fetchVariables();
  }, []);

  const handleEditorChange = (content: string) => {
    handleEditMessage(index, content);
  };

  const handleSetLocalIsEditable = () => {
    handleSetIsEditable(index, !isEditable);
  }

  const handleSelectVariableOption = (variableOption: string) => {
    setVariableOptionSelected(variableOption)
    handleEditValue(index, variableOption)
  }

  const handleSelectConditionOption = (conditionOption: string) => {
    setConditionOptionSelected(conditionOption)
    handleEditCondition(index, conditionOption)
  }
  
  return (
    <div>
      <div className="form-group">
        <h6 className="mb-0">{T("Condition")}</h6>
        <div className="row">
          <div className="col-5">
            <input type="text" className="form-control" placeholder={T('Enter variable')} value={variable} readOnly />
          </div>  
          <div className="col-2">
            <select className="form-control" value={conditionOptionSelected} onChange={(e) => handleSelectConditionOption(e.target.value)}>
              {conditionOptions.map((option) => {
                return (
                  <option key={option} value={option}>{option}</option>
                )
              })}
            </select>
          </div>
          <div className="col-5">
            {variableOptions.length === 0 &&
            <input type="text" className="form-control" placeholder={T('Enter option')} value={value} onChange={(e) => handleEditValue(index, e.target.value)} />
            }
            {variableOptions.length > 0 &&
            <select className="form-control" value={variableOptionSelected} onChange={(e) => handleSelectVariableOption(e.target.value)}>
              {variableOptions.map((option) => {
                return (
                  <option key={option.id} value={option.name}>{option.name}</option>
                )
              })}
            </select>
            }
          </div>
        </div>
      </div>
      <div className="form-group">
        <label>{T("Message")}</label>
        <Summernote value={message} onChange={handleEditorChange} showModal={showModal} />
      </div>
      <div className="form-check">
          <input className="form-check-input" type="checkbox" checked={isEditable} onChange={() => handleSetLocalIsEditable()} defaultChecked={isEditable} />
          <label className="custom-control-label">{T("Is Editable")}</label>
      </div>
      <hr className="horizontal dark my-3"></hr>
    </div>
  );
};

export default ConditionalParagraphWithVariableCondition
