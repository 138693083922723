import React, { type FormEvent, useEffect, useRef } from 'react';
import type { Message } from '../../types/AttachmentsChatType';
import { markdownToHtml } from '../../utils/markdown';

/**
 * ChatContainer component to display messages of a specific chat.
 * @param messages - Array of message objects.
 * @param messagesLoading - Loading state of messages.
 * @param onSubmit - Function to handle form submission.
 */
const ChatContainer = ({ messages, messagesLoading, onSubmit }: {
  messages: Message[],
  messagesLoading: boolean,
  onSubmit: (event: FormEvent<HTMLFormElement>) => void,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (messagesLoading) {
      inputRef.current?.setAttribute('disabled', 'true');
      buttonRef.current?.setAttribute('disabled', 'true');
    }
    else {
      inputRef.current?.removeAttribute('disabled');
      buttonRef.current?.removeAttribute('disabled');
    }
  }, [messagesLoading]);

  useEffect(() => {
    if (messages.length === 0) {
      formRef.current?.reset();
      inputRef.current?.focus();
    }
  }, [messages]);

  return (
    <div className="d-flex flex-column gap-4 p-4 w-100 bg-white" style={{
      borderRadius: '0 1rem 1rem 0',
    }}>
      <div className="flex-grow-1 d-flex flex-column gap-4 mh-100 overflow-auto">
        {
          messages.map((message) => (
            <div key={message.id} className={`d-flex gap-2 ${message.role === 'user' ? 'justify-content-end' : 'justify-content-start'}`}>
              {
                message.role === 'system' && (
                  <i
                    aria-hidden="true"
                    className="fa-solid fa-file-lines border border-secondary border-2 rounded-circle"
                    style={{
                      padding: '0.5rem 0.6rem',
                      height: 'fit-content',
                    }}
                  ></i>
                )
              }
              <pre className="py-2 px-3 rounded-2 fs-6 text-dark text-wrap"
                style={{
                  maxWidth: '60%',
                  backgroundColor: message.role === 'user' ? '#ddda' : '#ddd3',
                  fontFamily: 'inherit',
                }} dangerouslySetInnerHTML={{__html: markdownToHtml(message.content)}}>
              </pre>
            </div>
          ))
        }
      </div>
      <form ref={formRef} className="d-flex gap-2 w-100" onSubmit={onSubmit}>
        <input ref={inputRef} autoFocus type="text" name="user-message" className="form-control flex-grow-1" />
        <button ref={buttonRef} className="btn bg-gradient-primary mb-0 fs-6">
          <i className="fa-solid fa-paper-plane"></i>
        </button>
      </form>
    </div>
  );
}

export default ChatContainer;