import { ElementArgs, FindingsOptions} from "./ElementArgs";
import { IElementBase, ElementBase } from "./ElementBase";

export class FindingsElement extends ElementBase implements IElementBase {

  _findings: FindingsOptions[] | FindingsOptions;
  
  constructor(args: ElementArgs) {
    super(args);
    this._findings = args.options as FindingsOptions[] | FindingsOptions;
  }

  get findings(): FindingsOptions[] | FindingsOptions {
    return this._findings;
  }

  set findings(findings: FindingsOptions[]|FindingsOptions) {
    this._findings = findings
    this.args.options = findings
  }

  render(): void {
    return;
  }
}