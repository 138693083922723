import { getUrl } from '../utils/app-config'
import { fetchAPI } from '../services/api_services/fetchAPI'
export interface IMenuItems {
  name: string
  label: string
  icon: string
  url: string
  target: string
  submenu: IMenuItems[]
  visible: boolean
}

export interface IMenu {
  items: IMenuItems[]
}

/**
 * The menu repository. This class is responsible for fetching the menu data from the API.
 */
export class MenuRepository {
  private readonly apiUrl: string = getUrl('menu_api/get')

  /**
   * The constructor.
   * @param fetcher - The fetcher.
   */
  constructor (private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) {}

  /**
   * Get the menu data.
   * @returns The menu data.
   */
  async getMenuData (): Promise<IMenu> {
    try {
      const response = await this.fetcher(this.apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data: IMenu = await response.json()

      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return {
        items: []
      }
    }
  }
}
