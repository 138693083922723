import React, { useState, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { Constants } from '../../utils/Constants'
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from '../../repository/special-document/ElementRepository'
import { InformationExtractorElement } from '../../models/special-document/InformationExtractorElement'
import type { InformationExtractorArgs } from '../../models/special-document/ElementArgs'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import Dropzone from "../utils/Dropzone"

const InformationExtractorViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<InformationExtractorElement>(iElement as InformationExtractorElement).current
  const [attachmentName, setAttachmentName] = useState<string>(element.attachmentName)

  async function handleConfigurationsModalAccept() {
    const elementRepository = new ElementRepository()
    const lastAttachmentName = element.attachmentName
    element.attachmentName = attachmentName

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.attachmentName = lastAttachmentName
      setAttachmentName(lastAttachmentName)
      window.htmlHelpers?.swalError()
    }
  }

  return (
    <>
     <Dropzone
        elementId={element.id}
        attachmentName={attachmentName}
        onUpload={value => setAttachmentName(value)}
      /> 
    </>
  );
}

export default InformationExtractorViewMode;