import { ElementArgs } from './ElementArgs'
import { ElementBase, IElementBase} from './ElementBase'
import { VarianceAnalysisNotes} from './ElementArgs'

/**
 * Class to represent a variance analysis notes element.
 * @class VarianceAnalysisNotesElement
 */


export class VarianceAnalysisNotesElement extends ElementBase implements IElementBase{
  private _ledger_account_id: number
  private _ledger_account_code: string
  private _ledger_account_name: string
  private _amount_value: number
  private _amount_note: string
  private _percentage_value: number
  private _percentage_note: string
  private _is_alert_warning: boolean = false
  

  /**
   * Creates a new variance analysis notes element.
   * @param {ElementArgs} args - The element arguments.
   */
  constructor(args: ElementArgs){
    super(args)
    const options = args.options? args.options as VarianceAnalysisNotes : {ledger_account_id: 0, ledger_account_code: "", ledger_account_name: ""}
    this._ledger_account_id = options.ledger_account_id
    this._ledger_account_code = options.ledger_account_code
    this._ledger_account_name = options.ledger_account_name
    this._amount_value = options.amount_value ?? 0
    this._amount_note = options.amount_note ?? ""
    this._percentage_value = options.percentage_value ?? 0
    this._percentage_note = options.percentage_note ?? ""
    this._is_alert_warning = args.is_alert_warning ?? false
  }

  get ledger_account_id(): number {
    return this._ledger_account_id
  }
  set ledger_account_id(value: number) {
    this._ledger_account_id = value
  }
  get ledger_account_code(): string {
    return this._ledger_account_code
  }
  set ledger_account_code(value: string) {
    this._ledger_account_code = value
  }
  get ledger_account_name(): string {
    return this._ledger_account_name
  }
  set ledger_account_name(value: string) {
    this._ledger_account_name = value
  }
  get amount_value(): number {
    return this._amount_value
  }
  set amount_value(value: number) {
    this._amount_value = value
  }
  get amount_note(): string {
    return this._amount_note
  }
  set amount_note(value: string | undefined) {
    this._amount_note = value ?? ""
  }
  get percentage_value(): number {
    return this._percentage_value
  }
  set percentage_value(value: number) {
    this._percentage_value = value
  }
  get percentage_note(): string {
    return this._percentage_note
  }
  set percentage_note(value: string | undefined) {
    this._percentage_note = value ?? ""
  }
  get is_alert_warning(): boolean {
    return this._is_alert_warning
  }
  set is_alert_warning(value: boolean) {
    this._is_alert_warning = value
  }

  render(): void {
    return;
  }
}