import React, { type FormEvent, useState, useEffect } from 'react';
import { AttachmentsChatRepository } from '../../repository/AttachmentsChatRepository';
import type { Chat, Message } from '../../types/AttachmentsChatType';
import ChatSidebar from './ChatSidebar';
import ChatContainer from './ChatContainer';

interface AttachmentsChatRendererProps {
  chatId: number | null;
  engagementId: number;
  attachmentsIds: number[];
}

function useChats({ engagementId, attachmentsIds }: { engagementId: number, attachmentsIds: number[] }) {
  const [chats, setChats] = useState<Chat[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const repository = new AttachmentsChatRepository();
    async function fetchChats() {
      setLoading(true);
      const response = await repository.getChats({
        engagement_id: engagementId,
        attachment_ids: attachmentsIds
      });
      setChats(response.chats);
      setLoading(false);
    };

    fetchChats();
  }, [engagementId, attachmentsIds]);

  return { chats, setChats, loading };
}

function useMessages(chatId: number | null) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const repository = new AttachmentsChatRepository();
    async function fetchMessages(chatId: number) {
      setLoading(true);
      const response = await repository.getMessages(chatId);
      setMessages((prevMessages) => [...prevMessages, ...response.messages]);
      setLoading(false);
    };

    if(chatId) fetchMessages(chatId);
  }, [chatId]);

  return { messages, setMessages, loading };
}

const AttachmentsChat = ({ chatId, engagementId, attachmentsIds }: AttachmentsChatRendererProps) => {
  const [currentChatId, setCurrentChatId] = useState<number | null>(chatId);
  const [userMessage, setUserMessage] = useState<string>('');
  const { chats, setChats, loading: chatsLoading } = useChats({ engagementId, attachmentsIds });
  const { messages, setMessages, loading: messagesLoading } = useMessages(currentChatId);

  /**
   * Send a user message to the chat.
   * @param message The message to send.
   */
  async function sendUserMessage(message: string) {
    const repository = new AttachmentsChatRepository();
    const response = await repository.sendUserMessage({
      chat_id: currentChatId as number,
      engagement_id: engagementId,
      attachment_ids: attachmentsIds,
      message: message,
    });

    if (response.success) {
      const { message } = response;
      setMessages((prevMessages) => [...prevMessages, {
        id: message.id,
        role: message.role,
        content: message.content
      }]);
    }
  };

  /**
   * Create a new chat for the user.
   * @param userMessage The message to send.
   */
  async function createChat(userMessage: string) {
    const repository = new AttachmentsChatRepository();
    const response = await repository.setNewChat({
      engagement_id: engagementId,
      attachment_ids: attachmentsIds,
      message: userMessage,
    });

    if (response.success) {
      setCurrentChatId(response.chat.id);
      setChats((prevChats) => [...prevChats, response.chat])
    };
  }

  useEffect(() => {
    if (currentChatId && userMessage) sendUserMessage(userMessage);
  }, [currentChatId, userMessage]);

  /**
   * Handle the submit event of the form.
   * @param event The form event
   */
  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    
    const form = event.currentTarget;
    const formData = new FormData(form);
    const message = formData.get("user-message") as string;
    form.reset();
    
    if(currentChatId == null) await createChat(message);
    setMessages((prevMessages) => [...prevMessages, {
      id: Math.random(),
      role: 'user',
      content: message
    }]);
    setUserMessage(message);
  }

  /**
   * Handle the creation of a chat.
   */
  function handleCreateChat() {
    setMessages([]);
    setUserMessage('');
    setCurrentChatId(null);
  }

  /**
   * Handle the selection of a chat.
   * @param chatId The chat id to select.
   */
  function handleSelectChat(chatId: number) {
    setMessages([]);
    setUserMessage('');
    setCurrentChatId(chatId);
  }

  /**
   * Handle the deletion of a chat.
   * @param chatId The chat id to delete.
   */
  async function handleDeleteChat(chatId: number) {
    const repository = new AttachmentsChatRepository();
    const response = await repository.deleteChat(chatId);
    if (response.success) {
      setChats((prevChats) => prevChats.filter((chat) => chat.id !== chatId));
      if (chatId === currentChatId) {
        setMessages([]);
        setCurrentChatId(null);
      }
    }
  }

  return (
    <>
      <ChatSidebar
        chats={chats}
        chatsLoading={chatsLoading}
        onCreateChat={handleCreateChat}
        onSelectChat={handleSelectChat}
        onDeleteChat={handleDeleteChat}
      />
      <ChatContainer
        messages={messages}
        messagesLoading={messagesLoading}
        onSubmit={handleSubmit}
      />
    </>
  );
}

export default AttachmentsChat;