
export const Constants = {

  // Other constants
  CTR_POP_UP: 'ctr-pop-up',
  NOTIFICATION_FETCH_INTERVAL: 1000 * 60,
  CONTENT_CLASS: 'document-content',
  //--------------------------------------------

  // Paragraph
  PARAGRAPH_ELEMENT_TYPE : 1,
  //--------------------------------------------

  // Findings
  FINDING_ELEMENT_TYPE : 15,
  FINDING_EVALUATION_ELEMENT_TYPE : 16,
  FINDING_REFERENCE: 'finding_template',
  //--------------------------------------------

  // Risks and Variance Analysis
  VARIANCE_ANALYSIS_NOTES_TYPE: 12,
  RISK_REFERENCE: 'risk',
  //--------------------------------------------

  // Probability Analysis
  PROBABILITY_ANALYSIS_TYPE: 18,
  //--------------------------------------------

  // Dropzone File Images
  MAX_FILES: 1,
  FILE_IMAGE_PATH: '/audix/static/images/thumbnail',
  FILE_IMAGES: {
    'application/vnd.ms-excel': 'excel-120x120.png',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel-120x120.png',
    'application/msword': 'word-120x120.png',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word-120x120.png',
  },
  FILE_EXTENSIONS: {
    "doc": "word-120x120.png",
    "docx": "word-120x120.png",
    "xls": "excel-120x120.png",
    "xlsx": "excel-120x120.png",
  },
  //--------------------------------------------

  // Redirection Button
  REDIRECTION_FORMULARIES: [
    {
      text: "Memorando desestimación de ingresos",
      value: "income_disallowance_memo"
    },
    {
      text: "Hipótesis de negocio en marcha",
      value: "going_concern_memorandum"
    },
    {
      text: "Leyes y Regulaciones",
      value: "laws_and_regulations"
    }
  ],
  //--------------------------------------------  

  ITEM_TYPE_OPTIONS: ["Title", "Subtitle", "Question"] as const,
  ANSWER_TYPE_OPTIONS: ["Textbox", "Attachment", "Yes/No", "Unique", "External"] as const,
  ANSWER_REFERENCE_OPTIONS: ["OFAC", "Budgeted_Hours", "Independence_Format"] as const,
}
