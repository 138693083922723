import { ElementBase, IElementBase } from "./ElementBase"
import { ElementArgs } from "./ElementArgs"

export class IndependenceFormatElement extends ElementBase implements IElementBase {

  constructor(args: ElementArgs) {
    super(args)
  }

  render(): void {
    console.log("rendering IndependenceFormatElement")
  }
}