export function markdownToHtml(markdown: string): string {
  return markdown
    .replace(/(?:\r\n|\r|\n)/g, '<br>')
    .replace(/(?:\*\*|__)(.*?)(?:\*\*|__)/g, '<strong>$1</strong>')
    .replace(/(?:[\*_])(.*?)(?:[\*_])/g, '<em>$1</em>')
    .replace(/(?:~~)(.*?)(?:~~)/g, '<del>$1</del>')
    .replace(/(?:```)(.*?)(?:```)/g, '<code>$1</code>')
    .replace(/(?:`)(.*?)(?:`)/g, '<code>$1</code>')
    .replace(/(?:\[(.*?)\]\((.*?)\))/g, '<a href="$2">$1</a>')
}
