import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'

export interface BasicResponse {
  success: boolean
  error_message?: string
}


export interface IndependenceFormatStatsType  {
  progress: number
  approve_formats: number
  reject_formats: number
  missing_formats: number
}

export interface IndependenceFormatFormsType {
  user_id: number
  user_name: string
  state: boolean | null
  email: string
  role: string
  role_description: string
  documents: {
    document_id: number
    document_description: string
    document_reference: string
    document_state: boolean | null
  }[]
}


export class IndependenceFormatRepository {

  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  async getStatsInfo(engagement_id: number|null): Promise<IndependenceFormatStatsType|BasicResponse> {
    const apiUrl = getUrl('independence_format_element_api/get_stats_info/'+engagement_id)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: IndependenceFormatStatsType | BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async getFormsInfo(engagement_id: number|null): Promise<IndependenceFormatFormsType[]|BasicResponse> {
    const apiUrl = getUrl('independence_format_element_api/get_forms_info/'+engagement_id)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: IndependenceFormatFormsType[] | BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
   
}