import React from 'react';
import ReactDOM from 'react-dom';
import AttachmentsChat from '../components/attachments-chat/AttachmentsChat';

interface AttachmentsChatArgs {
  containerId: string;
  engagementId: number;
  attachmentsIds: number[];
  chatId: number | null;
}

/**
 * Renders the AttachmentsChat component
 * @param containerId - The id of the container element
 * @param engagementId - The id of the engagement
 * @param attachmentsIds - The ids of the attachments
 * @param chatId - The id of the chat
 */
export function AttachmentsChatRenderer(args: AttachmentsChatArgs) {
  ReactDOM.render(
    <AttachmentsChat chatId={args.chatId} engagementId={args.engagementId} attachmentsIds={args.attachmentsIds} />,
    document.getElementById(args.containerId)
  );
}