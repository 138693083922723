import React, { useEffect, useState, useRef} from 'react';
import { T } from "../../utils/i18n-config"
import { FindingsRepository } from '../../repository/special-document/FindingsRepository';
import { FindingsElement } from '../../models/special-document/FindingsElement';
import { FindingsConfig } from '../utils/FindingsConfig';
import ViewModeProps from './ViewModeProps';
import { FindingsOptions } from '../../models/special-document/ElementArgs';
import { FindingArea } from '../utils/FindingArea';
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs';

const FindingsContainerViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<FindingsElement>(iElement as FindingsElement).current
  const [elementFindings, setElementFindings] = useState<FindingsOptions[]>([])
  const [currentFindingId, setCurrentFindingId] = useState(0)
  const [currentUser, setCurrentUser] = useState('')
  const [showConfigurationsModal, setShowConfigurationsModal] = useState<boolean|null>(null)
  const [refresh, setRefresh] = useState(false)
  const [evaluationTypes, setEvaluationTypes] = useState<FindingsEvaluationOptions[]>([])
  
  useEffect(() => {
    const findingData = () => {
      const findingsRepository = new FindingsRepository()
      if (element.args.engagement_id === undefined) {
        window.htmlHelpers?.swalError()
        return
      }
      findingsRepository.getFindingElements(element.engagement_id, element.args.document_id, element.id).then((response) => {
        if (response) {
          if("success" in response) {
            if (response.error_message) window.htmlHelpers?.customSwalError(T(response.error_message))
            else window.htmlHelpers?.swalError()
            return
          }
          const zeroRegister = response.find((finding) => finding.id === 0)
          if (zeroRegister !== undefined) {
            element.findings = []
            setElementFindings([])
          }
          else{
            element.findings = response
            setCurrentUser(response[0].created_by)
            setElementFindings(response)
          }
        } 
        else {
          window.htmlHelpers?.swalError()
        }
      })
    }
    findingData()
  }, [iElement, refresh])

  const handleAddFinding = async() => {
    const findingsRepository = new FindingsRepository()
    if (element.args.engagement_id === undefined) {
      window.htmlHelpers?.swalError()
      return
    }
    const response = await findingsRepository.createFinding(element.args.engagement_id, element.args.document_id, element.id, element.reference)
    if (response.basic_response.success) {
      setCurrentFindingId(response.basic_response.finding_id as number)
      setEvaluationTypes(response.evaluation_types)
      setShowConfigurationsModal(true)
    } 
    else {
      if (response.basic_response.error_message) window.htmlHelpers?.customSwalError(T(response.basic_response.error_message))
      else window.htmlHelpers?.swalError()
    } 
  }

  const handleAcceptInDirectMode = () => {
    setShowConfigurationsModal(false)
    setRefresh(!refresh)
  }
  
  const handleCancelOrDelete = (registerId: number) => {
    const findingsRepository = new FindingsRepository()
    findingsRepository.deleteFinding(
      element.args.engagement_id ?? null, 
      element.args.document_id,
      registerId
    ).then((response) => {
        if (response.success) {
          setRefresh(!refresh)
        } 
        else {
          if (response.error_message) window.htmlHelpers?.customSwalError(T(response.error_message))
          else window.htmlHelpers?.swalError()
        }
        setShowConfigurationsModal(false)
      }
    )
  }

  return (
    <div className='d-flex w-full flex-column'>
      {
        element.args.state === "open" && 
        <div className='d-flex justify-content-end'>
          <button className='btn btn-primary' onClick={handleAddFinding}>{T("Add Finding")}</button>
        </div>
      }
      { 
        elementFindings.length > 0 && elementFindings.map((finding) => (
          <div key={'finding'+finding.id}>
            <div>
              <FindingArea
              engagementId={element.args.engagement_id !== undefined ? element.args.engagement_id : 0}
              documentId={element.args.document_id}
              permissions={element.args.permissions}
              title={T("Finding Configurations")}
              reference={element.reference}
              size="xl"
              directMode={false}
              handleCancelOrDelete={handleCancelOrDelete}
              initialData={{
                id: finding.id,
                finding_state: finding.finding_state,
                evaluation_types: finding.evaluation_types,
                finding_evalution_type_id: finding.finding_evalution_type_id,
                created_by: finding.created_by,
                finding_title: finding.finding_title,
                description: finding.description,
                recomendations: finding.recomendations,
                answer_field: finding.answer_field,
                origins_and_causes: finding.origins_and_causes,
                admin_action_plan: finding.admin_action_plan,
                implementation_date: finding.implementation_date
              }}/>
            </div>
          </div>
        ))
      }
    {
      showConfigurationsModal &&
      <FindingsConfig 
        findingBase={{
          engagementId: element.args.engagement_id !== undefined ? element.args.engagement_id : 0,
          documentId: element.args.document_id,
          permissions: element.args.permissions,
          title: T("Finding Configurations"),
          reference: element.reference,
          size: "xl",
          directMode: true,
          handleAcceptInDirectMode: handleAcceptInDirectMode,
          handleCancelOrDelete: handleCancelOrDelete,
          initialData: {
            id: currentFindingId,
            finding_state: false,
            evaluation_types: evaluationTypes,
            finding_evalution_type_id: 1,
            created_by: currentUser,
            finding_title: '',
            description: '',
            recomendations: '',
            answer_field: '',
            origins_and_causes: '',
            admin_action_plan: '',
            implementation_date: ''
          },
        }}
        
      />
    }
    </div>
  );
};

export default FindingsContainerViewMode;
