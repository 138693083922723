import React, { useState, CSSProperties } from 'react';

interface ExpandableImageProps {
  src: string
  alt: string
  pictureClassName?: string
}

const ExpandableImage = ({ src, alt, pictureClassName }: ExpandableImageProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  return (
    <>
      <picture className={pictureClassName?? ''}>
        <img
            style={thumbnailStyle}
            src={src}
            alt={alt}
            onClick={handleClick}
          />
      </picture>
      {isExpanded && (
        <div style={overlayStyle} onClick={handleClose}>
          <img
            style={expandedImgStyle}
            src={src}
            alt={alt}
            onClick={handleClick}
          />
          <span style={closeBtnStyle} onClick={handleClose}>&times;</span>
        </div>
      )}
    </>
  );
};

const thumbnailStyle: CSSProperties = {
  width: '100%',
  borderRadius: '20px',
  cursor: 'pointer',
  transition: 'transform 0.3s ease',
  objectFit: 'cover'
}

const overlayStyle: CSSProperties = {
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: 1000
};

const expandedImgStyle: CSSProperties = {
  maxWidth: '90%',
  maxHeight: '90%'
}

const closeBtnStyle: CSSProperties = {
  position: 'absolute',
  top: '20px',
  right: '30px',
  color: 'white',
  fontSize: '30px',
  fontWeight: 'bold',
  cursor: 'pointer'
}

export default ExpandableImage;
