
import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2';
import Summernote from '../utils/Summernote';
import ModalWithButtons from '../modals/AceptCancelModalBase';
import { T } from "../../utils/i18n-config"
import { FindingsRepository } from '../../repository/special-document/FindingsRepository';
import { FindingsOptions } from '../../models/special-document/ElementArgs';
import { Permissions } from '../../models/special-document/ElementBase';
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'

export interface StatesVariables {
  findingTitle: string
  setFindingTitle: React.Dispatch<React.SetStateAction<string>>
  description: string
  setDescription: React.Dispatch<React.SetStateAction<string>>
  recomendations: string
  setRecomendations: React.Dispatch<React.SetStateAction<string>>
  origingsAndCauses: string
  setOrigingsAndCauses: React.Dispatch<React.SetStateAction<string>>
  answer: string
  setAnswer: React.Dispatch<React.SetStateAction<string>>
  adminActionPlan: string
  setAdminActionPlan: React.Dispatch<React.SetStateAction<string>>
  implementationDate: string
  setImplementationDate: React.Dispatch<React.SetStateAction<string>>
  evaluation: number
  setEvaluation: React.Dispatch<React.SetStateAction<number>>
}

export interface FindingsBase {
  engagementId: number | null;
  documentId: number;
  permissions: Permissions | undefined;
  title: string;
  reference: string | undefined;
  size: "sm" | "lg" | "xl" | "fullscreen" | undefined;
  directMode: boolean;
  handleAcceptInDirectMode?: () => void;
  handleCancelOrDelete: (findingId: number) => void;
  initialData: FindingsOptions;
}

export interface FindingsConfigArgs {
  findingBase: FindingsBase
  stateVariables?: StatesVariables
}

export const FindingsConfig = ({ ...args }: FindingsConfigArgs) => {
  const [showModal, setShowModal] = useState<boolean>(args.findingBase.directMode)
  const findingId = useRef<number>(args.findingBase.initialData.id).current
  const findingState = useRef<boolean>(args.findingBase.initialData.finding_state ?? false).current
  const createdBy = useRef<string>(args.findingBase.initialData.created_by ?? '').current
  const evaluationOptions = useRef<FindingsEvaluationOptions[]>(args.findingBase.initialData.evaluation_types ?? []).current

  const [configFindingTitle, setConfigFindingTitle] = useState<string>(args.stateVariables?.findingTitle ?? '')
  const [configDescription, setConfigDescription] = useState<string>(args.stateVariables?.description ?? '')
  const [configRecomendations, setConfigRecomendations] = useState<string>(args.stateVariables?.recomendations ?? '')
  const [configOrigingsAndCauses, setConfigOrigingsAndCauses] = useState<string>(args.stateVariables?.origingsAndCauses ?? '')
  const [configAnswer, setConfigAnswer] = useState<string>(args.stateVariables?.answer ?? '')
  const [configAdminActionPlan, setConfigAdminActionPlan] = useState<string>(args.stateVariables?.adminActionPlan ?? '')
  const [configImplementationDate, setConfigImplementationDate] = useState<string>(args.stateVariables?.implementationDate ?? '')
  const [configEvaluation, setConfigEvaluation] = useState<number>(args.stateVariables?.evaluation ?? 1)

  const maxFindingTitleLength = useRef<number>(255).current
  const maxDescriptionLength = useRef<number>(10000).current
  const maxRecomendationsLength = useRef<number>(4000).current
  const maxOrigingsAndCausesLength = useRef<number>(4000).current
  const maxAdminActionPlanLength = useRef<number>(4000).current
  const maxImplementationDateLength = useRef<number>(2000).current


  const handleFindingTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfigFindingTitle(event.target.value)
  }

  const handleDescriptionChange = (text: string) => {
    setConfigDescription(text)
  }

  const handleRecomendationsChange = (text: string) => {
    setConfigRecomendations(text)
  }

  const handleAnswerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setConfigAnswer(event.target.value)
  }

  const handleOrigingsAndCausesChange = (text: string) => {
    setConfigOrigingsAndCauses(text)
  }

  const handleAdminActionPlanChange = (text: string) => {
    setConfigAdminActionPlan(text)
  }

  const handelEvaluationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setConfigEvaluation(parseInt(event.target.value))
  }


  const handleAcceptButton = async () => {
    const newData = {
      id: findingId,
      finding_state: findingState,
      finding_evalution_type_id: configEvaluation,
      created_by: createdBy,
      finding_title: configFindingTitle,
      description: configDescription,
      recomendations: configRecomendations,
      answer_field: configAnswer,
      origins_and_causes: configOrigingsAndCauses,
      admin_action_plan: configAdminActionPlan,
      implementation_date: configImplementationDate
    }

    const findingsRepository = new FindingsRepository()
    const response = await findingsRepository.updateFinding(
      args.findingBase.engagementId,
      args.findingBase.documentId,
      newData,
      args.findingBase.reference
    )

    if (response.success) {
      if (args.findingBase.directMode) {
        args.findingBase.handleAcceptInDirectMode?.()
      }
      args.stateVariables?.setFindingTitle(configFindingTitle)
      args.stateVariables?.setDescription(configDescription)
      args.stateVariables?.setRecomendations(configRecomendations)
      args.stateVariables?.setAnswer(configAnswer)
      args.stateVariables?.setOrigingsAndCauses(configOrigingsAndCauses)
      args.stateVariables?.setAdminActionPlan(configAdminActionPlan)
      args.stateVariables?.setImplementationDate(configImplementationDate)
      args.stateVariables?.setEvaluation(configEvaluation)
    }
    else {
      setConfigFindingTitle(args.stateVariables?.findingTitle ?? '')
      setConfigDescription(args.stateVariables?.description ?? '')
      setConfigRecomendations(args.stateVariables?.recomendations ?? '')
      setConfigOrigingsAndCauses(args.stateVariables?.origingsAndCauses ?? '')
      setConfigAnswer(args.stateVariables?.answer ?? '')
      setConfigAdminActionPlan(args.stateVariables?.adminActionPlan ?? '')
      setConfigImplementationDate(args.stateVariables?.implementationDate ?? '')
      setConfigEvaluation(args.stateVariables?.evaluation ?? 1)

      if (response.error_message) window.htmlHelpers?.customSwalError(T(response.error_message))
      else window.htmlHelpers?.swalError()
    }
    setShowModal(false)

    if (args.findingBase.reference && args.findingBase.reference.startsWith("engagement_finding")) {
      const errorIconDiv = document.querySelector<HTMLElement>(".error-icon");
      if (errorIconDiv) {
        const elementError = errorIconDiv.querySelector<HTMLElement>("h4");
        if (elementError) {
          const currentNumber = parseInt(elementError.textContent || '', 10);
          if (!isNaN(currentNumber)) {
            elementError.textContent = (currentNumber + 1).toString();
          }
        }
      }
    }

    if (args.findingBase.reference && response.is_error_sheet == false) {
      Swal.fire({
        title: T('Is the finding created associated with any error?'),
        text: T('You will be redirected to create the respective error.'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: T('Yes'),
        cancelButtonText: T('No')
      }).then((result) => {
        if (result.isConfirmed) {
          window.menuService?.breadcrumbAddLevel(`/audix/engagement_finding/index.load/${args.findingBase.engagementId}?confirm_navigate_error=True`, T('Error Sheet'))
        }
      });
    }
  };

  const handleCancelButton = () => {
    if (args.findingBase.directMode) {
      args.findingBase.handleCancelOrDelete(findingId)
    }

    setConfigFindingTitle(args.stateVariables?.findingTitle ?? '')
    setConfigDescription(args.stateVariables?.description ?? '')
    setConfigRecomendations(args.stateVariables?.recomendations ?? '')
    setConfigOrigingsAndCauses(args.stateVariables?.origingsAndCauses ?? '')
    setConfigAnswer(args.stateVariables?.answer ?? '')
    setConfigAdminActionPlan(args.stateVariables?.adminActionPlan ?? '')
    setConfigImplementationDate(args.stateVariables?.implementationDate ?? '')
    setConfigEvaluation(args.stateVariables?.evaluation ?? 1)
    setShowModal(false)
  };

  const handleFindingConfig = () => {
    setShowModal(true)
  }

  const handleDelete = async () => {
    window.htmlHelpers?.customSwalConfirm(T('Are you sure you want to delete this finding?'), args.findingBase.handleCancelOrDelete, findingId)
  }
  return (
    <>
      {
        !args.findingBase.directMode && args.findingBase.initialData.finding_state &&
        <div className='w-full h-full p-0 m-0 d-flex justify-content-center align-items-center gap-2'>
          {args.findingBase.permissions?.update &&
            <button
              className='btn btn-primary p-0'
              style={{ width: '27px', height: '27px' }}
              onClick={handleFindingConfig}>
              <i className='fas fa-edit'></i>
            </button>
          }
          {args.findingBase.permissions?.delete &&
            <button
              className='btn btn-danger p-0'
              style={{ width: '27px', height: '27px' }}
              onClick={handleDelete}>
              <i className='fas fa-trash-alt'></i>
            </button>
          }
        </div>
      }

      <ModalWithButtons
        showModal={showModal}
        title={args.findingBase.title}
        size={args.findingBase.size}
        onAccept={handleAcceptButton}
        onCancel={handleCancelButton}>

        <div className="d-flex flex-column gap-2">
          <div className="form-group">
            <label>{T("Finding Title")}</label>
            <input type="text" className="form-control" placeholder={T('Title')} value={configFindingTitle} onChange={handleFindingTitleChange} maxLength={maxFindingTitleLength} />
          </div>
          <div className="form-group">
            <label>{T("Evaluation type")}</label>
            <select className='form-select' name="evaluation" id="evaluation" onChange={handelEvaluationChange} value={configEvaluation}>
              {
                evaluationOptions.map((option, index) => (
                  <option key={'evaluationOption' + index} value={option.id}>{T(option.name)}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group">
            <label>{T("Description")}</label>
            <Summernote value={configDescription} onChange={handleDescriptionChange} showModal={showModal} maxLength={maxDescriptionLength} />
          </div>
          <div className="form-group">
            <label>{T("Recomendations")}</label>
            <Summernote value={configRecomendations} onChange={handleRecomendationsChange} showModal={showModal} maxLength={maxRecomendationsLength} />
          </div>
          <div className="form-group">
            <label>{T("Are you agree with the recomendation?")}</label>
            <select className='form-select' name="answer" id="answer" onChange={handleAnswerChange} value={configAnswer}>
              <option value="">{T("Select an option")}</option>
              <option value="Yes">{T("Yes")}</option>
              <option value="No">{T("No")}</option>
            </select>
          </div>
          <div className="form-group">
            <label>{T("Origins and Causes")}</label>
            <Summernote value={configOrigingsAndCauses} onChange={handleOrigingsAndCausesChange} showModal={showModal} maxLength={maxOrigingsAndCausesLength} />
          </div>
          <div className="form-group">
            <label>{T("Admin Action Plan")}</label>
            <Summernote value={configAdminActionPlan} onChange={handleAdminActionPlanChange} showModal={showModal} maxLength={maxAdminActionPlanLength} />
          </div>
          <div className="form-group">
            <label>{T("Implementation Date")}</label>
            <Summernote value={configImplementationDate} onChange={setConfigImplementationDate} showModal={showModal} maxLength={maxImplementationDateLength} />
          </div>
        </div>
      </ModalWithButtons>
    </>
  );
}
