import { Board } from "../models/special-document/Board"
import { BoardRender } from "../renderer/special-document/BoardRender"
import { DocumentSpecial } from "../models/special-document/DocumentSpecial"
import { DocumentSpecialFactory}  from "../factory/special-document/DocumentSpecialFactory"
import { IPermissions }  from "./IPermissions"



export class BoardContext{
  private _documentSpecial: DocumentSpecial | null = null
  private _renderingMode: string = "edition"
  private _board: Board | null = null
  private _boardRender: BoardRender | null = null
  private _reference: string = ""
  private _boardContentID: string = ""
  private _documentContentID: string = "document-content"
  private _permissions: IPermissions = {created: false, updated: false, deleted: false, read: false}

  constructor(reference: string, renderingMode: string, documentSpecialFactory: DocumentSpecialFactory,
    permissions: IPermissions, boardContentID: string = ""){
    this._reference = reference
    this._renderingMode = renderingMode
    this._boardContentID = boardContentID
    this._permissions = permissions
    this._documentContentID = boardContentID + "-document-content"
  }

  private async inicialize(documentSpecialFactory: DocumentSpecialFactory, engagementID: number = 0){
    this._documentSpecial = await documentSpecialFactory.createDocumentSpecial(this._reference, this._renderingMode, engagementID)
    this._board = new Board(this._documentSpecial)
    this._boardRender = new BoardRender(this._board, this._renderingMode, this._boardContentID, 
      this._permissions,this._documentContentID)
  }

  public static async createInstance(reference: string, renderingMode: string, documentSpecialFactory: DocumentSpecialFactory,
    permissions:IPermissions, boardContentID: string = "", engagementID: number = 0): Promise<BoardContext>{
    const boardContext = new BoardContext(reference, renderingMode, documentSpecialFactory, permissions, boardContentID)
    await boardContext.inicialize(documentSpecialFactory, engagementID)
    return boardContext
  }

  get board(): Board | null{
    return this._board
  }

  get boardRender(): BoardRender | null{
    return this._boardRender
  }

  get renderingMode(): string{
    return this._renderingMode
  }

  get documentSpecial(): DocumentSpecial | null{
    return this._documentSpecial
  }

  get boardContentID(): string{
    return this._boardContentID
  }

  get permissions(): IPermissions{
    return this._permissions
  }

  get documentContentID(): string{
    return this._documentContentID
  }
}