import { getUrl } from '../utils/app-config'
import { fetchAPI } from '../services/api_services/fetchAPI'


export type AttributeToUpdate = 'is_important' | 'require_transaction_flow' | 'same_account'

/**
 * The TrialBalanceUploadSession repository. This class is responsible for updating the session data for the trial balance upload.
 */
export class TrialBalanceUploadSessionRepository {
  private readonly apiUrl: string = getUrl('trial_balance_upload_api/update_account_session')

  /**
   * The constructor.
   * @param fetcher - The fetcher.
   */
  constructor (private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) {}

  /**
   * Save if the matching account is the same account as the system.
   * @param code - The code.
   * @param value - The value.
   * @param attributeToUpdate - The attribute to update.
   * @param isNewAccount - If the account is new or existing.
   */
  public async updateAccountAttributeByCode (code: string, value: boolean, attributeToUpdate: AttributeToUpdate, isNewAccount: boolean): Promise<boolean> {
    /**
     * This function is responsible for updating the session data for the trial balance upload.
     */

    try {
      const response = await this.fetcher(this.apiUrl, {
        method: 'POST',
        body: JSON.stringify({
          code: code,
          attribute_to_update: attributeToUpdate,
          is_new_account: isNewAccount,
          value: !!value
        })
      })
      const data = await response.json()

      if (response.status === 200 && data.status === 'success') {
        return true
      }

      console.log('The register could not be updated')
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
    }

    return false;
  }
}