import { DocumentSpecial } from "../../models/special-document/DocumentSpecial"
import { ElementRenderFactory } from "../../factory/special-document/ElementRenderFactory"
import { IElementRender } from "./IElementRender"
import { IPermissions } from "../../controller/IPermissions"

export class DocumentSpecialRender{
    private _documentSpecial:DocumentSpecial
    private _documentContentID: string = "document-content"
    private _elementRenderFactory: ElementRenderFactory
    private _permissions: IPermissions = {created: false, updated: false, deleted: false, read: false}
    
    constructor(documentSpecial: DocumentSpecial, permissions: IPermissions,documentContentID: string = "document-content",
    ){
        this._documentSpecial = documentSpecial
        this._documentContentID = documentContentID
        this._permissions = permissions
        this._elementRenderFactory = ElementRenderFactory.getInstance()
    }
        
    render(renderingMode: string, reference:string): void {
        const documentContent = document.getElementById(this._documentContentID)
        if (!documentContent) return
        if (this._permissions.read === false) return
        const renderStrategy = this.getRenderStrategy(renderingMode)
        for (const element of this._documentSpecial.content) {
            const classElementRenderFactory = this._elementRenderFactory.createClassByType(element, this._permissions, reference, renderingMode)
            renderStrategy(classElementRenderFactory, this._documentContentID, element.id)
        }
        
    }

    getRenderStrategy(renderingMode: string): (factory: IElementRender, contentID: string, elementId: number) => void {
        if (renderingMode === 'edition') {
            return (factory, contentID, id) => factory.renderInEdit(contentID, id)
        } else {
            return (factory, contentID, id) => factory.renderInView(contentID, id)
        }
    }

    cleanDiv(): void {
        const documentContent = document.getElementById(this._documentContentID)
        if (!documentContent) return
        documentContent.innerHTML = ''
    }

    
       
        
        
}