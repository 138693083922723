import { getUrl } from '../utils/app-config';
import { fetchAPI } from '../services/api_services/fetchAPI';
import type { Chat, Message, BaseRepositoryProps } from '../types/AttachmentsChatType';

type GetChatsProps = Omit<BaseRepositoryProps, 'chat_id' | 'message'>
type PostUserChatProps = Omit<BaseRepositoryProps, 'chat_id'>

export class AttachmentsChatRepository {
  private readonly getChatsApiUrl: string = getUrl("attachment_chat_api/get_chats")
  private readonly getMessagesApiUrl: string = getUrl("attachment_chat_api/get_messages")
  private readonly postUserChatApiUrl: string = getUrl("attachment_chat_api/post_user_chat")
  private readonly postUserMessageApiUrl: string = getUrl("attachment_chat_api/post_user_message")
  private readonly deleteChatApiUrl: string = getUrl("attachment_chat_api/delete_chat")
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
   * Fetches the chats of the user.
   * @param props The properties to fetch the chats.
   * @returns Object with the status of the request and the chats.
   */
  async getChats(props: GetChatsProps): Promise<{ success: boolean, chats: Chat[] }> {
    const apiUrl = `${this.getChatsApiUrl}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(props)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Failed to fetch chats')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return {
        success: false,
        chats: []
      }
    }
  }

  /**
   * Fetches the messages of the chat.
   * @param chatId The id of the chat to fetch the messages.
   * @returns Object with the status of the request and the messages.
   */
  async getMessages(chatId: number): Promise<{ success: boolean, messages: Message[] }> {
    const apiUrl = `${this.getMessagesApiUrl}/${chatId}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return {
        success: false,
        messages: []
      }
    }
  }

  /**
   * Creates a new chat for the user.
   * @param props The properties to create the new chat.
   * @returns Object with the status of the request and the chat.
   */
  async setNewChat(props: PostUserChatProps): Promise<{ success: boolean, chat: Chat }> {
    const apiUrl = `${this.postUserChatApiUrl}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(props)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false, chat: {} as Chat }
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, chat: {} as Chat }
    }
  }

  /**
   * Sends a user message to the chat.
   * @param props The properties to send the user message.
   * @returns Object with the status of the request and the answer message.
   */
  async sendUserMessage(props: BaseRepositoryProps): Promise<{ success: boolean, message: Message }> {
    const apiUrl = `${this.postUserMessageApiUrl}/${props.engagement_id}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(props)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false, message: {} as Message}
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, message: {} as Message }
    }
  }

  /**
   * Deletes a chat.
   * @param chatId The id of the chat to delete.
   */
  async deleteChat(chatId: number): Promise<{ success: boolean }> {
    const apiUrl = `${this.deleteChatApiUrl}/${chatId}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'DELETE',
          headers: this._jsonContentType
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Failed to delete chat')
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }
}
