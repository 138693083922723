import { Menu } from '../models/menu/Menu'
import { MenuItem } from '../models/menu/MenuItem'
import { Page } from '../models/menu/Page'
import { BreadcrumbLevel } from '../models/menu/BreadcrumbLevel'
import { Breadcrumb } from '../models/menu/Breadcrumb'
import { MenuRepository, type IMenu, type IMenuItems } from '../repository/MenuRepository'

/**
 * This class is responsible for creating the menu.
 */
export class MenuFactory {
  private readonly _menuRepository: MenuRepository

  /**
   * The constructor.
   * @param menuRepository - The menu repository.
   */
  constructor (menuRepository: MenuRepository = new MenuRepository()) {
    this._menuRepository = menuRepository
  }

  /**
   * Create the menu.
   * @returns The menu.
   */
  createMenu = async (): Promise<Menu> => {
    const menuData: IMenu = await this._menuRepository.getMenuData()
    const menu = new Menu()
    menuData.items.forEach((item: IMenuItems) => {
      if (item.submenu.length > 0) {
        const submenu = new Menu()
        item.submenu.forEach((subItem: IMenuItems) => {
          const breadcrumb = new Breadcrumb()
          breadcrumb.addLevel(new BreadcrumbLevel(subItem.label, subItem.url))
          const page = new Page(subItem.target, breadcrumb, false)
          const subMenuItem = new MenuItem(subItem.name, subItem.label, null, subItem.url, page, null)
          submenu.addItem(subMenuItem)
        })
        const menuItem = new MenuItem(item.name, item.label, item.icon, null, null, submenu, item.visible)
        menu.addItem(menuItem)
      }
    })
    return menu
  }
}
