import { ElementArgs, ConditionalParagraphOption } from "./ElementArgs";
import { IElementBase, ElementBase } from "./ElementBase";


export class ConditionalParagraphElementWithGlobalVariable extends ElementBase implements IElementBase{
  _variable: string
  _options: ConditionalParagraphOption[]
  _optionValueSelected: string | number | null

  constructor(args:ElementArgs) {
    super(args);
    this._variable = args.variable ?? ""
    this._optionValueSelected= args.optionValueSelected ?? null
    if (Array.isArray(args.options) ){
      this._options = args.options as ConditionalParagraphOption[]
    }else{
      this._options = []
    }
  }

  get variable(): string {
    return this._variable;
  }

  get options(): ConditionalParagraphOption[] {
    return this._options;
  }

  get optionValueSelected():  string | number | null {
    return this._optionValueSelected;
  }

  set variable(variable: string) {
    this._variable = variable;
    this.args.variable = variable;
  }

  set options(options: ConditionalParagraphOption[]) {
    this._options = options;
    this.args.options = options;
  }

  set optionValueSelected(optionValueSelected: string | number | null) {
    this._optionValueSelected = optionValueSelected;
    this.args.optionValueSelected = optionValueSelected;
  }

  render(): void {
    console.log("render conditional paragraph element with global variable")
  }

}
  
