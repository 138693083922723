import React, { useState, useEffect } from 'react';
import { T } from "../../utils/i18n-config";
import BaseNotification from './BaseNotification';
import { type NotificationArgs } from './NotificationType';
import { AppNotificationsRepository } from '../../repository/AppNotificationsRepository';
import { Constants } from '../../utils/Constants';

const AppNotifications = ({ userId }: { userId: number }) => {
  const [currentUserId] = useState(userId);
  const [notifications, setNotifications] = useState<NotificationArgs[]>([]);

  /**
   * Fetches all the notifications for the current user.
   */
  const getRegisters = async () => {
    try {
      const repository = new AppNotificationsRepository();
      const data = await repository.getRegisters(currentUserId);
      setNotifications(data);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Updates the status of the notification to `read`.
   * @param notificationId The id of the notification to update.
   */
  const updateStatus = async (notificationId: number) => {
    try {
      const repository = new AppNotificationsRepository();
      const status = await repository.updateStatusRegister({
        notification_id: notificationId,
        user_id: currentUserId,
        status: "read"
      });
      if (status) {
        setNotifications(registers => {
          return registers.filter(register => register.id !== notificationId)
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRegisters();
    
    const intervalId = setInterval(() => {
      getRegisters();
    }, Constants.NOTIFICATION_FETCH_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <button
        type="button"
        className="dropdown-toggle nav-link position-relative p-0 text-body"
        id="appNotificationsButton"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        style={{
          marginBottom: "0",
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          transform: "none"
        }}
      >
        <i className="fa fa-bell cursor-pointer"></i>
        {
          notifications.length > 0 && (
            <span className="position-absolute bg-danger rounded-pill text-white text-center"
            style={{ top: "-8px", left: "4px", padding: "0 .35rem", fontSize: "12px", fontWeight: "bold" }}
            >
              {notifications.length}
              <span className="visually-hidden">
                {T('Unread notifications')}
              </span>
            </span>
          )
        }
      </button>
      <ul
        aria-labelledby="appNotificationsButton"
        className="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4"
        style={{ maxHeight: "300px", overflowY: "auto" }}
      >
        {
          notifications.length > 0 ? (
            notifications.map((notification) => (
              <BaseNotification
                key={notification.id}
                id={notification.id}
                title={notification.title}
                description={notification.description}
                stateType={notification.stateType}
                createdAt={notification.createdAt}
                onMarkAsRead={updateStatus}
              />
            ))
          ) : (
            <li className="text-center text-muted">
              {T('There are no notifications')}
            </li>
          )
        }
      </ul>
    </>
  );
}

export default AppNotifications;