export const fetchAPI = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  try {
    const response = await fetch(input, init)
    
    if (response.status == 401) {
      window.location.href = '/login'
    }

    return response
  } catch (error) {
    console.error('There has been a problem with the fetch operation:', error)
    return new Response(null, { status: 500 })
  }
}