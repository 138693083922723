import {ElementBase, IElementBase} from "./ElementBase"
import { ElementArgs } from "./ElementArgs"

export class ManagementIntegrityElement extends ElementBase implements IElementBase{
  private _responseForYes: string
  private _responseForNo: string

  constructor(args:ElementArgs){
    super(args)
    this._responseForYes = args.responseForYes ?? ""
    this._responseForNo = args.responseForNo ?? ""
  }

  get responseForYes(): string {
    return this._responseForYes
  }
  get responseForNo(): string {
    return this._responseForNo
  }
  set responseForYes(responseForYes: string) {
    this._responseForYes = responseForYes
  }

  set responseForNo(responseForNo: string) {
    this._responseForNo = responseForNo
    
  }

  render(): void {
    console.log("render management integrity element")
  }
}