import React, { useEffect, useState } from 'react';
import {UserAvatarFactory} from '../../factory/UserAvatarFactory';

export interface UserAvatarData {
    background_color: string;
    initials_name: string;
    font_color: string;
    full_name: string;
  }

interface UserAvatarCommentProps {
    readonly userId: number;
}

function UserAvatarComment({ userId }: UserAvatarCommentProps ) {
    const [avatar, setAvatar] = useState<UserAvatarData | null>(null);    
    
    useEffect(() => {
        async function fetchAvatar() {
            try {
                const userAvatarFactory = UserAvatarFactory.getInstance();
                const result = await userAvatarFactory.create(userId);
                setAvatar(result);
            } catch (error) {
                console.error("Error fetching avatar:", error);
            }
        }
        fetchAvatar();        
    }, [userId]);
  
    if (!avatar) return <div>Loading...</div>;
    return (
        <div className='avatar-circle avatar-nano avatar shadow' style={{ backgroundColor: avatar['background_color'], color: avatar['font_color'] }} title={avatar['full_name']}>
            {avatar['initials_name']}
        </div>
    );
  }
export default UserAvatarComment;