import { getUrl } from '../utils/app-config';
import { fetchAPI } from '../services/api_services/fetchAPI';
import { type NotificationArgs } from '../components/notifications/NotificationType';


interface UpdateStatusRegister{
  notification_id: number;
  user_id: number;
  status: "read" | "unread"
}

export class AppNotificationsRepository {
  private readonly getRegistersApiUrl: string = getUrl("app_notifications_api/get_registers")
  private readonly updateRegisterApiUrl: string = getUrl("app_notifications_api/update_status_register")
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
   * Fetches all the notifications for the user.
   * @param userId The id of the user to fetch the notifications.
   * @returns A list of notifications for the user.
   */
  async getRegisters(userId: number): Promise<NotificationArgs[]> {
    const apiUrl = `${this.getRegistersApiUrl}/${userId}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Updates the status of the notification to `read`.
   * @param register The notification to update.
   * @returns `true` if the status was updated successfully, `false` otherwise.
   */
  async updateStatusRegister(register: UpdateStatusRegister): Promise<boolean> {
    const apiUrl = `${this.updateRegisterApiUrl}/${register.notification_id}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'PUT',
          headers: this._jsonContentType,
          body: JSON.stringify(register)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }
}

