
import React, { useEffect, useState } from 'react';
import { ThreeStatesTableRepository } from '../../repository/special-document/ThreeStatesTableRepository';
import { TablesOptions} from '../../models/special-document/ElementArgs';
import ModalWithButtons from '../modals/AceptCancelModalBase';
import CustomTextArea from '../utils/CustomTextArea';
import { T } from "../../utils/i18n-config"
import { BasicResponse } from '../../repository/special-document/ThreeStatesTableRepository';
import { modifyThreeStatesTableName } from '../../utils/utilities';

interface ThreeStateTableBase {
    engagementId: number | null;
    tableId: number;
    documentId: number;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    size: "sm" | "lg" | "xl" | "fullscreen" | undefined;
    onAccept: (newData: TablesOptions) => void;
    onCancel: () => void;
}

export const ThreeStatesTableConfig: React.FC<ThreeStateTableBase> = ({engagementId, tableId, documentId, showModal, setShowModal, title, size, onAccept, onCancel}) => {
    const [tableData, setTableData] = useState<TablesOptions>();

    useEffect(() => {
        if (!showModal) {
            return
        }
        const threeStatesTableRepository = new ThreeStatesTableRepository()
        threeStatesTableRepository.getTableData(documentId, engagementId, tableId, 'view').then((data) => {
            if((data as BasicResponse ).success === false) {
                data = data as BasicResponse
                if(data.error_message) window.htmlHelpers?.customSwalError(data.error_message);
                setShowModal(false)
                return
            }
            data = data as TablesOptions
            data.name = modifyThreeStatesTableName(data.name as string)
            setTableData(data)  

        }).catch((error) => {
            console.error('There has been a problem with your fetch operation:', error)
        })
    },[showModal]);

    const handleAcceptButton = () => {
        if (!tableData) {
            return
        }
        onAccept(tableData)
    }

    const handleOptionButtonClick = (event: React.MouseEvent<HTMLButtonElement>, questionId: number, option: string) => {
        const newTableData = JSON.parse(JSON.stringify(tableData)) as TablesOptions
        const question = newTableData?.questions?.find((question) => question.id === questionId)
        if (question) {
            question.answer = option
        }
        setTableData(newTableData)
    }
    return (
        <ModalWithButtons
            showModal={showModal}
            title={title}
            size={size}
            onAccept={handleAcceptButton}
            onCancel={onCancel}>
            <div className='d-flex w-full justify-content-center p-4'>
                <table className='custom-table table-bordered w-75'>
                    <thead>
                        <tr>
                            <th colSpan={3}>
                                {tableData?.name}
                            </th>
                        </tr>
                        <tr>
                            <th>{T("Questions")}</th>
                            <th>{T("Answers")}</th>
                            <th>{T("Comments")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.questions?.map((option) => (
                            <tr key={`row-${option.id}`}>
                                
                                <td id={`question-${option.id}`}>
                                    <div dangerouslySetInnerHTML={{__html: option.question}}></div>
                                </td>
                                <td>
                                    <div className='d-flex flex-column w-100 h-100 justify-content-center align-items-center'>
                                        {tableData.answer_options?.map((answer) => (
                                                <button key={`answer-${answer}`} className={`btn btn-sm ${option.answer === answer ? 'btn-secondary' : 'btn-outline-secondary'}`} onClick={(e) => {handleOptionButtonClick(e,option.id,answer)}}>
                                                    {T(answer)}
                                                </button>
                                            ))
                                        }
                                    </div>
                                </td>
                                <td className='comments'>   
                                    <CustomTextArea
                                        optionId={option.id}
                                        text={option.comments}
                                        tableData={tableData}
                                        setTableData={setTableData} 
                                    />             
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </ModalWithButtons>
    );
};
