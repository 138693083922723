import { getUrl } from '../utils/app-config'
import { fetchAPI } from '../services/api_services/fetchAPI'
import { TeamUser, User } from '../components/notes/Notes'


export class TeamUsersRepository{
  private readonly getApiUrl: string = getUrl('team_notes_avatar_api/get')
  private readonly postApiUrl: string = getUrl('team_notes_avatar_api/post')
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  private async fetchJSON(url: string, options?: RequestInit): Promise<any> {
    const response = await this.fetcher(url, options);
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  async setRegister(dataRegister: TeamUser): Promise<boolean> {
    const apiUrl = `${getUrl('team_notes_avatar_api/set_teams_avatars/')}/${dataRegister.engagement_id}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(dataRegister)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  async getRegisters(engagementId: number, noteId:number): Promise<number[]> {
    const apiUrl = `${getUrl("team_notes_avatar_api/get_team_notes_avatars")}/${engagementId}/${noteId}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async getTeamUsersRegisters(engagementId: number): Promise<User[]> {
    const apiUrl = `${getUrl("team_notes_avatar_api/get_team_users")}/${engagementId}/`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}

