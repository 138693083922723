import React, { useEffect } from 'react';

export interface ModalBaseProps {
    showModal: boolean;
    title: string;
    size?: 'sm' | 'lg' | 'xl' | 'fullscreen';
    zIndex?: number;
    fixedTop?: boolean;
}

const ModalBase = ({ showModal, title, size, zIndex, children, fixedTop = false}: React.PropsWithChildren<ModalBaseProps>) => {
    const sizeClass = size ? `modal-${size}` : '';

    useEffect(() => {
        if (showModal) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
    }, [showModal]);

    return (
        <div>
            <div className={`modal fade ${showModal ? 'show' : ''}`} 
            style={
                showModal ? { 
                    display: 'block',
                    zIndex: zIndex ?? 1050,                    
                    } 
                : { display: 'none' }} tabIndex={-1}
            >
                <div className={`modal-dialog ${fixedTop ? '' : 'modal-dialog-centered'} ${sizeClass}`}>                
                    <div className='modal-content' 
                    style={{
                        overflowX: 'hidden',
                        overflowY: `${fixedTop ? 'auto' : 'hidden'}`,
                        minHeight: `${fixedTop ? '90vh' : ''}`,
                        height: `${fixedTop ? '90vh' : ''}`,
                        maxHeight: `${fixedTop ? '90vh' : ''}`,
                    }}>
                        {
                            title !== "" &&
                            <div className="modal-header">
                                <h5 className="modal-title">{title}</h5>
                            </div>
                        }
                        {children}
                    </div>
                </div>
            </div>
            {showModal ? <div 
            className="modal-backdrop fade show" 
            style={{zIndex: zIndex ? zIndex - 1 : 1040}}>
            </div> : null}
        </div>
    );
};

export default ModalBase;
