import React, { useState, useEffect, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { Constants } from '../../utils/Constants'
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from '../../repository/special-document/ElementRepository'
import { JournalEntriesElement } from '../../models/substantive-tests/JournalEntriesElement'
import { JournalEntriesRepository, type JEAnswer } from '../../repository/substantive-tests/JournalEntriesRepository'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import Conclusion from '../commons/Conclusion'
import Table from '../commons/TableComponent'

const DATE_RANGE_LABEL = {
  [Constants.JOURNAL_DATE_RANGE_ALL_YEAR]: "All year",
  [Constants.JOURNAL_DATE_RANGE_LAST_MONTH]: "Last month",
}

/**
 * Custom hook to manage journal entries view mode.
 * @param {ViewModeProps} props - The view mode properties containing the element.
 */
function useJournalEntriesElement({ iElement }: ViewModeProps) {
  const element = useRef<JournalEntriesElement>(iElement as JournalEntriesElement).current
  const [taskId, setTaskId] = useState<string | undefined>(element.taskProcessingId)
  const [taskStatus, setTaskStatus] = useState<string | undefined>(element.taskStatus)
  const [items, setItems] = useState<JEAnswer[]>([])
  const [dateRange, setDateRange] = useState<string>("")
  const [error, setError] = useState<string | null>(null)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const previousItems = useRef<JEAnswer[]>([])

  const TASK_PENDING = taskStatus === "PENDING"
  const TASK_STARTED = taskStatus === "STARTED"
  const TASK_PROCESSING = taskStatus === "PROGRESS"
  const TASK_SUCCESS = taskStatus === "SUCCESS"
  const TASK_FAILED = taskStatus === "FAILURE"

  /**
   * Saves the task data in the element.
   * @param {string} taskId - The task ID.
   * @param {string} taskStatus - The task status.
   */
  async function saveTaskData(taskId: string, taskStatus: string) {
    const elementRepository = new ElementRepository()
    element.taskProcessingId = taskId
    element.taskStatus = taskStatus
    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) return window.htmlHelpers?.swalError()
  }

  /**
   * Begins the process of the journal entries review.
   */
  async function beginProcess() {
    const repository = new JournalEntriesRepository()
    const { success, taskId } = await repository.beginProcess(element.engagement_id, element.documentId, element.id)

    if (!success) return setError(T("There is missing steps to begin the review"))
    if (taskId == null) return

    setTaskId(taskId)
    setTaskStatus("STARTED")
    setError(null)
    await saveTaskData(taskId, "STARTED")
  }

  /**
   * Gets the status of the task.
   */
  async function getStatus() {
    if (taskId == null) return
    const repository = new JournalEntriesRepository()
    const { success, data, error } = await repository.getStatus(element.engagement_id, element.documentId, element.id, taskId, taskStatus!)
    if (!success) return setError(error ?? "There was an error getting the status")
    if (data == null) return

    setTaskStatus(data.status || "PENDING")
    if (data.result) setError(data.result)
    await saveTaskData(taskId, data.status)
  }

  /**
   * Gets the answers from the repository.
   */
  async function getAnswers() {
    const repository = new JournalEntriesRepository()
    const { success, data } = await repository.getAnswers(element.engagement_id, element.documentId, element.id)
    if (!success) return window.htmlHelpers?.swalError()
    if (data == null) return

    setItems(data.items)
    setDateRange(data.date_range)
    previousItems.current = structuredClone(data.items)
  }

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }

    if (TASK_STARTED || TASK_PROCESSING) {
      intervalRef.current = setInterval(getStatus, 5000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [taskId, taskStatus])

  useEffect(() => {
    if (TASK_SUCCESS) getAnswers()
  }, [taskStatus])

  return {
    element, taskStatus, error, setError,
    items, dateRange, beginProcess, getStatus,
    TASK_PENDING, TASK_STARTED, TASK_PROCESSING, TASK_SUCCESS, TASK_FAILED,
  }
}

/**
 * Journal entries view mode component.
 * @param {ViewModeProps} props - The view mode properties containing the element.
 */
const JournalEntriesViewMode = ({ iElement }: ViewModeProps) => {
  const {
    element, taskStatus, error, setError,
    items, dateRange, beginProcess, getStatus,
    TASK_PENDING, TASK_STARTED, TASK_PROCESSING, TASK_SUCCESS, TASK_FAILED,
  } = useJournalEntriesElement({ iElement })

  return (
    <>
      <ViewModeBase
        isEditable={false}
        handleEdit={() => { }}
        permissions={element.permissions as Permissions}
      >
        {error && (
          <div className="alert alert-danger d-flex justify-content-between align-items-center py-2 text-white lh-sm" role="alert">
            <span>{T(error)}</span>
            <button className="m-0 px-2 border-0 bg-transparent text-white fs-4" onClick={() => setError(null)}>
              <span>&times;</span>
            </button>
          </div>
        )}
        {taskStatus == null && (
          <NoDataView titleText="Complete the previous steps to see the results" buttonText="Begin the review" onAction={beginProcess} />
        )}
        {(TASK_PENDING || TASK_STARTED || TASK_PROCESSING) && (
          <NoDataView titleText="The review is in progress" buttonText="Check manually" onAction={getStatus}>
            <div className="je-progress progress" style={{ height: "1rem" }}>
              <div
                role="progressbar"
                className="progress-bar bg-primary progress-bar-striped progress-bar-animated h-100"
                style={{ width: "100%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
          </NoDataView>
        )}
        {TASK_SUCCESS && (
          <AnswersSection element={element} items={items} dateRange={dateRange} onExecution={beginProcess} />
        )}
        {TASK_FAILED && (
          <NoDataView titleText="The review failed" buttonText="Try again" onAction={beginProcess} />
        )}
        <Conclusion element={element} subtitle={T("Based on the audit procedures performed at the end of the period, evaluate and conclude whether any accounting records have been identified that may be fraudulent or that seek to intentionally modify or alter the entity's financial statements")} />
      </ViewModeBase>
    </>
  )
}

interface AnswersSectionProps {
  element: JournalEntriesElement
  items: JEAnswer[]
  dateRange: string
  onExecution: () => void
}
function AnswersSection({ element, items, dateRange, onExecution }: AnswersSectionProps) {
  const JEHeaders = [
    T("Document Date"),
    T("Account"),
    T("Auxiliary Name"),
    T("Responsible Name"),
    T("Notes"),
    T("Movement"),
    T("Fraud/Error"),
    T("Justification"),
  ]

  async function saveAnswers(newItems: JEAnswer[]) {
    const repository = new JournalEntriesRepository()
    const itemsToSave = newItems.map(item => ({
      reference: item.reference,
      is_error: item.hasError,
      justification: item.justification,
    }))
    const { success } = await repository.setAnswers(element.engagement_id, element.documentId, element.id, itemsToSave)
    if (!success) return window.htmlHelpers?.swalError()
  }

  function handleUpdateAnswer(rowIndex: number, colIndex: number, value: any) {
    const newItems = items.map((item, index) => {
      if (index === rowIndex) {
        if (colIndex === 6) item.hasError = value
        if (colIndex === 7) item.justification = value
      }
      return item
    })
    saveAnswers(newItems)
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <strong>{T("Review Period")}: {T(DATE_RANGE_LABEL[dateRange])}</strong>
        <button className="btn m-0 py-2 bg-primary text-white align-self-center" onClick={onExecution}>
          {T("Review again")}
        </button>
      </div>
      <div className="d-flex flex-column gap-5">
        {items.map((item, index) => (
          <details key={index}>
            <summary>
              <h6 className="d-inline m-0">{item.reference} {item.question}</h6>
            </summary>
            <Table labels={JEHeaders} values={item.answers} inputColumns={[6, 7]} onChange={handleUpdateAnswer} />
          </details>
        ))}
      </div>
    </>
  )
}

interface NoDataViewProps {
  titleText: string
  buttonText: string
  onAction: () => void
  children?: React.ReactNode
}
function NoDataView({ titleText, buttonText, onAction, children }: NoDataViewProps) {
  return (
    <div className="card card-body d-flex flex-column gap-3 p-3 bg-light">
      <p className="m-0 text-center">{T(titleText)}.</p>
      {children}
      <button className="btn mb-2 bg-primary text-white align-self-center" onClick={onAction}>
        {T(buttonText)}
      </button>
    </div>
  )
}


export default JournalEntriesViewMode;