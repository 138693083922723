export enum NotificationType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export interface NotificationArgs {
  id: number;
  userId: number;
  title: string;
  description: string;
  stateType: NotificationType;
  status: "read" | "unread";
  createdAt: string;
  readAt?: string;
}
