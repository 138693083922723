import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import { T } from "../../utils/i18n-config"
import ModalWithButtons from '../modals/AcceptModalBase';
import ViewModeProps from './ViewModeProps';
import { ElementOfacResults } from "@models/special-document/ElementOfacResults"
import type { PartnerOfac } from '@models/special-document/ElementArgs';
import ExpandableImage from '../../components/utils/ExpandableImage';


const ShowOfacResultsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<ElementOfacResults>(iElement as ElementOfacResults).current
  const [ofacResult, setOfacResult] = useState<PartnerOfac[] | null>(null)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  

  useEffect(() => {
    setOfacResult(element.ofac_results)
  }, [])

  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);
  };

  return (
    <div>
      <button type="button" className="btn bg-gradient-secondary" onClick={
        () => {setShowConfigurationsModal(true)}
      }>{T("See OFAC results")}</button>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("OFAC results")}
        size="xl"
        onAccept={handleEditModalAccept}>
        {
          ofacResult && ofacResult?.length > 0?
          ofacResult?.map((result, index) => {
            return (
              <article key={index} className="d-flex">
                <ExpandableImage
                  src={result.evidence_img}
                  alt={`Evidencia de ${result.full_name}`}
                  pictureClassName='ratio ratio-16x9 w-25 h-auto me-4 mb-4'
                />
                <aside className="d-flex flex-column align-items-start gap-2">
                  <b>{result.full_name}</b>
                  <span>{result.id_number}</span>
                  {
                    result.reported?
                    <small style={{...articleReportedTagStyle, ...Reported}}>
                      {T("Reported")}
                    </small>
                    :
                    <small style={articleReportedTagStyle}>
                      {T("Not reported")}
                    </small>
                  }
                </aside>
              </article>
            )
          })
          :
          <h6>{T("No OFAC results so far")}</h6>
        }
      </ModalWithButtons>
    </div>
  );
};

const articleReportedTagStyle: CSSProperties = {
  backgroundColor: '#c5c5c5',
  borderRadius: '10px',
  padding: '0 5px'
}

const Reported: CSSProperties = {
  backgroundColor: '#854040',
  color: 'white'
}

export default ShowOfacResultsViewMode;
