import React, { useEffect, useState } from 'react';
import { UserAvatarFactory } from '../../factory/UserAvatarFactory';
import { TeamUsersRepository } from '../../repository/TeamUsersRepository';


export interface UserAvatarData {
  background_color: string;
  initials_name: string;
  font_color: string;
  full_name: string;
}

interface UserAvatarCommentProps {
  readonly notesId: number;
  readonly engagementId: number;
}

function TeamUsersAvatar({ notesId , engagementId}: UserAvatarCommentProps) {
  const [avatar, setAvatar] = useState<UserAvatarData[] | null>(null);
  
  useEffect(() => {
    async function fetchAvatar() {
      try {

        let arrayUsers: UserAvatarData[] = [];
        const teamUsersRepository = new TeamUsersRepository();
        const teamUsersRegisters = await teamUsersRepository.getRegisters(engagementId, notesId);    
        
        if (!teamUsersRegisters) return;
        const userAvatarFactory = UserAvatarFactory.getInstance();
        for (const register of teamUsersRegisters) {
          const result = await userAvatarFactory.create(register);
          arrayUsers.push(result)
        }
        setAvatar(arrayUsers);
      } catch (error) {
        console.error("Error fetching avatar:", error);
      }
    }
    fetchAvatar();
  }, []);

  if (!avatar) return
  return (
    <>
      {avatar.map((userAvatar, index) => (
        <div key={index} className='avatar-circle avatar-nano avatar shadow ms-1' style={{ backgroundColor: userAvatar.background_color, color: userAvatar.font_color }} title={userAvatar.full_name}>
          {userAvatar.initials_name}
        </div>
      ))}
    </>
  );
}
export default TeamUsersAvatar;