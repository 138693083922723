import React, { useState, useEffect, useRef, useMemo, useId } from 'react'
import { T } from "@/utils/i18n-config"
import { Permissions } from '@/models/special-document/ElementBase'
import { Strategy, Control, SubstantiveTestStrategy } from '@/models/special-document/ElementArgs'
import { TransactionFlowStrategiesElement } from '@/models/special-document/TransactionFlowStrategiesElement'
import { TransactionFlowStrategyRepository } from '@/repository/special-document/TransactionFlowStrategyRepository'
import ToggleSummernoteParagraph from '../commons/toogle-summernote'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { ButtonModal } from '../commons/button-modal'
import EditModeProps from './EditModeProps'
import EditModeBase from './EditModeBase'


type ControlsByRisk = Record<string, Control[]>
type AddSubstantiveProcedure = {
  categoryId: number
  asseverationId: number
  substantiveProcedure: string
  isMandatory: boolean
}

/**
 * TransactionFlowStrategiesEditMode is a React functional component that renders the edit mode for transaction flow strategies.
 * It fetches the transaction flow strategies from the repository and displays them in a structured format.
 * @param {EditModeProps} props - The properties for the component.
 */
const TransactionFlowStrategiesEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<TransactionFlowStrategiesElement>(iElement as TransactionFlowStrategiesElement).current
  const [transactionFlowStrategies, setTransactionFlowStrategies] = useState<Strategy[]>([])

  /**
   * Fetches the transaction flow strategies from the repository and updates the state.
   * This function is called once when the component mounts.
   */
  async function getTransactionFlowStrategy() {
    const transactionFlowStragyRepository = new TransactionFlowStrategyRepository()
    const { success, data } = await transactionFlowStragyRepository.getTransactionFlowStrategiesInEdit(null, element.id, "edition")
    if (success) setTransactionFlowStrategies(data)
  }

  useEffect(() => {
    getTransactionFlowStrategy()
  }, [])

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={() => { }}
        permissions={iElement.permissions as Permissions}
      >
        <div className="d-flex flex-column gap-5">
          {transactionFlowStrategies.map((strategy: Strategy, index: number) => (
            <TransactionStrategyView key={index} element={element} strategy={strategy} />
          ))}
        </div>
      </EditModeBase>
    </>
  );
}


interface TransactionStrategyViewProps {
  element: TransactionFlowStrategiesElement
  strategy: Strategy
}

/**
 * TransactionStrategyView is a component that displays a transaction strategy.
 * It includes a button to edit the strategy and a modal for editing the description.
 * @param {TransactionStrategyViewProps} props - The properties for the component.
 */
function TransactionStrategyView({ element, strategy }: TransactionStrategyViewProps) {
  const [currentStrategy, setCurrentStrategy] = useState(strategy)
  const controlsByRisk = useMemo(() => {
    return Object.groupBy(currentStrategy.controls, ({ name_risk, is_confidence }) => {
      return `${is_confidence ? "" : T("No Confidence") + " - "}${T("Risk")} ${T(name_risk)}`
    })
  }, [currentStrategy]) as ControlsByRisk

  /**
   * Handles the change in the strategy description.
   * It updates the current strategy state and also updates the description in the repository.
   * @param text - The new description text.
   */
  async function handleDescriptionChange(text: string) {
    setCurrentStrategy(prev => ({ ...prev, description: text }))
    
    const transactionFlowStrategyRepository = new TransactionFlowStrategyRepository()
    await transactionFlowStrategyRepository.updateStrategyDescription(
      element.engagement_id, element.id, "edition", currentStrategy.id, text
    )
  }

  /**
   * Handles the addition of substantive procedures to the strategy.
   * It updates the current strategy state and also creates the new substantive procedures in the repository.
   * @param procedures - The array of substantive procedures to add.
   */
  async function handleAddSubstantiveProcedures(procedures: AddSubstantiveProcedure[]) {
    if (procedures.length === 0) return

    let updatedControls = [...currentStrategy.controls]
    for (const procedure of procedures) {
      const { categoryId, substantiveProcedure, isMandatory } = procedure
      const newSubstantiveProcedure = {
        substantive_test_name: substantiveProcedure,
        is_mandatory: isMandatory
      } as SubstantiveTestStrategy
      
      const substantiveId = await createSubstantiveProcedure(categoryId, newSubstantiveProcedure)
      if (!substantiveId) return

      updatedControls = updatedControls.map(control => {
        if (control.id === categoryId) {
          return {
            ...control,
            substantive_test_strategy: [
              ...control.substantive_test_strategy,
              { ...newSubstantiveProcedure, id: substantiveId }
            ]
          }
        }
        return control
      })
    }
    setCurrentStrategy(prev => ({ ...prev, controls: updatedControls }))
  }

  /**
   * Handles the deletion of a substantive procedure from the strategy.
   * It updates the current strategy state and also removes the substantive procedure from the repository.
   * @param controlId - The ID of the control to which the substantive procedure belongs.
   * @param substantiveId - The ID of the substantive procedure to delete.
   */
  function handleDeleteSubstantiveProcedure(controlId: number, substantiveId: number) {
    removeSubstantiveProcedure(controlId, substantiveId)
    const updatedControls = currentStrategy.controls.map(control => {
      return {
        ...control,
        substantive_test_strategy: control.substantive_test_strategy.filter(sub => sub.id !== substantiveId)
      }
    })
    setCurrentStrategy(prev => ({ ...prev, controls: updatedControls }))
  }

  /**
   * Creates a substantive procedure in the repository and returns its ID.
   * @param controlId - The ID of the control to which the substantive procedure belongs.
   * @param procedure - The substantive procedure to create.
   * @returns The ID of the created substantive procedure.
   */
  async function createSubstantiveProcedure(controlId: number, procedure: SubstantiveTestStrategy) {
    const transactionFlowStrategyRepository = new TransactionFlowStrategyRepository()
    const { success, data } = await transactionFlowStrategyRepository.createSubstantiveProcedure(
      null, element.id, "edition", controlId, procedure
    )
    if (success && data) return data
  }

  /**
   * Removes a substantive procedure from the repository.
   * @param controlId - The ID of the control to which the substantive procedure belongs.
   * @param substantiveId - The ID of the substantive procedure to delete.
   */
  async function removeSubstantiveProcedure(controlId: number, substantiveId: number) {
    const transactionFlowStrategyRepository = new TransactionFlowStrategyRepository()
    await transactionFlowStrategyRepository.deleteSubstantiveProcedure(
      element.engagement_id, element.id, "edition", controlId, substantiveId
    )
  }

  /**
   * Gets the control categories for the controls in the current strategy.
   * It returns an array of unique category names and IDs.
   * @param controls - The array of controls to get the categories from.
   * @returns An array of unique category names and IDs.
   */
  function getControlCategories(controls: Control[]) {
    return Array.from(new Set(controls.map(control => [
      `${control.id}-${control.asseveration_id}`, `${control.name} - ${control.asseveration_name}`
    ])))
  }

  return (
    <div className="position-relative">
      <details>
        <summary>
          <h5 className="d-inline m-0 fw-bolder">{strategy.title}</h5>
        </summary>
        <div className="pt-4 ps-3">
          <ToggleSummernoteParagraph
            initialText={currentStrategy.description ?? ""}
            onChange={handleDescriptionChange}
          />
          <div className="d-flex flex-column gap-4">
            {Object.entries(controlsByRisk).map(([riskLabel, controls], index) => (
              <TransactionTable
                key={index}
                title={riskLabel}
                controls={controls}
                controlId={controls[index].id}
                categories={getControlCategories(controls)}
                risk={T(controls[0].name_risk).toLowerCase()}
                onAddSubstantiveProcedures={handleAddSubstantiveProcedures}
                onRemoveSubstantiveProcedure={handleDeleteSubstantiveProcedure}
              />
            ))}
          </div>
        </div>
      </details>
    </div>
  )
}


interface TransactionTableProps {
  title: string
  risk: string
  controlId: number
  controls: Control[]
  categories: (string | number)[][]
  onAddSubstantiveProcedures: (procedures: AddSubstantiveProcedure[]) => Promise<void>
  onRemoveSubstantiveProcedure: (controlId: number, substantiveId: number) => void
}

/**
 * TransactionTable is a component that displays a table of controls for a specific risk level.
 * It allows the user to add and remove substantive procedures for each control.
 * @param {TransactionTableProps} props - The properties for the component.
 */
function TransactionTable({
  title, risk, controlId, categories, controls, onAddSubstantiveProcedures, onRemoveSubstantiveProcedure
}: TransactionTableProps) {
  const formId = useId()
  const [showModal, setShowModal] = useState(false)
  const [rowCategoryId, setRowCategory] = useState("")
  const [rowSubstantiveProcedure, setRowSubstantiveProcedure] = useState("")
  const [rowMandatory, setRowMandatory] = useState(false)
  const values = useMemo(() => getControlValues(), [controls])
  const isPasting = useRef(false)

  const TABLE_LABELS = [
    T("Category"),
    T("Asseveration"),
    T("Strategy"),
    T("Risk Level"),
    T("Substantive Procedures"),
    T("Modification"),
    T("Quality"),
  ]

  /**
   * Handles the acceptance of the modal for adding a substantive procedure.
   * It adds the new substantive procedures to the control and updates the state.
   */
  async function handleModalAccept() {
    setShowModal(false)
    const valuesToInsert = rowSubstantiveProcedure.split("\n").filter(v => v.trim() !== "")
    if (valuesToInsert.length === 0 || rowCategoryId === "") {
      return window.htmlHelpers?.customSwalError(T("Please complete all fields before saving."))
    }

    const procedures = valuesToInsert.map((value) => {
      const [categoryId, asseverationId] = rowCategoryId.split("-").map(Number)
      return {
        categoryId,
        asseverationId,
        substantiveProcedure: value,
        isMandatory: rowMandatory
      }
    })
    onAddSubstantiveProcedures(procedures)
  }

  /**
   * Handles the cancellation of the modal for adding a substantive procedure.
   * It closes the modal without making any changes.
   */
  function handleModalCancel() {
    setShowModal(false)
  }

  /**
   * Handles the deletion of a substantive procedure from the control.
   * It prompts the user for confirmation if the procedure is mandatory.
   * @param substantiveId - The ID of the substantive procedure to delete.
   * @param isMandatory - A flag indicating if the procedure is mandatory.
   * If true, a confirmation dialog is displayed before deleting the procedure.
   */
  function handleDeleteRow(substantiveId: number, isMandatory: boolean) {
    if (isMandatory) {
      window.htmlHelpers?.customSwalConfirm(
        T("Are you sure you want to delete this mandatory procedure?"),
        (substantiveId) => onRemoveSubstantiveProcedure(controlId, substantiveId),
        substantiveId
      )
    } else onRemoveSubstantiveProcedure(controlId, substantiveId)
  }

  /**
   * Handles the pasting of text into the substantive procedure textarea.
   * It sets a flag to prevent the change event from being triggered when pasting.
   * @param ev - The clipboard event for pasting text.
   * @returns The pasted text
   */
  function handleOnPastedProcedure(ev: React.ClipboardEvent) {
    isPasting.current = true
    const text = ev.clipboardData.getData("text")
    if (!text) return
    
    const lines = text.split("\n").filter(line => line.trim() !== "")
    setRowSubstantiveProcedure(lines.join("\n"))
    setTimeout(() => isPasting.current = false, 0) // Reset after a short delay
  }

  /**
   * Handles the change in the substantive procedure textarea.
   * It updates the state with the new procedure text.
   * @param ev - The change event for the textarea.
   * @returns The new procedure text
   */
  function handleOnChangedProcedure(ev: React.ChangeEvent<HTMLTextAreaElement>) {
    if (isPasting.current) return
    setRowSubstantiveProcedure(ev.target.value)
  }

  /**
   * Gets the values for the controls in the current strategy.
   * It returns an array of values for each control and its substantive procedures.
   * @returns An array of values for each control and its substantive procedures.
   */
  function getControlValues() {
    return controls.map(control => {
      return control.substantive_test_strategy.map(substantiveTest => {
        return [
          control.name,
          control.asseveration_name,
          T(control.is_confidence ? "Confidence" : "No Confidence"),
          T(control.name_risk),
          substantiveTest.substantive_test_name,
          T("Manager/Partner"),
          substantiveTest.is_mandatory,
          // Include ids to facilitate deletion but not display them
          substantiveTest.id
        ]
      })
    }).flat()
  }

  return (
    <>
      <div className="position-relative py-2">
        <h6 className="text-dark fs-6 fw-bold">{title}</h6>
        <p>
          {T("If the audit team establishes in the planning memorandum a controls-based approach to revenue and accounts receivable testing, but also a {{risk}} risk is identified, IARA should suggest performing the following detailed substantive procedures.", { risk })}
        </p>
        <table
          className="position-relative table table-responsive table-striped table-hover"
          style={{ width: "fit-content", borderCollapse: "collapse" }}>
          <ButtonModal onDisplayModal={() => setShowModal(true)} />
          <thead className="table-dark border">
            <tr>
              {TABLE_LABELS.map((label, index) => (
                <th key={index} scope="col" className="text-center text-wrap align-middle">{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values.map((row, rowIndex) => (
              <tr key={rowIndex} className="position-relative">
                {row.slice(0, 7).map((cell, colIndex) => (
                  <td key={colIndex} className="text-center text-wrap border align-middle">
                    {colIndex === 6 ? T(cell ? "Mandatory" : "Optional") : cell}
                  </td>
                ))}
                <button
                  style={{ top: "-0.5rem", right: "-0.5rem", opacity: 0.25, zIndex: 2 }}
                  className="icon-action-button position-absolute p-1 bg-secondary border-0 rounded-1 text-sm text-white lh-1"
                  onClick={() => handleDeleteRow(
                    values[rowIndex][7] as number,
                    row[6] as boolean
                  )} // 7 and 8 are the controlId and asseverationId respectively
                >
                  <i className="fa-solid fa-trash-can" />
                </button>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ModalWithButtons
        showModal={showModal}
        title={T(title)}
        size="xl"
        onAccept={handleModalAccept}
        onCancel={handleModalCancel}
      >
        <div className="d-grid gap-4" style={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
          <div>
            <label htmlFor={`${formId}-category`} className="form-label">{T("Sector")}</label>
            <select
              value={rowCategoryId}
              id={`${formId}-category`}
              className="form-select"
              aria-label={T("Category")}
              onChange={(e) => setRowCategory(e.target.value)}
            >
              <option value="" disabled>{T("Select Sector")}</option>
              {categories.map((value, index) => (
                <option key={index} value={value[0]}>{value[1]}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor={`${formId}-substantive-procedure`} className="form-label">{T("Substantive Procedure")}</label>
            <textarea
              id={`${formId}-substantive-procedure`}
              rows={1}
              className="form-control"
              placeholder={T("Substantive Procedure")}
              value={rowSubstantiveProcedure}
              onChange={handleOnChangedProcedure}
              onPaste={handleOnPastedProcedure}
            ></textarea>
          </div>

          <div className="form-check">
            <input
              type="checkbox"
              id={`${formId}-procedure-mandatory`}
              className="form-check-input"
              checked={rowMandatory}
              onChange={(e) => setRowMandatory(e.target.checked)}
            />
            <label htmlFor={`${formId}-procedure-mandatory`} className="form-check-label ms-2">{T("Is Mandatory?")}</label>
          </div>
        </div>
      </ModalWithButtons>
    </>
  )
}

export default TransactionFlowStrategiesEditMode;