import React, { useEffect, useState, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ConditionalParagraphElementWithGlobalVariable } from "../../models/special-document/ConditionalParagraphElementWithGlobalVariable"
import EditModeBase from "./EditModeBase"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from './EditModeProps'
import ConditionalParagraphWithVariableCondition from './ConditionalParagraphWithVariableCondition'
import { ConditionalParagraphOption } from "../../models/special-document/ElementArgs"
import { createId } from "../../utils/utilities";
import VariablesModal from '../special-document/EngagementVariablesModal';
import { Permissions } from '../../models/special-document/ElementBase'
import DOMPurify from 'isomorphic-dompurify';

const ConditionalParagraphWithVariableEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ConditionalParagraphElementWithGlobalVariable>(iElement as ConditionalParagraphElementWithGlobalVariable).current
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [variable, setVariable] = useState(element.variable)
  const [options, setOptions] = useState<ConditionalParagraphOption[]>(JSON.parse(JSON.stringify(element.options)))
  const [isEditable , setIsEditable] = useState<boolean>(element.options.some(option => option.editable));
  

  const handleConfiguration = () => {
    setVariable(element.variable)
    setOptions(JSON.parse(JSON.stringify(element.options)));
    setShowConfigurationsModal(true);
  };

  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);    
    const elementRepository = new ElementRepository()
    const lastQuestion = element.variable
    const lastOptions = JSON.parse(JSON.stringify(element.options))
    element.variable = variable
    element.options = JSON.parse(JSON.stringify(options))
    setIsEditable(options.some(option => option.editable))
    
    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.variable = lastQuestion
      element.options = lastOptions
      setVariable(lastQuestion)
      setOptions(JSON.parse(JSON.stringify(lastOptions)))
      window.htmlHelpers?.swalError()
    }
  };

  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    setVariable(element.variable)
    //Tiene que hacerse un cambio que permita la mutación esto debido al summernote
    options.forEach((option, index) => {
      option.value = element.options[index].value
      option.message = element.options[index].message
      option.condition = element.options[index].condition
      option.editable = element.options[index].editable
    })
    setOptions(options)
    setIsEditable(element.options.some(option => option.editable));
  };

  const handleAddValueMessage = () => {
    const conditionalParagraphOption: ConditionalParagraphOption = {
      id: createId(),
      value: "",
      message: "",
      editable: false
    }
    const newOptions = [...options, conditionalParagraphOption]
    setOptions(newOptions)
  }

  const handleRemoveLastValueMessage = () => {
    const newOptions = [...options]
    newOptions.pop()
    setOptions(newOptions)
  }

  const handleEditValue = (index: number, value: string) => {
    const newOptions = [...options]
    newOptions[index].value = value
    setOptions(newOptions)
  }

  const handleEditMessage = (index: number, message: string) => {
    const newOptions = [...options]
    newOptions[index].message = message
    setOptions(newOptions)
  }

  const handleSetIsEditable = (index: number, editable: boolean) => {
    const newOptions = [...options];
    newOptions[index].editable = editable;
    setOptions(newOptions);
    if(editable){
      setIsEditable(true);
    }
    else if(!newOptions.some(option => option.editable)){
        setIsEditable(false);
    }
  }

  const handleSelectVariable = (variable: string) => {
    setIsVariableModalOpen(false)
    setVariable(variable)
  }

  const handleEditCondition = (index: number, condition: string) => {
    const newOptions = [...options];
    newOptions[index].condition = condition;
    setOptions(newOptions);
  }

  const initFunction = (index: number, initialVariableOptionParams: {condition: string, value: string}) => {
    const newOptions = [...options]
    newOptions[index].condition = initialVariableOptionParams.condition
    newOptions[index].value = initialVariableOptionParams.value
    setOptions(newOptions)
  }

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div>
          {element.options.length === 0 && 
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Add variable")}</h6>
              <div className="chart pt-3">
                <p>{T("Add Option/Message")}</p>
              </div>
            </div>
          </div>
          }
          {element.options.length > 0 && 
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <div className="d-flex flex-column">
                <h6 className="mb-0 lh-1">{T("Conditional Paragraph With Global Variable")}</h6>
                <span className="text-sm fw-bold">({element.variable})</span>
              </div>
              <div className="chart pt-3">
                <ul>
                  {element.options.map((option) => {
                    return (
                      <li key={option.id}>
                        <div className="row">
                          <div className="col-2 d-flex flex-wrap align-content-start text-sm font-weight-normal gap-2">
                            <span>{option.condition}</span>
                            <span>{T(option.value)}</span>
                          </div>
                          <div className="col-10 d-flex">
                            <div style={{minWidth: "20px"}}>
                              {option.editable && element.permissions?.update && <i className="fas fa-pencil-alt text-primary"></i>}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option.message) }} />
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
          }
        </div>
      </EditModeBase>
      <VariablesModal 
                isOpen={isVariableModalOpen} 
                onClose={() => setIsVariableModalOpen(false)} 
                onSelectVariable={handleSelectVariable}
      />
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <button 
            className="btn btn-primary btn-sm me-2" 
            type="button" 
            style={{padding: "8px 8px", fontSize: "12px"}}
            onClick={handleAddValueMessage}>
            {T("Add Condition/Message")}
          </button>
          <button
            className="btn btn-outline-danger"
            type="button"
            style={{padding: "8px 8px", fontSize: "12px"}}
            onClick={handleRemoveLastValueMessage}>
              {T("Remove last Condition/Message")}
          </button>
        </div>
        <div className="form-group">
          <div className="row">
          <label>{T("Variable")}</label>
            <div className="col-11">
              <input type="text" className="form-control"placeholder={T('Enter variable')} value={variable} onChange={(e) => setVariable(e.target.value)} readOnly/>
            </div>
            <div className="col-1">
              <button 
                className="btn btn-info btn-sm me-2" 
                type="button" 
                style={{padding: "8px 8px", fontSize: "12px"}}
                onClick={() => setIsVariableModalOpen(true)}>
                {T("Add")}
              </button>
            </div>
          </div>  
        </div>
        <hr className="horizontal dark my-3"></hr>
        <div>
          {options.map((option, index) => {
            return (
              <ConditionalParagraphWithVariableCondition
              key={`${option.id}-${options.length}`}
              index={index}
              variable={variable}
              condition={option.condition ?? ""}
              value={option.value}
              message={option.message}
              isEditable={option.editable}
              showModal={showConfigurationsModal}
              handleEditValue={handleEditValue}
              handleEditMessage={handleEditMessage}
              handleSetIsEditable={handleSetIsEditable}
              handleEditCondition={handleEditCondition}
              initFunction={initFunction}/>
            )
          })}
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default ConditionalParagraphWithVariableEditMode;
