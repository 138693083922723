import { InformationExtractorArgs } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class InformationExtractorElement extends ElementBase implements IElementBase {
  private _attachmentName: string
  private _informationList: string[]

  constructor(args: InformationExtractorArgs) {
    super(args)
    this._attachmentName = args.attachment_name || ''
    this._informationList = args.information_list || []
  }

  get attachmentName(): string {
    return this._attachmentName
  }

  get informationList(): string[] {
    return this._informationList
  }

  get args(): InformationExtractorArgs {
    return {
      ...super.args,
      attachment_name: this._attachmentName,
      information_list: this._informationList,
    }
  }

  set attachmentName(attachmentName: string) {
    this._attachmentName = attachmentName
  }

  set informationList(informationList: string[]) {
    this._informationList = informationList
  }

  render(): void {}
}