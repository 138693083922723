import { ElementArgs, TablesOptions} from "./ElementArgs";
import { IElementBase, ElementBase } from "./ElementBase";


export class ThreeStatesTableElement extends ElementBase implements IElementBase {
  
  private _table_options: TablesOptions[] | TablesOptions

  constructor(args: ElementArgs) {
    super(args);
    const opt = args.options as TablesOptions[]

    if (Array.isArray(opt)) {
      this._table_options = opt
    } else {
      this._table_options = []
    }
  }

  get table_options(): TablesOptions[] | TablesOptions{
    return this._table_options;
  }

  set table_options(table_options: TablesOptions[] | TablesOptions) {
    this._table_options = table_options;
    this.args.options = table_options;
  }

  render(): void {
    return;
  }
}