import { T } from '../../utils/i18n-config'
//import * as bootstrap from 'bootstrap'

export class ModalHelper{

  static openModal(modalID: string): void {
    let modalElement = document.getElementById(modalID)
    if (modalElement) {
      // let myModal = new bootstrap.Modal(modalElement)
      // myModal.show()
    }
  }

  static closeModal(modalID: string): void {
    let modalElement = document.getElementById(modalID)
    if (modalElement) {
      // let myModal = bootstrap.Modal.getInstance(modalElement)
      // myModal?.hide()
    }
  }
  
  static createModal(contentID: string, modalContent: string): void {
    const divTarget = document.getElementById(contentID)
    if (divTarget) {
      let div = document.createElement('div')
      div.innerHTML = modalContent
      divTarget.appendChild(div)
    }
  }

  static createContentModal(modalID: string, title: string, bodyContent: string, footerButtons: string[]): string {
    return `
      <div class="modal fade" id="${modalID}" tabindex="-1" role="dialog" aria-labelledby="${modalID}Label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="${modalID}Label">${title}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="${T("Close")}">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">${bodyContent}</div>
            <div class="modal-footer">
              ${footerButtons.join('')}
              <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">${T("Close")}</button>
            </div>
          </div>
        </div>
      </div>`
  }

  static createContentModalDelete(modalID: string, title: string, bodyContent: string, footerButtons: string[]): string {
    return `<div class="col-md-4">
               <div class="modal fade" id="${modalID}" tabindex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
                <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h6 class="modal-title" id="modal-title-notification">Your attention is required</h6>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="${T("Close")}">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="py-3 text-center">
                        <i class="ni ni-bell-55 ni-3x"></i>
                        <h4 class="text-gradient text-danger mt-4">You should read this!</h4>
                        ${bodyContent}  
                      </div>
                    </div>
                    <div class="modal-footer">
                      ${footerButtons.join('')}
                      <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">${T("Close")}</button></div>
                  </div>
                </div>
              </div>
            </div>`
  }
}