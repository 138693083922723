import { type BreadcrumbLevel } from './BreadcrumbLevel'

/**
 * Manages breadcrumb navigation levels.
 */
export class Breadcrumb {
  private readonly _levels: BreadcrumbLevel[] = []

  /**
   * Add a new breadcrumb level.
   * @param level - The level to add.
   */
  addLevel (level: BreadcrumbLevel): void {
    // set index level
    level.index = this._levels.length
    if (level.index === 0) {
      level.icon = 'fas fa-home'
    }
    this._levels.push(level)
  }

  /**
   * set the new url and label for the last breadcrumb level.
   */
  setPassLevel (level: BreadcrumbLevel): void {
    this._levels[this._levels.length - 2] = level
    this._levels.splice(this._levels.length - 1, 1)
  }

  /**
   * Remove all breadcrumb levels to the right of the given index.
   * @param index - The index after which levels will be removed.
   */
  removeLevels (index: number): void {
    this._levels.splice(index + 1, this._levels.length - index)
  }

  /**
   * Get all breadcrumb levels.
   * @returns An array of breadcrumb levels.
   */
  get levels (): BreadcrumbLevel[] {
    return this._levels
  }

  countLevels(): number {
    return this._levels.length
  }

  getReactContainerId(): string | null {
    if (this._levels.length > 0) {
      return this._levels[this._levels.length - 1].reactContainerId
    }
    return null
  }

  setReactContainerId(reactContainerId: string | null): void {
    if (this._levels.length > 0) {
      this._levels[this._levels.length - 1].reactContainerId = reactContainerId
    }
  }
}
