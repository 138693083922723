import { ElementArgs } from './ElementArgs'
import { ElementBase, IElementBase} from './ElementBase'

export class MaterialityElement extends ElementBase implements IElementBase{
  private _benchmarkTable: string
  constructor(args:ElementArgs){
    super(args)
    this._benchmarkTable = args.benchmarkTable ?? ""
  }

  get benchmarkTable(): string {
    return this._benchmarkTable
  }

  set benchmarkTable(benchmarkTable: string) {
    this._benchmarkTable = benchmarkTable
  }

  render(): void {
    console.log("MaterialityElement render") 
  }
}