import React, { useState } from 'react';
import { T } from "../../utils/i18n-config"
import { ThreeStatesTableRepository } from "../../repository/special-document/ThreeStatesTableRepository"
import { ThreeStatesTableElement } from "../../models/special-document/ThreeStatesTableElement"
import ViewModeBase from "./ViewModeBase";
import { ThreeStatesTableConfig } from '../tables/ThreeStatesTableConfig';
import { ThreeStatesTableView } from '../tables/ThreeStatesTableView';
import ViewModeProps from './ViewModeProps';
import { TablesOptions } from '../../models/special-document/ElementArgs';
import { Permissions } from '../../models/special-document/ElementBase';

const ThreeStatesTableViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<ThreeStatesTableElement>(iElement as ThreeStatesTableElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)

  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  const handleEditModalAccept = async (newData:TablesOptions) => {
    const threeStatesTableRepository = new ThreeStatesTableRepository()
    element.table_options = newData
    setElement(element)
    const response = await threeStatesTableRepository.updateThreeStatesTableData(
      element.args.document_id,
      element.args.engagement_id !== undefined? element.args.engagement_id : 0, 
      newData,
      'view'
    )
    if (response.success) {
      setShowConfigurationsModal(false)
      setRefreshTable(!refreshTable)
    } else {
      if (response.error_message) window.htmlHelpers?.customSwalError(T(response.error_message))
      else window.htmlHelpers?.swalError()
    }
  };

  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
  };

  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}>
        <ThreeStatesTableView 
              tableId={element.args.optionIdSelected as number}
              documentId={element.args.document_id}
              engagementId={element.args.engagement_id !== undefined ? element.args.engagement_id : 0}
              refreshFlag = {refreshTable}
            />
      </ViewModeBase>
      
      <ThreeStatesTableConfig 
        engagementId={element.args.engagement_id !== undefined ? element.args.engagement_id : 0}
        tableId={element.args.optionIdSelected as number}
        documentId={element.args.document_id}
        showModal={showConfigurationsModal}
        setShowModal={setShowConfigurationsModal}
        title={T("Three States Table Config")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}
      />
    </div>
  );
};

export default ThreeStatesTableViewMode;
