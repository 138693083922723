import { getWeb2pyAppName } from './app-config';
import i18n, { TOptions, TFunction } from 'i18next';
import Backend from 'i18next-http-backend';


i18n
  .use(Backend)
  .init({
    lng: 'es',
    fallbackLng: 'en',
    backend: {
      loadPath: `/${getWeb2pyAppName()}/static/locales/{{lng}}/{{ns}}.json`,
    }
  });

export function T(key: string, options?: TOptions): string {
  const result = i18n.t(key, options);
  return typeof result === 'string' ? result : key;
}


export function changeLanguage(lng: string): Promise<void> {
  return new Promise((resolve, reject) => {
    i18n.changeLanguage(lng).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

