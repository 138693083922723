import React,{useState, useEffect} from 'react';  
import { IndependenceFormatFormsType } from '../../repository/special-document/IndependenceFormatRepository';
import { IndependenceDocument } from '../utils/IndependenceDocument';
import { T } from '../../utils/i18n-config';

export const IndependenceFormsTable = (
    {
        engagementID,
        independenceFormatForms
    }
    :{
        engagementID: number,
        independenceFormatForms: IndependenceFormatFormsType[] | undefined
    }
) => {

    const [currentDocumentId, setCurrentDocumentId] = useState<number>(0);
    const [currentDocumentReference, setCurrentDocumentReference] = useState<string>('');
    const [searchInput, setSearchInput] = useState<string>('');
    const [copyIndependeceFormatForms, setCopyIndependeceFormatForms] = useState<IndependenceFormatFormsType[] | undefined>(independenceFormatForms);
    const [stateFilterOption, setStateFilterOption] = useState<string>('');

    useEffect(() => {
        setCopyIndependeceFormatForms(independenceFormatForms);
        setSearchInput('');
        setStateFilterOption('all');
        
    },[independenceFormatForms])

    const handleSubMenu = (menuId: number) => {

        const dropIcon = document.getElementById(`drop-icon-${menuId}`);
        if(dropIcon?.classList.contains('fa-chevron-down')){
            dropIcon.classList.remove('fa-chevron-down');
            dropIcon.classList.add('fa-chevron-up');
        } else {
            dropIcon?.classList.remove('fa-chevron-up');
            dropIcon?.classList.add('fa-chevron-down');
        }

        const submenus = document.querySelectorAll(`.submenu-${menuId}`);
        submenus.forEach(submenu => {
            if(submenu.classList.contains('d-none')){
                submenu.classList.remove('d-none');
            } else {
                submenu.classList.add('d-none');
            }
        });
    }

    const handleDocument = (documentId: number, documentReference: string) => {
        setCurrentDocumentId(documentId);
        setCurrentDocumentReference(documentReference);
    }

    

    useEffect(() => {
        if(stateFilterOption !== ''){
            const filteredForms = independenceFormatForms?.filter((form) => {
                return (
                    (
                        form.user_name.toLowerCase().includes(searchInput.toLowerCase()) || 
                        form.email.toLowerCase().includes(searchInput.toLowerCase()) || 
                        form.role.toLowerCase().includes(searchInput.toLowerCase()) || 
                        form.role_description.toLowerCase().includes(searchInput.toLowerCase())
                    ) &&
                    form.state === (
                        stateFilterOption === 'all' ? form.state :
                        stateFilterOption === 'approved' ? true : 
                        stateFilterOption === 'rejected' ? false : 
                        null
                    )
                )
            });
            setCopyIndependeceFormatForms(filteredForms);
        } else {
            setCopyIndependeceFormatForms(independenceFormatForms);
        }        
        
    },[searchInput, stateFilterOption])

    const handleSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedFilter = e.target.value;
        setStateFilterOption(selectedFilter);
    }

    const handleInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputSearch = e.target.value;
        setSearchInput(inputSearch);
    }

    return(
        <>
            {
            currentDocumentId === 0 ?
            <div className='px-6'>
                <div className='d-flex justify-content-center align-items-center mt-5 gap-2'>
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder={T("Search")+"..."}
                        value={searchInput} 
                        onChange={handleInputSearch} 
                    />
                    <div style={{
                        width: '200px',
                    }}>
                        <select 
                            className="form-select cursor-pointer"
                            value={stateFilterOption} 
                            onChange={handleSelectFilter}>
                                <option value='all'>{T("All")}</option>
                                <option value='approved'>{T("Approved")}</option>
                                <option value='rejected'>{T("Refused")}</option>
                                <option value='pending'>{T("Pending")}</option>
                        </select>
                    </div>
                    <div>
                        <i className="fas fa-search"/>
                    </div>
                </div>
                <div className="table-responsive mt-4">
                    <table className="table table-collapsed align-items-center mb-0 border-collapse">
                        <thead>
                            <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{T("Author")}</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{T("Function")}</th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{T("State")}</th>
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        
                        {
                            copyIndependeceFormatForms?.map((form) => {
                                return (
                                    <tbody key={form.user_id}>
                                        <tr className='independence-forms-table-row' onClick={() => handleSubMenu(form.user_id)}>
                                            <td>
                                                <div className="d-flex flex-column justify-content-center">
                                                    <p className="mb-0 font-weight-bold text-xs">{form.user_name}</p>
                                                    <p className="text-xs mb-0">{form.email}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <p className="text-xs font-weight-bold mb-0">{form.role}</p>
                                                <p className="text-xs mb-0">{form.role_description}</p>
                                            </td>
                                            <td className="align-middle text-center text-sm">
                                                <span 
                                                    className={`badge badge-sm ${form.state ? 'badge-primary' : form.state === false ? 'badge-danger': 'badge-info'}`}
                                                    style={{ pointerEvents: 'none' }}>
                                                        {form.state ? T("Approved") : form.state === false ? T("Refused"): T("Pending")}
                                                </span>
                                            </td>
                                            <td className="align-middle">
                                                <div className='d-flex justify-content-center'>
                                                    <i id={`drop-icon-${form.user_id}`} className="fas fa-chevron-down cursor-pointer"/>
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            form.documents.map((doc) => {
                                                return (
                                                    <tr key={doc.document_id} id={`submenu-${form.user_id}-${doc.document_id}`} className={`d-none independence-forms-table-sub-row submenu-${form.user_id}`} >
                                                        <td colSpan={2}>
                                                            <p className="text-xs mb-0">{doc.document_description}</p>
                                                        </td>
                                                        <td className="align-middle text-center text-sm">
                                                            <span 
                                                                className={`badge badge-sm ${doc.document_state ? 'badge-primary' : doc.document_state === false ? 'badge-danger': 'badge-info'}`}
                                                                style={{ pointerEvents: 'none' }}>
                                                                    {doc.document_state ? T("Approved") : doc.document_state === false ? T("Refused"): T("Pending")}
                                                            </span>
                                                        </td>
                                                        <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <i className="far fa-file-alt cursor-pointer" onClick={() => handleDocument(doc.document_id, doc.document_reference)}/>
                                                        </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                        
                                    </tbody>
                                    
                                )
                            })
                        }                         
                    
                    </table>
                </div>
            </div>
            :
            <IndependenceDocument 
            props={{
                engagementId: engagementID,
                documentId: currentDocumentId,
                setDocumentId: setCurrentDocumentId,
                documentReference: currentDocumentReference,
            }} />
           
            }
        </>
    )
}