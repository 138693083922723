import { T } from "../../utils/i18n-config"
import { ElementArgs} from "./ElementArgs";
import { IElementBase, ElementBase } from "./ElementBase";

export class FindingsEvaluationElement extends ElementBase implements IElementBase {
  
  private _high_evaluation_text: string;
  private _medium_evaluation_text: string;
  private _low_evaluation_text: string;
  private _optional_evaluation_text: string;
  private _editable: boolean;

  constructor(args:ElementArgs) {
    super(args);
    this._high_evaluation_text = args.high_evaluation_text ?? T("Add text here")
    this._medium_evaluation_text = args.medium_evaluation_text ?? T("Add text here")
    this._low_evaluation_text = args.low_evaluation_text ?? T("Add text here")
    this._optional_evaluation_text = args.optional_evaluation_text ?? T("Add text here")
    this._editable = args.editable ?? false
  }
  
  get high_evaluation_text(): string {
    return this._high_evaluation_text;
  }
  get medium_evaluation_text(): string {
    return this._medium_evaluation_text;
  }
  get low_evaluation_text(): string {
    return this._low_evaluation_text;
  }
  get optional_evaluation_text(): string {
    return this._optional_evaluation_text;
  }
  get editable(): boolean {
    return this._editable;
  }
 
  set high_evaluation_text(text: string) {
    this._high_evaluation_text = text;
    this.args.high_evaluation_text = text;
  }
  set medium_evaluation_text(text: string) {
    this._medium_evaluation_text = text;
    this.args.medium_evaluation_text = text;
  }
  set low_evaluation_text(text: string) {
    this._low_evaluation_text = text;
    this.args.low_evaluation_text = text;
  }
  set optional_evaluation_text(text: string) {
    this._optional_evaluation_text = text;
    this.args.optional_evaluation_text = text;
  }
  set editable(editable: boolean) {
    this._editable = editable;
    this.args.editable = editable;
  }
  render(): void {
    return;
  }
}