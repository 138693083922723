import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { VarianceAnalysisNotesElement } from "../../models/special-document/VarianceAnalysisNotesElement"
import EditModeBase from "./EditModeBase";
import EditModeProps from './EditModeProps';
import ModalBase from '../modals/ModalBase';
import { Permissions } from '../../models/special-document/ElementBase';

/**
  * Component to display variance analysis notes in edit mode.
  * @param {EditModeProps} iElement - The variance analysis element.
  */


const VarianceAnalysisNotesEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<VarianceAnalysisNotesElement>(iElement as VarianceAnalysisNotesElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [ledgerAccountID, setLedgerAccountID] = useState(0)
  const [ledgerAccountCode, setLedgerAccountCode] = useState("")
  const [ledgerAccountName, setLedgerAccountName] = useState("")

  useEffect(() => {
    setLedgerAccountID(element.ledger_account_id)
    setLedgerAccountCode(element.ledger_account_code)
    setLedgerAccountName(element.ledger_account_name)
  }, [element])
  
  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  }

  const handleConfigurationsModalAccept = () => {
    setShowConfigurationsModal(false);
  }
  
  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={element.permissions as Permissions}>
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h5>{T("Variance Analysis Notes")}</h5>
              { ledgerAccountID > 0 ?
                <h6>
                  {ledgerAccountCode} - {ledgerAccountName}
                </h6>
                :
                <h6>{T("The notes corresponding to the account will appear here")}</h6>
              }
            </div>
          </div>
      </EditModeBase>
      <ModalBase 
        showModal={showConfigurationsModal}
        title={T("Variance Analysis Notes")}
        size="xl">
            <div className="modal-body">
              <div>
                <h6>{T("Variance Analysis Notes")}</h6>
              </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn bg-gradient-primary" onClick={handleConfigurationsModalAccept}>{T("Accept")}</button>
            </div>
      </ModalBase>
    </div>
  )

}

export default VarianceAnalysisNotesEditMode;