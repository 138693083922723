
import React, { useEffect, useState } from 'react';
import { ThreeStatesTableRepository } from '../../repository/special-document/ThreeStatesTableRepository';
import { TablesOptions} from '../../models/special-document/ElementArgs';
import { T } from "../../utils/i18n-config"
import { BasicResponse } from '../../repository/special-document/ThreeStatesTableRepository';
import { modifyThreeStatesTableName } from '../../utils/utilities';
import { Constants } from '../../utils/Constants';

interface TableBase {
    tableId: number;
    engagementId: number | null;
    documentId: number;
    refreshFlag: boolean;
}

export const ThreeStatesTableView: React.FC<TableBase> = ({tableId, engagementId, documentId, refreshFlag }) => {
    const [tableData, setTableData] = useState<TablesOptions>();

    useEffect(() => {
        const threeStatesTableRepository = new ThreeStatesTableRepository()
        threeStatesTableRepository.getTableData(documentId, engagementId, tableId, 'view').then((data) => {
            if((data as BasicResponse ).success === false) {
                data = data as BasicResponse
                const errorMsg = data.error_message? data.error_message : "Error"
                const nullData = {
                    "id" : null,
                    "name" : errorMsg,
                    "questions" : null,
                    "answer_options" : null,
                    "reference_engagement_id" : 0

                } as TablesOptions
                setTableData(nullData)
                return
            }
            data = data as TablesOptions
            data.name = modifyThreeStatesTableName(data.name as string)
            setTableData(data)  
        }).catch((error) => {
            console.error('There has been a problem with your fetch operation:', error)
        })
    },[refreshFlag, ]);

    return (
        <div className={`${Constants.CONTENT_CLASS} d-flex w-full justify-content-center p-4`}>
            <table className='custom-table table-bordered w-75'>
                <thead>
                    <tr>
                        <th colSpan={3}>
                            {T(tableData?.name as string)}
                        </th>
                    </tr>
                    <tr>
                        <th>{T("Questions")}</th>
                        <th>{T("Answers")}</th>
                        <th>{T("Comments")}</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData?.questions?.map((option) => {
                        return(
                            <tr className='row-hover' key={`question-${option.id}`}>
                                <td className='p-2'>
                                    <div dangerouslySetInnerHTML={{__html: option.question}}></div>
                                </td>
                                <td className='p-2'>{T(option.answer)}</td>
                                <td className='p-2'>{option.comments}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};