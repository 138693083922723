import React, { useEffect , useState} from 'react';
import { TablesOptions} from '../../models/special-document/ElementArgs';

const CustomTextArea = ({optionId, text, tableData, setTableData}: {optionId: number, text: string, tableData: TablesOptions, setTableData: any}) => {
  const [comments, setComments] = useState<string>(text)
  const [textAreaHeight, setTextAreaHeight] = useState<number>()

  useEffect(() => {
    const questionContainer = document.getElementById(`question-${optionId}`)
    if (questionContainer) {
      const height = questionContainer.clientHeight
      setTextAreaHeight(height)
    }
  },[optionId])


  const handleCommentsInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newTableData = JSON.parse(JSON.stringify(tableData)) as TablesOptions
    const question = newTableData?.questions?.find((question) => question.id === optionId)
    if (question) {
      question.comments = e.target.value
    }
    setTableData(newTableData)
    setComments(e.target.value)
  }
  return(
    <textarea
      className='custom-text-area'
      value={comments}
      onChange={(e) => {handleCommentsInputChange(e)}}
      style={{
        height: `${textAreaHeight}px`
      }}
    />
  )
}

export default CustomTextArea;