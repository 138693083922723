import { getUrl } from '@/utils/app-config'
import { fetchAPI } from '@/services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '@/models/special-document/ElementArgs'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface RegistersMemorandumComplexityTechnologyFirstStageArgs {
  id: number
  title_name: string | null
  subtitle_name: string | null
  question: string | null
  strategy: string | null
  engagement_id: number
  transaction_flow_id: number
  value_yes_no: number | null
  value_description: string | null
  is_test_controls: boolean | null
  was_changed: boolean
  order_in_stage: number
}


export class MemorandumComplexityTechnologyControlsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  /**
  * Creates an instance of MemorandumComplexityTechnologyControlsRepository.
  * @param fetcher - A function for making fetch requests, bound to the window context by default.
  */
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
  * Retrieves the memorandum technology environment data by stage.
  * @param engagementId - The engagement identifier (nullable or undefined).
  * @param elementId - The element identifier.
  * @param stage - The stage number.
  * @returns A promise resolving to an object with a success flag and data array.
  */
  async getMemorandumComplexityTechnologyEnvironmentByStage(engagementId: number | null | undefined, elementId: number, stage: number): Promise<{ success: boolean, data: RegistersMemorandumComplexityTechnologyFirstStageArgs[] }> {
    const apiUrl = getUrl('memorandum_complexity_technology_environment_api/get_memorandum_complexity_technology_environment_by_stage/' + engagementId + '/' + elementId + '/' + stage)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: RegistersMemorandumComplexityTechnologyFirstStageArgs[] } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Updates the memorandum complexity technology environment by stage.
   * @param engagementId - The engagement identifier (nullable or undefined).
   * @param documentId - The document identifier.
   * @param elementId - The element identifier.
   * @param data_changes - An object containing changes to be applied.
   * @returns A promise resolving to a BasicResponse object.
   */
  async updateMemorandumComplexityTechnologyEnvironmentByStage(engagementId: number | null | undefined, documentId: number, elementId: number, data_changes: { [key: number]: Partial<RegistersMemorandumComplexityTechnologyFirstStageArgs> }): Promise<BasicResponse> {
    const apiUrl = getUrl('memorandum_complexity_technology_environment_api/update_memorandum_complexity_technology_environment_by_stage/' + engagementId + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ "changes": data_changes })
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Changes the current status conclusion.
   * @param engagementId - The engagement identifier (nullable or undefined).
   * @param documentId - The document identifier.
   * @param elementId - The element identifier.
   * @param controlId - The control identifier.
   * @param transactionFlowId - The transaction flow identifier.
   * @returns A promise resolving to a BasicResponse object.
   */
  async changeCurrentStatusConclusion(engagementId: number | null | undefined, documentId: number, elementId: number, controlId: number, transactionFlowId: number): Promise<BasicResponse> {
    const apiUrl = getUrl('memorandum_complexity_technology_environment_api/change_current_status_conclusion/' + engagementId + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ "control_id": controlId, "transaction_flow_id": transactionFlowId })
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Deletes memorandum complexity technology transaction flows.
   * @param engagementId - The engagement identifier (nullable or undefined).
   * @param documentId - The document identifier.
   * @param elementId - The element identifier.
   * @param itemId - The item identifier.
   * @param transactionFlowId - The transaction flow identifier.
   * @returns A promise resolving to a BasicResponse object.
   */
  async deleteMemorandumComplexityTransactionFlows(engagementId: number | null | undefined, documentId: number, elementId: number, itemId: number, transactionFlowId: number): Promise<BasicResponse> {
    const apiUrl = getUrl('memorandum_complexity_technology_environment_api/delete_complexity_technology_transaction_flows/' + engagementId + '/' + documentId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ "control_id": itemId, "transaction_flow_id": transactionFlowId })
        }
      )

      if (!response.ok) {
        throw new Error(`Network response was not ok, status: ${response.status}`)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Validates the controls complexity technology.
   * @param engagementId - The engagement identifier (nullable or undefined).
   * @param elementId - The element identifier.
   * @returns A promise resolving to an object with success flag, reference, and is_finished status.
   */
  async getValidationControlsComplexityTechnology(engagementId: number | null | undefined, elementId: number): Promise<{ success: boolean, reference: string, is_finished: boolean }> {
    const apiUrl = getUrl('memorandum_complexity_technology_environment_api/validate_controls_complexity_technology/' + engagementId + '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, reference: string, is_finished: boolean } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Function to get the validation controls complexity technology
   * @param engagementId - The engagement ID
   * @param elementId - The element ID
   * @returns A promise that resolves to the response data
   */
  async validateRecordsCreation(engagementId: number|null | undefined, documentId: number, reference: string | undefined): Promise<{can_create: boolean, data: RegistersMemorandumComplexityTechnologyFirstStageArgs[]}> {
    const apiUrl = getUrl('substantive_test_api/validate_records_to_create_difference_reconciling_items/' + engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {can_create: boolean, data: RegistersMemorandumComplexityTechnologyFirstStageArgs[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Function to get the validation controls complexity technology
   * @param engagementId - The engagement ID
   * @param elementId - The element ID
   * @returns A promise that resolves to the response data
   */
  async deleteDifferenceReconcilingItems(engagement_id: number|null | undefined, documentId: number, reference: string | undefined, registerId: number): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/delete_substantive_test_difference_reconciling_items/' + engagement_id + '/' + documentId + '/' + reference + '/' + registerId)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Function to get the validation controls complexity technology
   * @param engagementId - The engagement ID
   * @param elementId - The element ID
   * @returns A promise that resolves to the response data
   */
  async markErrorDifferenceReconcilingItems(engagementId: number|null | undefined, documentId: number,registerId: number): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/mark_error_difference_reconciling_items/' + engagementId + '/' + documentId + '/' + registerId)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

}
