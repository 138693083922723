import { ConditionalParagraphOption } from '../../models/special-document/ConditionalParagraphOption'

export class ConditionalParagraphOptionFactory {
    
    constructor() {}

    createInstanceConditionalParagraphOption(value: string, message: string, condition?: string): ConditionalParagraphOption {
        return new ConditionalParagraphOption(message, value, condition)
        
    }
}