import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { type Permissions } from "../../models/special-document/ElementBase"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { RedirectionButtonElement } from "../../models/special-document/RedirectionButtonElement"
import ViewModeProps from './ViewModeProps'
import ViewModeBase from "./ViewModeBase"

const RedirectionButtonViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<RedirectionButtonElement>(iElement as RedirectionButtonElement).current
  const [url, setUrl] = useState(element.url)
  const title = element.title

  useEffect(() => {
    /**
     * Fetch the redirection URL from the API and set it to the state
     */
    async function fetchRedirectionUrl() {
      const elementRepository = new ElementRepository()
      const redirectUrl = await elementRepository.getRedirectUrl(
        element.engagement_id, element.documentId, element.id, element.context
      )
      setUrl(redirectUrl)
    }

    fetchRedirectionUrl()
  }, [])

  /**
   * Redirects the user to the document/formulary
   * If the URL is empty, it shows an error message
   */
  function handleRedirection() {
    if (url !== "") return window.menuService?.breadcrumbAddLevel(url, title)
    return window.htmlHelpers?.customSwalError(T("The document/formulary does not exist yet"))
  }

  return (
    <ViewModeBase
      isEditable={false}
      permissions={element.permissions as Permissions}
    >
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleRedirection}
      >
        {title}
      </button>
    </ViewModeBase>
  );
};

export default RedirectionButtonViewMode;
