import React, { useState, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { Constants } from '../../utils/Constants'
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from '../../repository/special-document/ElementRepository'
import { InformationExtractorElement } from '../../models/special-document/InformationExtractorElement'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from './EditModeProps'
import EditModeBase from './EditModeBase'
import TagInput from "../commons/TagInput"

const InformationExtractorEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<InformationExtractorElement>(iElement as InformationExtractorElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [informationList, setInformationList] = useState(element.informationList)

  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastInformationList = element.informationList
    element.informationList = informationList

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.informationList = lastInformationList
      setInformationList(lastInformationList)
      window.htmlHelpers?.swalError()
    }
  }

  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
  }

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={true}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <div className="card card-body bg-light">
          <div className="form-group mb-0">
            <label className="fs-6">{T("Data List to Extract")}</label>
            {
              informationList.length === 0
                ? (<p>{T("No data list to extract")}</p>)
                : (
                  <ul className="d-flex flex-wrap gap-2 ps-0">
                    {informationList.map((item, index) => (
                      <li key={index} className="list-group-item px-2 border border-secondary rounded-pill">{item}</li>
                    ))}
                  </ul>
                )
            }
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div className="form-group w-50">
          <label htmlFor="item-reference" className="form-label fs-6">{T("Data List to Extract")}</label>
          <TagInput
            defaultValue={informationList}
            placeholder={T("Add an option and press Enter")}
            onChange={(values) => setInformationList(values)}
          />
        </div>
      </ModalWithButtons>
    </>
  );
}

export default InformationExtractorEditMode;