import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'

interface IHtml2PdfRepositoryResponse {
    status: string;
    message: string;
    complete_error: string;
    base64_content: string;
}



export class Html2PdfRepository {
    private _jsonContentType = {
        'Content-Type': 'application/json'
    }
    constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

    /**
     * Converts HTML content to a PDF
     * @param htmlContent The HTML content to convert
     * @returns The response from the PDF conversion API
     */
    async convertToPdf(htmlContent: string): Promise<IHtml2PdfRepositoryResponse> {
        const apiUrl = getUrl('html_to_pdf_api/convert')
        try {
            const response = await this.fetcher(
                apiUrl,
                {
                    method: 'POST',
                    headers: this._jsonContentType,
                    body: JSON.stringify({ html_content: htmlContent })
                }
            )

            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            if (response.status !== 200) {
                throw new Error('Status is : ' + response.status)
            }

            return await response.json() as IHtml2PdfRepositoryResponse;
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error)
            throw error
        }
    }
}