import { getUrl } from '../utils/app-config'
import { fetchAPI } from '../services/api_services/fetchAPI'
import { T } from '../utils/i18n-config'

export class AccountFlowchartRepository {
  private readonly updateApiUrl: string = getUrl('trial_balance_upload_api/create_or_update_flowcharts');

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { } 

  assignTransactionFlowToAccount(event: Event, ledger_account_id: string) {
    const transactionFlowId = (event.target as HTMLInputElement).value;

    if (transactionFlowId === "") {
        return;
    }
    
    this.updateOrAssignTransactionFlow(ledger_account_id, transactionFlowId)
    .then(status => {
        if (!status) {
            (event.target as HTMLInputElement).value = "";
            window.htmlHelpers!.showToast('danger', T('There was an error while adding the transaction flow'), T('Ups!'));
        }
    })
  }

  async updateOrAssignTransactionFlow(ledgerAccountId: string, transactionFlowId: string): Promise<boolean> {
    try {
        const response = await this.fetcher(this.updateApiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ledger_account_id: ledgerAccountId,
                transaction_flow_id: transactionFlowId
            })
        });

        if (!response.ok) {
            throw new Error("HTTP error " + response.status);
        }

        const data = await response.json();
        if (data['success'] === false) {
            throw new Error(data['message']);
        }

        return true;
    } catch (error) {
        console.error("Error:", error);
        return false;
    }
  }
}

