import React from 'react';
import ReactDOM from 'react-dom';
import Notes from '../components/notes/Notes';


interface NotesArgs {
  containerId: string;
  engagementId: number;
  reference: string;
  userId: number;
  readPermission: boolean;
  createPermission: boolean;
  updatePermission: boolean;
  deletePermission: boolean;
  completePermission: boolean;
  approvePermission: boolean;
  refusePermission: boolean;
  replyPermission: boolean;
}


export function notesRenderer(args: NotesArgs) {
  ReactDOM.render(
  <Notes 
    engagementId={args.engagementId}
    reference={args.reference}
    userId={args.userId}
    readPermission={args.readPermission}
    createPermission={args.createPermission}
    updatePermission={args.updatePermission}
    deletePermission={args.deletePermission}    
    completePermission={args.completePermission}
    approvePermission={args.approvePermission}
    refusePermission={args.refusePermission}
    replyPermission={args.replyPermission}
  />, 
  document.getElementById(args.containerId)
  );
  window.menuService?.breadcrumbSetReactContainerId(args.containerId);
}
