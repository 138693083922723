import React, { useState, useRef, useId, useEffect } from 'react'
import { T } from '@/utils/i18n-config'
import { Permissions } from '@/models/special-document/ElementBase'
import { CommitmentQuestion } from '@/models/special-document/ElementArgs'
import { ElementRepository } from '@/repository/special-document/ElementRepository'
import { CommitmentFormRepository } from "@/repository/special-document/CommitmentFormRepository"
import { CommitmentFormElement } from '@/models/special-document/CommitmentFormElement'
import ToggleSummernoteParagraph from '../commons/toogle-summernote'
import ToggleDropzone from '../commons/toggle-dropzone'
import ViewModeProps from './ViewModeProps'
import ViewModeBase from './ViewModeBase'

const CommitmentFormViewMode = ({ iElement }: ViewModeProps) => {
  const element = useRef<CommitmentFormElement>(iElement as CommitmentFormElement).current
  const [questions, setQuestions] = useState<CommitmentQuestion[]>(element.questions)

  /**
   * Update the questions in the element
   */
  async function updateQuestion(questions: CommitmentQuestion[]) {
    const elementRepository = new ElementRepository()
    element.questions = questions
    const success = await elementRepository.saveElement("edition", element.args)
    if (!success) return window.htmlHelpers?.swalError()
  }

  /**
   * Handle the change of a question
   * @param id The id of the question
   * @param field The field to change
   * @param value The new value of the field
   */
  async function handleQuestionChange(id: number, field: string, value: string) {
    const newQuestions = questions.map(q => {
      return (q.id === id) ? (
        { ...q, answers: q.answers ? { ...q.answers, [field]: value } : { [field]: value } }
      ) : q
    })
    setQuestions(newQuestions as CommitmentQuestion[])
    await updateQuestion(newQuestions as CommitmentQuestion[])
  }

  /**
   * Get the compared value of a question by its id
   * @param id The id of the question
   * @param value The value to compare
   * @returns The compared value
   */
  function getComparedValueById(id: number, value: string) {
    const questionAnswer = questions.find(q => q.id === id)?.answers?.mainInput ?? ""
    return questionAnswer === value
  }

  return (
    <>
      <ViewModeBase
        isEditable={false}
        handleEdit={() => { }}
        permissions={element.permissions as Permissions}
      >
        <div className="mb-5" style={{ marginTop: "-1.5rem"}}>
          {questions.map((question) => (
            <React.Fragment key={question.id}>
              {question.type === "title" && <h5 className="mt-5">{T(question.text)}</h5>}
              {question.type === "sub-header" && !question.dependsOn && (
                <strong className="d-block mt-4">{T(question.text)}</strong>
              )}
              {question.type === "sub-header" && question.dependsOn && getComparedValueById(question.dependsOn, "true") && (
                <strong className="d-block mt-4">{T(question.text)}</strong>
              )}
              {question.type === "question" && !question.dependsOn && (
                <QuestionOption
                  element={element}
                  question={question}
                  handleQuestionChange={handleQuestionChange}
                />
              )}
              {question.type === "question" && question.dependsOn && getComparedValueById(question.dependsOn, "true") && (
                <QuestionOption
                  element={element}
                  question={question}
                  handleQuestionChange={handleQuestionChange}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </ViewModeBase>
    </>
  );
}


interface QuestionOptionProps {
  element: CommitmentFormElement
  question: CommitmentQuestion
  handleQuestionChange: (id: number, field: string, value: string) => void
}

/**
 * Component for rendering a question with options in the Commitment Form
 * @param element The element to render
 * @param question The question to render
 * @param handleQuestionChange The function to handle the change of the question
 * @returns The Question Option component
 */
function QuestionOption({ element, question, handleQuestionChange }: QuestionOptionProps) {
  const [externalContent, setExternalContent] = useState<string>("")

  /**
   * Retrieve the external information for the question
   */
  async function retrieveExternalInformation() {
    const commitmentRepository = new CommitmentFormRepository()
    const { success, content } = await commitmentRepository.getExternalContent(element.id, element.engagement_id, question.reference ?? "")
    if (success) setExternalContent(content)
  }

  useEffect(() => {
    if (question.reference) retrieveExternalInformation()
  }, [question.reference])

  return (
    <div className="d-flex flex-column gap-1 mt-4">
      <p className="m-0">{T(question.text)}</p>
      {question.reference && (
        <span className="mb-2 fw-bold" style={{ marginTop: "-0.5rem"}}>{externalContent}</span>
      )}
      <YesNoOption
        defaultValue={question.answers?.mainInput ?? ""}
        onChange={(value) => handleQuestionChange(question.id, "mainInput", value)}
      />
      {question.hasTextbox && (
        <ToggleSummernoteParagraph
          initialText={question.answers?.textbox ?? ""}
          onChange={(text) => handleQuestionChange(question.id, "textbox", text)}
        />
      )}
      {question.hasAttachment && (
        <ToggleDropzone
          elementId={question.id}
          attachmentName={question.answers?.attachment ?? ""}
          onUpload={(attachmentName) => handleQuestionChange(question.id, "attachment", attachmentName)}
        />
      )}
    </div>
  )
}


interface YesNoOptionProps {
  defaultValue: string
  onChange: (value: string) => void
}

/**
 * Component for rendering a Yes/No option in the Commitment Form
 * @param defaultValue The default value of the radio button
 * @param onChange The function to handle the change of the radio button
 */
function YesNoOption({ defaultValue, onChange }: YesNoOptionProps) {
  const [value, setValue] = useState(defaultValue)
  const optionId = useId()

  /**
   * Handle the change of the radio button
   * @param newValue The new value of the radio button
   */
  function handleOnChange(newValue: string) {
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <div className="d-flex align-items-center gap-3">
      <div className="form-check">
        <input
          type="radio"
          name={`${optionId}`}
          id={`${optionId}-yes`}
          className="form-check-input"
          value="true"
          checked={value === "true"}
          onChange={() => handleOnChange("true")}
        />
        <label className="form-check-label" htmlFor={`${optionId}-yes`}>
          {T("Yes")}
        </label>
      </div>
      <div className="form-check">
        <input
          type="radio"
          name={`${optionId}`}
          id={`${optionId}-no`}
          className="form-check-input"
          value="false"
          checked={value === "false"}
          onChange={() => handleOnChange("false")}
        />
        <label className="form-check-label" htmlFor={`${optionId}-no`}>
          {T("No")}
        </label>
      </div>
    </div>
  )
}

export default CommitmentFormViewMode;