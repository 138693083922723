import { ElementArgs } from "../../models/special-document/ElementArgs"
import { IElementBase } from "../../models/special-document/ElementBase"
import { ElementTypeFactory } from "./ElementTypeFactory"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ConditionalParagraphOptionFactory } from "./ConditionalParagraphOptionFactory"
import { IConditionalParagraphOption } from "../../models/special-document/IConditionalParagraphOption"


export class ElementFactory {
  private _elementRepository: ElementRepository
  private _elementTypeFactory: ElementTypeFactory
  private _conditionalParagraphOptionFactory: ConditionalParagraphOptionFactory
  
  constructor(elementRepository: ElementRepository = new ElementRepository()){
    this._elementTypeFactory = new ElementTypeFactory()
    this._elementRepository = elementRepository
    this._conditionalParagraphOptionFactory = new ConditionalParagraphOptionFactory()
  }

  async createClassByType(type: number, documentId: number, engagementId?: number|null): Promise<IElementBase> {
    const classType = this._elementTypeFactory.getElementType(type)
    if (classType) {
      const args: ElementArgs = {
        id: 0,
        type: classType.type,
        document_id: documentId,
        engagement_id: engagementId,
        state: "open",
      }
      const registerId = await this._elementRepository.createElement(args)
      args.id = registerId
      return new classType.classes(args)
    }
    
    throw new Error("ID not found")
  }

  loadClassByType(args: ElementArgs): IElementBase {
    const classType = this._elementTypeFactory.getElementType(args.type)
    if (classType) {
      return new classType.classes(args)
    }
    throw new Error(`Element type ${args.type} not found`)
  }

  async loadClassessByDocumentId(documentId: number, renderingMode:string, engagementId: number|null): Promise<IElementBase[]> {
    const iElements = await this._elementRepository.getElementsByDocumentSpecialId(engagementId, documentId, renderingMode)
    const elements: IElementBase[] = []
    for (const iElement of iElements) {
      
      if (iElement.options){
        if (typeof iElement.options === 'string'){
          const parsed = JSON.parse(iElement.options)
          const optionsArray: IConditionalParagraphOption[] = typeof parsed === 'string' ? JSON.parse(parsed) : parsed
          const conditionalParagraphOptions = optionsArray.map(obj => this._conditionalParagraphOptionFactory.createInstanceConditionalParagraphOption(obj.value, obj.message, obj.condition))
          iElement.options = conditionalParagraphOptions
        }
      }
      const element = this.loadClassByType(iElement)
      elements.push(element)
    }
    return elements
  }

}