import { TransactionFlowStrategiesArgs, Strategy } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

/**
 * TransactionFlowStrategiesElement handles the configuration of transaction flow strategies in a special document.
 */
export class TransactionFlowStrategiesElement extends ElementBase implements IElementBase {
  private _strategies: Strategy[]

  /**
   * Constructs a new TransactionFlowStrategiesElement using the provided arguments.
   * @param {TransactionFlowStrategiesArgs} args - The parameters required to initialize the element.
   */
  constructor(args: TransactionFlowStrategiesArgs) {
    super(args)
    this._strategies = args.strategies || []
  }

  get strategies(): Strategy[] {
    return this._strategies
  }

  get args(): TransactionFlowStrategiesArgs {
    return {
      ...super.args,
      strategies: this._strategies,
    }
  }

  set strategies(value: Strategy[]) {
    this._strategies = value
  }

  render(): void {}
}