import { getUrl } from '../utils/app-config'
import { fetchAPI } from '../services/api_services/fetchAPI'


export class EngagementMaterialityReportRepository {
  private readonly apiUrl: string = getUrl('engagement_materiality_report_api/save')

  /**
   * The constructor.
   * @param fetcher - The fetcher.
   */
  constructor (private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) {}

  async saveData (engagementID: number, considerations: string, conclusions: string): Promise<boolean> {
    try {

      const response = await this.fetcher(`${this.apiUrl}/${engagementID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conclusions,
          considerations,
          engagementID
        })
      })

      if (!response.ok) {
        return false
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      if (!data.success) {
        return false
      }
      
      return true
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }
}
